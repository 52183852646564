import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { IReference } from "../../../../components/utils";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import { isValidUrl } from "../../../../components/common/CommonFunctions";
import CardEditHeader from "../../../../components/common/CardEditHeader";
import ReactQuill from "react-quill";
import { quillModules } from "../../../../App";

interface CourseProps {
  sectionName: string;
  courseData: IReference;
  coursesList: IReference[];
  setCoursesList: Dispatch<SetStateAction<IReference[]>>;
  sectionLabel: string;
  index: number;
  handleDeleteEmptySection: (sectionName: string) => void;
}

const CustomSectionEdit: React.FC<CourseProps> = ({
  sectionName,
  courseData,
  coursesList,
  setCoursesList,
  sectionLabel,
  index,
  handleDeleteEmptySection,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const [addLink, setAddLink] = useState<boolean>(false);
  //formData handles local form state
  const [formData, setFormData] = useState<IReference>(courseData);
  const [errors, setErrors] = useState({
    organization: "",
    url: "",
  });
  const handleChange = (newValue: any, key: string) => {
    setFormData({
      ...formData,
      [key]: newValue,
    });
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  const handleCancel = () => {
    const updatedCourses = !!formData?.isNewEntry
      ? coursesList.slice(0, -1)
      : coursesList;
    if (!!formData?.isNewEntry) {
      setCoursesList(updatedCourses);
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (courEle?.organization === courseData.organization) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }

    //  Below Method remove +Add More when there is no element present
    if (!updatedCourses?.[0]) {
      handleDeleteEmptySection(sectionName);
    }
  };

  const handleSave = () => {
    if (!formData?.organization) {
      setErrors((prev) => ({
        ...prev,
        organization: "Organization is required",
      }));
    }
    if (!isValidUrl(addLink, formData?.link ?? "")) {
      setErrors((prev) => ({
        ...prev,
        url: "Invalid URL",
      }));
    }
    if (formData?.organization) {
      //checking if link is present then It must contain the valid url
      if (!isValidUrl(addLink, formData?.link ?? "")) {
        setErrors((prev) => ({
          ...prev,
          url: "Invalid URL",
        }));
      } else {
        //perform new course entry
        if (formData?.isNewEntry) {
          /**
           * New Entry
           */
          delete formData?.isNewEntry;
          delete formData?.index;
          dispatch(
            setExtractedData({
              ...extractedData,
              customSections: {
                ...extractedData.customSections,
                [sectionName]: {
                  label: sectionLabel,
                  value: [
                    ...(extractedData?.customSections?.[sectionName]?.value ??
                      []),
                    { ...formData, isEdit: false },
                  ],
                },
              },
            })
          );
        } else {
          const updatedCourses = coursesList?.map((course) => {
            if (course?.index === formData?.index) {
              delete formData?.index;
              return { ...formData, isEdit: false };
            }
            delete course?.index;
            return course;
          });
          dispatch(
            setExtractedData({
              ...extractedData,
              customSections: {
                ...extractedData?.customSections,
                [sectionName]: {
                  label: sectionLabel,
                  value: updatedCourses,
                },
              },
            })
          );
        }
        //both cases remove error
        setErrors({
          organization: "",
          url: "",
        });
      }
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
          margin:
            index === 0 && courseData?.isNewEntry
              ? "0 0 1rem 0 !important"
              : "1rem 0",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.organization,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="input-box-container">
          <InputLabel htmlFor="company">Title*</InputLabel>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Eg: Title"
            value={formData?.organization}
            onChange={(e) => handleChange(e.target.value, "organization")}
            error={!!errors.organization}
            helperText={errors.organization ? errors.organization : ""}
          />
        </Box>

        <Box className="input-box-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <InputLabel htmlFor="address">Description</InputLabel>
          </Box>
          <Box className="quill-container">
            <ReactQuill
              value={formData?.courseDescription}
              onChange={(e: any) => handleChange(e, "courseDescription")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>

        {addLink && (
          <Box className="input-box-container">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <InputLabel htmlFor="link">Link*</InputLabel>
              <Typography
                className="delete-link-section"
                onClick={() => setAddLink(false)}
              >
                Delete
              </Typography>
            </Box>
            <TextField
              fullWidth
              id="link"
              name="link"
              placeholder="Eg. www.google.com"
              value={formData?.link}
              onChange={(e) => handleChange(e.target.value, "link")}
              error={!!errors.url}
              helperText={errors.url ? errors.url : ""}
            />
          </Box>
        )}

        <Box className="section-action">
          <Box className="link-container">
            {!addLink && (
              <Typography
                className="add-link-text"
                onClick={() => setAddLink(true)}
              >
                + Add Link
              </Typography>
            )}
          </Box>
          {
            <Box className="button-container">
              {/* courseData?.isNewEntry &&  */}
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Add
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default CustomSectionEdit;
