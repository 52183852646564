import {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
  Svg,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5032_43202)">
        <rect width="596" height="842" fill="white" />
        <path
          opacity="0.4"
          d="M552.5 104.6V146.3L588.6 125.5V83.8L552.5 104.6Z"
          fill="#CC81EA"
        />
        <path
          d="M481.1 104.1L445 83.3L514.5 43.2L625.6 -21V20.7L481.1 104.1Z"
          fill="url(#paint0_linear_5032_43202)"
        />
        <path
          opacity="0.4"
          d="M445 125V166.7L481.1 145.9V104.1L445 125Z"
          fill="#CC81EA"
        />
        <path
          d="M552.501 62.9V104.6L516.301 83.8V42.1L552.501 62.9Z"
          fill="#4442FF"
        />
        <path
          d="M589.5 166.7L592.2 165.1L661.7 125L625.6 104.1L553.4 145.9L481.1 104.1V145.9L517.2 166.7L481.1 187.6V229.3L553.4 187.6L625.6 229.3L661.7 208.4L592.2 168.3L589.5 166.7Z"
          fill="url(#paint1_linear_5032_43202)"
        />
        <path
          opacity="0.4"
          d="M35.5 761.6V803.3L71.6 782.5V740.8L35.5 761.6Z"
          fill="#CC81EA"
        />
        <path
          d="M-35.9 761.1L-72 740.3L-2.5 700.2L108.6 636V677.7L-35.9 761.1Z"
          fill="url(#paint2_linear_5032_43202)"
        />
        <path
          d="M35.5008 719.9V761.6L-0.699219 740.8V699.1L35.5008 719.9Z"
          fill="#4442FF"
        />
        <path
          d="M72.4996 823.7L75.1996 822.1L144.7 782L108.6 761.1L36.3996 802.9L-35.9004 761.1V802.9L0.199615 823.7L-35.9004 844.6V886.3L36.3996 844.6L108.6 886.3L144.7 865.4L75.1996 825.3L72.4996 823.7Z"
          fill="url(#paint3_linear_5032_43202)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5032_43202"
          x1="75219.2"
          y1="-6765.66"
          x2="107838"
          y2="-6765.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#85EAFF" />
          <stop offset="0.5" stop-color="#C25FE2" />
          <stop offset="1" stop-color="#4442FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5032_43202"
          x1="81775.5"
          y1="9013.61"
          x2="114394"
          y2="9013.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#85EAFF" />
          <stop offset="1" stop-color="#4442FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5032_43202"
          x1="74702.2"
          y1="-6108.66"
          x2="107321"
          y2="-6108.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#85EAFF" />
          <stop offset="0.5" stop-color="#C25FE2" />
          <stop offset="1" stop-color="#4442FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5032_43202"
          x1="81258.5"
          y1="9670.61"
          x2="113877"
          y2="9670.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#85EAFF" />
          <stop offset="1" stop-color="#4442FF" />
        </linearGradient>
        <clipPath id="clip0_5032_43202">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5032_43202)">
        <Rect width="596" height="842" fill="white" />
        <Path
          opacity="0.4"
          d="M552.5 104.6V146.3L588.6 125.5V83.8L552.5 104.6Z"
          fill={props?.c1 || "#CC81EA"}
        />
        <Path
          d="M481.1 104.1L445 83.3L514.5 43.2L625.6 -21V20.7L481.1 104.1Z"
          fill="url(#paint0_linear_5032_43202)"
        />
        <Path
          opacity="0.4"
          d="M445 125V166.7L481.1 145.9V104.1L445 125Z"
          fill={props?.c1 || "#CC81EA"}
        />
        <Path
          d="M552.501 62.9V104.6L516.301 83.8V42.1L552.501 62.9Z"
          fill={props?.c2 || "#4442FF"}
        />
        <Path
          d="M589.5 166.7L592.2 165.1L661.7 125L625.6 104.1L553.4 145.9L481.1 104.1V145.9L517.2 166.7L481.1 187.6V229.3L553.4 187.6L625.6 229.3L661.7 208.4L592.2 168.3L589.5 166.7Z"
          fill="url(#paint1_linear_5032_43202)"
        />
        <Path
          opacity="0.4"
          d="M35.5 761.6V803.3L71.6 782.5V740.8L35.5 761.6Z"
          fill={props?.c1 || "#CC81EA"}
        />
        <Path
          d="M-35.9 761.1L-72 740.3L-2.5 700.2L108.6 636V677.7L-35.9 761.1Z"
          fill="url(#paint2_linear_5032_43202)"
        />
        <Path
          d="M35.5008 719.9V761.6L-0.699219 740.8V699.1L35.5008 719.9Z"
          fill={props?.c2 || "#4442FF"}
        />
        <Path
          d="M72.4996 823.7L75.1996 822.1L144.7 782L108.6 761.1L36.3996 802.9L-35.9004 761.1V802.9L0.199615 823.7L-35.9004 844.6V886.3L36.3996 844.6L108.6 886.3L144.7 865.4L75.1996 825.3L72.4996 823.7Z"
          fill="url(#paint3_linear_5032_43202)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5032_43202"
          x1="75219.2"
          y1="-6765.66"
          x2="107838"
          y2="-6765.66"
        >
          <Stop offset="0" stopColor={props?.c3 || "#85EAFF"} />
          <Stop offset="0.5" stopColor="#C25FE2" />
          <Stop offset="1" stopColor={props?.c2 || "#4442FF"} />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5032_43202"
          x1="81775.5"
          y1="9013.61"
          x2="114394"
          y2="9013.61"
        >
          <Stop offset="0" stopColor={props?.c3 || "#85EAFF"} />
          <Stop offset="1" stopColor={props?.c2 || "#4442FF"} />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5032_43202"
          x1="74702.2"
          y1="-6108.66"
          x2="107321"
          y2="-6108.66"
        >
          <Stop offset="0" stopColor={props?.c3 || "#85EAFF"} />
          <Stop offset="0.5" stopColor="#C25FE2" />
          <Stop offset="1" stopColor={props?.c2 || "#4442FF"} />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5032_43202"
          x1="81258.5"
          y1="9670.61"
          x2="113877"
          y2="9670.61"
        >
          <Stop offset="0" stopColor={props?.c3 || "#85EAFF"} />
          <Stop offset="1" stopColor={props?.c2 || "#4442FF"} />
        </LinearGradient>
        <ClipPath id="clip0_5032_43202">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture22 = {
  ViewTexture,
  PdfTexture,
};

export default Texture22;
