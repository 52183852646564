import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as ResumeScoreMarkIcon } from "../assets/SVG/resume-score-mark.svg";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { IResume } from "../components/utils";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 1,
  background: "#cbd5e1",
}));

const calculateResumeScore = (resumeDetails: any) => {
  if (resumeDetails.tailoredResumeObject?.atsScore)
    return resumeDetails.tailoredResumeObject?.atsScore;
  let score = 0;
  // Basic info
  if (
    resumeDetails?.name &&
    resumeDetails?.name?.firstName?.trim() &&
    resumeDetails?.name?.lastName?.trim()
  )
    score += 10;
  //if (resumeDetails?.profilePicture) score += 5; // Renamed from profilePicture to profilePicture
  if (resumeDetails?.email && resumeDetails?.email?.trim()) score += 5;
  if (resumeDetails?.phone && resumeDetails?.phone.length) score += 5;
  if (resumeDetails?.location && resumeDetails?.location?.trim()) score += 5; // Re-added location

  // Social links and career objective
  if (
    resumeDetails?.socialLinks &&
    resumeDetails?.socialLinks.linkedIn_URL &&
    resumeDetails?.socialLinks.linkedIn_URL?.trim()
  )
    score += 5; // Re-added socialLinks and linkedInURL
  if (
    resumeDetails?.socialLinks &&
    resumeDetails?.socialLinks.personal_Website &&
    resumeDetails?.socialLinks.personal_Website?.trim()
  )
    score += 5;
  if (
    resumeDetails?.socialLinks &&
    resumeDetails?.socialLinks.github_URL &&
    resumeDetails?.socialLinks.github_URL?.trim()
  )
    score += 5;
  if (
    resumeDetails?.socialLinks &&
    resumeDetails?.socialLinks.twitter_X_URL &&
    resumeDetails?.socialLinks.twitter_X_URL?.trim()
  )
    score += 5;
  if (resumeDetails?.careerObjective && resumeDetails?.careerObjective?.trim())
    score += 10;

  // Detailed sections
  if (
    resumeDetails?.educationalDetails &&
    resumeDetails?.educationalDetails.length
  )
    score += 15;
  if (resumeDetails?.workExperiences && resumeDetails?.workExperiences.length)
    score += 20;
  if (resumeDetails?.projects && resumeDetails?.projects.length) score += 20; // Re-added projects
  if (
    resumeDetails?.toolsTechnicalSkills &&
    resumeDetails?.toolsTechnicalSkills?.length
  )
    score += 10;
  if (
    resumeDetails?.nonTechnicalSkills &&
    resumeDetails?.nonTechnicalSkills?.length
  )
    score += 5;
  if (
    resumeDetails?.programmingLanguages &&
    resumeDetails?.programmingLanguages?.length
  )
    score += 10; // Re-added programmingLanguages

  const maxScore = 155; // Adjusted maxScore back to 120 to account for all sections
  let normalizedScore = (score / maxScore) * 100;

  // Ensure the score is within 0 to 100
  normalizedScore = Math.max(0, Math.min(100, normalizedScore));

  return normalizedScore;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "1px solid #e2e4e9",
    borderRadius: "0.75rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white, // Set the arrow color to match the tooltip background
    "&::before": {
      border: "1px solid #e2e4e9", // Optional: border for arrow
      boxSizing: "border-box",
    },
  },
}));

const ResumeScoreProgressBar = () => {
  const resumeDetails = useSelector((state: IResume) => state.extractedData);

  const score = useMemo(
    () => calculateResumeScore(resumeDetails),
    [resumeDetails],
  );

  return (
    <Box className="resume-score-progress-bar">
      <Box className="score-progress-bar-container">
        <Box className="score-progress-content">
          <Box className="left">
            <Box
              //className="progress-chip"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  "linear-gradient(to left, rgb(104, 122, 243), rgb(208, 128, 255))",
                borderRadius: "0.375rem",
                cursor: "pointer",
                color: "#FFF",
                fontWeight: 700,
                px: 1,
                py: 0.5,
              }}
            >
              {score.toFixed(0)}%
            </Box>
            <Box>
              <Typography variant="body1">Resume Score</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <BorderLinearProgress variant="determinate" value={score} />
        </Box>
      </Box>
      <Box className="right">
        <LightTooltip
          title={
            <Box
              sx={{
                padding: "0.75rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "500",
                  color: "#0a0d14",
                  lineHeight: "19.12px",
                  marginBottom: "0.5rem",
                }}
              >
                Resume Score
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875",
                  fontWeight: "400",
                  color: "#525866",
                  lineHeight: "17.73px",
                }}
              >
                Your resume score reflects keyword relevance, formatting, and
                overall impact to match job requirements.
              </Typography>
            </Box>
          }
          arrow
        >
          <Box className="icon-cont">
            <ResumeScoreMarkIcon />
          </Box>
        </LightTooltip>
      </Box>
    </Box>
  );
};

export default ResumeScoreProgressBar;
