import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { ReactComponent as CreateResumeIcon } from "./assets/SVG/user-landing-page-create-resume.svg";
import { ReactComponent as UploadResumeIcon } from "./assets/SVG/user-landing-page-upload-resume.svg";
import { ReactComponent as OnePageResumeIcon } from "./assets/SVG/user-landing-page-convert-to-single-page.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setExtractedData,
  setSelectedTemplateId,
  showSnackbar,
} from "./redux/actions";
import Loading from "./components/Loading/Loading";
import { Alert } from "@mui/material";

import { WorkExperienceTypes } from "./components/utils";
import SideDrawer from "./ProcessResume/SideDrawer";
import { Illustration_1 } from "./components/Icons";
import HomeScreenTabs from "./components/HomeScreenTabs/HomeScreenTabs";
import { seprateExtractedBulletPointsStringToArray } from "./components/common/CommonFunctions";
import * as UploadingResume from "./assets/lottie/ATSCheckScreen.json";
import { StateProps } from "./redux/reducer";
import { initialState } from "./redux/reducer"; // Import initialState
import { getUsernameFromToken } from "./services/getUserFromToken";
import {
  extractFaceFromPDF,
  extractText,
  withAuthHandling,
  generateContent,
  generateContentForSinglePageResume,
  fetchResumes,
  fetchResumeById,
} from "./services/apiService";
import { useDropzone } from "react-dropzone";
import SinglePageConversionModal from "./components/UserDashboard/SinglePageConversionModal";
import { ReactComponent as UploadResumeImg } from "./assets/SVG/uploadResume.svg";
// import { getDocument } from 'pdfjs-dist';
// const PDFJS = window.pdfjsLib;

interface userOption {
  title: string;
  subTitle: string;
  Icon: React.ReactNode;
  svgClass: string;
  link: string;
  onClick?: () => void;
}

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: UploadingResume,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const UserLandingPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    isSinglePageConversionInProgress,
    setIsSinglePageConversionInProgress,
  ] = useState(false);
  const [
    isExtractionAndGenerationInProgress,
    setIsExtractionAndGenerationInProgress,
  ] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [resumes, setResumes] = useState([]);
  const extractedData = useSelector(
    (state: StateProps) => state?.extractedData
  );
  const fetchWithAuthHandling = withAuthHandling(navigate);
  const [searchQuery, setSearchQuery] = useState("");

  // Add filtered resumes state
  const [filteredResumes, setFilteredResumes] = useState([]);

  // const [linkedInLoading, setLinkedInLoading] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);

  const location = useLocation();

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFileSelect = async (
    file: File,
    resumeActionType: string = ""
  ) => {
    if (file) {
      setIsExtractionAndGenerationInProgress(true);
      const formData = new FormData();
      formData.append("file", file);
      console.log(process.env.REACT_APP_API_URL, "from API");

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      try {
        const { textContent } = await extractText(
          formData,
          fetchWithAuthHandling
        );
        const requestData = { content: textContent } as any;
        // const endpointURL =  `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/convert-resume-to-single-page` : `${process.env.REACT_APP_BACKEND_DOTNET_API_URL}/generate-content`;

        const getBase64Data = async (file: File): Promise<string> => {
          return new Promise((resolve) => {
            const reader: any = new FileReader();
            reader.onloadend = () => {
              const base64string = reader.result.toString().split(",")[1];
              resolve(base64string);
            };
            reader.readAsDataURL(file);
          });
        };

        const base64string = await getBase64Data(file);

        let extractedProfilePicture: string | null = null;

        const [resumeObj] = await Promise.all([
          generateContent(requestData.content, fetchWithAuthHandling, dispatch),
          extractFaceFromPDF(base64string)
            .then((extractedFace) => {
              if (extractedFace) {
                extractedProfilePicture = extractedFace;
              }
            })
            .catch((error) => console.error("Error extracting face:", error)),
        ]);

        setIsExtractionAndGenerationInProgress(false);

        const formattedResumeObject = await processExtractedData(
          resumeObj,
          resumeActionType
        );

        // Check if values exist and aren't "NA"
        const isValidFullName =
          formattedResumeObject.fullName &&
          formattedResumeObject.fullName !== "NA";
        const isValidDesignation =
          formattedResumeObject.jobTitle &&
          formattedResumeObject.jobTitle !== "NA";

        // Create title with whichever valid parts exist
        const title =
          [
            isValidFullName ? formattedResumeObject.fullName : "",
            isValidDesignation ? formattedResumeObject.jobTitle : "",
          ]
            .filter(Boolean) // Remove empty strings
            .join("_") +
          (isValidFullName || isValidDesignation ? "_Resume" : "Resume");

        dispatch(
          setExtractedData({
            ...formattedResumeObject,
            title,
            profilePicture:
              extractedProfilePicture || formattedResumeObject.profilePicture,
          })
        );
        navigate(`/create-resume/new/personal-details`);
      } catch (error: any) {
        console.error("Error uploading file:", error);
        dispatch(
          showSnackbar(
            error.message || "Failed to upload and process the resume.",
            "error"
          )
        );
      } finally {
        setIsLoading(false);
        setIsExtractionAndGenerationInProgress(false);
      }
    }
  };

  const processExtractedData = async (
    resumeObj: any,
    resumeActionType: string
  ) => {
    const username = getUsernameFromToken();

    resumeObj =
      resumeActionType === "single-page-conversion"
        ? {
            ...resumeObj,
            ...(await generateContentForSinglePageResume(resumeObj, dispatch)),
          }
        : { ...resumeObj, title: username + "'s Resume" || "" };

    // Formatting resume object for projects section
    let formattedResumeObject = resumeObj;
    if (resumeObj?.projects?.[0]) {
      const formattedProjects = resumeObj?.projects?.map((proj: any) => ({
        ...proj,
        organization: proj?.title,
        courseDescription: proj?.description,
      }));
      formattedResumeObject = {
        ...formattedResumeObject,
        projects: formattedProjects,
      };
    }
    if (resumeObj?.workExperiences?.[0]) {
      const formattedExperience: WorkExperienceTypes =
        resumeObj?.workExperiences?.map((exp: WorkExperienceTypes) => ({
          ...exp,
          jobType: exp?.jobType ?? "fullTime",
          location: exp?.location ?? "",
          keyResponsibilities: seprateExtractedBulletPointsStringToArray(
            exp?.keyResponsibilities
          ),
        }));
      formattedResumeObject = {
        ...formattedResumeObject,
        workExperiences: formattedExperience,
      };
    }

    dispatch(setSelectedTemplateId(initialState.selectedTemplateId));
    return formattedResumeObject;
  };

  const triggerFileInputClick = () => {
    fileInputRef.current?.click();
  };

  /* const handleLinkedInImport = () => {
        try {
            setLinkedInLoading(true);
            const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
            const REDIRECT_URI = encodeURIComponent(`${window.location.origin}/linkedin-callback`);
            
            // Updated scopes to use current LinkedIn API v2 scopes
            const SCOPES = [
                'r_liteprofile',
                'r_emailaddress',
                'w_member_social'
            ].join(' ');
            
            const STATE = crypto.randomUUID(); // Generate random state for security
            sessionStorage.setItem('linkedin_oauth_state', STATE);
    
            const LINKEDIN_AUTH_URL = `https://www.linkedin.com/oauth/v2/authorization?` + 
                `response_type=code` +
                `&client_id=${LINKEDIN_CLIENT_ID}` +
                `&redirect_uri=${REDIRECT_URI}` +
                `&scope=${encodeURIComponent(SCOPES)}` +
                `&state=${STATE}`;
    
            console.log('Redirecting to:', LINKEDIN_AUTH_URL);
            window.location.href = LINKEDIN_AUTH_URL;
        } catch (error) {
            console.error('LinkedIn redirect error:', error);
            dispatch(showSnackbar('Failed to connect to LinkedIn', 'error'));
            setLinkedInLoading(false);
        }
    }; */

  const userOptions: userOption[] = [
    {
      title: "Create New Resume",
      subTitle: "Create and customize your unique version yourself!",
      Icon: <CreateResumeIcon />,
      svgClass: "svg-fill-secondary-hover-primary cursor-pointer",
      link: "/",
      onClick: () => {
        const username = getUsernameFromToken();
        const resumeObj = initialState.extractedData;
        dispatch(setExtractedData({ ...resumeObj }));
        // title: username || ""
        dispatch(setSelectedTemplateId(initialState.selectedTemplateId));
        navigate(`/create-resume/new/personal-details`);
      },
    },
    {
      title: "Upload Resume",
      subTitle: "Quickly create your resume with an easy upload!",
      Icon: <UploadResumeIcon />,
      svgClass: "svg-stroke-secondary-hover-primary cursor-pointer",
      link: "/",
      onClick: triggerFileInputClick,
    },
    {
      title: "Convert to Single Page Resume",
      subTitle: "Simplify your multi-page resume into a single page!",
      Icon: <OnePageResumeIcon />,
      svgClass: "svg-stroke-secondary-hover-primary cursor-pointer",
      link: "/",
      onClick: () => {
        handleOpenPopup();
        // triggerFileInputClick();
      },
    },
    /* {
            title: "Import from LinkedIn",
            subTitle: "Create resume using your LinkedIn profile data",
            Icon: <OnePageResumeIcon />,
            svgClass: "svg-stroke-secondary-hover-primary cursor-pointer",
            link: "/",
            onClick: handleLinkedInImport
        } */
  ];

  const username = getUsernameFromToken();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: any) => {
      if (acceptedFiles[0]) {
        setIsSinglePageConversionInProgress(true);
        await handleFileSelect(acceptedFiles[0], "single-page-conversion");
        setIsSinglePageConversionInProgress(false);
      }
    },
  });

  useEffect(() => {
    const loadResumes = async () => {
      resumes.length === 0 && setLoading(true);

      try {
        const data = await fetchResumes(fetchWithAuthHandling);
        setResumes(data);
        setFilteredResumes(data); // Initialize filtered resumes
      } catch (error) {
        console.error("Error fetching resumes:", error);
      } finally {
        setLoading(false);
      }
    };

    loadResumes();
  }, []);

  const handleCancelProcessing = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsLoading(false);
    setIsSinglePageConversionInProgress(false);
    dispatch(setExtractedData(initialState.extractedData));
    navigate(`/my-resumes`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = resumes.filter((resume: any) =>
      resume.title?.toLowerCase().includes(query)
    );
    setFilteredResumes(filtered);
  };

  const getSectionHeading = (): string => {
    if (location.pathname.includes("dashboard")) {
      return `Welcome, ${username}`;
    } else if (location.pathname.includes("my-resumes")) {
      return "Resumes";
    } else if (location.pathname.includes("ats-checker")) {
      return "ATS Checker";
    } else if (location.pathname.includes("job-board")) {
      return "Job Board";
    } else {
      return `Welcome, ${username}`;
    }
  };

  const handleSinglePageConversionClick = async (resumeId: string) => {
    setIsSinglePageConversionInProgress(true);
    setIsExtractionAndGenerationInProgress(true);
    const resumeObject = await fetchResumeById(
      resumeId,
      fetchWithAuthHandling,
      dispatch
    );
    setIsExtractionAndGenerationInProgress(false);
    const formattedResumeObject = await processExtractedData(
      resumeObject,
      "single-page-conversion"
    );
    const singlePageName = resumeObject?.title ? resumeObject?.title : username;
    dispatch(
      setExtractedData({
        ...formattedResumeObject,
        title: singlePageName + "_SinglePage" || "",
      })
    );
    navigate(`/create-resume/new/personal-details`);
    setIsSinglePageConversionInProgress(false);
  };

  return (
    <>
      {isLoading && (
        <Loading
          lottieContent={UploadingResume}
          title={"Uploading your Resume..."}
          subTitle="We’re processing your file. This may take a few moments."
          onClose={handleCancelProcessing}
          icon={<UploadResumeImg />}
        />
      )}
      <Box sx={{ display: "flex" }}>
        <Box className="side-drawer-container">
          <SideDrawer />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100%",
          }}
        >
          <Box className="user-landing-page-container" sx={{ m: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                mb: 2,
                fontSize: "24px",
              }}
            >
              Resumes
            </Typography>
            <Grid
              container
              direction="column"
              spacing={4} /*  sx={{ my: 4 }} */
            >
              <Grid item>
                <Alert
                  severity="info"
                  icon={<Illustration_1 />}
                  sx={{
                    background: "#EBF2FF",
                    borderRadius: "12px",
                    "& .MuiAlert-icon": {
                      padding: 0,
                    },
                    "& .MuiAlert-message": {
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#162664",
                    },
                  }}
                >
                  Build a perfect resume today!
                </Alert>
              </Grid>
              <Grid item className="top-heading-container">
                <Box sx={{ mb: 4, textAlign: "center" }}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold" }}
                    className="heading"
                  >
                    Let’s get Started !
                  </Typography>
                  <Typography
                    variant="h6"
                    color="#5A697E"
                    className="sub-heding"
                  >
                    We can begin in a few ways: pick the one that works best for
                    you.
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="landing-page-content">
                <Grid
                  container
                  columnGap={5}
                  className="card-container"
                  justifyContent="center"
                >
                  {userOptions.map((option: userOption, index: number) => (
                    <Grid
                      key={index}
                      item
                      onClick={() =>
                        option.onClick
                          ? option.onClick()
                          : navigate(`${option.link}`)
                      }
                      sx={{ maxWidth: "350px !important" }}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderRadius: "12px",
                          border: "2px solid #E2E8F0",
                          boxShadow: "none",
                          transition:
                            "border-color 0.3s, background-color 0.3s",
                          cursor: "pointer",
                          "&:hover": {
                            border: "2px solid #5594FF",
                            background:
                              "linear-gradient(180deg, #E1EDFF 0%, #FFF 70%)",
                          },
                        }}
                      >
                        <CardMedia>
                          <Box pt={4} className="flex-row-center">
                            {option.Icon}
                          </Box>
                        </CardMedia>
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold" }}
                          >
                            {option.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ fontSize: "1.05rem", textAlign: "center" }}
                          >
                            {option.subTitle}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    setIsLoading(true);
                    if (e.target.files?.[0]) {
                      await handleFileSelect(e.target.files[0]);
                      setIsLoading(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <HomeScreenTabs
              setIsPopupOpen={setIsPopupOpen}
              handleSinglePageConversionClick={handleSinglePageConversionClick}
            />
          </Box>
        </Box>
        {/* <AtsResumeCheckerPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
            /> */}

        <SinglePageConversionModal
          isPopupOpen={isPopupOpen}
          handleClosePopup={handleClosePopup}
          isExtractionAndGenerationInProgress={
            isExtractionAndGenerationInProgress
          }
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          isSinglePageConversionInProgress={isSinglePageConversionInProgress}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          loading={loading}
          handleCancelProcessing={handleCancelProcessing}
          filteredResumes={filteredResumes}
          handleSinglePageConversionClick={handleSinglePageConversionClick}
        />
      </Box>
    </>
  );
};

export default UserLandingPage;
