import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { hobbiesData, NonTechnicalSkill } from "../../../Data/skillsData";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../redux/actions";
import { StateProps } from "../../../redux/reducer";
import { ReactComponent as AddSkillIcon } from "../../../assets/SVG/add-skill-icon.svg";
import { ReactComponent as HobbiesIcon } from "../../../assets/SVG/hobbies-icon.svg";
import { avatarColors } from "../Skills";
import { regexTotestAddKeyWord } from "../../../components/common/CommonFunctions";

interface HobbiesProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
}

const filter = createFilterOptions<NonTechnicalSkill>();

const getNewHobby = (hooby: string) => {
  return {
    label: hooby,
    value: hooby,
  };
};

const Hobbies: React.FC<HobbiesProps> = ({
  handleSectionDelete,
  sectionName,
}) => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);

  const extractedHobbies = extractedData?.hobbies; //?.map((sk) => ({label:sk?.trim(),value:sk?.trim()}));
  const avialbleHobbiesExist =
    hobbiesData.filter((hb) =>
      extractedHobbies?.some(
        (selectedHb) => selectedHb?.toLowerCase() === hb.label?.toLowerCase()
      )
    ) ?? [];
  const remainingAvailableHobbies = hobbiesData.filter(
    (hb) =>
      !extractedHobbies?.some(
        (selectedHb) => selectedHb?.toLowerCase() === hb.label?.toLowerCase()
      )
  );
  const filteredExtractedHobbies =
    extractedHobbies
      ?.filter(
        (hb) =>
          !avialbleHobbiesExist?.some(
            (selectedHb) =>
              selectedHb?.label?.toLowerCase() === hb?.toLowerCase()
          )
      )
      ?.map((sk) => ({ label: sk?.trim(), value: sk?.trim() })) ?? [];
  const formattedExtractedHobbies = [
    ...avialbleHobbiesExist,
    ...filteredExtractedHobbies,
  ];

  const [hobbies, setHobbies] = useState<NonTechnicalSkill[]>(
    formattedExtractedHobbies ?? []
  );
  const [availableHobbies, setAvailableHobbies] = useState(
    remainingAvailableHobbies
  );
  const dispatch = useDispatch();

  /**
   * Below use Effect is to get the value of already existing Hobbies
   */
  useEffect(() => {
    if (hobbies?.length > 0) {
      dispatch(
        setExtractedData({
          ...extractedData,
          hobbies: hobbies?.map((hb) => hb.label),
        })
      );
    } else {
      dispatch(
        setExtractedData({
          ...extractedData,
          hobbies: [],
        })
      );
    }
  }, [hobbies]);

  const handleHobbiesChange = (event: any, newValue: any[]) => {
    let allHobbies = newValue;
    if (regexTotestAddKeyWord(allHobbies?.[allHobbies?.length - 1]?.label)) {
      const val = allHobbies?.[allHobbies?.length - 1]?.value;
      allHobbies[allHobbies?.length - 1] = {
        label: val,
        value: val,
      };
    }
    setHobbies(allHobbies);
    dispatch(
      setExtractedData({
        ...extractedData,
        hobbies: newValue?.map((hobby) => hobby?.label),
      })
    );
    const updatedAvailableHobbies = hobbiesData.filter(
      (hb) => !newValue.some((selectedHb) => selectedHb.value === hb.value)
    );
    setAvailableHobbies(updatedAvailableHobbies);
  };

  const handleHobbyDelete = (hbToDelete: string) => {
    const updatedHobbies = hobbies.filter((hb) => hb.value !== hbToDelete);
    const deletedHobby = hobbiesData.find(
      (skill) => skill.value === hbToDelete
    );
    if (deletedHobby) {
      setAvailableHobbies([...availableHobbies, deletedHobby]);
    }
    setHobbies(updatedHobbies);
    dispatch(
      setExtractedData({
        ...extractedData,
        hobbies: updatedHobbies?.map((hobby) => hobby?.label),
      })
    );
  };

  const handleValueChange = (value: string | boolean, key: string) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        metaInformation: { ...extractedData.metaInformation, [key]: value },
      })
    );
  };

  return (
    <Box className="hobbies-section">
      <Box className="heading-box">
        <Box className="icon-heading-cont">
          <Box className="icon-container">
            <HobbiesIcon />
          </Box>
          <Typography variant="h5" color="color.primary" className="heading">
            Hobbies
          </Typography>
        </Box>

        <Typography
          className="sub-heading"
          onClick={() => handleSectionDelete(sectionName)}
        >
          Remove section
        </Typography>
      </Box>

      <Box className="input-box-container">
        <Autocomplete
          multiple
          id="hobbies"
          fullWidth
          options={availableHobbies} // Use updated available skills
          //@ts-ignore
          value={hobbies}
          //value={skills.map(({label,value}) => ({label,value}))}
          onChange={handleHobbiesChange}
          renderTags={() => null}
          renderOption={(props, option, newValue) => {
            if (option?.label?.includes("Add ")) {
              const newStr = option.label.replace(/^Add\s*/, "");
              return (
                <li
                  {...props}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <AddSkillIcon />
                  <span style={{ color: "#5594ff", margin: "0 0.25rem" }}>
                    Add {newStr}{" "}
                  </span>
                </li>
              );
            }

            return (
              <li {...props} style={{ display: "flex", alignItems: "center" }}>
                {option.icon}
                {option.label}
              </li>
            );
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                label: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} placeholder="Eg.Swimming" />
          )}
        />
      </Box>

      <Box className="input-box-container">
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) =>
                handleValueChange(e.target.checked, "showHobbiesIcon")
              }
            />
          }
          checked={extractedData?.metaInformation?.showHobbiesIcon}
          label="Show Icons"
        />
      </Box>

      {hobbies.length > 0 && (
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexWrap: "wrap",
            gap: 1.4,
            padding: 0,
            borderRadius: "8px",
          }}
        >
          {hobbies?.map((hb, index) => (
            <Box>
              <Chip
                sx={{
                  height: "2.2rem",
                  fontSize: "14px", // Adjust font size
                  fontWeight: 400,
                  padding: "0.3rem", // Adjust padding
                  border: "1px solid #e2e8f0", // Add border
                  borderRadius: "8px", // Optional: Adjust border radius
                  "& .MuiChip-deleteIcon": {
                    position: "absolute",
                    top: "-7px",
                    right: "-13px",
                    fontSize: "17px",
                    color: "#b0b0b0",
                  },
                  "& .MuiChip-label": {
                    paddingLeft: hb.icon ? "0rem" : "auto", // Example padding for the label
                  },
                }}
                avatar={
                  hb.icon ? (
                    hb.icon
                  ) : (
                    <Avatar
                      sx={{
                        background: `${
                          avatarColors[
                            avatarColors.length -
                              2 -
                              (index % avatarColors.length)
                          ]
                        }`,
                      }}
                    >
                      <Typography sx={{ fontSize: "11px", color: "#fff" }}>
                        {hb?.value?.[0]?.toUpperCase()}
                      </Typography>
                    </Avatar>
                  )
                }
                label={hb.value}
                variant="outlined"
                onDelete={() => handleHobbyDelete(hb.value)}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
export default Hobbies;
