import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { StateProps } from "../../../../redux/reducer";
import ReactQuill from "react-quill";
import { quillModules } from "../../../../App";

const GoalEdit: React.FC = () => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const handleChange = (newValue: any, key: string) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        goals: newValue,
      })
    );
  };

  return (
    <Box className="section-item-container">
      <Box className="courses-edit-section">
        <Box className="input-box-container">
          <Box className="quill-container">
            <ReactQuill
              value={extractedData.goals}
              onChange={(e: any) => handleChange(e, "description")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoalEdit;
