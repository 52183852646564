import { Box, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { TReference } from "../../../../components/utils";
import { setExtractedData } from "../../../../redux/actions";
import ReferenceCard from "./ReferenceCard";
import ReferenceEdit from "./ReferenceEdit";
import { ReactComponent as ReferencesIcon } from "../../../../assets/SVG/preferencess-icon.svg";

interface CoursesListProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
  handleDeleteEmptySection: (section: string) => void;
}

const DEFAULT_FORM_DATA: TReference = {
  referentName: "",
  company: "",
  phone: "",
  email: "",
  isEdit: true,
  isNewEntry: true,
};

const ReferencesList: React.FC<CoursesListProps> = ({
  handleSectionDelete,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const dispatch = useDispatch();
  const [coursesList, setCoursesList] = useState<TReference[]>(
    extractedData?.references ?? [],
  );
  const [draggingItemIndex, setDraggingItemIndex] = useState<number | null>(
    null,
  );

  //Adding key to extrated course elements
  useEffect(() => {
    if (!extractedData?.references?.[0]) {
      setCoursesList([{ ...DEFAULT_FORM_DATA, index: 0 }]);
    } else {
      const updatedList = extractedData?.references?.map((course, i) => {
        return { ...course, isEdit: false, index: i };
      });
      setCoursesList(updatedList ?? []);
    }
  }, [extractedData?.references]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    const updateCourseList = coursesList?.map((course) => ({
      ...course,
      isEdit: false,
    }));
    setCoursesList([
      ...updateCourseList,
      { ...DEFAULT_FORM_DATA, index: coursesList?.length ?? 1 },
    ]);
  };

  const handleDragStart = (index: number) => {
    setDraggingItemIndex(index);
  };

  const handleDragOver = (e: any, index: number) => {
    e.preventDefault();
  };

  const handleDrop = (index: number) => {
    const draggedOverItem = extractedData?.references?.[index];

    // If the item is dragged over itself, don't change the list
    if (draggingItemIndex === index) {
      return;
    }

    if (
      draggedOverItem &&
      (draggingItemIndex || draggingItemIndex === 0) &&
      extractedData?.references?.[draggingItemIndex]
    ) {
      const updatedItems = [...extractedData?.references];

      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[draggingItemIndex];
      updatedItems[draggingItemIndex] = temp;

      dispatch(
        setExtractedData({
          ...extractedData,
          references: updatedItems,
        }),
      );
      setDraggingItemIndex(index);
    }
  };

  const handleDragEnd = () => {
    setDraggingItemIndex(null);
  };

  return (
    <Box className="courses-section">
      <Box className="heading-box">
        <Box className="icon-heading-cont">
          <Box className="icon-container">
            <ReferencesIcon />
          </Box>
          <Typography variant="h5" color="color.primary" className="heading">
            References
          </Typography>
        </Box>

        <Typography
          className="sub-heading"
          onClick={() => {
            handleSectionDelete(sectionName);
          }}
        >
          Remove section
        </Typography>
      </Box>

      {coursesList?.map((course, index) => (
        <ListItem
          className="card-wrapper"
          draggable={course?.isEdit ? false : true}
          onDragStart={() => handleDragStart(index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDrop={() => handleDrop(index)}
          onDragEnd={handleDragEnd}
          sx={
            draggingItemIndex === index
              ? {
                  transition: "transform 0.3s ease",
                  opacity: "0.7",
                  transform: "scale(1.05)",
                  cursor: course?.isEdit ? "none" : "grab",
                }
              : {}
          }
        >
          {course?.isEdit ? (
            <ReferenceEdit
              courseData={course}
              coursesList={coursesList}
              setCoursesList={setCoursesList}
              index={index}
              handleDeleteEmptySection={handleDeleteEmptySection}
              sectionName={sectionName}
            />
          ) : (
            <ReferenceCard
              courseData={course}
              coursesList={coursesList}
              setCoursesList={setCoursesList}
            />
          )}
        </ListItem>
      ))}

      {!coursesList?.[coursesList?.length - 1]?.isNewEntry && (
        <Box className="add-course-button-cont">
          <Typography className="summary-ui-brand" onClick={handleAddCourse}>
            +Add More
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReferencesList;
