import { Box, Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { SecondaryButton } from "../button/Buttons";
import TeknoSwitch from "../CommonComponents/TeknoSwitch";
import authenticatedFetch from "../../services/AuthenticatedFetch";
import toast from "react-hot-toast";
import { responseHandler } from "../../utils/responseHandler";
import { tecknoApiBaseUrl } from "../../utils/config";
import { useSelector } from "react-redux";
import { ILoggedinUserData } from "../utils";

const notificationOptions: Array<{
  title: string;
  description: string;
  key: keyof INotificationFormValues;
}> = [
  {
    title: "Resume optimization",
    description:
      "Get tips to enhance your resume, showcasing skills and experience to attract opportunities.",
    key: "resumeOptimization",
  },
  {
    title: "Job recommendations",
    description:
      "We send you occasional job recommendations based on your skills and experience.",
    key: "jobRecommendations",
  },
  {
    title: "Job application updates",
    description:
      "Get timely updates on your applications to stay informed on interview requests and progress.",
    key: "jobApplicationUpdates",
  },
];

interface INotificationFormValues {
  resumeOptimization: boolean;
  jobRecommendations: boolean;
  jobApplicationUpdates: boolean;
}

const Notifications = () => {
  const loggedInUserData: ILoggedinUserData = useSelector(
    (state: any) => state.loggedInUserData,
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<INotificationFormValues>({
    defaultValues: {
      resumeOptimization: false,
      jobRecommendations: false,
      jobApplicationUpdates: false,
    },
  });

  const formValues = watch();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: INotificationFormValues) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        resumeOptimization: data.resumeOptimization,
        enableJobRecommondations: data.jobRecommendations,
        enableJobApplicationUpdates: data.jobApplicationUpdates,
      });

      //API call to update the notifications
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user-notif`,
        {
          method: "PUT",
          body,
        },
      );

      if (response.ok) {
        toast.success("Notification settings updated successfully");
      } else {
        responseHandler(response);
      }
    } catch (error) {
      console.log("Error updating notifications:", error);
      toast.error("An unexpected error occurred while updating notifications.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("resumeOptimization", loggedInUserData.resumeOptimization);
    setValue("jobRecommendations", loggedInUserData.enableJobRecommondations);
    setValue(
      "jobApplicationUpdates",
      loggedInUserData.enableJobApplicationUpdates,
    );
  }, [loggedInUserData]);

  return (
    <Box sx={{ flex: 1, padding: 4 }}>
      <Typography variant="h5" gutterBottom>
        Notifications
      </Typography>
      <Typography variant="body2" gutterBottom>
        Update your personal details here.
      </Typography>

      <Divider sx={{ mt: 2, mb: 1 }} />

      <Box sx={{ mt: 1, maxWidth: 500 }}>
        {notificationOptions.map((option, idx) => (
          <div key={idx}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 2,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {option.title}
                  </Typography>
                  <TeknoSwitch
                    {...register(option.key)}
                    checked={formValues[option.key]}
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {option.description}
                </Typography>
              </Box>
            </Box>
            {notificationOptions.length - 1 !== idx && <Divider />}
          </div>
        ))}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <SecondaryButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            // disabled={!Object.keys(dirtyFields).length}
          >
            Save
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Notifications;
