import { Box, Button, Typography } from "@mui/material";

const Section7 = () => {
  return (
    <Box className="section-7-wrapper">
      <Box className="section-7-content">
        <Typography
          component="h5"
          variant="h6"
          className="heading"
          gutterBottom
        >
          Still have questions?
        </Typography>
        <Typography className="sub-heading" gutterBottom>
          Can't find the answer you are looking for? Please chat to our friendly
          team.
        </Typography>
      </Box>
      <Box className="button-cont">
        <Button variant="contained" className="contact-button">
          Get in touch
        </Button>
      </Box>
    </Box>
  );
};

export default Section7;
