import {
  View,
  Text,
  StyleSheet,
  Image,
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
} from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";
import { TEXTURES } from "../../utils/textures";
import { text } from "stream/consumers";
const TextureHandler = ({
  extractedData,
  isATSPreview,
  styleVariables: { textureId },
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
}) => {
  const { svgColor1, svgColor2, svgColor3, svgColor4 } =
    extractedData?.metaInformation || {};
  const styles = StyleSheet.create({
    topLeftPattern: {
      position: "absolute",
      top: 20,
      left: 20,
      width: 400,
      height: 200,
      // maxWidth: 500,
      // maxHeight: 300,
      // zIndex: 999,
    },
    svgPattern: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      // maxWidth: 500,
      // maxHeight: 300,
      // zIndex: 999,
    },
  });

  const foundTexture = TEXTURES.find((el) => el.id === textureId);
  const FoundTextureSvg = foundTexture?.svg?.PdfTexture;

  return (
    <>
      {textureId && foundTexture && (
        <>
          {foundTexture.path && (
            <Image
              src={foundTexture.path}
              style={styles.topLeftPattern}
              fixed
            />
          )}
          {foundTexture.svg && (
            <View style={styles.svgPattern} fixed>
              {FoundTextureSvg && (
                <FoundTextureSvg
                  c1={svgColor1}
                  c2={svgColor2}
                  c3={svgColor3}
                  c4={svgColor4}
                />
              )}
            </View>
          )}
        </>
      )}
    </>
  );
};

export default TextureHandler;
