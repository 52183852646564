import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InputShimmer } from "../../ProcessResume/EditForms/PersonalDetails";
import { validateEmail } from "../common/CommonFunctions";
import ReactQuill from "react-quill";
import { quillModules } from "../../App";

interface ICoverletter {
  fullName: string;
  jobTitle: string;
  email: string;
  phone: string[];
  address: string;
  letterDetails: string;
}

const CoverLetterEdit = () => {
  const [isPersonalDetailsLoading, setIsPersonalDetailsLoading] =
    useState<boolean>(false);

  const [localData, setLocalData] = useState<ICoverletter>({
    fullName: "",
    jobTitle: "",
    email: "",
    phone: [],
    address: "",
    letterDetails: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    jobTitle: "",
  });

  const handleChange = (newValue: any, key: string) => {
    if (key === "phone") {
      if (/^\+?[\d()-]*$/.test(newValue)) {
        setLocalData({ ...localData, phone: [newValue] });
      }
      return;
    }
    setLocalData({ ...localData, [key]: newValue });
  };

  useEffect(() => {
    setErrors({
      fullName: "",
      email: "",
      jobTitle: "",
    });
  }, []);

  const validateField = (key: string, value: string) => {
    switch (key) {
      case "fullName":
        if (!value) {
          setErrors((prev) => ({ ...prev, fullName: "Name is required" }));
        } else {
          setErrors((prev) => ({ ...prev, fullName: "" }));
        }
        break;
      case "email":
        if (!value) {
          setErrors((prev) => ({ ...prev, email: "Email is required" }));
        } else if (!validateEmail(value)) {
          setErrors((prev) => ({
            ...prev,
            email: "Please provide a valid email",
          }));
        } else {
          setErrors((prev) => ({ ...prev, email: "" }));
        }
        break;
      case "jobTitle":
        if (!value) {
          setErrors((prev) => ({
            ...prev,
            jobTitle: "Job Title is required",
          }));
        } else {
          setErrors((prev) => ({ ...prev, jobTitle: "" }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box className="personal-details-container">
      <Box className="personal-details-wrapper">
        <Box className="personal-details-content">
          <Box className="main-heading">
            <Box className="heading-box">
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Personal Details
              </Typography>
            </Box>
          </Box>

          <Box className="profile-info">
            <Box className="profile-details">
              <Box className="input-box-container">
                <InputLabel
                  htmlFor="fullName"
                  className="personal-details-label"
                >
                  Full Name*
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="fullName"
                    value={localData?.fullName}
                    onBlur={(e) => validateField("fullName", e.target.value)}
                    onChange={(e) => handleChange(e.target.value, "fullName")}
                    placeholder="Eg: John Doe"
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.fullName}
                    helperText={errors.fullName ? errors.fullName : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>
              <Box className="input-box-container">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InputLabel
                    htmlFor="fullName"
                    className="personal-details-label"
                  >
                    Job Title*
                  </InputLabel>
                </Box>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="jobTitle"
                    value={localData?.jobTitle}
                    placeholder={"Eg: Software Engineer"}
                    onChange={(e) => handleChange(e.target.value, "jobTitle")}
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.jobTitle}
                    helperText={errors.jobTitle ? errors.jobTitle : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>

              <Box className="input-box-container">
                <InputLabel htmlFor="email" className="personal-details-label">
                  Address*
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="email"
                    value={localData?.email}
                    placeholder={"Eg:  John.Doe@gmail.com"}
                    onChange={(e) => handleChange(e.target.value, "address")}
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>

              <Box className="input-box-container">
                <InputLabel htmlFor="email" className="personal-details-label">
                  Email*
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="email"
                    value={localData?.email}
                    placeholder={"Eg:  John.Doe@gmail.com"}
                    onBlur={(e) => validateField("email", e.target.value)}
                    onChange={(e) => handleChange(e.target.value, "email")}
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>

              <Box className="input-box-container">
                <InputLabel
                  htmlFor="jobTitle"
                  className="personal-details-label"
                >
                  Phone
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    type="text"
                    fullWidth
                    id="jobTitle"
                    placeholder="Eg: + 1 (213) 456 7890"
                    value={localData?.phone}
                    onChange={(e) => handleChange(e.target.value, "phone")}
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>
            </Box>
          </Box>

          <Box className="main-heading">
            <Box className="heading-box">
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Employer Details
              </Typography>
            </Box>
          </Box>

          <Box className="profile-info">
            <Box className="profile-details">
              <Box className="input-box-container">
                <InputLabel
                  htmlFor="fullName"
                  className="personal-details-label"
                >
                  Compnay name*
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="fullName"
                    value={localData?.fullName}
                    onBlur={(e) => validateField("fullName", e.target.value)}
                    onChange={(e) => handleChange(e.target.value, "fullName")}
                    placeholder="Eg: John Doe"
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.fullName}
                    helperText={errors.fullName ? errors.fullName : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>

              <Box className="input-box-container">
                <InputLabel
                  htmlFor="fullName"
                  className="personal-details-label"
                >
                  Hiring Manager Name*
                </InputLabel>
                {!isPersonalDetailsLoading ? (
                  <TextField
                    fullWidth
                    id="fullName"
                    value={localData?.fullName}
                    onBlur={(e) => validateField("fullName", e.target.value)}
                    onChange={(e) => handleChange(e.target.value, "fullName")}
                    placeholder="Eg: John Doe"
                    inputProps={{
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                    error={!!errors.fullName}
                    helperText={errors.fullName ? errors.fullName : ""}
                  />
                ) : (
                  <InputShimmer />
                )}
              </Box>
            </Box>
          </Box>

          <Box className="main-heading">
            <Box className="heading-box">
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Letter Details
              </Typography>
            </Box>
          </Box>

          <Box className="input-box-container">
            <Box className="quill-container">
              <ReactQuill
                value={localData?.letterDetails}
                onChange={(e: any) => handleChange(e, "letterDetails")}
                theme="snow"
                modules={quillModules}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoverLetterEdit;
