import {
  Avatar,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Rating,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";

interface IJobsContainerProps {
  name: string;
  children: React.ReactNode;
  onDrop: (item: any) => void;
}

const JobsContainer = (props: IJobsContainerProps) => {
  const { name, children, onDrop } = props;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ["JOB"],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = "#f8f8f8";
  if (isActive) {
    backgroundColor = "#B8E2F2";
  } else if (canDrop) {
    backgroundColor = "#f8f8f8";
  }
  return (
    <>
      <Box
        ref={drop}
        sx={{
          flex: 1,
          border: "1px solid #ddd",
          background: backgroundColor,
          padding: 1,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
          {name}
        </Typography>
        {children}
      </Box>
    </>
  );
};

export default JobsContainer;
