import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as MenuIcon } from "../assets/SVG/menuIcon.svg";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { ReactComponent as TeknoHeaderIcon } from "../assets/SVG/TeknoHeaderLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setSideDrawerState } from "../redux/actions";
import { StateProps } from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../components/auth/storeToken";
import { SettingsGearIcon, UserAvatar } from "../components/Icons";

interface AppBarHeaderProps {
  title?: string;
}

const AppBarHeader: React.FC<AppBarHeaderProps> = ({ title = "" }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const drawerState = useSelector((state: StateProps) => state.sideDrawer);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" className="app-bar">
      <Toolbar className="tool-bar">
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <IconButton>
          <SettingsGearIcon />
        </IconButton>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <UserAvatar />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate("/my-resumes")}>
            My Resumes
          </MenuItem>
          <MenuItem onClick={() => navigate("/edit-account")}>
            My Account
          </MenuItem>
          <MenuItem onClick={() => navigate("/forgot-password")}>
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              removeToken();
              dispatch(logoutUser());
              navigate("/login");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
