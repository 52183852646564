import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import {
  NonTechnicalSkill,
  nonTechSkillsData,
  skillOption,
  skillsData,
} from "../../Data/skillsData";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { StateProps } from "../../redux/reducer";
import { ReactComponent as AddSkillIcon } from "../../assets/SVG/add-skill-icon.svg";
import { ReactComponent as CertificationIcon } from "../../assets/SVG/certifications_icon.svg";
import { regexTotestAddKeyWord } from "../../components/common/CommonFunctions";
import CertificationList from "./SummarySections/Certifications/CertificationList";
import ImproveResumeBtn from "../ImproveResumeBtn";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ClearIcon } from "@mui/x-date-pickers";

const filter = createFilterOptions<skillOption>();
const nonTechFilter = createFilterOptions<NonTechnicalSkill>();

export const avatarColors = [
  "#455D7A",
  "#478600",
  "#003EB1",
  "#008886",
  "#00BB38",
  "#2D64BC",
  "#CF5D3F",
  "#5382A1",
  "#05A9A1",
  "#794D9A",
  "#00A068",
  "#976500",
  "#A90593",
  "#A92605",
  "#959A00",
  "#00A1CE",
  "#7460BE",
  "#5A88FF",

  // "#794D9A",
  // "#0092CA",
  // "#118DF0",
  // "#25A55F",
  // "#A275E3",
  // "#EF4339",
  // "#FF6699",
  // "#0033C7",
  // "#D82FA0",
  // "#D65A31",
  // "#951556",
  // "#686354",
  // "#FF8364",
  // "#FEBO62",
  // "#5DA0A2",
  // "#AA915E",
  // "#1B5A7A",
  // "#4D6DE3",
  // "#538F6A",
  // "#2AC7D0",
  // "#C7004C",
  // "#729D39",
  // "#944AD5",
  // "#928B8B"
];

// const avatarColors = [
//   {
//     primary:"#FFFEAB",
//     secondary:"#FFE17C",
//     text:"#7C6416"
//   },
//   {
//     primary:"#F5FFEB",
//     secondary:"#AAFF7C",
//     text:"#2C7205"
//   },
//   {
//     primary:"#FFF0EB",
//     secondary:"#FFA17C",
//     text:"#8D3411",
//   },
//   {
//     primary:"#EBFFFF",
//     secondary:"#7CFFFD",
//     text:"#117492",
//   },
//   {
//     primary:"#EBEBFF",
//     secondary:"#8D7CFF",
//     text:"#27188E",
//   },
//   {
//     primary:"#FBEBFF",
//     secondary:"#E057A9",
//     text:"#850A55",
//   },
//   {
//     primary:"#FFEBF3",
//     secondary:"#FF7CB5",
//     text:"#811342",
//   },
//   {
//     primary:"#FAEBFF",
//     secondary:"#C87CFF",
//     text:"#5D178E",
//   },
//   {
//     primary:"#F4FFEB",
//     secondary:"#46DF68",
//     text:"#08761E",
//   },
//   {
//     primary:"#EBF5FF",
//     secondary:"#7CC2FF",
//     text:"#0E4F89",
//   },
//   {
//     primary:"#E9FFF9",
//     secondary:"#60CDBB",
//     text:"#157564",
//   },
//   {
//     primary:"#FFFAEF",
//     secondary:"#C1A771",
//     text:"#835C0A",
//   },
//   {
//     primary:"#FFFFFF",
//     secondary:"#FFC57E",
//     text:"#83380A",
//   },
// ]

const getRandomColor = (): string => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.8;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const getContrastingColor = (baseColor: string): string => {
  // Extract RGB values from the base color
  const colorParts = baseColor.match(/(\d+),\s*(\d+),\s*(\d+)/);

  if (!colorParts) throw new Error("Invalid base color format");

  let r = parseInt(colorParts[1]);
  let g = parseInt(colorParts[2]);
  let b = parseInt(colorParts[3]);

  // Calculate contrasting values by inverting each color component
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  return `rgba(${r}, ${g}, ${b}, 0.8)`;
};

export const generateContrastingColors = (): [string, string] => {
  const color1 = getRandomColor();
  const color2 = getContrastingColor(color1);

  return [color1, color2];
};

const Skills = () => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const extractedSkills = extractedData?.toolsTechnicalSkills; //?.map((sk) => ({label:sk?.trim(),value:sk?.trim()}));
  const avialbleSkillsExist =
    extractedSkills.filter((skill) =>
      skillsData?.some(
        (selectedSkill) =>
          selectedSkill?.label?.toLowerCase() === skill.label?.toLowerCase()
      )
    ) ?? [];
  const remainingAvailableSkills = skillsData.filter(
    (skill) =>
      !extractedSkills?.some(
        (selectedSkill) =>
          selectedSkill?.label?.toLowerCase() === skill.label?.toLowerCase()
      )
  );
  const filteredExtractedSkills =
    extractedSkills
      ?.filter(
        (skill) =>
          !avialbleSkillsExist?.some(
            (selectedSkill) =>
              selectedSkill?.label?.toLowerCase() ===
              skill?.label?.toLowerCase()
          )
      )
      ?.map((sk) => ({
        ...sk,
        label: sk?.label?.trim(),
        value: sk?.label?.trim(),
        score: 5,
        total: 5,
      })) ?? [];
  const formattedExtractedSkills = [
    ...avialbleSkillsExist,
    ...filteredExtractedSkills,
  ];

  const [skills, setSkills] = useState<skillOption[]>(
    formattedExtractedSkills ?? []
  );
  const [availableSkills, setAvailableSkills] = useState(
    remainingAvailableSkills
  );

  const extractedNonTSkills = extractedData?.nonTechnicalSkills; //?.map((sk) => ({label:sk?.trim(),value:sk?.trim()}));
  const avialbleNonTSkillsExist =
    nonTechSkillsData.filter((skill) =>
      extractedNonTSkills?.some(
        (selectedSkill) =>
          selectedSkill?.label?.toLowerCase() === skill.label?.toLowerCase()
      )
    ) ?? [];
  const remainingAvailableNonTSkills = nonTechSkillsData.filter(
    (skill) =>
      !extractedNonTSkills?.some(
        (selectedSkill) =>
          selectedSkill?.label?.toLowerCase() === skill.label?.toLowerCase()
      )
  );
  const filteredExtractedNonTSkills =
    extractedNonTSkills
      ?.filter(
        (skill) =>
          !avialbleNonTSkillsExist?.some(
            (selectedSkill) =>
              selectedSkill?.label?.toLowerCase() ===
              skill?.label?.toLowerCase()
          )
      )
      ?.map((sk) => ({
        label: sk?.label?.trim(),
        value: sk?.label?.trim(),
        score: 5,
        total: 5,
      })) ?? [];
  const formattedExtractedNonTSkills = [
    ...avialbleNonTSkillsExist,
    ...filteredExtractedNonTSkills,
  ];

  const [nonTechSkills, setNonTechSkills] = useState<NonTechnicalSkill[]>(
    formattedExtractedNonTSkills ?? []
  );
  const [availableNonTechSkills, setAvailableNonTechSkills] = useState(
    remainingAvailableNonTSkills
  );

  // const [addCertifications, setAddCertifications] = useState(false);

  // useEffect(() => {
  //   if (
  //     extractedData?.certifications &&
  //     extractedData?.certifications?.length > 0
  //   ) {
  //     setAddCertifications(true);
  //   }
  // }, [extractedData?.certifications]);
  /**
   * Below use Effect is to get the value of already existing skills
   */
  useEffect(() => {
    if (skills?.length > 0) {
      dispatch(
        setExtractedData({
          ...extractedData,
          toolsTechnicalSkills: skills?.map((sk) => sk),
        })
      );
    } else {
      dispatch(
        setExtractedData({
          ...extractedData,
          toolsTechnicalSkills: [],
        })
      );
    }
  }, [skills]);

  /**
   * Below use Effect is to get the value of already existing nonTechnicalSkills
   */
  useEffect(() => {
    if (nonTechSkills?.length > 0) {
      dispatch(
        setExtractedData({
          ...extractedData,
          nonTechnicalSkills: nonTechSkills?.map((sk) => sk),
        })
      );
    } else {
      dispatch(
        setExtractedData({
          ...extractedData,
          nonTechnicalSkills: [],
        })
      );
    }
  }, [nonTechSkills]);

  const handleSkillChange = (event: any, newValue: any[]) => {
    let allSkills = newValue;
    if (regexTotestAddKeyWord(allSkills?.[allSkills?.length - 1]?.label)) {
      const val = allSkills?.[allSkills?.length - 1]?.value;
      allSkills[allSkills?.length - 1] = {
        label: val,
        value: val,
      };
    }
    allSkills = allSkills.map((sk, i) => {
      if (i === allSkills.length - 1) {
        return {
          ...sk,
          score: 5,
          total: 5,
          isGraph: allSkills[0].isGraph || false,
          graphType: allSkills[0].graphType || "linear",
        };
      }
      return sk;
    });
    setSkills(allSkills);
    const updatedAvailableSkills = skillsData.filter(
      (skill) =>
        !newValue.some((selectedSkill) => selectedSkill.value === skill.value)
    );
    setAvailableSkills(updatedAvailableSkills); // Update options after selection
  };

  const handleNonTechSkillChange = (event: any, newValue: any[]) => {
    let allnonTSkills = newValue;
    if (
      regexTotestAddKeyWord(allnonTSkills?.[allnonTSkills?.length - 1]?.label)
    ) {
      const val = allnonTSkills?.[allnonTSkills?.length - 1]?.value;
      allnonTSkills[allnonTSkills?.length - 1] = { label: val, value: val };
    }
    allnonTSkills = allnonTSkills.map((sk, i) => {
      if (i === allnonTSkills.length - 1) {
        return {
          ...sk,
          score: 5,
          total: 5,
          isGraph: allnonTSkills[0].isGraph || false,
          graphType: allnonTSkills[0].graphType || "linear",
        };
      }
      return sk;
    });
    setNonTechSkills(allnonTSkills);
    const updatedAvailableNonTexhSkills = nonTechSkillsData.filter(
      (skill) =>
        !newValue.some((selectedSkill) => selectedSkill.value === skill.value)
    );
    setAvailableNonTechSkills(updatedAvailableNonTexhSkills); // Update options after selection
  };

  const handleSkillDelete = (skillToDelete: string) => {
    const updatedSkills = skills.filter(
      (skill) => skill.value !== skillToDelete
    );
    const deletedSkill = skillsData.find(
      (skill) => skill.value === skillToDelete
    );
    if (deletedSkill) {
      setAvailableSkills([...availableSkills, deletedSkill]);
    }
    setSkills(updatedSkills);
  };

  const handleNonTechSkillDelete = (skillToDelete: string) => {
    const updatedSkills = nonTechSkills.filter(
      (skill) => skill.value !== skillToDelete
    );
    const deletedSkill = nonTechSkillsData.find(
      (skill) => skill.value === skillToDelete
    );
    if (deletedSkill) {
      setAvailableNonTechSkills([...availableNonTechSkills, deletedSkill]);
    }
    setNonTechSkills(updatedSkills);
  };

  const handleSectionDelete = () => {
    return null;
  };

  const handleSkillScoreChange = (event: any, skill: skillOption) => {
    const updatedSkills = skills.map((sk) =>
      sk.value === skill.value
        ? { ...sk, [event.target.name]: +event.target.value }
        : sk
    );
    setSkills(updatedSkills);
  };

  const handleSkillsGraphChange = (event: any, name: string) => {
    const updatedSkills = skills.map((sk) => ({
      ...sk,
      [name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
    setSkills(updatedSkills);
  };

  const handleNonTechSkillScoreChange = (
    event: any,
    skill: NonTechnicalSkill
  ) => {
    const updatedSkills = nonTechSkills.map((sk) =>
      sk.value === skill.value
        ? { ...sk, [event.target.name]: +event.target.value }
        : sk
    );
    setNonTechSkills(updatedSkills);
  };

  const handleNonTechSkillsGraphChange = (event: any, name: string) => {
    const updatedSkills = nonTechSkills.map((sk) => ({
      ...sk,
      [name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
    setNonTechSkills(updatedSkills);
  };

  console.log(skills, "from skills");

  return (
    <Box className="skills-container">
      <Box className="skills-wrapper">
        <Box className="skills-content">
          <Box className="main-heading">
            <Box className="heading-box">
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Technical Skills
                {extractedData.tailoredResumeObject?.tailoredResumeFeedback?.technicalSkills && (
                  <ImproveResumeBtn
                    section={"technicalSkills"}
                    value={
                      extractedData?.tailoredResumeObject?.tailoredResumeFeedback?.technicalSkills
                    }
                    addMissingSkill={(newSkill: skillOption) => setSkills([...skills, newSkill])}
                    updateDesignation={() => {
                      console.log(extractedData);
                      // handleChange(
                      //   extractedData.tailoredResumeObject?.jobDetails?.jobTitle,
                      //   "jobTitle"
                      // );
                      // setExtractedData({
                      //   ...extractedData,
                      //   tailoredResumeObject: {
                      //     ...extractedData.tailoredResumeObject,
                      //     matchingScore:
                      //       (extractedData.tailoredResumeObject as any)?.matchingScore +
                      //       (extractedData.tailoredResumeObject?.tailoredResumeFeedback["designation"]?.maxScore),
                      //   },
                      // } as any);
                    }}
                  />
                )}
              </Typography>
            </Box>
          </Box>

          <Box className="skills-section">
            <Box className="input-box-container">
              <Autocomplete
                multiple
                id="skills"
                fullWidth
                options={availableSkills} // Use updated available skills
                //@ts-ignore
                value={skills}
                //value={skills.map(({label,value}) => ({label,value}))}
                onChange={handleSkillChange}
                renderTags={() => null}
                renderOption={(props, option) => {
                  if (option?.label?.includes("Add ")) {
                    const newStr = option.label.replace(/^Add\s*/, "");
                    return (
                      <li
                        {...props}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AddSkillIcon />
                        <span style={{ color: "#5594ff", margin: "0 0.25rem" }}>
                          Add {newStr}{" "}
                        </span>
                      </li>
                    );
                  }

                  return (
                    <li
                      {...props}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {option.icon && (
                        <option.icon
                          style={{
                            marginRight: "8px",
                            width: "26px",
                            height: "26px",
                          }}
                        />
                      )}
                      {option.label}
                    </li>
                  );
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Eg.Java"
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>

            {skills.length > 0 && (
              <>
                <Grid2
                  container
                  spacing={1}
                  alignItems={"center"}
                  marginBottom={2}
                >
                  <Grid2 xs={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={skills?.[0]?.isGraph}
                          onChange={(e) =>
                            handleSkillsGraphChange(e, "isGraph")
                          }
                        />
                      }
                      label="Make it a Graph"
                    />
                  </Grid2>
                  {skills?.[0]?.isGraph && (
                    <Grid2 xs={8} textAlign={"right"}>
                      <ToggleButtonGroup
                        color="primary"
                        value={skills?.[0]?.graphType}
                        exclusive
                        onChange={(e) =>
                          handleSkillsGraphChange(e, "graphType")
                        }
                        aria-label="Graph Type"
                        size="small"
                      >
                        <ToggleButton value="linear">Linear</ToggleButton>
                        <ToggleButton value="lollipop">Lollipop</ToggleButton>
                        <ToggleButton value="circle">Circle</ToggleButton>
                        <ToggleButton value="star">Star</ToggleButton>
                        <ToggleButton value="circular">Circular</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid2>
                  )}
                </Grid2>
                <Stack spacing={1}>
                  {skills?.map((skill, index) => (
                    <>
                      <Grid2 container spacing={1} alignItems={"center"}>
                        <Grid2 xs={1}>
                          {skill.icon ? (
                            <skill.icon height={20} width={20} />
                          ) : (
                            <Avatar
                              sx={{
                                background: `${
                                  avatarColors[index % avatarColors.length]
                                }`,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "11px", color: "#fff" }}
                              >
                                {skill?.value?.[0]?.toUpperCase()}
                              </Typography>
                            </Avatar>
                          )}
                        </Grid2>
                        <Grid2 xs={6}>
                          <Typography>{skill?.value}</Typography>
                        </Grid2>
                        <Grid2 xs={2}>
                          <TextField
                            label="Score"
                            value={skill?.score}
                            onChange={(e) => handleSkillScoreChange(e, skill)}
                            name="score"
                            type="number"
                          />
                        </Grid2>
                        <Grid2 xs={2}>
                          <TextField
                            label="Total"
                            value={skill?.total}
                            onChange={(e) => handleSkillScoreChange(e, skill)}
                            name="total"
                            type="number"
                          />
                        </Grid2>
                        <Grid2 xs={1}>
                          <IconButton
                            onClick={() => handleSkillDelete(skill.value)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                    </>
                  ))}
                </Stack>
              </>
            )}
          </Box>

          <Box className="main-heading">
            <Box className="heading-box">
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Soft Skills
                {extractedData.tailoredResumeObject?.tailoredResumeFeedback?.softSkills && (
                  <ImproveResumeBtn
                    section={"softSkills"}
                    value={
                      extractedData?.tailoredResumeObject?.tailoredResumeFeedback?.softSkills
                    }
                    addMissingSkill={(newSkill: NonTechnicalSkill) => setNonTechSkills([...nonTechSkills, newSkill])}
                    updateDesignation={() => {
                      console.log(extractedData);
                      // handleChange(
                      //   extractedData.tailoredResumeObject?.jobDetails?.jobTitle,
                      //   "jobTitle"
                      // );
                      // setExtractedData({
                      //   ...extractedData,
                      //   tailoredResumeObject: {
                      //     ...extractedData.tailoredResumeObject,
                      //     matchingScore:
                      //       (extractedData.tailoredResumeObject as any)?.matchingScore +
                      //       (extractedData.tailoredResumeObject?.tailoredResumeFeedback["designation"]?.maxScore),
                      //   },
                      // } as any);
                    }}
                  />
                )}
              </Typography>
            </Box>
          </Box>

          <Box className="skills-section">
            <Box className="input-box-container">
              <Autocomplete
                multiple
                id="nonTeckSkills"
                fullWidth
                options={availableNonTechSkills} // Use updated available skills
                //@ts-ignore
                value={nonTechSkills}
                onChange={handleNonTechSkillChange}
                renderTags={() => null}
                renderOption={(props, option) => {
                  if (option?.label?.includes("Add ")) {
                    const newStr = option.label.replace(/^Add\s*/, "");
                    return (
                      <li
                        {...props}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AddSkillIcon />
                        <span style={{ color: "#5594ff", margin: "0 0.25rem" }}>
                          Add {newStr}{" "}
                        </span>
                      </li>
                    );
                  }

                  return (
                    <li
                      {...props}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {option.icon}
                      {option.label}
                    </li>
                  );
                }}
                filterOptions={(options, params) => {
                  const filtered = nonTechFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Eg.Communication"
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>

            {nonTechSkills.length > 0 && (
              <>
                <Grid2
                  container
                  spacing={1}
                  alignItems={"center"}
                  marginBottom={2}
                >
                  <Grid2 xs={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={nonTechSkills?.[0]?.isGraph}
                          onChange={(e) =>
                            handleNonTechSkillsGraphChange(e, "isGraph")
                          }
                        />
                      }
                      label="Make it a Graph"
                    />
                  </Grid2>
                  {nonTechSkills?.[0]?.isGraph && (
                    <Grid2 xs={8} textAlign={"right"}>
                      <ToggleButtonGroup
                        color="primary"
                        value={nonTechSkills?.[0]?.graphType}
                        exclusive
                        onChange={(e) =>
                          handleNonTechSkillsGraphChange(e, "graphType")
                        }
                        aria-label="Graph Type"
                        size="small"
                      >
                        <ToggleButton value="linear">Linear</ToggleButton>
                        <ToggleButton value="lollipop">Lollipop</ToggleButton>
                        <ToggleButton value="circle">Circle</ToggleButton>
                        <ToggleButton value="star">Star</ToggleButton>
                        <ToggleButton value="circular">Circular</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid2>
                  )}
                </Grid2>
                <Stack spacing={1}>
                  {nonTechSkills?.map((skill, index) => (
                    <>
                      <Grid2 container spacing={1} alignItems={"center"}>
                        <Grid2 xs={1}>
                          {skill.icon ? (
                            skill.icon
                          ) : (
                            <Avatar
                              sx={{
                                background: `${
                                  avatarColors[index % avatarColors.length]
                                }`,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "11px", color: "#fff" }}
                              >
                                {skill?.value?.[0]?.toUpperCase()}
                              </Typography>
                            </Avatar>
                          )}
                        </Grid2>
                        <Grid2 xs={6}>
                          <Typography>{skill?.value}</Typography>
                        </Grid2>
                        <Grid2 xs={2}>
                          <TextField
                            label="Score"
                            value={skill?.score}
                            onChange={(e) =>
                              handleNonTechSkillScoreChange(e, skill)
                            }
                            name="score"
                            type="number"
                          />
                        </Grid2>
                        <Grid2 xs={2}>
                          <TextField
                            label="Total"
                            value={skill?.total}
                            onChange={(e) =>
                              handleNonTechSkillScoreChange(e, skill)
                            }
                            name="total"
                            type="number"
                          />
                        </Grid2>
                        <Grid2 xs={1}>
                          <IconButton
                            onClick={() =>
                              handleNonTechSkillDelete(skill.value)
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                    </>
                  ))}
                </Stack>
              </>
            )}

            {/* {nonTechSkills.length > 0 && (
              <Box
                sx={{
                  marginTop: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1.4,
                  padding: 0,
                  borderRadius: "8px",
                }}
              >
                {nonTechSkills?.map((skill, index) => (
                  <Box>
                    <Chip
                      sx={{
                        height: "2.2rem",
                        fontSize: "14px",
                        fontWeight: 400,
                        padding: "0.3rem",
                        border: "1px solid #e2e8f0",
                        borderRadius: "8px",
                        position: "relative",
                        "& .MuiChip-deleteIcon": {
                          position: "absolute",
                          top: "-7px",
                          right: "-13px",
                          fontSize: "17px",
                        },
                        "& .MuiChip-label": {
                          paddingLeft: skill.icon ? "0rem" : "auto", // Example padding for the label
                        },
                      }}
                      avatar={
                        skill.icon ? (
                          skill.icon
                        ) : (
                          <Avatar
                            sx={{
                              background: `${
                                avatarColors[
                                  avatarColors.length -
                                    2 -
                                    (index % avatarColors.length)
                                ]
                              }`,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "11px", color: "#fff" }}
                            >
                              {skill?.value?.[0]?.toUpperCase()}
                            </Typography>
                          </Avatar>
                        )
                      } //`${avatarIconColors[avatarIconColors.length - 2 - index%avatarIconColors.length]}`
                      label={
                        regexTotestAddKeyWord(skill.label)
                          ? skill.value
                          : skill.label
                      }
                      variant="outlined"
                      onDelete={() => handleNonTechSkillDelete(skill.value)}
                    />
                  </Box>
                ))}
              </Box>
            )} */}
          </Box>

          {/* <Box className="main-heading">
            <Box
              className="heading-box"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                color="color.primary"
                className="heading"
              >
                Certifications
              </Typography>

              {!addCertifications && (
                <Box className="add-course-button-cont">
                  <Typography
                    className="summary-ui-brand"
                    onClick={() => setAddCertifications(true)}
                  >
                    + Add Certifications{" "}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          {addCertifications && (
            <Box className="summary-container" sx={{ padding: "0 0 1rem 0" }}>
              <CertificationList
                handleSectionDelete={handleSectionDelete}
                sectionName={"Certifications"}
                handleDeleteEmptySection={() => {}}
              />
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default Skills;
