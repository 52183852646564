import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const KeyAchievementsTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
}) => {
  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
  });

  return (
    <>
      {extractedData?.keyAchievements && (
        <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>Key Achievements</Text>
          <View>
            <Text style={styles.sectionContent}>
              {extractedData?.keyAchievements}
            </Text>
          </View>
        </View>
      )}
    </>
  );
};

export default KeyAchievementsTemplate;
