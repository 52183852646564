import { Box, ListItem, Switch, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import { EducationTypes } from "../../../components/utils";
import {
  setExtractedData,
  setPriorToggleDetails,
} from "../../../redux/actions";
import EducationEdit from "./EducationEdit";
import EducationCardWrapper from "./EducationCardWrapper";
import { styled } from "@mui/material/styles";
import { AntSwitch } from "../WorkExperience/WorkExperienceList";
import { ReactComponent as NoEducationIcon } from "../../../assets/SVG/no-education-big-icon.svg";
import { ReactComponent as NoEducationalDetails } from "./../../../assets/SVG/no-education-details.svg";

interface CoursesListProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
}

const DEFAULT_FORM_DATA: EducationTypes = {
  universityCollege: "",
  startDate: "",
  endDate: "",
  degree: "",
  location: "",
  specialization: "",
  isEdit: true,
  isNewEntry: true,
};

const EducationList: React.FC = () => {
  const { extractedData, priorToggle } = useSelector(
    (state: StateProps) => state,
  );
  const dispatch = useDispatch();
  const [coursesList, setCoursesList] = useState<EducationTypes[]>(
    extractedData?.educationalDetails ?? [],
  );
  const [draggingItemIndex, setDraggingItemIndex] = useState<number | null>(
    null,
  );

  //Adding key to extrated course elements
  useEffect(() => {
    if (!extractedData?.educationalDetails?.[0]) {
      setCoursesList([{ ...DEFAULT_FORM_DATA, index: 0 }]);
    } else {
      const updatedList = extractedData?.educationalDetails?.map(
        (course, i) => {
          return { ...course, isEdit: false, index: i };
        },
      );
      setCoursesList(updatedList ?? []);
    }
  }, [extractedData?.educationalDetails]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    const updateCourseList = coursesList?.map((course) => ({
      ...course,
      isEdit: false,
    }));
    setCoursesList([
      ...updateCourseList,
      { ...DEFAULT_FORM_DATA, index: coursesList?.length ?? 1 },
    ]);
  };

  const handleDragStart = (index: number) => {
    setDraggingItemIndex(index);
  };

  const handleDragOver = (e: any, index: number) => {
    e.preventDefault();
  };

  const handleDrop = (index: number) => {
    const draggedOverItem = extractedData?.educationalDetails?.[index];

    // If the item is dragged over itself, don't change the list
    if (draggingItemIndex === index) {
      return;
    }

    if (
      draggedOverItem &&
      (draggingItemIndex || draggingItemIndex === 0) &&
      extractedData?.educationalDetails?.[draggingItemIndex]
    ) {
      const updatedItems = [...extractedData?.educationalDetails];

      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[draggingItemIndex];
      updatedItems[draggingItemIndex] = temp;

      dispatch(
        setExtractedData({
          ...extractedData,
          educationalDetails: updatedItems,
        }),
      );
      setDraggingItemIndex(index);
    }
  };

  const handleDragEnd = () => {
    setDraggingItemIndex(null);
  };

  const handlePriorEduChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(
        setExtractedData({
          ...extractedData,
          educationalDetails: priorToggle?.educationList,
        }),
      );
      dispatch(
        setPriorToggleDetails({
          ...priorToggle,
          educationFlag: true,
          educationList: [],
        }),
      );
    } else {
      dispatch(
        setPriorToggleDetails({
          ...priorToggle,
          educationFlag: false,
          educationList: extractedData?.educationalDetails,
        }),
      );
      dispatch(setExtractedData({ ...extractedData, educationalDetails: [] }));
    }
  };

  return (
    <Box
      className="summary-container"
      sx={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <Box
        className="courses-section"
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Box className="heading-box">
          <Typography variant="h5" color="color.primary" className="heading">
            Education
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Typography
              sx={{ fontSize: "1.125rem", color: "#344054", fontWeight: "500" }}
            >
              Have Prior Education?
            </Typography>
            {/* <Switch defaultChecked /> */}
            <AntSwitch
              checked={priorToggle?.educationFlag}
              onChange={handlePriorEduChange}
              inputProps={{ "aria-label": "ant design" }}
            />
          </Box>
        </Box>

        {!priorToggle?.educationFlag ? (
          <Box
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Box>
              <NoEducationIcon />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  lineHeight: "2em",
                  color: "#344054",
                  marginBottom: "0.5rem",
                }}
              >
                No Education added
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "19.12px",
                  color: "#5A697E",
                }}
              >
                You can click on next to continue
              </Typography>
            </Box>
          </Box>
        ) : (
          coursesList?.map((course, index) => (
            <ListItem
              className="card-wrapper"
              draggable={course?.isEdit ? false : true}
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={() => handleDrop(index)}
              onDragEnd={handleDragEnd}
              sx={
                draggingItemIndex === index
                  ? {
                      transition: "transform 0.3s ease",
                      opacity: "0.7",
                      transform: "scale(1.05)",
                      cursor: course?.isEdit ? "none" : "grab",
                    }
                  : {}
              }
            >
              {course?.isEdit ? (
                <EducationEdit
                  courseData={course}
                  coursesList={coursesList}
                  setCoursesList={setCoursesList}
                  index={index}
                />
              ) : (
                <EducationCardWrapper
                  courseData={course}
                  coursesList={coursesList}
                  setCoursesList={setCoursesList}
                />
              )}
            </ListItem>
          ))
        )}

        {!coursesList?.[coursesList?.length - 1]?.isNewEntry && (
          <Box className="add-course-button-cont">
            <Typography className="summary-ui-brand" onClick={handleAddCourse}>
              + Add More Education
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EducationList;
