import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const JobBoardFilters = () => {
  return (
    <Box
      sx={{
        minWidth: "350px",
        borderRight: "1px solid #E2E8F0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          pb: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Filters
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", color: "#EF4444" }}
        >
          Clear All
        </Typography>
      </Box>

      <Divider />
    </Box>
  );
};

export default JobBoardFilters;
