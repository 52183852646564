import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  Circle,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5077_85557)">
        <rect width="596" height="842" fill="white" />
        <circle cx="630" cy="617" r="119" stroke="#FFD799" strokeWidth="60" />
        <circle cy="280" r="169" stroke="#FBEA81" strokeWidth="120" />
      </g>
      <defs>
        <clipPath id="clip0_5077_85557">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5077_85557)">
        <Rect width="596" height="842" fill="white" />
        <Circle
          cx="630"
          cy="617"
          r="119"
          stroke={props?.c1 || "#FFD799"}
          strokeWidth="60"
          fill="#fff"
        />
        <Circle
          cy="280"
          r="169"
          stroke={props?.c2 || "#FBEA81"}
          strokeWidth="120"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5077_85557">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture15 = {
  ViewTexture,
  PdfTexture,
};

export default Texture15;
