import { Grid, Box, Typography, Chip, Avatar } from "@mui/material";
import { Template1 } from "../../templates";
import { PDFIcon, UploadIcon, WordIcon } from "./../Icons";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDropzone } from "react-dropzone";
import { getToken } from "../auth/storeToken";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils";
import ResumesList from "../ResumesList";
import { fetchResumes, withAuthHandling } from "../../services/apiService";

const ResultsList = (props: any) => {
  const { onFileUpload, itemClickHandler } = props;
  const navigate = useNavigate();
  const [resumes, setResumes] = useState([]);
  const fetchWithAuthHandling = withAuthHandling(navigate);

  useEffect(() => {
    const loadResumes = async () => {
      try {
        const data = await fetchResumes(fetchWithAuthHandling);
        setResumes(data);
      } catch (error) {
        console.error("Error fetching resumes:", error);
      }
    };

    loadResumes();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      onFileUpload(acceptedFiles);
    },
  });

  const dragAndDropElement = (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: "1.5px dashed #B7B7B7",
          borderRadius: "8px",
          overflow: "hidden",
          height: "340px",
          mb: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 1.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon />
          <Typography sx={{ fontSize: "0.9rem", textAlign: "center" }}>
            Drag & Drop Resume here <br /> or <br />{" "}
            <span style={{ color: "#5594FF", textDecoration: "underline" }}>
              Browse
            </span>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "1rem", textAlign: "center" }}>
          Upload your Resume
        </Typography>
      </Box>
    </Box>
  );

  return (
    <ResumesList
      prependElement={dragAndDropElement}
      itemClickHandler={itemClickHandler}
    />
  );
};

export default ResultsList;
