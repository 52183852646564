import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const GradientButton = styled(Button)<GradientButtonProps>(
  ({ theme, size }) => ({
    cursor: "pointer",
    borderRadius: "8px",
    border: "1px solid var(--ui-background, #F8FAFC)",
    color: "#F8FAFC",
    background: "linear-gradient(90deg, #D080FF 10%, #687AF3 60%, #5594FF 80%)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      background:
        "linear-gradient(90deg, #C070FF 10%, #577AF3 60%, #4594FF 80%)",
      boxShadow: "none",
    },
    ...(size === "small" && {
      fontSize: "12px",
      minWidth: "fit-content",
      padding: "2px 10px 2px 6px",
    }),
  }),
);

interface GradientButtonProps {
  text?: string;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  children?: React.ReactNode;
}

const GradientButtonComponent: React.FC<GradientButtonProps> = ({
  text,
  onClick,
  size = "medium",
  children,
}) => {
  return (
    <GradientButton onClick={onClick} size={size}>
      {children}
      {text}
    </GradientButton>
  );
};

export default GradientButtonComponent;
