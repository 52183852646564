import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import CardEditHeader from "../../../../components/common/CardEditHeader";
import { TInternships } from "../../../../components/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { usePickerPopperOffset } from "../../../../components/Hooks/usePickerPopperOffeset";
import classNames from "classnames";
import { ReactComponent as DatePickerIcon } from "../../../../assets/SVG/date-picker.svg";
import ReactQuill from "react-quill";
import { quillModules } from "../../../../App";

interface CourseProps {
  courseData: TInternships;
  coursesList: TInternships[];
  setCoursesList: Dispatch<SetStateAction<TInternships[]>>;
  index: number;
  sectionName: string;
  handleDeleteEmptySection: (sectionName: string) => void;
}

const InternshipEdit: React.FC<CourseProps> = ({
  courseData,
  coursesList,
  setCoursesList,
  index,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const [addLink, setAddLink] = useState<boolean>(false);
  //formData handles local form state
  const [formData, setFormData] = useState<TInternships>(courseData);
  const [errors, setErrors] = useState({
    internshipTitle: "",
    organization: "",
    location: "",
    startDate: "",
    endDate: "",
  });

  /**
   * Date Piciker popper position
   */
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const startDateOffset = usePickerPopperOffset(startDateRef);
  const endDateOffset = usePickerPopperOffset(endDateRef);

  const handleChange = (newValue: any, key: string) => {
    // if(key === "courseDescription") {
    //     const wordLimit = 50
    //     const wordCount = newValue.trim().split(/\s+/).filter(Boolean).length;
    //     if(wordCount<=wordLimit){
    //         setFormData({
    //             ...formData,
    //             [key]: newValue,
    //           });
    //     }
    // }
    // else
    // {
    setFormData({
      ...formData,
      [key]: newValue,
    });
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
    // }
  };

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}-${day}-${year}`;
    if (formData.endDate && newValue > new Date(formData.endDate)) {
      handleChange(null, "endDate");
    }
    handleChange(formattedDate, "startDate");
  };

  const handleStartDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, startDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, startDate: "" }));
    }
  };

  const handleEndDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, endDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, endDate: "" }));
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}-${day}-${year}`;
    handleChange(formattedDate, "endDate");
  };

  const handleCancel = () => {
    const updatedCourses = !!formData?.isNewEntry
      ? coursesList.slice(0, -1)
      : coursesList;
    if (!!formData?.isNewEntry) {
      setCoursesList(updatedCourses);
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (courEle?.internshipTitle === courseData.internshipTitle) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }

    //  Below Method remove +Add More when there is no element present
    if (!updatedCourses?.[0]) {
      handleDeleteEmptySection(sectionName);
    }
  };

  const handleSave = () => {
    if (!formData?.internshipTitle) {
      setErrors((prev) => ({
        ...prev,
        internshipTitle: "Internship Title is required",
      }));
    }
    if (!formData?.organization) {
      setErrors((prev) => ({
        ...prev,
        organization: "Organization is required",
      }));
    }
    if (!formData?.location) {
      setErrors((prev) => ({
        ...prev,
        location: "Location is required",
      }));
    }
    if (!formData?.startDate) {
      setErrors((prev) => ({
        ...prev,
        startDate: "Start Date is required",
      }));
    }

    if (!formData?.endDate) {
      setErrors((prev) => ({
        ...prev,
        endDate: "End Date is required",
      }));
    }
    // if(!isValidUrl(addLink,formData?.link ?? "")) {
    //     setErrors((prev)=>({
    //         ...prev,
    //         url:"Invalid URL"
    //       }))
    //   }
    if (
      formData?.internshipTitle &&
      formData?.organization &&
      formData?.location &&
      formData?.startDate &&
      formData?.endDate
    ) {
      //checking if link is present then It must contain the valid url
      // if(!isValidUrl(addLink,formData?.link ?? "")) {
      //   setErrors((prev)=>({
      //       ...prev,
      //       url:"Invalid URL"
      //     }))
      // }
      // else {
      //perform new course entry
      if (formData?.isNewEntry) {
        /**
         * New Entry
         */
        delete formData?.isNewEntry;
        delete formData?.index;
        dispatch(
          setExtractedData({
            ...extractedData,
            internships: [
              ...(extractedData.internships ?? []),
              { ...formData, isEdit: false },
            ],
          })
        );
      } else {
        const updatedCourses = coursesList?.map((course) => {
          if (course?.index === formData?.index) {
            delete formData?.index;
            return { ...formData, isEdit: false };
          }
          delete course?.index;
          return course;
        });
        dispatch(
          setExtractedData({
            ...extractedData,
            internships: updatedCourses,
          })
        );
      }
      //both cases remove error
      setErrors({
        internshipTitle: "",
        organization: "",
        location: "",
        startDate: "",
        endDate: "",
      });
      // }
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
          margin:
            index === 0 && courseData?.isNewEntry
              ? "0 0 1rem 0 !important"
              : "1rem 0",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.organization,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="input-box-container">
          <InputLabel htmlFor="company">Internship Title*</InputLabel>
          <TextField
            fullWidth
            id="internshipTitle"
            name="internshipTitle"
            placeholder="Eg: Software Engineer"
            value={formData?.internshipTitle}
            onChange={(e) => handleChange(e.target.value, "internshipTitle")}
            error={!!errors.internshipTitle}
            helperText={errors.internshipTitle ? errors.internshipTitle : ""}
          />
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container">
            <InputLabel htmlFor="company">Organization*</InputLabel>
            <TextField
              fullWidth
              id="Organization"
              name="Organization"
              placeholder="Eg: Microsoft Corporation"
              value={formData?.organization}
              onChange={(e) => handleChange(e.target.value, "organization")}
              error={!!errors.organization}
              helperText={errors.organization ? errors.organization : ""}
            />
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Location*</InputLabel>
            <TextField
              fullWidth
              id="Location"
              name="Location"
              placeholder="Eg: New York"
              value={formData?.location}
              onChange={(e) => handleChange(e.target.value, "location")}
              error={!!errors.location}
              helperText={errors.location ? errors.location : ""}
            />
          </Box>
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container" ref={startDateRef}>
            <InputLabel htmlFor="startDate">Start Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.startDate}
                className={classNames(
                  { "date-error": !!errors.startDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={
                    formData?.startDate ? new Date(formData.startDate) : null
                  }
                  onChange={handleStartDateChange}
                  onError={handleStartDateError}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: startDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.startDate && (
                  <FormHelperText>{errors?.startDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>

          <Box className="input-box-container" ref={endDateRef}>
            <InputLabel htmlFor="endDate">End Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.endDate}
                className={classNames(
                  { "date-error": !!errors.endDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={formData?.endDate ? new Date(formData.endDate) : null}
                  onChange={handleEndDateChange}
                  onError={handleEndDateError}
                  minDate={
                    formData?.startDate
                      ? new Date(formData?.startDate)
                      : undefined
                  }
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: endDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.endDate && (
                  <FormHelperText>{errors?.endDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box className="input-box-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <InputLabel htmlFor="address">Description</InputLabel>
            {/* <Box
                sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 2,
                // backgroundColor: '#0088ff',
                background:"linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
                color:"#fff",
                borderRadius: "5.71px",
                cursor: 'pointer',
                px: 1,
                py: 0.5,
                }}
                >
                <AiIcon /> <Typography variant="caption" sx={{fontWeight:"500",marginLeft:"0.3rem",fontSize:"1rem"}} onClick={()=>setWorkExpModal(true)}>Description with AI</Typography>
            </Box> */}
          </Box>
          <Box className="quill-container">
            <ReactQuill
              value={formData?.description}
              onChange={(e: any) => handleChange(e, "description")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>

        {/* <Box className="input-box-container">
    <InputLabel htmlFor="address">
    Description
    </InputLabel>
    <TextField
    multiline
    rows={2}
    fullWidth
    value={formData?.courseDescription}
    id="address"
    placeholder="Add the relevant description."
    onChange={(e) => handleChange(e.target.value,"courseDescription")}
    />
     <Typography
        variant="body2"
        className="word-count-text"
      >
        {`${formData?.courseDescription?.trim().split(/\s+/).filter(Boolean).length}/50 words`}
      </Typography>
   </Box> */}

        {/* {addLink &&   <Box className="input-box-container">
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
    <InputLabel htmlFor="link">Link*</InputLabel>
    <Typography className="delete-link-section" onClick={()=>setAddLink(false)}>Delete</Typography>
    </Box>
    <TextField
        fullWidth
        id="link"
        name="link"
        placeholder="Eg. www.google.com"
      value={formData?.link}
      onChange={(e) => handleChange(e.target.value, 'link')}
      error={!!errors.url}
      helperText={errors.url ? errors.url : ''}
    />
    </Box>} */}

        <Box className="section-action">
          <Box className="link-container">
            {/* {!addLink && <Typography className="add-link-text" onClick={()=>setAddLink(true)}>+ Add Link</Typography>} */}
          </Box>
          {
            <Box className="button-container">
              {/* courseData?.isNewEntry && */}
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Add
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default InternshipEdit;
