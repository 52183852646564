import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4983_23705)">
        <rect width="596" height="842" fill="white" />
        <path
          opacity="0.9"
          d="M596 825C581.438 831.368 566.771 837.035 552 842H596V825Z"
          fill="url(#paint0_radial_4983_23705)"
        />
        <path
          opacity="0.5"
          d="M134.089 841.05C134.72 841.367 135.299 841.683 135.825 842H233C176.682 822.849 125.413 793.727 82.5044 757.64C50.0075 730.417 22.4008 699.237 0 666V736.906C36.914 777.266 82.1889 813.036 134.089 841.05Z"
          fill="#184673"
        />
        <g opacity="0.9">
          <path
            opacity="0.1"
            d="M596 823.933C580.191 830.73 564.224 836.738 548.099 841.797H596V823.933Z"
            fill="#184673"
          />
          <path
            opacity="0.1"
            d="M596 796.426C594.208 797.374 592.416 798.323 590.625 799.271C552.999 818.4 514.267 832.312 475.377 841.797H596V796.426Z"
            fill="#184673"
          />
          <path
            opacity="0.1"
            d="M230.97 841.797C188.443 831.521 149.237 816.344 113.825 798.322C76.3575 779.193 43.6329 757.218 14.8605 734.612C9.80159 730.659 4.90079 726.549 0 722.597V839.741C0.737754 840.374 1.42282 841.059 2.05518 841.797H230.97Z"
            fill="#184673"
          />
        </g>
        <g opacity="0.9">
          <path
            opacity="0.4"
            d="M44.8976 841.797C25.4525 817.609 10.4339 793.263 0 769.866V841.797H44.8976Z"
            fill="url(#paint1_radial_4983_23705)"
          />
          <path
            opacity="0.4"
            d="M37.4673 841.796H98.4901C73.9861 820.454 52.644 797.373 34.6217 773.343C21.1841 755.321 9.64349 736.825 0 718.17V801.167C11.5406 815.237 24.0297 828.675 37.4673 841.796Z"
            fill="url(#paint2_radial_4983_23705)"
          />
        </g>
        <g clip-path="url(#clip1_4983_23705)">
          <path
            opacity="0.1"
            d="M-63.4707 37.1532C33.5442 69.4851 140.501 83.4389 249.342 75.441C366.069 66.7624 485.366 32.7289 596.436 -26.1492C558.727 -19.1723 527.703 -18.4916 507.477 -27C395.379 19.2856 277.281 40.2163 164.497 35.9621C98.1636 33.4096 33.3728 22.1785 -27.6472 3.46003L-63.4707 37.1532Z"
            fill="#184673"
          />
          <path
            opacity="0.1"
            d="M-45.1304 19.9665C29.4305 38.3446 110.162 46.6828 193.465 41.9182C284.309 36.8131 378.581 15.8825 469.94 -22.2351C379.781 -0.964123 294.936 1.24806 221.746 -9.64268C145.986 -3.34647 71.596 -8.79184 1.49163 -24.1069L-45.1304 19.9665Z"
            fill="#184673"
          />
          <path
            opacity="0.1"
            d="M-58.3291 50.7671C-49.0733 53.7167 -39.7032 56.5528 -30.2188 59.2755C88.5645 74.5906 219.003 66.9331 348.414 28.6453C267.339 41.2377 188.665 41.4079 115.989 31.7083C67.1389 25.242 21.0311 14.1811 -21.4772 -0.283203L-58.3291 50.7671Z"
            fill="#184673"
          />
          <path
            opacity="0.4"
            d="M-117.807 89.6488C102.791 110.358 181.637 82.6317 358.183 -13.897C216.26 31.4578 65.4246 51.6535 -89.6963 39.1596L-117.807 89.6488Z"
            fill="#184673"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7313.7 10685.6) rotate(6.29494) scale(480.429 185.491)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.43" stop-color="#00A4E3" />
          <stop offset="0.66" stop-color="#003669" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(452020 70933.5) rotate(-69.22) scale(26631.1 42666)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.43" stop-color="#00A4E3" />
          <stop offset="0.66" stop-color="#003669" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(413079 -178393) rotate(-100.646) scale(61512.6 77211.7)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.43" stop-color="#00A4E3" />
          <stop offset="0.66" stop-color="#003669" />
        </radialGradient>
        <clipPath id="clip0_4983_23705">
          <rect width="596" height="842" fill="white" />
        </clipPath>
        <clipPath id="clip1_4983_23705">
          <rect width="596" height="95" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_4983_23705)">
        <Rect width="596" height="842" fill="white" />
        <Path
          opacity="0.9"
          d="M596 825C581.438 831.368 566.771 837.035 552 842H596V825Z"
          fill={props?.c2 || "#003669"}
        />
        <Path
          opacity="0.5"
          d="M134.089 841.05C134.72 841.367 135.299 841.683 135.825 842H233C176.682 822.849 125.413 793.727 82.5044 757.64C50.0075 730.417 22.4008 699.237 0 666V736.906C36.914 777.266 82.1889 813.036 134.089 841.05Z"
          fill={props?.c1 || "#0081CC"}
        />
        <G opacity="0.9">
          <Path
            opacity="0.1"
            d="M596 823.933C580.191 830.73 564.224 836.738 548.099 841.797H596V823.933Z"
            fill={props?.c1 || "#0081CC"}
          />
          <Path
            opacity="0.1"
            d="M596 796.426C594.208 797.374 592.416 798.323 590.625 799.271C552.999 818.4 514.267 832.312 475.377 841.797H596V796.426Z"
            fill={props?.c1 || "#0081CC"}
          />
          <Path
            opacity="0.1"
            d="M230.97 841.797C188.443 831.521 149.237 816.344 113.825 798.322C76.3575 779.193 43.6329 757.218 14.8605 734.612C9.80159 730.659 4.90079 726.549 0 722.597V839.741C0.737754 840.374 1.42282 841.059 2.05518 841.797H230.97Z"
            fill={props?.c1 || "#0081CC"}
          />
        </G>
        <G opacity="0.9">
          <Path
            opacity="0.4"
            d="M44.8976 841.797C25.4525 817.609 10.4339 793.263 0 769.866V841.797H44.8976Z"
            fill={props?.c2 || "#003669"}
          />
          <Path
            opacity="0.4"
            d="M37.4673 841.796H98.4901C73.9861 820.454 52.644 797.373 34.6217 773.343C21.1841 755.321 9.64349 736.825 0 718.17V801.167C11.5406 815.237 24.0297 828.675 37.4673 841.796Z"
            fill={props?.c2 || "#003669"}
          />
        </G>
        <G clip-path="url(#clip1_4983_23705)">
          <Path
            opacity="0.1"
            d="M-63.4707 37.1532C33.5442 69.4851 140.501 83.4389 249.342 75.441C366.069 66.7624 485.366 32.7289 596.436 -26.1492C558.727 -19.1723 527.703 -18.4916 507.477 -27C395.379 19.2856 277.281 40.2163 164.497 35.9621C98.1636 33.4096 33.3728 22.1785 -27.6472 3.46003L-63.4707 37.1532Z"
            fill={props?.c1 || "#0081CC"}
          />
          <Path
            opacity="0.1"
            d="M-45.1304 19.9665C29.4305 38.3446 110.162 46.6828 193.465 41.9182C284.309 36.8131 378.581 15.8825 469.94 -22.2351C379.781 -0.964123 294.936 1.24806 221.746 -9.64268C145.986 -3.34647 71.596 -8.79184 1.49163 -24.1069L-45.1304 19.9665Z"
            fill={props?.c1 || "#0081CC"}
          />
          <Path
            opacity="0.1"
            d="M-58.3291 50.7671C-49.0733 53.7167 -39.7032 56.5528 -30.2188 59.2755C88.5645 74.5906 219.003 66.9331 348.414 28.6453C267.339 41.2377 188.665 41.4079 115.989 31.7083C67.1389 25.242 21.0311 14.1811 -21.4772 -0.283203L-58.3291 50.7671Z"
            fill={props?.c1 || "#0081CC"}
          />
          <Path
            opacity="0.4"
            d="M-117.807 89.6488C102.791 110.358 181.637 82.6317 358.183 -13.897C216.26 31.4578 65.4246 51.6535 -89.6963 39.1596L-117.807 89.6488Z"
            fill={props?.c1 || "#0081CC"}
          />
        </G>
      </G>
      <Defs>
        <radialGradient
          id="paint0_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7313.7 10685.6) rotate(6.29494) scale(480.429 185.491)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.66" stop-color={props?.c2 || "#003669"} />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(452020 70933.5) rotate(-69.22) scale(26631.1 42666)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.66" stop-color={props?.c2 || "#003669"} />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4983_23705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(413079 -178393) rotate(-100.646) scale(61512.6 77211.7)"
        >
          <stop offset="0.22" stop-color="white" />
          <stop offset="0.66" stop-color={props?.c2 || "#003669"} />
        </radialGradient>
        <ClipPath id="clip0_4983_23705">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
        <ClipPath id="clip1_4983_23705">
          <Rect width="596" height="95" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture7 = {
  ViewTexture,
  PdfTexture,
};

export default Texture7;
