import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <svg
    width="596"
    height="842"
    viewBox="0 0 596 842"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4983_16088)">
      <rect width="596" height="842" fill="white" />
      <path
        // style="mix-blend-mode:multiply"
        opacity="0.3"
        d="M266 842H48.629L157.315 706L266 842Z"
        fill="#EAF9F8"
      />
      <path
        d="M161.514 841.95H12.8684L87.1848 748.951L161.514 841.95Z"
        fill="#12B8B2"
      />
      <path
        // style="mix-blend-mode:multiply"
        opacity="0.3"
        d="M376 0H603.035L489.518 135.743L376 0Z"
        fill="#BAEBE9"
      />
      <path d="M485.132 0H640.399L562.766 92.8362L485.132 0Z" fill="#12B8B2" />
    </g>
    <defs>
      <clipPath id="clip0_4983_16088">
        <rect width="596" height="842" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PdfTexture = (props: any) => (
  <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
    <G clip-path="url(#clip0_4983_16088)">
      <Rect width="596" height="842" fill="white" />
      <Path
        // style="mix-blend-mode:multiply"
        opacity="0.3"
        d="M266 842H48.629L157.315 706L266 842Z"
        fill={props?.c1 || "#EAF9F8"}
      />
      <Path
        d="M161.514 841.95H12.8684L87.1848 748.951L161.514 841.95Z"
        fill={props?.c2 || "#12B8B2"}
      />
      <Path
        // style="mix-blend-mode:multiply"
        opacity="0.3"
        d="M376 0H603.035L489.518 135.743L376 0Z"
        fill={props?.c1 || "#EAF9F8"}
      />
      <Path
        d="M485.132 0H640.399L562.766 92.8362L485.132 0Z"
        fill={props?.c2 || "#12B8B2"}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_4983_16088">
        <Rect width="596" height="842" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

const Texture3 = {
  ViewTexture,
  PdfTexture,
};

export default Texture3;
