import {
  View,
  Text,
  StyleSheet,
  Image,
  Svg,
  Circle,
} from "@react-pdf/renderer";
import { CourseType } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";
import {
  hasObjTruthyValue,
  isPhoneNumberExists,
} from "../../components/common/CommonFunctions";
import PdfFontAwesome from "../../components/PdfFontAwesome/PdfFontAwesome";

const TechnicalSkillsTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize, graphColor },
  SectionHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    contact: {
      fontSize: 12 + relativeFontSize,
      marginTop: 5,
    },
    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
    graphSingleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    graphLinearGaugeLineContainer: {
      width: "100%",
      height: 5,
      position: "relative",
      backgroundColor: "#ddd",
      borderRadius: 5,
      marginBottom: 10,
      overflow: "hidden",
    },

    graphLinearGaugeLineBackground: {
      top: 0,
      left: 0,
      position: "absolute",
      width: "100%",
      height: 5,
      backgroundColor: "#ddd",
    },
    graphLinearGaugeLineMain: {
      top: 0,
      left: 0,
      position: "absolute",
      height: 5,
      // backgroundColor: "#2980b9",
    },
    graphLollipopContainer: {
      width: "100%",
      height: 15,
      position: "relative",
      // backgroundColor: "#ddd",
      borderRadius: 5,
      marginBottom: 10,
      overflow: "hidden",
    },
    graphLollipopLineBackground: {
      top: 5,
      left: 0,
      position: "absolute",
      width: "100%",
      height: 5,
      backgroundColor: "#ddd",
    },
    graphLollipopLineMain: {
      top: 5,
      left: 0,
      position: "absolute",
      height: 5,
      // backgroundColor: "#2980b9",
    },
    graphLollipopCircleMain: {
      top: 1,
      position: "absolute",
      height: 13,
      width: 13,
      borderRadius: "100%",
    },
    graphCircleMain: {
      width: 15,
      height: 15,
      borderRadius: "100%",
      backgroundColor: "#2980b9",
    },
    graphCircleBackground: {
      width: 15,
      height: 15,
      borderRadius: "100%",
      backgroundColor: "#ddd",
    },
    graphCircleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
    },
    graphCircularContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
    },
    graphCircularSingleContainer: {
      width: 80,
    },
    alignHVCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 10,
    },
    skills: {
      fontSize: 12 + relativeFontSize,
      marginBottom: 10,
    },
  });

  const CircularGauge = ({
    progress,
    fillColor,
  }: {
    progress: number;
    fillColor: string;
  }) => {
    console.log("progress:", progress);
    const size = 80;
    const trackWidth = 5;
    const indicatorWidth = 8;
    const trackColor = "#ddd";
    const indicatorColor = "#2980b9";
    const indicatorCap = 100;

    const center = size / 2,
      radius =
        center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
      dashArray = 2 * Math.PI * radius,
      dashOffset = dashArray * ((100 - progress) / 100);

    const getOffset = (percentage: number) =>
      2 * Math.PI * radius - 2 * Math.PI * radius * ((100 - percentage) / 100);
    return (
      <Svg width="100" height="100" viewBox="0 0 100 100">
        <Circle
          r={radius}
          cx={center}
          cy={center}
          strokeDasharray={`${getOffset(progress)}, 360`}
          stroke={"#0058a3"}
          fill="white"
          strokeWidth={indicatorWidth}
          style={
            {
              transform: "rotate(90deg)",
              transformOrigin: "45px 45px",
            } as any
          }
        />
        <Text x="50%" y="45" textAnchor="middle">
          {Math.round(progress)}
        </Text>
      </Svg>
    );
  };

  const GraphHandler = ({
    type,
    score,
    total,
    item,
  }: {
    type: "none" | "linear" | "circle" | "star" | "circular" | "lollipop";
    score: number;
    total: number;
    item: any;
  }) => {
    if (type === "none") {
      return (
        <>
          <Text style={styles.skills}>{item}</Text>
        </>
      );
    }

    if (type === "linear") {
      return (
        <>
          <Text style={styles.skills}>{item}</Text>
          <View style={styles.graphLinearGaugeLineContainer}>
            <View style={styles.graphLinearGaugeLineBackground}></View>
            <View
              style={{
                ...styles.graphLinearGaugeLineMain,
                width: `${(score * 100) / total}%`,
                backgroundColor: graphColor,
              }}
            ></View>
          </View>
        </>
      );
    }

    if (type === "lollipop") {
      return (
        <>
          <Text style={styles.skills}>{item}</Text>
          <View style={styles.graphLollipopContainer}>
            <View style={styles.graphLollipopLineBackground} />
            <View
              style={{
                ...styles.graphLollipopLineMain,
                width: `${(score * 100) / total}%`,
                backgroundColor: graphColor,
              }}
            />
            <View
              style={{
                ...styles.graphLollipopCircleMain,
                left: `${(score * 100) / total - 7.5}%`,
                backgroundColor: graphColor,
              }}
            />
          </View>
        </>
      );
    }
    if (type === "circle") {
      return (
        <>
          <Text style={styles.skills}>{item}</Text>
          <View style={styles.graphCircleContainer}>
            {Array.from({ length: total }, (_, i) => {
              return (
                <View
                  style={
                    i < score
                      ? {
                          ...styles.graphCircleMain,
                          backgroundColor: graphColor,
                        }
                      : styles.graphCircleBackground
                  }
                ></View>
              );
            })}
          </View>
        </>
      );
    }
    if (type === "star") {
      return (
        <>
          <Text style={styles.skills}>{item}</Text>
          <View style={styles.graphCircleContainer}>
            {Array.from({ length: total }, (_, i) => {
              return i < score ? (
                <PdfFontAwesome
                  faIcon={allIcons.faStar}
                  style={{
                    color: graphColor,
                  }}
                />
              ) : (
                <PdfFontAwesome
                  faIcon={allIcons.faStar}
                  style={{ color: "#ddd" }}
                />
              );
            })}
          </View>
        </>
      );
    }
    if (type === "circular") {
      return (
        <View
          style={{
            ...styles.alignHVCenter,
            ...styles.graphCircularSingleContainer,
          }}
        >
          <Text style={styles.skills}>{item}</Text>
          <CircularGauge
            progress={(score * 100) / total}
            fillColor={graphColor}
          />
        </View>
      );
    }
    return <></>;
  };

  const renderToolsTechnicalSkills = (skills: any[]) => {
    // Return null for falsy values or empty arrays
    if (!skills || skills.length === 0) {
      return null;
    }

    // Filter out null/undefined values
    const validSkills = skills.filter(
      (item) => item !== null && item !== undefined
    );

    // Return null if no valid skills remain
    if (validSkills.length === 0) {
      return null;
    }

    // Check the type of the first valid element
    const isObjectArray = typeof validSkills[0] === "object";

    return (
      <View
        style={{
          ...(validSkills?.[0]?.isGraph &&
            validSkills?.[0]?.graphType === "circular" &&
            styles.graphCircularContainer),
        }}
      >
        {validSkills.map((item: any, index: number) => {
          const total = item?.total || 9;
          const score = item?.score || 9;
          if (isObjectArray) {
            return (
              <GraphHandler
                type={item.isGraph ? item.graphType : "none"}
                item={item.label}
                score={score}
                total={total}
              />
              // <Text key={index} style={styles.skills}>{item.label}</Text>
            );
          }
          return (
            <>
              <GraphHandler
                type="linear"
                item={item}
                score={score}
                total={total}
              />
            </>
          );
        })}
      </View>
    );
  };

  return (
    <>
      {extractedData.toolsTechnicalSkills &&
        extractedData.toolsTechnicalSkills?.length > 0 && (
          // <View
          //   style={
          //     isATSPreview ? { ...styles.atsIssueMedium, marginTop: 20 } : {}
          //   }
          // >
          <>
            {SectionHeader ? (
              <SectionHeader>TECHNICAL SKILLS</SectionHeader>
            ) : (
              <Text style={styles.sectionHeader}>TECHNICAL SKILLS</Text>
            )}
            <BodyContainer>
              {isATSPreview && (
                <View style={styles.fixATSButton}>
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                    style={styles.fixATSButtonImg}
                  />
                  <View
                    style={{
                      ...styles.fixATSButtonImg,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <Text style={{ fontSize: "0.1px", color: "transparent" }}>
                      ATS_ISSUE-SKILLS
                    </Text>
                  </View>
                </View>
              )}
              {extractedData.toolsTechnicalSkills &&
                renderToolsTechnicalSkills(extractedData.toolsTechnicalSkills)}
            </BodyContainer>
          </>
          // </View>
        )}
    </>
  );
};

export default TechnicalSkillsTemplate;
