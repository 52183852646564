import { Box, Button, Container, Typography } from "@mui/material";
import { ReactComponent as NtoFoundErrorIcon } from "../../assets/SVG/404Error.svg";

const NotFoundError404 = () => {
  return (
    <Container maxWidth="sm" sx={{ height: "100vh" }}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          textAlign: "center",
        }}
      >
        <Box>
          <NtoFoundErrorIcon />
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              color: "#0f172a",
              lineHeight: "33px",
            }}
          >
            Oops! Page not found.
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
              color: "#b4b4b4",
              lineHeight: "22.5px",
            }}
          >
            The page you are looking for may have been moved, deleted, or
            possibly never existed.
          </Typography>
          <Button
            variant="contained"
            size="medium"
            sx={{ mt: 4 }}
            onClick={() => (window.location.href = "/")}
          >
            Go to Home Page
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFoundError404;
