import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactComponent as DatePickerIcon } from "../../../assets/SVG/date-picker.svg";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import {
  calculateDuration,
  isValidUrl,
} from "../../../components/common/CommonFunctions";
import { setExtractedData } from "../../../redux/actions";
import { EducationTypes, formatDate } from "../../../components/utils";
import { usePickerPopperOffset } from "../../../components/Hooks/usePickerPopperOffeset";
import CardEditHeader from "../../../components/common/CardEditHeader";
import classNames from "classnames";

interface CourseProps {
  courseData: EducationTypes;
  coursesList: EducationTypes[];
  setCoursesList: Dispatch<SetStateAction<EducationTypes[]>>;
  index: number;
}

const EducationEdit: React.FC<CourseProps> = ({
  courseData,
  coursesList,
  setCoursesList,
  index,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  //formData handles local form state
  const [formData, setFormData] = useState<EducationTypes>(courseData);
  const [errors, setErrors] = useState({
    universityCollege: "",
    startDate: "",
    endDate: "",
    degree: "",
    location: "",
    specialization: "",
  });

  /**
   * Date Piciker popper position
   */
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const startDateOffset = usePickerPopperOffset(startDateRef);
  const endDateOffset = usePickerPopperOffset(endDateRef);

  const handleChange = (newValue: any, key: string) => {
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
    setFormData((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}-${day}-${year}`;
    if (formData.endDate && newValue > new Date(formData.endDate)) {
      handleChange("", "endDate");
    }
    handleChange(formattedDate, "startDate");
  };

  const handleStartDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, startDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, startDate: "" }));
    }
  };

  const handleEndDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, endDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, endDate: "" }));
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${month}-${day}-${year}`;
    handleChange(formattedDate, "endDate");
  };

  const handleCancel = () => {
    if (!!formData?.isNewEntry) {
      setCoursesList((prev) => (prev ? prev.slice(0, -1) : prev));
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (
            courEle?.universityCollege === courseData.universityCollege &&
            courEle?.startDate === courseData?.startDate &&
            courEle?.endDate === courseData?.endDate
          ) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }
  };

  const handleSave = () => {
    if (!formData?.universityCollege) {
      setErrors((prev) => ({
        ...prev,
        universityCollege: "Instituition is required",
      }));
    }
    if (!formData?.startDate) {
      setErrors((prev) => ({
        ...prev,
        startDate: "Start Date is required",
      }));
    }
    if (!formData?.endDate) {
      setErrors((prev) => ({
        ...prev,
        endDate: "End Date is required",
      }));
    }
    if (!formData?.degree) {
      setErrors((prev) => ({
        ...prev,
        degree: "Degree is required",
      }));
    }

    if (
      formData?.universityCollege &&
      formData?.degree &&
      formData?.startDate &&
      formData?.endDate
    ) {
      //perform new course entry
      if (formData?.isNewEntry) {
        /**
         * New Entry
         */
        delete formData?.isNewEntry;
        delete formData?.index;
        dispatch(
          setExtractedData({
            ...extractedData,
            educationalDetails: [
              ...(extractedData.educationalDetails ?? []),
              { ...formData, isEdit: false },
            ],
          })
        );
      } else {
        const updatedCourses = coursesList?.map((course) => {
          if (course?.index === formData?.index) {
            delete formData?.index;
            return { ...formData, isEdit: false };
          }
          delete course?.index;
          return course;
        });
        dispatch(
          setExtractedData({
            ...extractedData,
            educationalDetails: updatedCourses,
          })
        );
      }
      //both cases remove error
      setErrors({
        universityCollege: "",
        startDate: "",
        endDate: "",
        degree: "",
        location: "",
        specialization: "",
      });
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.degree,
              company: courseData?.universityCollege,
              startDate: courseData?.startDate,
              endDate: courseData?.endDate,
              isEducationSection: true,
              duration: calculateDuration(
                courseData?.startDate,
                courseData?.endDate
              ),
              jobType: courseData?.specialization,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="input-box-container">
          <InputLabel htmlFor="company" className="personal-details-label">
            Instituition*
          </InputLabel>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Eg: Columbia University"
            value={formData?.universityCollege}
            onChange={(e) => handleChange(e.target.value, "universityCollege")}
            error={!!errors.universityCollege}
            helperText={
              errors.universityCollege ? errors.universityCollege : ""
            }
            inputProps={{
              sx: {
                "@media(min-height:910px)": {
                  fontSize: "18px",
                },
              },
            }}
          />
        </Box>

        <Box className="input-box-container">
          <InputLabel htmlFor="company" className="personal-details-label">
            Degree*
          </InputLabel>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Eg: Bachelors of technology"
            value={formData?.degree}
            onChange={(e) => handleChange(e.target.value, "degree")}
            error={!!errors.degree}
            helperText={errors.degree ? errors.degree : ""}
            inputProps={{
              sx: {
                "@media(min-height:910px)": {
                  fontSize: "18px",
                },
              },
            }}
          />
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container">
            <InputLabel htmlFor="company" className="personal-details-label">
              Specialization
            </InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: Computer Science"
              value={formData?.specialization}
              onChange={(e) => handleChange(e.target.value, "specialization")}
              error={!!errors.specialization}
              helperText={errors.specialization ? errors.specialization : ""}
              inputProps={{
                sx: {
                  "@media(min-height:910px)": {
                    fontSize: "18px",
                  },
                },
              }}
            />
          </Box>
          <Box className="input-box-container">
            <InputLabel htmlFor="company" className="personal-details-label">
              Location
            </InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: New York"
              value={formData?.location}
              onChange={(e) => handleChange(e.target.value, "location")}
              error={!!errors.location}
              helperText={errors.location ? errors.location : ""}
              inputProps={{
                sx: {
                  "@media(min-height:910px)": {
                    fontSize: "18px",
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container" ref={startDateRef}>
            <InputLabel htmlFor="startDate" className="personal-details-label">
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.startDate}
                className={classNames(
                  { "date-error": !!errors.startDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={
                    formData?.startDate ? new Date(formData.startDate) : null
                  }
                  onChange={handleStartDateChange}
                  onError={handleStartDateError}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: startDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.startDate && (
                  <FormHelperText>{errors?.startDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>

          <Box className="input-box-container" ref={endDateRef}>
            <InputLabel htmlFor="endDate" className="personal-details-label">
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.endDate}
                className={classNames(
                  { "date-error": !!errors.endDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={formData?.endDate ? new Date(formData.endDate) : null}
                  onChange={handleEndDateChange}
                  onError={handleEndDateError}
                  minDate={
                    formData?.startDate
                      ? new Date(formData?.startDate)
                      : undefined
                  }
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: endDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.endDate && (
                  <FormHelperText>{errors?.endDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>
        </Box>

        {/* <Box className="input-box-container">
    <InputLabel htmlFor="address">
    Description
    </InputLabel>
    <TextField
    multiline
    rows={2}
    fullWidth
    value={formData?.courseDescription}
    id="address"
    placeholder="Eg: +91 123456789"
    onChange={(e) => handleChange(e.target.value,"courseDescription")}
    />
     <Typography
        variant="body2"
        className="word-count-text"
      >
        {`${formData?.courseDescription?.trim().split(/\s+/).filter(Boolean).length}/50 words`}
      </Typography>
   </Box> */}

        {
          <Box className="section-action">
            {/* courseData?.isNewEntry && */}
            <Box className="link-container"></Box>
            <Box className="button-container">
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default EducationEdit;
