import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <svg
    width="596"
    height="842"
    viewBox="0 0 596 842"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4983_15914)">
      <rect width="596" height="842" fill="white" />
      <g clip-path="url(#clip1_4983_15914)">
        <path
          d="M617.126 183.788C512.168 216.123 585.327 88.1743 542.271 84.2786C503.261 80.7483 541.738 201.893 498.739 197.904C458.598 194.179 523.187 30.8346 486.387 0.128296"
          stroke="#D1D1D1"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M630.479 177.264C510.358 209.81 593.355 78.6477 543.959 74.3395C499.206 70.4357 544.164 195.372 494.834 190.968C448.782 186.856 521.706 19.2085 479.299 -12.6472"
          stroke="#D1D1D1"
          stroke-width="0.8182"
          stroke-miterlimit="10"
        />
        <path
          d="M643.83 170.742C508.547 203.497 601.382 69.1212 545.647 64.4004C495.151 60.1232 546.59 188.852 490.928 184.032C438.967 179.533 520.226 7.58138 472.211 -25.4237"
          stroke="#D1D1D1"
          stroke-width="0.8864"
          stroke-miterlimit="10"
        />
        <path
          d="M657.183 164.218C506.735 197.185 609.409 59.5946 547.336 54.4613C491.095 49.8097 549.015 182.331 487.022 177.096C429.149 172.21 518.745 -4.04576 465.122 -38.1992"
          stroke="#D1D1D1"
          stroke-width="0.9545"
          stroke-miterlimit="10"
        />
        <path
          d="M670.535 157.694C504.925 190.873 617.437 50.068 549.024 44.5223C487.041 39.4972 551.441 175.81 483.116 170.161C419.333 164.887 517.266 -15.6719 458.034 -50.9748"
          stroke="#D1D1D1"
          stroke-width="1.0227"
          stroke-miterlimit="10"
        />
        <path
          d="M683.888 151.17C503.114 184.56 625.464 40.5414 550.713 34.5832C482.986 29.1846 553.868 169.289 479.211 163.224C409.517 157.564 515.786 -27.299 450.946 -63.7503"
          stroke="#D1D1D1"
          stroke-width="1.0909"
          stroke-miterlimit="10"
        />
        <path
          d="M697.239 144.646C501.303 178.248 633.491 31.0148 552.401 24.6441C478.931 18.8711 556.293 162.768 475.305 156.289C399.7 150.241 514.305 -38.9251 443.858 -76.5258"
          stroke="#D1D1D1"
          stroke-width="1.1591"
          stroke-miterlimit="10"
        />
        <path
          d="M710.592 138.122C499.492 171.935 641.519 21.4883 554.09 14.705C474.876 8.55855 558.718 156.247 471.4 149.354C389.884 142.919 512.826 -50.5513 436.771 -89.3013"
          stroke="#D1D1D1"
          stroke-width="1.2273"
          stroke-miterlimit="10"
        />
        <path
          d="M723.944 131.598C497.681 165.622 649.546 11.9617 555.778 4.76595C470.821 -1.75397 561.145 149.726 467.493 142.417C380.067 135.595 511.345 -62.1784 429.682 -102.078"
          stroke="#D1D1D1"
          stroke-width="1.2955"
          stroke-miterlimit="10"
        />
        <path
          d="M737.296 125.074C495.87 159.311 657.573 2.43513 557.465 -5.17311C466.764 -12.0665 563.569 143.205 463.587 135.482C370.25 128.272 509.864 -73.8045 422.593 -114.852"
          stroke="#D1D1D1"
          stroke-width="1.3636"
          stroke-miterlimit="10"
        />
        <path
          d="M750.648 118.55C494.06 152.998 665.601 -7.09144 559.154 -15.1122C462.71 -22.38 565.995 136.684 459.682 128.546C360.434 120.949 508.385 -85.4306 415.506 -127.628"
          stroke="#D1D1D1"
          stroke-width="1.4318"
          stroke-miterlimit="10"
        />
        <path
          d="M764 112.026C492.248 146.685 673.628 -16.618 560.841 -25.0523C458.654 -32.6935 568.419 130.162 455.775 121.609C350.616 113.624 506.903 -97.0587 408.417 -140.405"
          stroke="#D1D1D1"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </g>
      <g clip-path="url(#clip2_4983_15914)">
        <path
          d="M186.917 848.381C181.912 807 202.798 666.02 141.171 668.052C83.7666 669.944 150.484 839.964 88.4362 839.299C21.3625 838.58 77.1147 647.035 2.57599 646.664C-54.0984 646.382 9.39485 766.723 -49.4268 767.02C-135.819 767.456 -24.2046 534.159 -70.2126 499.46C-121.729 460.606 -179.744 629.548 -214.775 639.835"
          stroke="#D1D1D1"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M194.515 863.802C189.115 821.095 211.647 675.601 145.164 677.698C83.2345 679.651 155.211 855.115 88.2722 854.429C15.9118 853.687 76.0586 656.008 -4.35593 655.625C-65.4969 655.333 3.00007 779.529 -60.4582 779.836C-153.66 780.285 -33.2477 539.518 -82.8822 503.707C-138.459 463.609 -201.047 637.961 -238.84 648.577"
          stroke="#D1D1D1"
          stroke-width="0.8182"
          stroke-miterlimit="10"
        />
        <path
          d="M202.114 879.221C196.32 835.189 220.498 685.18 149.156 687.342C82.7024 689.356 159.938 870.267 88.1082 869.558C10.461 868.793 75.0024 664.979 -11.2868 664.584C-76.8954 664.283 -3.39279 792.333 -71.4877 792.649C-171.499 793.113 -42.2889 544.873 -95.5499 507.951C-155.188 466.608 -222.347 646.372 -262.902 657.318"
          stroke="#D1D1D1"
          stroke-width="0.8864"
          stroke-miterlimit="10"
        />
        <path
          d="M209.712 894.641C203.523 849.284 229.349 694.759 153.149 696.986C82.1694 699.061 164.664 885.416 87.9441 884.686C5.01031 883.898 73.9453 673.95 -18.2188 673.543C-88.2939 673.233 -9.78767 805.137 -82.5192 805.463C-189.34 805.94 -51.3321 550.229 -108.219 512.196C-171.918 469.609 -243.651 654.782 -286.966 666.057"
          stroke="#D1D1D1"
          stroke-width="0.9545"
          stroke-miterlimit="10"
        />
        <path
          d="M217.311 910.061C210.728 863.378 238.199 704.339 157.142 706.631C81.6383 708.765 169.392 900.566 87.7801 899.815C-0.440388 899.004 72.8892 682.922 -25.1506 682.502C-99.6934 682.184 -16.1815 817.942 -93.5496 818.277C-207.179 818.769 -60.3752 555.586 -120.888 516.442C-188.647 472.61 -264.953 663.195 -311.029 674.799"
          stroke="#D1D1D1"
          stroke-width="1.0227"
          stroke-miterlimit="10"
        />
        <path
          d="M224.909 925.481C217.931 877.472 247.049 713.918 161.135 716.275C81.1062 718.471 174.118 915.717 87.6161 914.945C-5.89108 914.111 71.8331 691.894 -32.0815 691.462C-111.091 691.135 -22.5753 830.748 -104.58 831.092C-225.02 831.598 -69.4174 560.943 -133.557 520.687C-205.376 475.611 -286.255 671.606 -335.092 683.541"
          stroke="#D1D1D1"
          stroke-width="1.0909"
          stroke-miterlimit="10"
        />
        <path
          d="M232.507 940.9C225.135 891.567 255.898 723.497 165.127 725.919C80.5742 728.176 178.845 930.866 87.4521 930.073C-11.3418 929.216 70.7769 700.864 -39.0135 700.421C-122.49 700.085 -28.9701 843.551 -115.61 843.906C-242.859 844.425 -78.4596 566.298 -146.225 524.931C-222.105 478.611 -307.557 680.016 -359.155 692.28"
          stroke="#D1D1D1"
          stroke-width="1.1591"
          stroke-miterlimit="10"
        />
        <path
          d="M240.106 956.321C232.34 905.663 264.749 733.078 169.12 735.565C80.0421 737.882 183.572 946.018 87.2881 945.204C-16.7935 944.323 69.7198 709.837 -45.9454 709.382C-133.889 709.037 -35.364 856.358 -126.641 856.72C-260.699 857.254 -87.5018 571.655 -158.894 529.177C-238.835 481.612 -328.858 688.429 -383.218 701.022"
          stroke="#D1D1D1"
          stroke-width="1.2273"
          stroke-miterlimit="10"
        />
        <path
          d="M247.704 971.741C239.543 919.757 273.598 742.657 173.112 745.21C79.51 747.588 188.298 961.168 87.124 960.332C-22.2442 959.429 68.6636 718.808 -52.8763 718.341C-145.287 717.987 -41.7578 869.161 -137.671 869.534C-278.539 870.081 -96.544 577.011 -171.563 533.421C-255.564 484.612 -350.16 696.84 -407.282 709.763"
          stroke="#D1D1D1"
          stroke-width="1.2955"
          stroke-miterlimit="10"
        />
        <path
          d="M255.303 987.161C246.748 933.852 282.45 752.236 177.106 754.853C78.9789 757.291 193.026 976.317 86.961 975.46C-27.694 974.534 67.6085 727.778 -59.8072 727.3C-156.685 726.935 -48.1516 881.965 -148.701 882.347C-296.378 882.909 -105.586 582.367 -184.23 537.666C-272.293 487.612 -371.462 705.25 -431.344 718.502"
          stroke="#D1D1D1"
          stroke-width="1.3636"
          stroke-miterlimit="10"
        />
        <path
          d="M262.901 1002.58C253.951 947.946 291.3 761.816 181.098 764.498C78.4469 766.997 197.752 991.468 86.797 990.59C-33.1447 989.641 66.5523 736.75 -66.7391 736.26C-168.084 735.886 -54.5455 894.77 -159.732 895.162C-314.219 895.737 -114.629 587.723 -196.901 541.912C-289.024 490.614 -392.766 713.662 -455.409 727.244"
          stroke="#D1D1D1"
          stroke-width="1.4318"
          stroke-miterlimit="10"
        />
        <path
          d="M270.5 1018C261.156 962.04 300.15 771.394 185.091 774.142C77.9148 776.701 202.479 1006.62 86.633 1005.72C-38.5954 1004.75 65.4962 745.721 -73.67 745.219C-179.482 744.836 -60.9393 907.574 -170.762 907.975C-332.058 908.565 -123.67 593.079 -209.569 546.156C-305.752 493.614 -414.066 722.073 -479.471 735.983"
          stroke="#D1D1D1"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4983_15914">
        <rect width="596" height="842" fill="white" />
      </clipPath>
      <clipPath id="clip1_4983_15914">
        <rect
          width="198"
          height="188"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 596 198)"
        />
      </clipPath>
      <clipPath id="clip2_4983_15914">
        <rect
          width="218"
          height="323"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 270.5 842)"
        />
      </clipPath>
    </defs>
  </svg>
);

const PdfTexture = (props: any) => (
  <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
    <G clip-path="url(#clip0_4983_15914)">
      <Rect width="596" height="842" fill="white" />
      <G clip-path="url(#clip1_4983_15914)">
        <Path
          d="M617.126 183.788C512.168 216.123 585.327 88.1743 542.271 84.2786C503.261 80.7483 541.738 201.893 498.739 197.904C458.598 194.179 523.187 30.8346 486.387 0.128296"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <Path
          d="M630.479 177.264C510.358 209.81 593.355 78.6477 543.959 74.3395C499.206 70.4357 544.164 195.372 494.834 190.968C448.782 186.856 521.706 19.2085 479.299 -12.6472"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="0.8182"
          stroke-miterlimit="10"
        />
        <Path
          d="M643.83 170.742C508.547 203.497 601.382 69.1212 545.647 64.4004C495.151 60.1232 546.59 188.852 490.928 184.032C438.967 179.533 520.226 7.58138 472.211 -25.4237"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="0.8864"
          stroke-miterlimit="10"
        />
        <Path
          d="M657.183 164.218C506.735 197.185 609.409 59.5946 547.336 54.4613C491.095 49.8097 549.015 182.331 487.022 177.096C429.149 172.21 518.745 -4.04576 465.122 -38.1992"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="0.9545"
          stroke-miterlimit="10"
        />
        <Path
          d="M670.535 157.694C504.925 190.873 617.437 50.068 549.024 44.5223C487.041 39.4972 551.441 175.81 483.116 170.161C419.333 164.887 517.266 -15.6719 458.034 -50.9748"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.0227"
          stroke-miterlimit="10"
        />
        <Path
          d="M683.888 151.17C503.114 184.56 625.464 40.5414 550.713 34.5832C482.986 29.1846 553.868 169.289 479.211 163.224C409.517 157.564 515.786 -27.299 450.946 -63.7503"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.0909"
          stroke-miterlimit="10"
        />
        <Path
          d="M697.239 144.646C501.303 178.248 633.491 31.0148 552.401 24.6441C478.931 18.8711 556.293 162.768 475.305 156.289C399.7 150.241 514.305 -38.9251 443.858 -76.5258"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.1591"
          stroke-miterlimit="10"
        />
        <Path
          d="M710.592 138.122C499.492 171.935 641.519 21.4883 554.09 14.705C474.876 8.55855 558.718 156.247 471.4 149.354C389.884 142.919 512.826 -50.5513 436.771 -89.3013"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.2273"
          stroke-miterlimit="10"
        />
        <Path
          d="M723.944 131.598C497.681 165.622 649.546 11.9617 555.778 4.76595C470.821 -1.75397 561.145 149.726 467.493 142.417C380.067 135.595 511.345 -62.1784 429.682 -102.078"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.2955"
          stroke-miterlimit="10"
        />
        <Path
          d="M737.296 125.074C495.87 159.311 657.573 2.43513 557.465 -5.17311C466.764 -12.0665 563.569 143.205 463.587 135.482C370.25 128.272 509.864 -73.8045 422.593 -114.852"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.3636"
          stroke-miterlimit="10"
        />
        <Path
          d="M750.648 118.55C494.06 152.998 665.601 -7.09144 559.154 -15.1122C462.71 -22.38 565.995 136.684 459.682 128.546C360.434 120.949 508.385 -85.4306 415.506 -127.628"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.4318"
          stroke-miterlimit="10"
        />
        <Path
          d="M764 112.026C492.248 146.685 673.628 -16.618 560.841 -25.0523C458.654 -32.6935 568.419 130.162 455.775 121.609C350.616 113.624 506.903 -97.0587 408.417 -140.405"
          stroke={props?.c1 || "#D1D1D1"}
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </G>
      <G clip-path="url(#clip2_4983_15914)">
        <Path
          d="M186.917 848.381C181.912 807 202.798 666.02 141.171 668.052C83.7666 669.944 150.484 839.964 88.4362 839.299C21.3625 838.58 77.1147 647.035 2.57599 646.664C-54.0984 646.382 9.39485 766.723 -49.4268 767.02C-135.819 767.456 -24.2046 534.159 -70.2126 499.46C-121.729 460.606 -179.744 629.548 -214.775 639.835"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <Path
          d="M194.515 863.802C189.115 821.095 211.647 675.601 145.164 677.698C83.2345 679.651 155.211 855.115 88.2722 854.429C15.9118 853.687 76.0586 656.008 -4.35593 655.625C-65.4969 655.333 3.00007 779.529 -60.4582 779.836C-153.66 780.285 -33.2477 539.518 -82.8822 503.707C-138.459 463.609 -201.047 637.961 -238.84 648.577"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="0.8182"
          stroke-miterlimit="10"
        />
        <Path
          d="M202.114 879.221C196.32 835.189 220.498 685.18 149.156 687.342C82.7024 689.356 159.938 870.267 88.1082 869.558C10.461 868.793 75.0024 664.979 -11.2868 664.584C-76.8954 664.283 -3.39279 792.333 -71.4877 792.649C-171.499 793.113 -42.2889 544.873 -95.5499 507.951C-155.188 466.608 -222.347 646.372 -262.902 657.318"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="0.8864"
          stroke-miterlimit="10"
        />
        <Path
          d="M209.712 894.641C203.523 849.284 229.349 694.759 153.149 696.986C82.1694 699.061 164.664 885.416 87.9441 884.686C5.01031 883.898 73.9453 673.95 -18.2188 673.543C-88.2939 673.233 -9.78767 805.137 -82.5192 805.463C-189.34 805.94 -51.3321 550.229 -108.219 512.196C-171.918 469.609 -243.651 654.782 -286.966 666.057"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="0.9545"
          stroke-miterlimit="10"
        />
        <Path
          d="M217.311 910.061C210.728 863.378 238.199 704.339 157.142 706.631C81.6383 708.765 169.392 900.566 87.7801 899.815C-0.440388 899.004 72.8892 682.922 -25.1506 682.502C-99.6934 682.184 -16.1815 817.942 -93.5496 818.277C-207.179 818.769 -60.3752 555.586 -120.888 516.442C-188.647 472.61 -264.953 663.195 -311.029 674.799"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.0227"
          stroke-miterlimit="10"
        />
        <Path
          d="M224.909 925.481C217.931 877.472 247.049 713.918 161.135 716.275C81.1062 718.471 174.118 915.717 87.6161 914.945C-5.89108 914.111 71.8331 691.894 -32.0815 691.462C-111.091 691.135 -22.5753 830.748 -104.58 831.092C-225.02 831.598 -69.4174 560.943 -133.557 520.687C-205.376 475.611 -286.255 671.606 -335.092 683.541"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.0909"
          stroke-miterlimit="10"
        />
        <Path
          d="M232.507 940.9C225.135 891.567 255.898 723.497 165.127 725.919C80.5742 728.176 178.845 930.866 87.4521 930.073C-11.3418 929.216 70.7769 700.864 -39.0135 700.421C-122.49 700.085 -28.9701 843.551 -115.61 843.906C-242.859 844.425 -78.4596 566.298 -146.225 524.931C-222.105 478.611 -307.557 680.016 -359.155 692.28"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.1591"
          stroke-miterlimit="10"
        />
        <Path
          d="M240.106 956.321C232.34 905.663 264.749 733.078 169.12 735.565C80.0421 737.882 183.572 946.018 87.2881 945.204C-16.7935 944.323 69.7198 709.837 -45.9454 709.382C-133.889 709.037 -35.364 856.358 -126.641 856.72C-260.699 857.254 -87.5018 571.655 -158.894 529.177C-238.835 481.612 -328.858 688.429 -383.218 701.022"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.2273"
          stroke-miterlimit="10"
        />
        <Path
          d="M247.704 971.741C239.543 919.757 273.598 742.657 173.112 745.21C79.51 747.588 188.298 961.168 87.124 960.332C-22.2442 959.429 68.6636 718.808 -52.8763 718.341C-145.287 717.987 -41.7578 869.161 -137.671 869.534C-278.539 870.081 -96.544 577.011 -171.563 533.421C-255.564 484.612 -350.16 696.84 -407.282 709.763"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.2955"
          stroke-miterlimit="10"
        />
        <Path
          d="M255.303 987.161C246.748 933.852 282.45 752.236 177.106 754.853C78.9789 757.291 193.026 976.317 86.961 975.46C-27.694 974.534 67.6085 727.778 -59.8072 727.3C-156.685 726.935 -48.1516 881.965 -148.701 882.347C-296.378 882.909 -105.586 582.367 -184.23 537.666C-272.293 487.612 -371.462 705.25 -431.344 718.502"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.3636"
          stroke-miterlimit="10"
        />
        <Path
          d="M262.901 1002.58C253.951 947.946 291.3 761.816 181.098 764.498C78.4469 766.997 197.752 991.468 86.797 990.59C-33.1447 989.641 66.5523 736.75 -66.7391 736.26C-168.084 735.886 -54.5455 894.77 -159.732 895.162C-314.219 895.737 -114.629 587.723 -196.901 541.912C-289.024 490.614 -392.766 713.662 -455.409 727.244"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.4318"
          stroke-miterlimit="10"
        />
        <Path
          d="M270.5 1018C261.156 962.04 300.15 771.394 185.091 774.142C77.9148 776.701 202.479 1006.62 86.633 1005.72C-38.5954 1004.75 65.4962 745.721 -73.67 745.219C-179.482 744.836 -60.9393 907.574 -170.762 907.975C-332.058 908.565 -123.67 593.079 -209.569 546.156C-305.752 493.614 -414.066 722.073 -479.471 735.983"
          stroke={props?.c2 || "#D1D1D1"}
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </G>
    </G>
    <Defs>
      <ClipPath id="clip0_4983_15914">
        <Rect width="596" height="842" fill="white" />
      </ClipPath>
      <ClipPath id="clip1_4983_15914">
        <Rect
          width="198"
          height="188"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 596 198)"
        />
      </ClipPath>
      <ClipPath id="clip2_4983_15914">
        <Rect
          width="218"
          height="323"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 270.5 842)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

const Texture1 = {
  ViewTexture,
  PdfTexture,
};

export default Texture1;
