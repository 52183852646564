import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import PdfFontAwesome from "../../components/PdfFontAwesome/PdfFontAwesome";
import {
  EducationTypes,
  WorkExperienceTypes,
  capitalizeFirstLetter,
} from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const EducationTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
  });

  return (
    <>
      {extractedData.educationalDetails &&
        extractedData.educationalDetails?.length > 0 && (
          <>
            {SectionHeader ? (
              <SectionHeader>EDUCATION</SectionHeader>
            ) : (
              <Text style={styles.sectionHeader}>EDUCATION</Text>
            )}
            <BodyContainer>
              {extractedData.educationalDetails.map(
                (edu: EducationTypes, index: number) => (
                  <View key={index}>
                    <Text
                      style={{
                        ...styles.sectionHeader,
                        ...styles.sectionSubHeader,
                      }}
                    >
                      {edu.universityCollege}
                    </Text>
                    <Text style={styles.sectionContent}>
                      {edu.degree} in {edu.specialization}
                    </Text>
                    <Text style={{ ...styles.sectionContent, ...styles.mb0 }}>
                      {edu.startDate as any} - {edu.endDate as any}
                    </Text>
                  </View>
                )
              )}
            </BodyContainer>
          </>
        )}
    </>
  );
};

export default EducationTemplate;
