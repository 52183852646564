import { Texture } from "@mui/icons-material";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { TEXTURES } from "../utils/textures";

const TextureHandler = (props: any) => {
  const { onChange, textureId } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#fff",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: "80vh",
    overflow: "auto",
  };

  const handleChange = (
    textureId: string,
    colorScheme: undefined | string[] = undefined
  ) => {
    const obj: any = {
      textureId,
    };
    if (colorScheme) {
      colorScheme.forEach((color, index) => {
        obj[`svgColor${index + 1}`] = color;
      });
    }
    onChange(obj);
    handleClose();
  };

  return (
    <>
      <div onClick={handleOpen} className="texture-modal-invokebutton">
        <Texture />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Texture
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 2,
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: 4,
              padding: 2,
            }}
          >
            {TEXTURES.map((texture) => (
              <Box
                key={texture.id}
                className={`texture-image-container ${
                  textureId === texture.id ? "selected" : ""
                }`}
              >
                {texture.path && (
                  <img
                    src={texture.path}
                    alt={texture.name}
                    style={{ height: 100 }}
                    onClick={() => handleChange(texture.id)}
                  />
                )}
                {texture.svg && (
                  <div
                    className="type-svg"
                    onClick={() =>
                      handleChange(texture.id, texture.colorScheme)
                    }
                  >
                    <texture.svg.ViewTexture />
                  </div>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default TextureHandler;
