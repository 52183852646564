import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { UploadIcon } from "../Icons";
import Shimmer from "../Shimmer";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { ReactComponent as CancelModalIcon } from "../../assets/SVG/cancel-modal.svg";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone/.";
import * as UploadingResume from "../../assets/lottie/ATSCheckScreen.json";
import { formatDate } from "../utils";
import { ReactComponent as SinglePageConversionModalIcon } from "../../assets/SVG/singlePageResumeModal.svg";

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: UploadingResume,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface SinglePageConversionModalProps {
  isPopupOpen: boolean;
  handleClosePopup: () => void;
  isExtractionAndGenerationInProgress: boolean;
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSinglePageConversionInProgress: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  loading: boolean;
  handleCancelProcessing: () => void;
  filteredResumes: never[];
  handleSinglePageConversionClick: (resumeId: string) => Promise<void>;
}

const SinglePageConversionModal: React.FC<SinglePageConversionModalProps> = ({
  isPopupOpen,
  handleClosePopup,
  isExtractionAndGenerationInProgress,
  searchQuery,
  handleSearchChange,
  isSinglePageConversionInProgress,
  getRootProps,
  getInputProps,
  loading,
  handleCancelProcessing,
  filteredResumes,
  handleSinglePageConversionClick,
}) => {
  return (
    <Dialog
      open={isPopupOpen}
      onClose={handleClosePopup}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 3,
          maxWidth: "1130px",
          width: isSinglePageConversionInProgress ? "55%" : "auto",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "750px",
          position: "relative",
          pt: 3,
        }}
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                mr: 2,
              }}
            >
              <Typography sx={{ fontSize: "1.3rem", fontWeight: "600" }}>
                Select a Resume to convert into single page
              </Typography>
              {!isExtractionAndGenerationInProgress &&
                !isSinglePageConversionInProgress && (
                  <TextField
                    name="search"
                    required
                    id="search"
                    sx={{
                      ...AuthInputFieldStyle,
                      maxWidth: {
                        xs: "50%",
                        sm: "40%",
                        md: "40%",
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "1rem",
                      },
                    }}
                    placeholder="Search resumes"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
            </Box>
            <CancelModalIcon
              style={{ cursor: "pointer" }}
              onClick={handleClosePopup}
            />
          </Box>
          {isSinglePageConversionInProgress ? (
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              // position="fixed"
              // top={0}
              // left={0}
              // width="100%"
              // height="100%"
              zIndex={9999}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                backdropFilter: "blur(4px)",
                color: "primary.main",
                p: 3,
                borderRadius: "10px",
              }}
            >
              <SinglePageConversionModalIcon />
              <Box sx={{ py: 2, textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    opacity: 0.85,
                    color: "#344054",
                    fontWeight: "bold",
                    fontSize: "1.7rem",
                  }}
                >
                  {isExtractionAndGenerationInProgress
                    ? "Converting your resume into a single page."
                    : "Converting your resume to a single page..."}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ opacity: 0.85, color: "#5A697E", fontSize: "1.45rem" }}
                >
                  {isExtractionAndGenerationInProgress
                    ? " Optimizing the content... Just a moment!"
                    : "Optimizing to a single page. Just a moment!"}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ color: "#344054", borderColor: "#CBD5E1" }}
                onClick={handleCancelProcessing}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gap: 2,
                  pb: 3,
                  height: "600px",
                  overflowY: "auto",
                  mt: 4,
                  pr: 1,
                }}
              >
                <Box>
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: "1.5px dashed #B7B7B7",
                      borderRadius: "8px",
                      overflow: "hidden",
                      height: "340px",
                      mb: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        p: 1.5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <UploadIcon />
                      <Typography
                        sx={{ fontSize: "0.9rem", textAlign: "center" }}
                      >
                        Drag & Drop Resume here <br /> or <br />{" "}
                        <span
                          style={{
                            color: "#5594FF",
                            textDecoration: "underline",
                          }}
                        >
                          Browse
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "1rem", textAlign: "center" }}>
                      Upload your Resume
                    </Typography>
                  </Box>
                </Box>
                {loading && <Shimmer count={3} disableMarginTop={true} />}

                {filteredResumes.map((resume: any, resumeIndex: number) => (
                  <Box
                    key={resume.id}
                    sx={{ height: "auto", margin: 0, padding: 0 }}
                  >
                    <Box
                      onClick={() => handleSinglePageConversionClick(resume.id)}
                      sx={{
                        flex: "1 1 230px",
                        border: "2px solid #E2E8F0",
                        borderRadius: "8px",
                        overflow: "hidden",
                        height: "340px",
                        mb: 2,
                        backgroundImage: `url(${
                          resume.screenshot !== ""
                            ? resume.screenshot
                            : "https://i.ibb.co/WkjCkwH/image-5.png"
                        })`,
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                        boxSizing: "border-box",
                        transition: "border 0.2s",
                        cursor: "pointer",
                        "&:hover": {
                          border: "2px solid #5594FF",
                        },
                      }}
                    ></Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {resume.title}
                      </Typography>
                      <Typography sx={{ fontSize: "0.9rem" }} color={"#5A697E"}>
                        Checked on {formatDate(resume.modifiedDate)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SinglePageConversionModal;
