import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Avatar,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Rating,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AppBarHeader from "../../ProcessResume/AppBarHeader";
import SideDrawer from "../../ProcessResume/SideDrawer";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { FavoriteBorder, Search } from "@mui/icons-material";
import SingleJob from "./SingleJob";
import JobsContainer from "./JobsContainer";
import { id } from "date-fns/locale";

type Job = {
  slug: string;
  company_name: string;
  title: string;
  description: string;
  remote: boolean;
  url: string;
  tags: string[];
  job_types: string[];
  location: string;
  created_at: number;
};

const JobTracker: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0]);
    }
  }, [jobs]);

  useEffect(() => {
    filterJobs();
  }, [searchTerm, jobs]);

  const fetchJobs = async (search = "") => {
    const response = await fetch(`https://www.arbeitnow.com/api/job-board-api`);
    const data = await response.json();
    setJobs(data.data);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterJobs = () => {
    const filtered = jobs.filter(
      (job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredJobs(filtered);
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
  };

  const handleApplyClick = () => {};

  const handleSaveJobClick = () => {
    // Implement save job functionality here
    alert("Job saved!");
  };

  const jobsListingData = [
    {
      id: 1,
      job: "DevOps Engineer",
      organisation: "Amazon Web Services",
      location: "India (Remote)",
      updatedAt: "05 Nov 2024, 05.58 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      status: "APPLIED",
    },
    {
      id: 2,
      job: "Project Manager",
      organisation: "Adobe, Inc.",
      location: "Australia (Hybrid)",
      updatedAt: "18 Nov 2024, 10.20 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Adobe_Corporate_Logo.png",
      status: "APPLIED",
    },
    {
      job: "Technical Lead",
      organisation: "Microsoft Corp.",
      location: "India (Remote)",
      updatedAt: "04 Jul 2024, 03.43 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg",
      status: "APPLIED",
      id: 3,
    },
    {
      job: "Project Manager",
      organisation: "Tesla, Inc.",
      location: "Germany (On-site)",
      updatedAt: "20 Aug 2024, 12.08 PM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
      status: "SCREENING",
      id: 4,
    },
    {
      job: "Technical Lead",
      organisation: "Meta Platforms",
      location: "UK (Hybrid)",
      updatedAt: "19 Nov 2024, 06.23 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/99/Meta_Platforms_Inc._logo.svg",
      status: "SCREENING",
      id: 5,
    },
    {
      job: "Technical Lead",
      organisation: "Amazon Web Services",
      location: "USA (On-site)",
      updatedAt: "06 Apr 2024, 08.25 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      status: "SCREENING",
      id: 6,
    },
    {
      job: "Technical Lead",
      organisation: "Tesla, Inc.",
      location: "India (Remote)",
      updatedAt: "04 Apr 2024, 09.05 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
      status: "SCREENING",
      id: 7,
    },
    {
      job: "QA Engineer",
      organisation: "Google LLC",
      location: "Australia (Hybrid)",
      updatedAt: "06 Oct 2024, 08.12 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg",
      status: "SCREENING",
      id: 8,
    },
    {
      job: "QA Engineer",
      organisation: "Microsoft Corp.",
      location: "India (Remote)",
      updatedAt: "28 Jan 2024, 11.29 PM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg",
      status: "SCREENING",
      id: 9,
    },
    {
      job: "DevOps Engineer",
      organisation: "Amazon Web Services",
      location: "USA (On-site)",
      updatedAt: "01 Jul 2024, 10.39 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      status: "REJECTED",
      id: 10,
    },
    {
      job: "Project Manager",
      organisation: "Tesla, Inc.",
      location: "USA (On-site)",
      updatedAt: "07 Aug 2024, 05.25 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
      status: "REJECTED",
      id: 11,
    },
    {
      job: "Backend Developer",
      organisation: "Amazon Web Services",
      location: "India (Remote)",
      updatedAt: "05 Sep 2024, 12.37 AM",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      status: "REJECTED",
      id: 12,
    },
  ];

  const [jobsListing, setJobsListing] = useState(jobsListingData);

  const handleDrop = (item: any, status: string) => {
    const updatedJobs = jobsListing.map((job) => {
      if (job.id === item.id) {
        return {
          ...job,
          status,
        };
      }
      return job;
    });
    setJobsListing(updatedJobs);
  };

  return (
    <Box>
      <Box className="app-bar-container">
        <AppBarHeader />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className="side-drawer-container">
          <SideDrawer />
        </Box>
        <DndProvider backend={HTML5Backend}>
          <Box sx={{ width: "100%" }}>
            <Grid item md={6} sx={{ m: 3 }}>
              <Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bolder" }}
                >
                  Job Tracker
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "text.secondary" }}
                >
                  Your recently created resumes for easy tracking.
                </Typography>
              </Box>
            </Grid>
            <Box sx={{ m: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <JobsContainer
                    name="Applied"
                    onDrop={(item: any) => handleDrop(item, "APPLIED")}
                  >
                    {jobsListing
                      .filter((job) => job.status === "APPLIED")
                      .map((job) => (
                        <SingleJob details={job} type="JOB" />
                      ))}
                  </JobsContainer>
                </Grid>
                <Grid item xs={4}>
                  <JobsContainer
                    name="Screening"
                    onDrop={(item: any) => handleDrop(item, "SCREENING")}
                  >
                    {jobsListing
                      .filter((job) => job.status === "SCREENING")
                      .map((job) => (
                        <SingleJob details={job} type="JOB" />
                      ))}
                  </JobsContainer>
                </Grid>
                <Grid item xs={4}>
                  <JobsContainer
                    name="Rejected"
                    onDrop={(item: any) => handleDrop(item, "REJECTED")}
                  >
                    {jobsListing
                      .filter((job) => job.status === "REJECTED")
                      .map((job) => (
                        <SingleJob details={job} type="JOB" />
                      ))}
                  </JobsContainer>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DndProvider>
      </Box>
    </Box>
  );
};

export default JobTracker;
