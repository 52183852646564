import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { TReference } from "../../../../components/utils";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import { validateEmail } from "../../../../components/common/CommonFunctions";
import CardEditHeader from "../../../../components/common/CardEditHeader";

interface CourseProps {
  courseData: TReference;
  coursesList: TReference[];
  setCoursesList: Dispatch<SetStateAction<TReference[]>>;
  index: number;
  sectionName: string;
  handleDeleteEmptySection: (sectionName: string) => void;
}

const ReferenceEdit: React.FC<CourseProps> = ({
  courseData,
  coursesList,
  setCoursesList,
  index,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const [addLink, setAddLink] = useState<boolean>(false);
  //formData handles local form state
  const [formData, setFormData] = useState<TReference>(courseData);
  const [errors, setErrors] = useState({
    referentName: "",
    company: "",
    phone: "",
    email: "",
  });
  const handleChange = (newValue: any, key: string) => {
    // if(key === "courseDescription") {
    //     const wordLimit = 50
    //     const wordCount = newValue.trim().split(/\s+/).filter(Boolean).length;
    //     if(wordCount<=wordLimit){
    //         setFormData({
    //             ...formData,
    //             [key]: newValue,
    //           });
    //     }
    // }
    // else
    //  {
    setFormData({
      ...formData,
      [key]: newValue,
    });
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
    // }
  };

  const handleCancel = () => {
    const updatedCourses = !!formData?.isNewEntry
      ? coursesList.slice(0, -1)
      : coursesList;
    if (!!formData?.isNewEntry) {
      setCoursesList(updatedCourses);
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (
            courEle?.referentName === courseData.referentName &&
            courEle?.company === courseData?.company &&
            courEle?.email === courseData?.email &&
            courEle?.phone === courseData?.phone
          ) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }

    //  Below Method remove +Add More when there is no element present
    if (!updatedCourses?.[0]) {
      handleDeleteEmptySection(sectionName);
    }
  };

  const handleSave = () => {
    if (!formData?.referentName) {
      setErrors((prev) => ({
        ...prev,
        referentName: "Referent Name is required",
      }));
    }
    if (!formData?.company) {
      setErrors((prev) => ({
        ...prev,
        company: "Company is required",
      }));
    }
    if (!formData?.phone) {
      setErrors((prev) => ({
        ...prev,
        phone: "Company is required",
      }));
    }
    if (!formData?.email) {
      setErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
    }

    if (formData?.email && !validateEmail(formData?.email ?? "")) {
      setErrors((prev) => ({
        ...prev,
        email: "Invalid Email",
      }));
    }
    if (formData?.referentName) {
      //checking if link is present then It must contain the valid url
      if (!validateEmail(formData?.email ?? "")) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid Email",
        }));
      } else {
        //perform new course entry
        if (formData?.isNewEntry) {
          /**
           * New Entry
           */
          delete formData?.isNewEntry;
          delete formData?.index;
          dispatch(
            setExtractedData({
              ...extractedData,
              references: [
                ...(extractedData.references ?? []),
                { ...formData, isEdit: false },
              ],
            })
          );
        } else {
          const updatedCourses = coursesList?.map((course) => {
            if (course?.index === formData?.index) {
              delete formData?.index;
              return { ...formData, isEdit: false };
            }
            delete course?.index;
            return course;
          });
          dispatch(
            setExtractedData({
              ...extractedData,
              references: updatedCourses,
            })
          );
        }
        //both cases remove error
        setErrors({
          referentName: "",
          company: "",
          phone: "",
          email: "",
        });
      }
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
          margin:
            index === 0 && courseData?.isNewEntry
              ? "0 0 1rem 0 !important"
              : "1rem 0",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.referentName,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="grid-layout-container">
          <Box className="input-box-container">
            <InputLabel htmlFor="company">Referent's Full Name*</InputLabel>
            <TextField
              fullWidth
              id="referentName"
              name="referentName"
              placeholder="Eg: John"
              value={formData?.referentName}
              onChange={(e) => handleChange(e.target.value, "referentName")}
              error={!!errors.referentName}
              helperText={errors.referentName ? errors.referentName : ""}
            />
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Company*</InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: Microsoft Corporation"
              value={formData?.company}
              onChange={(e) => handleChange(e.target.value, "company")}
              error={!!errors.company}
              helperText={errors.company ? errors.company : ""}
            />
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Phone*</InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: +1 (989) 123 4567"
              value={formData?.phone}
              onChange={(e) => handleChange(e.target.value, "phone")}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone : ""}
            />
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Email*</InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: johndoe@gmail.com"
              value={formData?.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              error={!!errors.email}
              helperText={errors.email ? errors.email : ""}
            />
          </Box>
        </Box>

        {/* {addLink &&   <Box className="input-box-container">
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
    <InputLabel htmlFor="link">Link*</InputLabel>
    <Typography className="delete-link-section" onClick={()=>setAddLink(false)}>Delete</Typography>
    </Box>
    <TextField
        fullWidth
        id="link"
        name="link"
        placeholder="Eg. www.google.com"
      value={formData?.link}
      onChange={(e) => handleChange(e.target.value, 'link')}
      error={!!errors.url}
      helperText={errors.url ? errors.url : ''}
    />
    </Box>} */}

        <Box className="section-action">
          <Box className="link-container">
            {/* {!addLink && <Typography className="add-link-text" onClick={()=>setAddLink(true)}>+ Add Link</Typography>} */}
          </Box>
          {
            <Box className="button-container">
              {/*  courseData?.isNewEntry && */}
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Add
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default ReferenceEdit;
