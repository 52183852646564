import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { StyledTab, StyledTabs } from "../../StyledComponents/StyledTabs";
import TabPanel from "../../StyledComponents/TabPanel";
import ResetPassword from "./ResetPassword";
import TwoStepVerification from "./TwoStepVerification";

const PasswordAndSecurity = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ flex: 1, padding: 4, display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Password
      </Typography>
      <Typography variant="body1" gutterBottom>
        Update your account password details here.
      </Typography>

      <Divider sx={{ my: 2.5 }} />

      <StyledTabs value={activeTab} onChange={handleActiveTab}>
        <StyledTab label="Reset Password" />
        <StyledTab label="2-Step Verification" />
      </StyledTabs>

      <TabPanel value={activeTab} index={0}>
        <ResetPassword />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={1}
        style={{
          flexGrow: "1",
          display: "flex",
        }}
      >
        <TwoStepVerification />
      </TabPanel>
    </Box>
  );
};

export default PasswordAndSecurity;
