import {
  Avatar,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Rating,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { useDrag } from "react-dnd";

interface ISingleJobProps {
  details: {
    job: string;
    organisation: string;
    location: string;
    updatedAt: string;
    logo: string;
    status: string;
    id: number;
  };
  type: string;
}

const SingleJob = (props: ISingleJobProps) => {
  const { details, type } = props;

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { ...details },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [details.id, type],
  );
  return (
    <>
      <Box
        ref={drag}
        sx={{
          flex: 1,
          background: "#fff",
          padding: 1.5,
          borderRadius: "8px",
          marginBottom: "15px",
          opacity,
        }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Avatar
            src={details.logo}
            sx={{ width: 56, height: 56 }}
            variant="rounded"
          >
            H
          </Avatar>
          <Box>
            <Typography sx={{ fontWeight: "bolder", fontSize: 18 }}>
              {details.job}
            </Typography>
            <Typography sx={{ color: "#333" }}>
              {details.organisation}
            </Typography>
            <Typography sx={{ color: "#777" }}>{details.location}</Typography>
            <Typography sx={{ color: "#777" }}>{details.updatedAt}</Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between"}}>
                <Typography gutterBottom sx={{ color: "#777" }}>
                    Applied Today
                </Typography> */}
        {/* <Rating name="half-rating" defaultValue={2.5} precision={0.5} /> */}

        {/* </Box> */}
      </Box>
    </>
  );
};

export default SingleJob;
