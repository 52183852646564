import { Box, Typography } from "@mui/material";
import { ReactComponent as KeyAchievementIcon } from "../../../../assets/SVG/key_achievements.svg";
import KeyAchievementEdit from "./KeyAchievementEdit";
import { useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { useDispatch } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";

interface CoursesListProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
  handleDeleteEmptySection: (section: string) => void;
}

const KeyAchievementList: React.FC<CoursesListProps> = ({
  handleSectionDelete,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const dispatch = useDispatch();
  return (
    <Box className="courses-section">
      <Box className="heading-box">
        {
          <Box className="icon-heading-cont">
            <Box className="icon-container">
              <KeyAchievementIcon />
            </Box>
            <Typography
              variant="h5"
              color="color.primary"
              className="heading"
              sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              Key Achievements
            </Typography>
          </Box>
        }

        <Typography
          className="sub-heading"
          onClick={() => {
            handleSectionDelete(sectionName);
            dispatch(
              setExtractedData({
                ...extractedData,
                keyAchievements: "",
              })
            );
          }}
        >
          Remove section
        </Typography>
      </Box>

      <KeyAchievementEdit />
    </Box>
  );
};

export default KeyAchievementList;
