import { DOMNode } from "html-react-parser";
import { CourseType, defaultProfilePicture, IResume } from "../utils";
import { SocialType } from "./SocialLinks";
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  addYears,
  addMonths,
} from "date-fns";
import { getUsernameFromToken } from "../../services/getUserFromToken";

export const isValueExist = (field: string): boolean => {
  if (field && field !== "NA") {
    return true;
  }
  return false;
};

// Function to get valid key-value pairs as an array of objects in the format { key, value }
export const getValidKeyValuePairs = (
  obj: Record<keyof SocialType, string | null>
): { key: keyof SocialType; value: string }[] => {
  return Object.keys(obj)
    .filter((key) => {
      const value = obj[key as keyof SocialType];
      return value && typeof value === "string" && value !== "NA"; // Only keep valid string values
    })
    ?.map((key) => ({
      key: key as keyof SocialType,
      value: obj[key as keyof SocialType] || "",
    })); // Return each valid key-value as an object
};

//Common utils to return error for link input
export const isValidUrl = (addLink: boolean, value: string) => {
  if (!addLink) return true;
  if (addLink) {
    if (!value) return false;
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
    return urlRegex.test(value);
  }
};

export const getCustomDataObject = (customSectionObject: {
  [key: string]: { label: string; value: CourseType[] };
}) => {
  if (!customSectionObject) {
    return [];
  }
  const keys = Object.keys(customSectionObject);
  const customSectionArray = keys.map((key) => ({
    label: customSectionObject[key]?.label,
    value: customSectionObject[key]?.value,
  }));
  return customSectionArray;
};

export const cleanSummaryHTMLstring = (summaryString: string): string => {
  if (!summaryString) return "";
  const filteredSummary = summaryString.replace(
    /(<ul>\s*(<li>\s*<br>\s*<\/li>\s*)+<\/ul>)|(<p>\s*<br>\s*<\/p>)/g,
    ""
  );
  return filteredSummary?.replace(
    /((?:<li\b[^>]*>.*?<\/li>\s*)+)(?!<\/ul>)/g, // Match consecutive <li> tags not already inside a <ul>
    (match, p1) => {
      // Ensure there's no <ul> before these <li> tags
      const beforeMatch = filteredSummary
        .slice(0, filteredSummary.indexOf(match))
        .trim();
      if (beforeMatch.endsWith("<ul>")) return match; // Already inside <ul>, skip wrapping
      return `<ul>${p1}</ul>`;
    }
  );
};

export const seprateExtractedBulletPointsStringToArray = (
  input: string | string[]
) => {
  if (Array.isArray(input)) {
    return input
      .filter((point) => point.length > 0)
      .map((point) => `<li>${point.trim()}</li>`)
      .join("");
  } else {
    return input
      ?.split("●")
      ?.map((point) => point.trim())
      ?.filter((point) => point.length > 0)
      ?.map((point) => `<li>${point}</li>`)
      ?.join("");
  }
};

export const regexTotestAddKeyWord = (str: string) => {
  const regex = /^Add\s/g;
  return regex.test(str);
};

export const convertChildNodesToDOMNodes = (
  childNodes: ChildNode[]
): DOMNode[] => {
  return Array.from(childNodes).map((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      const tagName = element.tagName.toLowerCase();

      // console.log({tagName,node,childNodes},"from Method")

      // Handling <li> elements specifically
      if (tagName === "li") {
        return {
          type: "tag",
          name: "li",
          attribs: Array.from(element.attributes).reduce(
            (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
            {}
          ),
          children: convertChildNodesToDOMNodes(Array.from(element.childNodes)),
        } as DOMNode;
      }

      // General element processing
      return {
        type: "tag",
        name: tagName,
        attribs: Array.from(element.attributes).reduce(
          (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
          {}
        ),
        children: convertChildNodesToDOMNodes(Array.from(element.childNodes)),
      } as DOMNode;
    } else if (node.nodeType === Node.TEXT_NODE) {
      // Text node
      return {
        type: "text",
        data: node.nodeValue || "",
      } as DOMNode;
    } else if (node.nodeType === Node.COMMENT_NODE) {
      // Comment node
      return {
        type: "comment",
        data: (node as Comment).data,
      } as DOMNode;
    } else {
      throw new Error(`Unsupported node type: ${node.nodeType}`);
    }
  });
};

export const isPhoneNumberExists = (
  phoneNumber: string | string[]
): boolean => {
  if (Array.isArray(phoneNumber) && phoneNumber.length > 0) {
    return true;
  } else if (typeof phoneNumber === "string" && phoneNumber) {
    return true;
  } else {
    return false;
  }
};

export const hasObjTruthyValue = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((value) => !!value);
};

const isSocialLinksHasValue = (socialLinks: {
  [key: string]: string;
  personal_Website: string;
  linkedIn_URL: string;
  github_URL: string;
  twitter_X_URL: string;
}) => {
  let isLinkValuePreset = false;
  for (const link in socialLinks) {
    if (socialLinks.hasOwnProperty(link) && socialLinks[link]) {
      isLinkValuePreset = true;
      break;
    }
  }
  return isLinkValuePreset;
};

export const isSavingEmpty = (
  extractedData: IResume,
  isTemplateChanged: boolean
) => {
  const {
    country,
    city_state,
    phone,
    fullName,
    email,
    location,
    jobTitle,
    socialLinks,
    profilePicture,
    title,
  } = extractedData;
  const isProfilePicChanged = profilePicture
    ? isProfilePictureUploaded(profilePicture)
    : false;
  const isTitleChanged = title === getUsernameFromToken() ? false : true;

  if (
    country ||
    city_state ||
    isPhoneNumberExists(phone) ||
    jobTitle ||
    fullName ||
    email ||
    location ||
    isProfilePicChanged ||
    isTitleChanged ||
    isTemplateChanged
  ) {
    return false;
  }
  if (isSocialLinksHasValue(socialLinks)) {
    return false;
  }
  return true;
};

export const calculateDuration = (startDateStr: string, endDateStr: string) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in years
  const years = differenceInYears(endDate, startDate);
  const intermediateDateAfterYears = addYears(startDate, years);

  // Calculate the difference in months after accounting for years
  const months = differenceInMonths(endDate, intermediateDateAfterYears);
  const intermediateDateAfterMonths = addMonths(
    intermediateDateAfterYears,
    months
  );

  // Calculate the remaining days
  const days = differenceInDays(endDate, intermediateDateAfterMonths);

  let duratioString = "";
  if (years) {
    if (years === 1) {
      duratioString += `${years} year`;
    } else {
      duratioString += `${years} years`;
    }
  }
  if (months) {
    if (years) {
      if (months === 1) {
        duratioString += `  ${months} month`;
      } else {
        duratioString += `  ${months} months`;
      }
    } else {
      if (months === 1) {
        duratioString += `${months} month`;
      } else {
        duratioString += `${months} months`;
      }
    }
  }
  if (!years && !months && days) {
    if (days === 1) {
      duratioString += `${days} day`;
    } else {
      duratioString += `${days} days`;
    }
  }
  return duratioString;
};

const getBase64Data = (base64String: string) => {
  return base64String.split(",")[1]; // Removes metadata
};

export const isProfilePictureUploaded = (profilePictureString: string) => {
  const uploadedImageBase = getBase64Data(profilePictureString);
  const defaultProfilePictureBase = getBase64Data(defaultProfilePicture);
  return uploadedImageBase === defaultProfilePictureBase ? false : true;
};

export const validateEmail = (email: string) => {
  const emailRegex = /^\S+@\S+\.\S+$/g;
  return emailRegex.test(email);
};
