import { Dispatch, SetStateAction, useEffect, useState } from "react";

import EducationCard from "../../EducationCard";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { setExtractedData } from "../../../../redux/actions";
import { TReference } from "../../../../components/utils";
import { ISectionEntryModalData } from "../Courses/CourseCard";
import SectionEntryModal from "../SectionEntryModal";

interface CourseCardProps {
  courseData: TReference;
  coursesList: TReference[];
  setCoursesList: Dispatch<SetStateAction<TReference[]>>;
}

const ReferenceCard: React.FC<CourseCardProps> = ({
  courseData,
  coursesList,
  setCoursesList,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const [sectionEntryModal, setSectionEntryModal] =
    useState<ISectionEntryModalData>({
      modalState: false,
      index: 0,
      entryDelete: false,
    });

  useEffect(() => {
    if (sectionEntryModal?.modalState && sectionEntryModal?.entryDelete) {
      const updatedCourses = coursesList
        ?.filter((course) => course?.index !== sectionEntryModal?.index)
        ?.map((course) => {
          delete course.index;
          return course;
        });
      dispatch(
        setExtractedData({
          ...extractedData,
          references: updatedCourses,
        })
      );
    }
    setSectionEntryModal({ modalState: false, index: 0, entryDelete: false });
  }, [sectionEntryModal?.entryDelete]);

  const handleEdit = (index: number | undefined) => {
    setCoursesList((prev) =>
      prev
        ?.filter((course) => !course?.isNewEntry)
        ?.map((course) => {
          if (index === course?.index) {
            return { ...course, isEdit: true };
          }
          return { ...course, isEdit: false };
        })
    );
  };

  const handleDelete = (index: number | undefined) => {
    setSectionEntryModal((prev) => ({
      ...prev,
      modalState: true,
      index: index ?? 0,
    }));
  };

  return (
    <>
      <EducationCard
        details={{
          heading: courseData?.referentName,
          startDate: courseData?.phone,
          company: courseData?.company,
          lastMidText: courseData?.email,
          index: courseData?.index,
        }}
        handleEditClick={() => handleEdit(courseData?.index)}
        handleDeleteClick={() => handleDelete(courseData?.index)}
      />
      <SectionEntryModal
        sectionEntryModal={sectionEntryModal}
        setSectionEntryModal={setSectionEntryModal}
      />
    </>
  );
};
export default ReferenceCard;
