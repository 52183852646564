import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5027_7708)">
        <rect width="596" height="842" fill="white" />
        <g clip-path="url(#clip1_5027_7708)">
          <path
            d="M429 -19.5283V19.3491L462.678 38.8208M429 -19.5283L462.678 -0.0566177M429 -19.5283L462.678 -39L496.355 -19.5283M462.678 38.8208V-0.0566177M462.678 38.8208L496.355 19.3491M462.678 38.8208V77.7642M462.678 38.8208L429 58.2925V97.2358L462.678 77.7642M462.678 38.8208L496.355 58.2925M462.678 -0.0566177L496.355 -19.5283M496.355 19.3491V-19.5283M496.355 19.3491L530.033 38.8208M496.355 -19.5283L530.033 -0.0566177M496.355 -19.5283L530.033 -39L563.644 -19.5283M530.033 38.8208V-0.0566177M530.033 38.8208L563.644 19.3491M530.033 38.8208V77.7642M530.033 38.8208L496.355 58.2925M530.033 38.8208L563.644 58.2925M530.033 -0.0566177L563.644 -19.5283M563.644 19.3491V-19.5283M563.644 19.3491L597.322 38.8208M563.644 -19.5283L597.322 -0.0566177V38.8208M462.678 77.7642L496.355 97.2358M496.355 97.2358V58.2925M496.355 97.2358L530.033 77.7642M496.355 97.2358L496.355 136.113L530.033 155.585M496.355 97.2358L530.033 116.708M530.033 77.7642L563.644 97.2358M597.322 38.8208V77.7642M597.322 38.8208L563.644 58.2925M597.322 38.8208L631 58.2925V97.2358M563.644 97.2358V58.2925M563.644 97.2358L597.322 77.7642M563.644 97.2358V136.113M563.644 97.2358L530.033 116.708M563.644 97.2358L597.322 116.708M597.322 77.7642L631 97.2358M631 97.2358V136.113L597.322 155.585M631 97.2358L597.322 116.708M530.033 155.585V116.708M530.033 155.585L563.644 136.113M530.033 155.585V194.528L496.355 214V175.057L530.033 155.585ZM563.644 136.113L597.322 155.585M597.322 155.585V116.708"
            stroke="#DAC825"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M-49 684.007V711.974L-24.8255 725.982M-49 684.007L-24.8255 698.015M-49 684.007L-24.8255 670L-0.651094 684.007M-24.8255 725.982V698.015M-24.8255 725.982L-0.651094 711.974M-24.8255 725.982V753.996M-24.8255 725.982L-49 739.989V768.004L-24.8255 753.996M-24.8255 725.982L-0.651094 739.989M-24.8255 698.015L-0.651094 684.007M-0.651094 711.974V684.007M-0.651094 711.974L23.5236 725.982M-0.651094 684.007L23.5236 698.015M-0.651094 684.007L23.5236 670L47.6507 684.007M23.5236 725.982V698.015M23.5236 725.982L47.6507 711.974M23.5236 725.982V753.996M23.5236 725.982L-0.651094 739.989M23.5236 725.982L47.6507 739.989M23.5236 698.015L47.6507 684.007M47.6507 711.974V684.007M47.6507 711.974L71.8252 725.982M47.6507 684.007L71.8252 698.015V725.982M-24.8255 753.996L-0.651094 768.004M-0.651094 768.004V739.989M-0.651094 768.004L23.5236 753.996M-0.651094 768.004L-0.650863 795.971M-0.651094 768.004L23.5236 782.011M23.5236 753.996L47.6507 768.004M71.8252 725.982V753.996M71.8252 725.982L47.6507 739.989M71.8252 725.982L96 739.989V768.004M47.6507 768.004V739.989M47.6507 768.004L71.8252 753.996M47.6507 768.004V795.971M47.6507 768.004L23.5236 782.011M47.6507 768.004L71.8252 782.011M71.8252 753.996L96 768.004M96 768.004V795.971L71.8252 809.978M96 768.004L71.8252 782.011M-0.650863 795.971L23.5236 809.978M-0.650863 795.971L-24.8257 809.978M23.5236 809.978V782.011M23.5236 809.978L47.6507 795.971M23.5236 809.978V837.993L-0.651094 852M23.5236 809.978L-0.651094 823.985M47.6507 795.971L71.8252 809.978M71.8252 809.978V782.011M-24.8257 809.978V837.993L-0.651094 852M-24.8257 809.978L-0.651094 823.985M-0.651094 852V823.985"
          stroke="#DAC825"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_5027_7708">
          <rect width="596" height="842" fill="white" />
        </clipPath>
        <clipPath id="clip1_5027_7708">
          <rect
            width="167"
            height="214"
            fill="white"
            transform="translate(429)"
          />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5027_7708)">
        <Rect width="596" height="842" fill="white" />
        <G clip-path="url(#clip1_5027_7708)">
          <Path
            d="M429 -19.5283V19.3491L462.678 38.8208M429 -19.5283L462.678 -0.0566177M429 -19.5283L462.678 -39L496.355 -19.5283M462.678 38.8208V-0.0566177M462.678 38.8208L496.355 19.3491M462.678 38.8208V77.7642M462.678 38.8208L429 58.2925V97.2358L462.678 77.7642M462.678 38.8208L496.355 58.2925M462.678 -0.0566177L496.355 -19.5283M496.355 19.3491V-19.5283M496.355 19.3491L530.033 38.8208M496.355 -19.5283L530.033 -0.0566177M496.355 -19.5283L530.033 -39L563.644 -19.5283M530.033 38.8208V-0.0566177M530.033 38.8208L563.644 19.3491M530.033 38.8208V77.7642M530.033 38.8208L496.355 58.2925M530.033 38.8208L563.644 58.2925M530.033 -0.0566177L563.644 -19.5283M563.644 19.3491V-19.5283M563.644 19.3491L597.322 38.8208M563.644 -19.5283L597.322 -0.0566177V38.8208M462.678 77.7642L496.355 97.2358M496.355 97.2358V58.2925M496.355 97.2358L530.033 77.7642M496.355 97.2358L496.355 136.113L530.033 155.585M496.355 97.2358L530.033 116.708M530.033 77.7642L563.644 97.2358M597.322 38.8208V77.7642M597.322 38.8208L563.644 58.2925M597.322 38.8208L631 58.2925V97.2358M563.644 97.2358V58.2925M563.644 97.2358L597.322 77.7642M563.644 97.2358V136.113M563.644 97.2358L530.033 116.708M563.644 97.2358L597.322 116.708M597.322 77.7642L631 97.2358M631 97.2358V136.113L597.322 155.585M631 97.2358L597.322 116.708M530.033 155.585V116.708M530.033 155.585L563.644 136.113M530.033 155.585V194.528L496.355 214V175.057L530.033 155.585ZM563.644 136.113L597.322 155.585M597.322 155.585V116.708"
            stroke={props?.c1 || "#0081CC"}
            stroke-miterlimit="10"
          />
        </G>
        <Path
          d="M-49 684.007V711.974L-24.8255 725.982M-49 684.007L-24.8255 698.015M-49 684.007L-24.8255 670L-0.651094 684.007M-24.8255 725.982V698.015M-24.8255 725.982L-0.651094 711.974M-24.8255 725.982V753.996M-24.8255 725.982L-49 739.989V768.004L-24.8255 753.996M-24.8255 725.982L-0.651094 739.989M-24.8255 698.015L-0.651094 684.007M-0.651094 711.974V684.007M-0.651094 711.974L23.5236 725.982M-0.651094 684.007L23.5236 698.015M-0.651094 684.007L23.5236 670L47.6507 684.007M23.5236 725.982V698.015M23.5236 725.982L47.6507 711.974M23.5236 725.982V753.996M23.5236 725.982L-0.651094 739.989M23.5236 725.982L47.6507 739.989M23.5236 698.015L47.6507 684.007M47.6507 711.974V684.007M47.6507 711.974L71.8252 725.982M47.6507 684.007L71.8252 698.015V725.982M-24.8255 753.996L-0.651094 768.004M-0.651094 768.004V739.989M-0.651094 768.004L23.5236 753.996M-0.651094 768.004L-0.650863 795.971M-0.651094 768.004L23.5236 782.011M23.5236 753.996L47.6507 768.004M71.8252 725.982V753.996M71.8252 725.982L47.6507 739.989M71.8252 725.982L96 739.989V768.004M47.6507 768.004V739.989M47.6507 768.004L71.8252 753.996M47.6507 768.004V795.971M47.6507 768.004L23.5236 782.011M47.6507 768.004L71.8252 782.011M71.8252 753.996L96 768.004M96 768.004V795.971L71.8252 809.978M96 768.004L71.8252 782.011M-0.650863 795.971L23.5236 809.978M-0.650863 795.971L-24.8257 809.978M23.5236 809.978V782.011M23.5236 809.978L47.6507 795.971M23.5236 809.978V837.993L-0.651094 852M23.5236 809.978L-0.651094 823.985M47.6507 795.971L71.8252 809.978M71.8252 809.978V782.011M-24.8257 809.978V837.993L-0.651094 852M-24.8257 809.978L-0.651094 823.985M-0.651094 852V823.985"
          stroke={props?.c1 || "#0081CC"}
          stroke-miterlimit="10"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5027_7708">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
        <ClipPath id="clip1_5027_7708">
          <Rect
            width="167"
            height="214"
            fill="white"
            transform="translate(429)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture8 = {
  ViewTexture,
  PdfTexture,
};

export default Texture8;
