export const preferredJobTypes = [
  "Full-time",
  "Contract",
  "Part-time",
  "Internship",
];

export const workArrangement = ["Onsite", "Remote", "Hybrid"];

export const companySize = ["All", "Startup", "Mid-size", "Enterprise"];

export const industries = [
  { label: "All", value: "all" },
  { label: "Information Technology", value: "information-technology" },
  { label: "Healthcare & Biotech", value: "healthcare-and-biotech" },
  { label: "Finance & Fintech", value: "finance-and-fintech" },
  { label: "E-commerce & Retail", value: "e-commerce-and-retail" },
  { label: "Education", value: "education" },
  {
    label: "Automotive & Transportation",
    value: "automotive-and-transportation",
  },
  { label: "Media & Entertainment", value: "media-and-entertainment" },
  { label: "Agriculture & Agritech", value: "agriculture-and-agritech" },
  { label: "Energy & Utilities", value: "energy-and-utilities" },
  {
    label: "Manufacturing & Industrial Automation",
    value: "manufacturing-and-industrial-automation",
  },
];

export const currencies = [
  {
    label: "USD",
    text: "US Dollar",
    symbol: "$",
  },
  {
    label: "EUR",
    text: "Euro",
    symbol: "€",
  },
  {
    label: "GBP",
    text: "British Pound",
    symbol: "£",
  },
  {
    label: "INR",
    text: "Indian Rupee",
    symbol: "₹",
  },
  {
    label: "JPY",
    text: "Japanese Yen",
    symbol: "¥",
  },
  {
    label: "CNY",
    text: "Chinese Yuan",
    symbol: "¥",
  },
];
