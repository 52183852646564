import { styled } from "@mui/system";
import { getToken } from "./auth/storeToken";
import { format, parseISO } from "date-fns";
import { generatePdfContent } from "../templates/TemplatePreview";
import { processWord } from "../services/apiService";
import Template_1 from "../templates/Template_1";
import Template_2 from "../templates/Template_2";
import Template_3 from "../templates/Template_3";
import { Description } from "@mui/icons-material";
import { NonTechnicalSkill, skillOption } from "../Data/skillsData";
import Template_4 from "../templates/Template_4";
import Template_5 from "../templates/Template_5";
import Template_6 from "../templates/Template_6";
import Template_7 from "../templates/Template_7";
import Template_8 from "../templates/Template_8";

export interface ITechSkills {
  label: string;
}

export interface IResume {
  id?: number;
  title: string;
  jobTitle: string;
  templateId?: string;
  fullName: string;
  profilePicture: null | string;
  email: string;
  phone: string[];
  country: string;
  city_state: string;
  location: string;
  socialLinks: {
    personal_Website: string;
    linkedIn_URL: string;
    github_URL: string;
    twitter_X_URL: string;
    [key: string]: string;
  };
  careerObjective: string;
  educationalDetails: EducationTypes[];
  metaInformation: MetaInformation;
  workExperiences: WorkExperienceTypes[];
  projects?: TProjectType[];
  awards?: TAwards[];
  references?: TReference[];
  volunteerWorks?: TVolunterrWorks[];
  internships?: TInternships[];
  publications?: TPublications[];
  certifications?: TCertifications[];
  goals?: string;
  keyAchievements?: string;
  customSections?: { [key: string]: { label: string; value: IReference[] } };
  toolsTechnicalSkills: skillOption[];
  nonTechnicalSkills: NonTechnicalSkill[];
  programmingLanguages: string[];
  interests: string[];
  courses?: CourseType[];
  languages?: string[];
  hobbies?: string[];
  [key: string]: IReference[] | any;
  tailoredResumeObject: ITailoredResume;
  screenshot?: string;
  isShowAddressLocation: boolean;
}

export interface CourseType {
  organization: string;
  university: string;
  startDate: string;
  endDate: string;
  courseDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface TProjectType {
  organization: string;
  startDate: string;
  endDate: string;
  courseDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface TAwards {
  awardTitle: string;
  issuer: string;
  recievedDate: string;
  awardDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface TReference {
  referentName: string;
  company: string;
  phone: string;
  email: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface TVolunterrWorks {
  instituitionName: string;
  role: string;
  location: string;
  startDate: string;
  endDate: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface TInternships {
  internshipTitle: string;
  organization: string;
  location: string;
  startDate: string;
  endDate: string;
  description: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface TPublications {
  publicationTitle: string;
  publicationDate: string;
  publisher: string;
  url: string;
  description: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface TCertifications {
  title: string;
  authority: string;
  url: string;
  date: string;
  description: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface IReference {
  organization: string;
  courseDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?: string;
}

export interface EducationTypes {
  universityCollege: string;
  startDate: string;
  endDate: string;
  degree: string;
  location: string;
  specialization: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface MetaInformation {
  label: string;
  icon: string;
  graphColor: string;
  primaryColor: string;
  secondaryColor: string;
  altBackgroundColor: string;
  relativeFontSize: 0;
  fontFamily: string;
  textureId: string;
  showContactFieldsIcon: boolean;
  showSocialLinksIcon: boolean;
  showHobbiesIcon: boolean;
  svgColor1: string;
  svgColor2: string;
  svgColor3: string;
  svgColor4: string;
}

export interface WorkExperienceTypes {
  jobType: string;
  company: string;
  startDate: string;
  endDate: string;
  role: string;
  location: string;
  keyResponsibilities: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface IWorkExperience {
  startDate: string;
  endDate: string;
  company: string;
  role: string;
  keyResponsibilities: string;
  projectDetails: string;
  toolsTechnologies: string;
  jobType?: string;
  location?: string;
}

export interface IProject {
  title: string;
  description: string;
  toolsTechnologies: string;
}

export interface ILoggedinUserData {
  fullName: string;
  email: string;
  phoneNumber: string;
  country: string;
  password: null;
  dateOfBirth: string;
  address: string;
  stateCity: string;
  profilePic: string;
  socialLinks: string;
  activelyLookingForJobs: boolean;
  preferredJobTypes: string;
  workArrangement: string;
  preferredWorkLocation: string;
  salaryCurrency: string;
  fromSalary: string;
  toSalary: string;
  companySize: string;
  preferredIndustries: string;
  resumeOptimization: boolean;
  enableJobRecommondations: boolean;
  enableJobApplicationUpdates: boolean;
}

export interface IResumeObjectWithChanges {
  professionalSummary: string;
  jobTitle: string;
  certifications: string[];
  toolsTechnicalSkills: string[];
  programmingLanguages: string[];
  interests: string[];
  workExperiences: { index: string; keyResponsibilities: string[] };
}

export interface ITailoredResume {
  jobDetails?: any;
  tailoredResumeFeedback?: any;
  tailoredResume?: any;
  resumeObjectWithChanges: IResumeObjectWithChanges;
  atsScore: number;
  keywordsToAdd: string[];
  missingKeywords: string[];
  jobTitle: string;
  companyName: string;
}

export interface IFetchedJobDetails {
  jobTitle: string;
  companyName: string;
  location: string;
  jobDescription: string;
  requiredSkills: string[];
  jobType: string;
  salary: string;
  experienceLevel: string;
}

export const isValueExist = (field: string): boolean => {
  if (field && field !== "NA") {
    return true;
  }
  return false;
};

export enum TemplateCategory {
  All = "All",
  Design = "Design",
  Development = "Development",
  Marketing = "Marketing",
  Sales = "Sales",
  HumanResources = "Human Resources",
  Testing = "Testing",
  Business = "Business",
}

export const Templates = [
  {
    id: "18903ed9-0338-438a-903a-ada93fde4a3c",
    name: "Template 1",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T1.png"),
    jsx: Template_1,
    metaInformation: {
      graphColor: "#FFC107",
      primaryColor: "#4f6071",
      secondaryColor: "#4f6071",
      altBackgroundColor: "#f6f7f8",
      fontFamily: "Helvetica",
    },
  },
  {
    id: "7e982afc-bbd2-41d7-af6b-3d9adc72cfb0",
    name: "Template 2",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T11.png"),
    jsx: Template_2,
  },
  {
    id: "4f1851f3-ec5d-488a-bfad-d314fff2ba21",
    name: "Template 3",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T3.png"),
    jsx: Template_3,
  },
  {
    id: "5f1851f3-ec5d-488a-bfad-d314fff2ba22",
    name: "Template 4",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T10.png"),
    jsx: Template_4,
  },
  {
    id: "5f1851f3-ec5d-488a-bfad-d314fff2ba23",
    name: "Template 5",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T5.png"),
    jsx: Template_5,
    metaInformation: {
      graphColor: "#f9ddc0",
      primaryColor: "#000000",
      secondaryColor: "#4f6071",
      altBackgroundColor: "#f6f7f8",
      fontFamily: "Open Sans",
    },
  },
  {
    id: "5f1851f3-ec5d-488a-bfad-d314fff2ba24",
    name: "Template 6",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T6_.png"),
    jsx: Template_6,
    metaInformation: {
      graphColor: "#0561f5",
      primaryColor: "#0561f5",
      secondaryColor: "#4f6071",
      altBackgroundColor: "#f6f7f8",
      fontFamily: "Montserrat",
    },
  },
  {
    id: "5f1851f3-ec5d-488a-bfad-d314fff2ba25",
    name: "Template 7",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T7_.png"),
    jsx: Template_7,
    metaInformation: {
      graphColor: "#61d1b8",
      primaryColor: "#61d1b8",
      // secondaryColor: string;
      altBackgroundColor: "#1a1a1a",
      // relativeFontSize: 0;
      fontFamily: "Open Sans",
    },
  },
  {
    id: "5f1851f3-ec5d-488a-bfad-d314fff2ba26",
    name: "Template 8",
    tag: TemplateCategory.Design,
    img: require("./../templates/preview/T8.png"),
    jsx: Template_8,
    metaInformation: {
      graphColor: "#61d1b8",
      primaryColor: "#a65f3d",
      // secondaryColor: string;
      altBackgroundColor: "#1a1a1a",
      // relativeFontSize: 0;
      fontFamily: "Open Sans",
    },
  },
  //   {
  //       id: "Template_2",
  //       name: "Template 2",
  //       tag: TemplateCategory.Design,
  //       img: require("./../templates/preview/T11.png")
  //   },
  //   {
  //       id: "Template_2",
  //       name: "Template 2",
  //       tag: TemplateCategory.Design,
  //       img: require("./../templates/preview/T2.png")
  //   },
  //   {
  //       id: "Template_3",
  //       name: "Template 3",
  //       tag: TemplateCategory.Development,
  //       img: require("./../templates/preview/T3.png")
  //   },
  //   {
  //       id: "Template_4",
  //       name: "Template 4",
  //       tag: TemplateCategory.Sales,
  //       img: require("./../templates/preview/T4.png")
  //   },
  //   {
  //       id: "Template_5",
  //       name: "Template 5",
  //       tag: TemplateCategory.Marketing,
  //       img: require("./../templates/preview/T2.png")
  //   },
  //   {
  //       id: "Template_6",
  //       name: "Template 6",
  //       tag: TemplateCategory.Testing,
  //       img: require("./../templates/preview/T3.png")
  //   },
  //   {
  //     id: "Template_7",
  //     name: "Template 7",
  //     tag: TemplateCategory.Testing,
  //     img: require("./../templates/preview/T7.png")
  // },
  // {
  //   id: "Template_8",
  //   name: "Template 8",
  //   tag: TemplateCategory.Testing,
  //   img: require("./../templates/preview/T6.png")
  // },
  // {
  //   id: "Template_9",
  //   name: "Template 9",
  //   tag: TemplateCategory.Marketing,
  //   img: require("./../templates/preview/T9.png")
  // },
  // {
  //   id: "Template_10",
  //   name: "Template 10",
  //   tag: TemplateCategory.Marketing,
  //   img: require("./../templates/preview/T10.png")
  // },
  // {
  //   id: "Template_11",
  //   name: "Template 11",
  //   tag: TemplateCategory.Marketing,
  //   img: require("./../templates/preview/T11.png")
  // },
  // {
  //   id: "Template_12",
  //   name: "Template 12",
  //   tag: TemplateCategory.Marketing,
  //   img: require("./../templates/preview/T12.png")
  // },
  // {
  //   id: "Template_13",
  //   name: "Template 13",
  //   tag: TemplateCategory.Business,
  //   img: require("./../templates/preview/T13.png")
  // },
  // {
  //   id: "Template_14",
  //   name: "Template 14",
  //   tag: TemplateCategory.Business,
  //   img: require("./../templates/preview/T14.png")
  // },
  // {
  //   id: "Template_15",
  //   name: "Template 15",
  //   tag: TemplateCategory.Business,
  //   img: require("./../templates/preview/T15.png")
  // },
  // {
  //   id: "Template_16",
  //   name: "Template 16",
  //   tag: TemplateCategory.Business,
  //   img: require("./../templates/preview/T16.png")
  // },
];

export const TemplatesById = Templates.reduce((acc, template) => {
  acc[template.id] = template;
  return acc;
}, {} as { [key: string]: (typeof Templates)[0] });

export const avatarColors = [
  {
    primary: "#FFFEAB",
    secondary: "#FFE17C",
    text: "#7C6416",
  },
  {
    primary: "#F5FFEB",
    secondary: "#AAFF7C",
    text: "#2C7205",
  },
  {
    primary: "#FFF0EB",
    secondary: "#FFA17C",
    text: "#8D3411",
  },
  {
    primary: "#EBFFFF",
    secondary: "#7CFFFD",
    text: "#117492",
  },
  {
    primary: "#EBEBFF",
    secondary: "#8D7CFF",
    text: "#27188E",
  },
  {
    primary: "#FBEBFF",
    secondary: "#E057A9",
    text: "#850A55",
  },
  {
    primary: "#FFEBF3",
    secondary: "#FF7CB5",
    text: "#811342",
  },
  {
    primary: "#FAEBFF",
    secondary: "#C87CFF",
    text: "#5D178E",
  },
  {
    primary: "#F4FFEB",
    secondary: "#46DF68",
    text: "#08761E",
  },
  {
    primary: "#EBF5FF",
    secondary: "#7CC2FF",
    text: "#0E4F89",
  },
  {
    primary: "#E9FFF9",
    secondary: "#60CDBB",
    text: "#157564",
  },
  {
    primary: "#FFFAEF",
    secondary: "#C1A771",
    text: "#835C0A",
  },
  {
    primary: "#FFFFFF",
    secondary: "#FFC57E",
    text: "#83380A",
  },
];

export const flattenExtractedData = (data: any) => {
  const { socialLinks, workExperiences, ...rest } = data;

  // Transform workExperiences to update keyResponsibilities
  const transformedWorkExperiences = workExperiences.map((experience: any) => ({
    ...experience,
    keyResponsibilities: experience.keyResponsibilities
      .split("\n")
      .map((e: string) => ({ keyResponsibility: e })),
  }));

  return {
    ...rest,
    careerObjective:
      rest.careerObjective !== "NA" ? rest.careerObjective : null,
    personal_Website:
      socialLinks.personal_Website !== "NA"
        ? socialLinks.personal_Website
        : null,
    linkedIn_URL:
      socialLinks.linkedIn_URL !== "NA" ? socialLinks.linkedIn_URL : null,
    github_URL: socialLinks.github_URL !== "NA" ? socialLinks.github_URL : null,
    twitter_X_URL:
      socialLinks.twitter_X_URL !== "NA" ? socialLinks.twitter_X_URL : null,
    workExperiences: transformedWorkExperiences,
    screenshot: "",
    themeColors: {
      primary: "#1E90FF",
      secondary: "#FF6347",
      tertiary: "#32CD32",
    },
  };
};

export const extractKeywords = (text: string) => {
  return text?.toLowerCase()?.split(/\s+/)?.filter(Boolean);
};

const extractPhrases = (text: string, phraseLength: number) => {
  // Normalize the text by replacing special characters with spaces
  const normalizedText = text?.toLowerCase().replace(/[\/\-]/g, " ");
  const words = normalizedText?.split(/\s+/).filter(Boolean) || [];
  const phrases = [];

  for (let i = 0; i <= words.length - phraseLength; i++) {
    phrases.push(words.slice(i, i + phraseLength).join(" "));
  }

  return phrases;
};

export const checkMissingKeywords = (
  sectionValue: string | IWorkExperience[] | IProject[],
  section: string,
  keywordsToAdd: string[]
) => {
  let sectionKeywords: string[] = [];

  if (section === "professionalSummary") {
    sectionKeywords = extractKeywords(stripHtmlTags(sectionValue as string));
  } else if (section === "workExperiences") {
    (sectionValue as IWorkExperience[]).forEach(
      (experience: IWorkExperience) => {
        const keyResponsibilities = experience.keyResponsibilities;
        const keywords = extractKeywords(stripHtmlTags(keyResponsibilities));
        sectionKeywords.push(...keywords);
      }
    );
  } else if (section === "projects") {
    (sectionValue as IProject[]).forEach((project: IProject) => {
      const description = project.description;
      const keywords = extractKeywords(stripHtmlTags(description));
      sectionKeywords.push(...keywords);
    });
  }

  const sectionPhrases = keywordsToAdd
    ?.filter((keyword) => keyword.split(/\s+/).length > 1)
    ?.flatMap((keyword) => {
      if (section === "workExperiences") {
        return (sectionValue as IWorkExperience[]).flatMap(
          (experience: IWorkExperience) =>
            extractPhrases(
              stripHtmlTags(experience.keyResponsibilities),
              keyword.split(/\s+/).length
            )
        );
      } else if (section === "projects") {
        return (sectionValue as IProject[]).flatMap((project: IProject) =>
          extractPhrases(
            stripHtmlTags(project.description),
            keyword.split(/\s+/).length
          )
        );
      }
      return extractPhrases(
        stripHtmlTags(sectionValue as string),
        keyword.split(/\s+/).length
      );
    });

  const allSectionKeywords = new Set([
    ...(sectionKeywords ?? []),
    ...(sectionPhrases ?? []),
  ]);

  return keywordsToAdd?.filter(
    (keyword: string) => !allSectionKeywords?.has(keyword?.toLowerCase())
  );
};

export const CustomIconStyle = styled("div")<{
  active: boolean;
  completed: boolean;
}>(({ theme, active, completed }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 14, // Custom width for step icon
  height: 14, // Custom height for step icon
  borderRadius: "50%",
  backgroundColor: completed ? "#3e7ee8" : "#fff", // Background color based on state
  color: active ? "#3e7ee8" : "black", // Text color
  fontSize: "8px", // Adjust step number font size
  border: active ? "1px solid #3e7ee8" : "1px solid grey",
}));

export const stripHtmlTags = (str: string): string => {
  return str?.replace(/<\/?[^>]+(>|$)/g, "");
};

export const formatDate = (isoString: string) => {
  if (!isoString) {
    console.error("Invalid date string:", isoString);
    return "";
  }

  try {
    const date = parseISO(isoString);
    return format(date, "d MMMM, hh:mm a");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const updateATSCheckResult = async (updatedResult: any) => {
  try {
    await fetch(`https://testapi.tekno.ai/api/update-ats-checker/`, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify(updatedResult),
    });
  } catch (error) {
    console.error("Error saving fixed sections:", error);
  }
};

export const handleDownloadPdf = async (
  extractedData: IResume,
  TemplateComponent: any
) => {
  const blob = await generatePdfContent(extractedData, TemplateComponent);
  if (blob) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${extractedData.title}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error("Failed to generate PDF content");
  }
};

export const generateDocument = async (extractedData: any) => {
  try {
    const serverResponse = await processWord(extractedData);

    // Create a blob from the response
    const processedBlob = await serverResponse.blob();
    const url = window.URL.createObjectURL(processedBlob);

    // Create a hidden link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${extractedData.title}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error: any) {
    return error;
  }
};

export const removeProperties = (obj: any, properties: string[]) => {
  const newObj = { ...obj };
  properties.forEach((prop) => {
    delete newObj[prop];
  });
  return newObj;
};

export const defaultProfilePicture =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABKCAYAAAAc0MJxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASdSURBVHgB7ZzdahtHFMfPzurDliJZIk6oUgesBJKSltaFBlx6kZZCb4uh98kjuE/Q9gnavoF6XwilNyUXDYaUBnwRpSShKbgSRHHc2K5l2ZKtr53sf83GcSLF47VnjyLPD3yhRdayv505c+aDY1EfSk/WJiNCXJVSfmoJa8omytAQ0pGySpKKRFYxKp0fc7lsudf3rJcvlEprGTshvo/Z1rXEaIxG41GK2IKGmVanS+12l9brTeo6shB1nO9eFrZHVOXp2hRJcX3sRHzyRCJOx5H6VovWN7fLkpyZidPZon/9uShIsoR983QmmRn2FrQfna5DT6v1qnS6n/myPCOIR2hJRtIOcAAXFonrCEW45lmxhfgmkxqZNJJ2gYtUMj6JeI3PFlpTLGKXzoynyPAqS/9vItrnhZA0O5Y8noFbheRIlFpE14SbI30Qjdpk6A3SI8uyrgh33Jtyux4ZeoNYhYRbuNn3UGbcR4ltUcYMc4oYUYoYUYoYUYoYUYoYUYoYUYoMnKhms021jQYNGhEaACqLK/Tg70e0UPrPE+UzceYkXXrnrPfHjbW4XJM5xpWDuVv36M5fpdd+J51K0FdffkzpdII4eLJS4+16N34v7isJoCv+/MufVKvxdUk2Ubfn//G6myqQBbFcsIhCHDqIJJ/K4ipVHq8QByyiFkpLgUc2tEQOWEQtr6xTUJZXa8QBk6jgD/ti+hAmLKK4hvnDwCIqHgue554aTxMHLKLO53MUlFPjY8QBi6iJt09605MgTH90gThgSzi/+HyK4u5W0EGYvnyBLb6xifLnb6qyIGn68kXign1SjMRz7tZ9LwntRTo1Slc+eY/On3uLuMCkmF2UD4RBlp+MxmMxT07QWHaUQNRArEcBdMUP3z9Hg4pZClbEiFJkILoe4hLmf4hT/qoCuiKSSwRzriTzRdhEQciduyV68PDRvhNdSEOSimSTK48KfdSDlLk/7gdauAPYaAhbWOij3sK/S3TjZvFQSyUQXHm86uZW74aaW4UWzLEy+etv80eynoRui9+6Pf+QwiIUUZCk46F0/W4vtItCd9P5MJCFe+hGqyhvHucGbt3sxL0O6USrKLztMM4R7Iyk90gn2kRBUNAUIAi4l86dZG2iOPbfVLbng6JNFHKdsEGWrwstorD1zXHGCbFK15a7FlGH2Qk+9L017SRrEsWz7b1zbz0vSYsoznNMzZaefEqLqINuQx0lzW09ZxO0rB5cuniW5TAFXhDurYOB2YUZZNjPcL5JGFGKGFGKGFGKGFGKGFGKGFGKCAf1kwyvpdOVVeE4sojqNobeoLaUS1FIKee2mM5uvwmgAJd05F3htqVCY8uI6ket3qSYRT+IfC5bbrY7P200mmTYC6qTdRxZQBk3b9RzRmi2ttksm1i1C1xUN7fLqHeHz56ofDZbJcuZWa7Wq0bWbuk2twnN+EUBexYDRHWy5GiMjiMbjZYbl7bL1K8YoA8qlLnN7NtoxL6aTsYJtaWGvWwSWhBG/rq7OtruOIVuw/k6n8/uyS+tfv/sCZM0i0JcqDE1rOWTum7C7Q7/RaRJbh8q9CtY+gwNPRDTUiY/MgAAAABJRU5ErkJggg==";

export const capitalizeFirstLetter = (txt: string) =>
  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();

export const base64ToFile = (base64: string, filename: string): File => {
  if (!base64 || typeof base64 !== "string") {
    throw new Error("Base64 string is required");
  }

  if (!filename || typeof filename !== "string") {
    throw new Error("Filename is required");
  }

  try {
    // Split the base64 string to separate MIME type and data
    const [header, data] = base64.split(",");

    if (!header || !data) {
      throw new Error(
        'Invalid base64 format. Expected "data:[<MIME-type>][;base64],<data>"'
      );
    }

    // Extract MIME type
    const mime = header.match(/:(.*?);/)?.[1];
    if (!mime) {
      throw new Error("Invalid MIME type in base64 string");
    }

    // Decode base64 data
    let binaryString: string;
    try {
      binaryString = atob(data);
    } catch (e) {
      throw new Error("Invalid base64 encoding");
    }

    // Convert binary string to Uint8Array
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create and return File object
    return new File([bytes], filename, { type: mime });
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    throw new Error(`Failed to convert base64 to file: ${errorMessage}`);
  }
};

export const convertToTemplateData = async (resumeData: IResume) => {
  if (resumeData.profilePicture) {
    try {
      const imageResponse = await fetch(resumeData.profilePicture);
      const imageBlob = await imageResponse.blob();

      // Convert blob to base64
      const reader = new FileReader();
      const base64Promise = new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(imageBlob);
      });

      resumeData.profilePicture = (await base64Promise) as any;
    } catch (imageError) {
      console.error("Error converting profile picture to base64:", imageError);
    }
  }
  return resumeData;
};
