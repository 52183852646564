import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ProcessResumePreviewHeader from "./ProcessResumePreviewHeader";
import ProcessResumePreview from "./ProcessResumePreview";
import ResumeScoreProgressBar from "./ResumeScoreProgressBar";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import UploadProfilePhoto from "./EditForms/UploadProfilePhoto";
import PersonalDetails from "./EditForms/PersonalDetails";
import Skills from "./EditForms/Skills";
import {
  Button,
  createTheme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProcessResumeFooter from "./ProcessResumeFooter";
import ResumeProgressStepper from "./ResumeProgressStepper";
import AppBarHeader from "./AppBarHeader";
import SideDrawer from "./SideDrawer";
import NameScoreWrapper from "./NameScoreWrapper";
import Summary from "./EditForms/Summary";
import Header from "../components/common/Header";
import EducationList from "./EditForms/Education/EducationList";
import WorkExperienceList from "./EditForms/WorkExperience/WorkExperienceList";
import TailoredResumeSuggestions from "./TailoredResumeSuggestions";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialState, StateProps } from "../redux/reducer";
import {
  setExtractedData,
  setIsPersonalDetailsLoading,
} from "../redux/actions";
import { getToken } from "../components/auth/storeToken";
import Layout from "../components/Layout";
import TemplatesList from "../components/templates/TemplatesList";
import { fetchResumeById, withAuthHandling } from "../services/apiService";
import { SnackBarState } from "../components/auth/Login";
import CustomizedAlert from "../components/CustomizedAlert";

const ProcessResumeFeature: React.FC = () => {
  const dispatch = useDispatch();
  const { resumeId } = useParams<{ resumeId: string }>();

  const location = useLocation();
  const navigate = useNavigate();
  const isSelectTemplatePage = location.pathname.includes("select-template");

  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fetchWithAuthHandling = withAuthHandling(navigate);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    const fetchResumeData = async () => {
      if (resumeId && resumeId !== "new") {
        try {
          const resume = await fetchResumeById(
            resumeId,
            fetchWithAuthHandling,
            dispatch
          );
          dispatch(setExtractedData(resume));
        } catch (error) {
          setOpenSnackbar(true);
          dispatch(setExtractedData(initialState.extractedData));
        }
      }
    };

    fetchResumeData();
  }, [resumeId]);

  const [saveAsDraftModal, setSaveAsDraftModal] = useState<boolean>(false);

  return (
    <Layout>
      <CustomizedAlert
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        alertState={openSnackbar}
        title={"Error"}
        description={"Failed to fetch resume data"}
        severity="error"
        handleCloseSnackbar={() => setOpenSnackbar(false)}
      />
      <Box className="process-resume-page-container">
        <Box className="side-drawer-container">
          <SideDrawer
            saveAsDraftModal={saveAsDraftModal}
            setSaveAsDraftModal={setSaveAsDraftModal}
          />
        </Box>

        <Box>
          <Box className="main-content">
            <Paper className="left-edit-container" sx={{ flex: 1.6 }}>
              {!isSelectTemplatePage && (
                <>
                  <Box className="left-progress-bar">
                    <NameScoreWrapper />
                    <ResumeProgressStepper />
                  </Box>
                  <TailoredResumeSuggestions />
                </>
              )}
              <Box className="left-edit-fields-container">
                <Routes>
                  <Route
                    path="profile-photo"
                    element={<UploadProfilePhoto />}
                  />
                  <Route
                    path="personal-details"
                    element={<PersonalDetails />}
                  />
                  <Route
                    path="work-experience"
                    element={<WorkExperienceList />}
                  />
                  <Route path="education" element={<EducationList />} />
                  <Route path="skills" element={<Skills />} />
                  <Route path="summary" element={<Summary />} />
                  <Route path="select-template" element={<TemplatesList />} />
                </Routes>
              </Box>

              {!isSelectTemplatePage && (
                <ProcessResumeFooter
                  saveAsDraftModal={saveAsDraftModal}
                  setSaveAsDraftModal={setSaveAsDraftModal}
                />
              )}
            </Paper>
            <Box
              className="right-preview-container"
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              }}
            >
              <Box className="right-preview-header-container">
                <ProcessResumePreviewHeader
                  currentPage={currentPage}
                  numPages={numPages}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                />
              </Box>
              <Box className="right-preview-content-container">
                <ProcessResumePreview
                  numPages={numPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setNumPages={setNumPages}
                />
              </Box>
              <Box className="right-preview-footer-container">
                <Typography
                  variant="body1"
                  sx={{
                    cursor: currentPage === 1 ? "default" : "pointer",
                    color: currentPage === 1 ? "grey" : "white",
                  }}
                  onClick={currentPage === 1 ? undefined : handlePrevPage}
                >
                  {"<"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: "nowrap", color: "#fff", px: 1 }}
                >
                  Page {currentPage} of {numPages}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    cursor: currentPage === numPages ? "default" : "pointer",
                    color: currentPage === numPages ? "grey" : "white",
                  }}
                  onClick={
                    currentPage === numPages ? undefined : handleNextPage
                  }
                >
                  {">"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ProcessResumeFeature;
