import { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { StyledTab, StyledTabs } from "../StyledComponents/StyledTabs";
import TabPanel from "../StyledComponents/TabPanel";
import ResumesList from "../ResumesList";
import { Templates } from "../utils";
import { initialState, StateProps } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTemplateId } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

interface HomeScreenTabsProps {
  setIsPopupOpen?: Dispatch<SetStateAction<boolean>>;
  handleSinglePageConversionClick?: (resumeId: string) => Promise<void>;
}

const HomeScreenTabs: React.FC<HomeScreenTabsProps> = ({
  setIsPopupOpen,
  handleSinglePageConversionClick,
}) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTemplateId = useSelector(
    (state: StateProps) => state?.selectedTemplateId
  );
  const [hoveredTemplate, setHoveredTemplate] = useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Recent" />
        <StyledTab label="Templates" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <ResumesList
          setIsPopupOpen={setIsPopupOpen}
          handleSinglePageConversionClick={handleSinglePageConversionClick}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            mt: 2,
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
            gap: 2,
          }}
        >
          {Templates.map((template, index) => (
            // <Box
            //     key={index}
            //     onClick={() => {
            //         dispatch(setSelectedTemplateId(template.id))
            //         dispatch(setExtractedData(initialState.extractedData));
            //         navigate(`/create-resume/new/personal-details`);
            //     }}
            //     className={`template-preview-box`}
            //     sx={{
            //         height: 340,
            //         cursor: "pointer",
            //         border: "2px solid #E2E8F0",
            //         borderRadius: "8px",
            //         transition: "border 0.1s",
            //         '&:hover': {
            //             border: "2px solid #5594FF",
            //         },
            //     }}
            // >
            //     {
            //         template.img && <img src={template.img} className="template-preview-img-in-tabs"/>
            //     }
            // </Box>

            <Box
              onMouseEnter={() => setHoveredTemplate(template.id)}
              onMouseLeave={() => setHoveredTemplate(null)}
              // onClick={() => {
              // itemClickHandler && itemClickHandler(resume);
              // navigate(`/create-resume/${resume.id}/personal-details`);
              // }}
              sx={{
                flex: "1 1 250px",
                border: "2px solid #E2E8F0",
                borderRadius: "8px",
                overflow: "hidden",
                height: "340px",
                mb: 2,
                backgroundImage: `url(${template.img})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                boxSizing: "border-box",
                transition: "border 0.2s",
                cursor: "pointer",
                "&:hover": {
                  border: "2px solid #5594FF",
                },
              }}
            >
              {hoveredTemplate === template.id && (
                <Box
                  display="flex"
                  height={"340px"}
                  gap={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {/* <Tooltip title="Edit">
                    <IconButton size="large"
                        sx={{
                        cursor: "pointer",
                        p: 1,
                        backgroundColor: '#5594FF',
                        borderRadius: '50%',
                        '&:hover': {
                            backgroundColor: '#4078C0',
                        },
                        }}
                        onClick={() => navigate(`/create-resume/${resume.id}/personal-details`)}
                    >
                        <PencilIcon fontSize="inherit" />
                    </IconButton>
                    </Tooltip> */}

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 0 }}
                    onClick={() => {
                      dispatch(setSelectedTemplateId(template.id));
                      navigate(`/create-resume/new/personal-details`);
                    }}
                  >
                    Use This Template
                  </Button>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default HomeScreenTabs;
