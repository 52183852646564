import { MoreVert } from "@mui/icons-material";
import {
  Grid,
  Box,
  Typography,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  MenuItem,
  Popover,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getToken } from "./../auth/storeToken";
import {
  formatDate,
  generateDocument,
  handleDownloadPdf,
  IResume,
  TemplatesById,
} from "./../utils";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PencilIcon } from "./pencil-icon.svg";
import { ReactComponent as DownloadIcon } from "./download-icon.svg";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import { ReactComponent as PDFDownloadIcon } from "./pdf-download.svg";
import { ReactComponent as DocxDownloadIcon } from "./docx-download.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/red-delete.svg";
import { ReactComponent as SinglePageResumeIcon } from "./single-page-resume-icon.svg";
import { ReactComponent as MoreVertIcon } from "./more-vert.svg";
import { id } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import {
  setExtractedData,
  setSelectedTemplateId,
  showSnackbar,
} from "../../redux/actions";
import { Illustration_2 } from "../Icons";
import { initialState, StateProps } from "../../redux/reducer";
import {
  addNewResume,
  deleteResume,
  fetchResumeById,
  fetchResumes,
  withAuthHandling,
} from "../../services/apiService";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import Shimmer from "../Shimmer";

interface ResumesListProps {
  prependElement?: React.ReactNode;
  itemClickHandler?: (resumeObj: any) => void;
  setIsPopupOpen?: Dispatch<SetStateAction<boolean>>;
  handleSinglePageConversionClick?: (resumeId: string) => Promise<void>;
}

const ResumesList: React.FC<ResumesListProps> = ({
  prependElement,
  itemClickHandler = (resumeObject) => {},
  setIsPopupOpen,
  handleSinglePageConversionClick,
}) => {
  const navigate = useNavigate();
  const [resumes, setResumes] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(true);
  const selectedTemplateId = useSelector(
    (state: StateProps) => state?.selectedTemplateId
  );
  const [hoveredBox, setHoveredBox] = useState(null);
  const [popoverType, setPopoverType] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [resumeToDelete, setResumeToDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const dispatch = useDispatch();
  const fetchWithAuthHandling = withAuthHandling(navigate);

  const handleClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setPopoverType(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loadResumes = async () => {
    try {
      const data = await fetchResumes(fetchWithAuthHandling);
      setResumes(data);
    } catch (error) {
      console.error("Error fetching resumes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadResumes();
  }, []);

  const copyResume = async (resume: IResume) => {
    /**
     *  get by Id will return two more keys 1) profilePictureOriginal and 2) screenshotOriginal
     */
    const resumeObject = await fetchResumeById(
      resume.id?.toString() || "",
      fetchWithAuthHandling,
      dispatch
    );
    if (!resumeObject) {
      return;
    }
    const {
      id,
      createdDate,
      modifiedDate,
      profilePictureOriginal,
      screenshotOriginal,
      ...resumeWithoutId
    } = resumeObject;
    const response = await addNewResume(
      {
        ...resumeWithoutId,
        screenshot: screenshotOriginal,
        profilePicture: profilePictureOriginal,
        title: `${resume.title}-Copy`,
      },
      fetchWithAuthHandling
    );
    dispatch(
      showSnackbar(`Created a copy of the resume successfully`, "success")
    );
    loadResumes();

    // dispatch(setExtractedData(resume));
    // dispatch(setSelectedTemplateId(resume.templateId || ""));
    // navigate(`/create-resume/new/personal-details`);
  };

  const handleDeleteResume = async (resumeId: string) => {
    const deletedResume: any = await deleteResume(
      resumeId,
      fetchWithAuthHandling
    );
    if (deletedResume.success) {
      dispatch(showSnackbar("Resume deleted successfully", "success"));
      const updatedResumes = resumes.filter(
        (resume: any) => resume.id !== resumeId
      );
      setResumes(updatedResumes);
    }
  };

  const confirmDeleteResume = (resumeId: string, resumeTitle: string) => {
    setResumeToDelete({ id: resumeId, name: resumeTitle });
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (resumeToDelete?.id) {
      handleDeleteResume(resumeToDelete?.id);
    }
    setDeleteModalOpen(false);
    setResumeToDelete(null);
  };

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        name={resumeToDelete?.name ?? ""}
      />
      {!prependElement && resumes.length === 0 && !loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Illustration_2 />
            <Typography
              sx={{ Fontsize: "1rem", Fontweight: "Bold", Color: "#667085" }}
            >
              No Resumes Found
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gap: 2,
        }}
      >
        {prependElement && <Box>{prependElement}</Box>}
        {loading && <Shimmer count={resumes.length || 6} />}
        {resumes.map((resume: any, resumeIndex: number) => (
          <Box key={resume.id} sx={{ height: "auto", margin: 0, padding: 0 }}>
            <Box
              onMouseEnter={() => setHoveredBox(resume.id)}
              onMouseLeave={() => setHoveredBox(null)}
              onClick={() => {
                itemClickHandler && itemClickHandler(resume);
                navigate(`/create-resume/${resume.id}/personal-details`);
              }}
              sx={{
                flex: "1 1 250px",
                border: "2px solid #E2E8F0",
                borderRadius: "8px",
                overflow: "hidden",
                height: "340px",
                mb: 2,
                backgroundImage: `url(${
                  resume.screenshot !== ""
                    ? resume.screenshot
                    : "https://i.ibb.co/WkjCkwH/image-5.png"
                })`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                boxSizing: "border-box",
                transition: "border 0.2s",
                cursor: "pointer",
                "&:hover": {
                  border: "2px solid #5594FF",
                },
              }}
            >
              {hoveredBox === resume.id && (
                <Box
                  display="flex"
                  gap={1}
                  justifyContent={"flex-end"}
                  mt={2}
                  mr={1}
                >
                  <Tooltip title="Edit">
                    <IconButton
                      size="large"
                      sx={{
                        cursor: "pointer",
                        p: 1,
                        backgroundColor: "#5594FF",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "#4078C0",
                        },
                      }}
                      onClick={() =>
                        navigate(`/create-resume/${resume.id}/personal-details`)
                      }
                    >
                      <PencilIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <IconButton
                      size="large"
                      sx={{
                        p: 1,
                        backgroundColor: "#5594FF",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "#4078C0",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e, "download");
                      }}
                    >
                      <DownloadIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Delete">
                    <IconButton
                      size="large"
                      sx={{
                        p: 1,
                        backgroundColor: "#5594FF",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "#4078C0",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteResume(resume.id);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="More">
                    <IconButton
                      size="large"
                      sx={{
                        p: 1,
                        backgroundColor: "#5594FF",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "#4078C0",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e, "more");
                      }}
                    >
                      <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #E2E8F0",
                        padding: "8px 0",
                        mt: 1,
                      },
                    }}
                  >
                    {popoverType === "download" && (
                      <>
                        <Typography sx={{ px: 1 }} variant="subtitle1">
                          Download As
                        </Typography>
                        <MenuItem
                          sx={{ px: 1, mx: 1 }}
                          onClick={async (e) => {
                            e.stopPropagation();
                            handleClose(e);
                            const TemplateComponent =
                              TemplatesById[selectedTemplateId].jsx;
                            const resumeData = await fetchResumeById(
                              resume.id,
                              fetchWithAuthHandling,
                              dispatch
                            );
                            handleDownloadPdf(resumeData, TemplateComponent);
                          }}
                        >
                          <PDFDownloadIcon style={{ marginRight: "8px" }} /> PDF
                          File
                        </MenuItem>
                        <MenuItem
                          sx={{ px: 1, mx: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            generateDocument(resume);
                          }}
                        >
                          <DocxDownloadIcon style={{ marginRight: "8px" }} />{" "}
                          DOCX File
                        </MenuItem>
                      </>
                    )}
                    {popoverType === "more" && (
                      <>
                        <MenuItem
                          sx={{ px: 1, mx: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            copyResume(resume);
                          }}
                        >
                          <CopyIcon style={{ marginRight: "8px" }} /> Duplicate
                        </MenuItem>
                        <MenuItem
                          sx={{ px: 1, mx: 1 }}
                          onClick={(e) => {
                            console.log(
                              {
                                setIsPopupOpen,
                                handleSinglePageConversionClick,
                              },
                              "from click single"
                            );
                            // Below if conditions are there to open single page conversion modal and call It's endpoint
                            if (setIsPopupOpen) {
                              setIsPopupOpen(true);
                              if (handleSinglePageConversionClick) {
                                handleSinglePageConversionClick(resume.id);
                              }
                            }
                            e.stopPropagation();
                            handleClose(e);
                          }}
                        >
                          <SinglePageResumeIcon
                            style={{ marginRight: "8px" }}
                          />{" "}
                          Convert into Single Page
                        </MenuItem>

                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            confirmDeleteResume(resume.id, resume.title);
                          }}
                        >
                          <DeleteIcon style={{ marginRight: "8px" }} />
                          Delete
                        </MenuItem>
                      </>
                    )}
                  </Popover>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                {resume.title}
              </Typography>
              <Typography sx={{ fontSize: "0.9rem" }} color={"#5A697E"}>
                Checked on {formatDate(resume.modifiedDate)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ResumesList;
