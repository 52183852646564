import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as DatePickerIcon } from "../../../assets/SVG/date-picker.svg";
import { ReactComponent as AiIcon } from "../../../assets/SVG/ai-description-icon.svg";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import {
  calculateDuration,
  cleanSummaryHTMLstring,
  isValidUrl,
} from "../../../components/common/CommonFunctions";
import { setExtractedData } from "../../../redux/actions";
import { EducationTypes, WorkExperienceTypes } from "../../../components/utils";
import ReactQuill from "react-quill";
import WorkExperienceModal from "./WorkExperienceModal";
import { AutoFixHigh } from "@mui/icons-material";
import { quillModules } from "../../../App";
import { usePickerPopperOffset } from "../../../components/Hooks/usePickerPopperOffeset";
import CardEditHeader from "../../../components/common/CardEditHeader";
import classNames from "classnames";
import { Badge, styled } from "@mui/material";
import isToday from "date-fns/isToday";

interface CourseProps {
  courseData: WorkExperienceTypes;
  coursesList: WorkExperienceTypes[];
  setCoursesList: Dispatch<SetStateAction<WorkExperienceTypes[]>>;
  index: number;
}

const NoIconComponent = () => null;

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#000000", // Custom background color
    color: "red", // Custom text color
    borderRadius: "50%", // Circular shape
    border: "1px solid red",
  },
}));

// export const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 20, // Updated width
//   height: 12, // Updated height
//   padding: 0,
//   display: 'flex',
//   '&:active': {
//     '& .MuiSwitch-thumb': {
//       width: 8, // Adjusted thumb width for active state
//     },
//     '& .MuiSwitch-switchBase.Mui-checked': {
//       transform: 'translateX(8px)', // Adjusted for the new size
//     },
//   },
//   '& .MuiSwitch-switchBase': {
//     padding: 1,
//     '&.Mui-checked': {
//       transform: 'translateX(8px)', // Adjusted for the new size
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: '#1890ff',
//         ...theme.applyStyles('dark', {
//           backgroundColor: '#177ddc',
//         }),
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//     width: 10, // Updated thumb width
//     height: 10, // Updated thumb height
//     borderRadius: 5, // Adjusted for the new size
//     transition: theme.transitions.create(['width'], {
//       duration: 200,
//     }),
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 6, // Updated to match half of new height
//     opacity: 1,
//     backgroundColor: 'rgba(0,0,0,.25)',
//     boxSizing: 'border-box',
//     ...theme.applyStyles('dark', {
//       backgroundColor: 'rgba(255,255,255,.35)',
//     }),
//   },
// }));

const WorkExperienceEdit: React.FC<CourseProps> = ({
  courseData,
  coursesList,
  setCoursesList,
  index,
}) => {
  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (date.toDateString() === new Date().toDateString()) {
      return (
        <div
          style={{
            backgroundColor: "#007bff",
            color: "white",
            padding: "5px",
            borderRadius: "50%",
          }}
        >
          Today
        </div>
      );
    }
    return null;
  };
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  //formData handles local form state
  const [formData, setFormData] = useState<WorkExperienceTypes>(courseData);
  const [errors, setErrors] = useState({
    role: "",
    startDate: "",
    endDate: "",
    company: "",
  });
  const [workExpModal, setWorkExpModal] = useState(false);
  const [disableEndData, setDisableEndDate] = useState(false);

  /**
   * Date Piciker popper position
   */
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const startDateOffset = usePickerPopperOffset(startDateRef);
  const endDateOffset = usePickerPopperOffset(endDateRef);

  useEffect(() => {
    if (courseData?.endDate?.toLocaleLowerCase() === "present") {
      setDisableEndDate(true);
      setErrors((prev) => ({ ...prev, endDate: "" }));
    }
  }, []);

  const handleChange = (newValue: any, key: string) => {
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
    setFormData((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${month}-${day}-${year}`;
    if (formData.endDate && newValue > new Date(formData.endDate)) {
      handleChange("", "endDate");
    }
    handleChange(formattedDate, "startDate");
  };

  const handleStartDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, startDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, startDate: "" }));
    }
  };

  const handleEndDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, endDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, endDate: "" }));
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}-${day}-${year}`;
    handleChange(formattedDate, "endDate");
  };

  const handleCancel = () => {
    if (!!formData?.isNewEntry) {
      setCoursesList((prev) => (prev ? prev.slice(0, -1) : prev));
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (
            courEle?.role === courseData.role &&
            courEle?.company === courseData.company &&
            courEle?.startDate === courseData?.startDate &&
            courEle?.endDate === courseData?.endDate
          ) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }
  };

  const handleSave = () => {
    if (!formData?.company) {
      setErrors((prev) => ({
        ...prev,
        company: "Organization is required",
      }));
    }
    if (!formData?.startDate) {
      setErrors((prev) => ({
        ...prev,
        startDate: "Start Date is required",
      }));
    }
    if (!formData?.endDate) {
      setErrors((prev) => ({
        ...prev,
        endDate: "End Date is required",
      }));
    }
    if (!formData?.role) {
      setErrors((prev) => ({
        ...prev,
        role: "Job Title is required",
      }));
    }

    if (
      formData?.role &&
      formData?.company &&
      formData?.startDate &&
      formData?.endDate
    ) {
      //perform new course entry
      if (formData?.isNewEntry) {
        /**
         * New Entry
         */
        delete formData?.isNewEntry;
        delete formData?.index;
        dispatch(
          setExtractedData({
            ...extractedData,
            workExperiences: [
              ...(extractedData.workExperiences ?? []),
              {
                ...formData,
                keyResponsibilities: cleanSummaryHTMLstring(
                  formData?.keyResponsibilities
                ),
                isEdit: false,
              },
            ],
          })
        );
      } else {
        const updatedCourses = coursesList?.map((course) => {
          if (course?.index === formData?.index) {
            delete formData?.index;
            return { ...formData, isEdit: false };
          }
          delete course?.index;
          return course;
        });
        dispatch(
          setExtractedData({
            ...extractedData,
            workExperiences: updatedCourses,
          })
        );
      }
      //both cases remove error
      setErrors({
        role: "",
        startDate: "",
        endDate: "",
        company: "",
      });
    }
  };

  const handleDisableEndDate = (e: ChangeEvent<HTMLInputElement>) => {
    setDisableEndDate(e.target.checked);

    setErrors((prev) => ({
      ...prev,
      endDate: "",
    }));

    if (e.target.checked) {
      handleChange("present", "endDate");
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
          margin:
            index === 0 && courseData?.isNewEntry
              ? "0 0 1rem 0 !important"
              : "1rem 0",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.role,
              company: courseData?.company,
              startDate: courseData?.startDate,
              endDate: courseData?.endDate,
              isWorkExperienceSection: true,
              duration: calculateDuration(
                courseData?.startDate,
                courseData?.endDate
              ),
              jobType: courseData?.jobType,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="courses-dates-container">
          <Box className="input-box-container">
            <InputLabel htmlFor="role">Job type</InputLabel>
            <Select
              value={
                ["Full Time", "Part Time"]?.includes(formData?.jobType)
                  ? formData?.jobType
                  : "Full Time"
              }
              onChange={(e) => handleChange(e.target.value, "jobType")}
              fullWidth
            >
              <MenuItem value={"Full Time"}>Full time</MenuItem>
              <MenuItem value={"Part Time"}>Part time</MenuItem>
            </Select>
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Organization*</InputLabel>
            <TextField
              fullWidth
              id="organization"
              name="organization"
              placeholder="Eg: Microsoft Corporation"
              value={formData?.company}
              onChange={(e) => handleChange(e.target.value, "company")}
              error={!!errors.company}
              helperText={errors.company ? errors.company : ""}
            />
          </Box>
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container">
            <InputLabel htmlFor="company">Job Title*</InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: Software Developer"
              value={formData?.role}
              onChange={(e) => handleChange(e.target.value, "role")}
              error={!!errors.role}
              helperText={errors.role ? errors.role : ""}
            />
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">Location</InputLabel>
            <TextField
              fullWidth
              id="company"
              name="company"
              placeholder="Eg: New York"
              value={formData?.location}
              onChange={(e) => handleChange(e.target.value, "location")}
            />
          </Box>
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container" ref={startDateRef}>
            <InputLabel htmlFor="startDate">Start Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.startDate}
                className={classNames(
                  { "date-error": !!errors.startDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={
                    formData?.startDate ? new Date(formData.startDate) : null
                  }
                  onChange={handleStartDateChange}
                  onError={handleStartDateError}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    day: ({ day, selected, ...props }) => {
                      const isTodayDate = isToday(day); // Check if the day is today's date
                      return (
                        <div {...props}>
                          {isTodayDate ? (
                            <StyledBadge badgeContent="" overlap="circular">
                              {day.getDate()}
                            </StyledBadge>
                          ) : (
                            day.getDate()
                          )}
                        </div>
                      );
                    },
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: startDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.startDate && (
                  <FormHelperText>{errors?.startDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>

          <Box className="input-box-container" ref={endDateRef}>
            <InputLabel
              htmlFor="endDate"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              End Date
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "14.34px",
                    color: "#667085",
                  }}
                >
                  I currently work here
                </Typography>
                <Checkbox
                  sx={{
                    width: "0.1rem",
                    height: "0.1rem",
                    "& .MuiSvgIcon-root": {
                      fontSize: "1rem", // Adjust the size of the checkbox icon
                    },
                    "&.Mui-checked + .MuiSvgIcon-root": {
                      backgroundColor: "#5598ff", // Change background color (if using SvgIcon)
                    },
                  }}
                  checked={disableEndData}
                  onChange={handleDisableEndDate}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Box>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.endDate}
                className={classNames(
                  { "date-error": !!errors.endDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  disabled={disableEndData}
                  value={
                    formData?.endDate && formData.endDate !== "present"
                      ? new Date(formData.endDate)
                      : null
                  }
                  onChange={handleEndDateChange}
                  onError={handleEndDateError}
                  minDate={
                    formData?.startDate
                      ? new Date(formData?.startDate)
                      : undefined
                  }
                  slots={
                    !disableEndData
                      ? {
                          openPickerIcon: DatePickerIcon,
                        }
                      : { openPickerButton: NoIconComponent }
                  }
                  slotProps={{
                    textField: {
                      placeholder: disableEndData ? "Present" : undefined, // Add your placeholder here
                    },
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: endDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      ...(disableEndData && {
                        backgroundColor: "#F1F5F9", // Light grey background
                        color: "#667085",
                        cursor: "not-allowed", // Disable cursor
                        opacity: 0.5, // Reduced opacity for the disabled state
                      }),
                    },
                  }}
                />
                {errors?.endDate && (
                  <FormHelperText>{errors?.endDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box className="input-box-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <InputLabel htmlFor="address">Description</InputLabel>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 2,
                // backgroundColor: '#0088ff',
                background: "linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
                color: "#fff",
                borderRadius: "5.71px",
                cursor: "pointer",
                px: 1,
                py: 0.5,
              }}
            >
              <AiIcon />{" "}
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "500",
                  marginLeft: "0.3rem",
                  fontSize: "1rem",
                }}
                onClick={() => setWorkExpModal(true)}
              >
                {
                  extractedData.tailoredResumeObject?.tailoredResumeFeedback?.workExperiences ? "Improve with AI" : "Generate with AI"
                }
              </Typography>
            </Box>
          </Box>
          <Box className="quill-container">
            <ReactQuill
              value={formData?.keyResponsibilities}
              onChange={(e: any) => handleChange(e, "keyResponsibilities")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>

        {
          <Box className="section-action">
            {/* courseData?.isNewEntry && */}
            <Box className="link-container"></Box>
            <Box className="button-container">
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      </Box>

      <WorkExperienceModal
        workExpModal={workExpModal}
        setWorkExpModal={setWorkExpModal}
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        errors={errors}
        handleStartDateError={handleStartDateError}
        handleEndDateError={handleEndDateError}
      />
    </Box>
  );
};

export default WorkExperienceEdit;
