import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { ReactComponent as CardIcon } from "../../assets/SVG/cardIcon.svg";
import { ReactComponent as EducationCardIcon } from "../../assets/SVG/general-education-card-icon.svg";
import { ReactComponent as ExperienceCardIcon } from "../../assets/SVG/general-experience-card-icon.svg";

interface EditHeaderDetails {
  details: {
    heading: string;
    subHeading?: string;
    startDate?: string;
    endDate?: string;
    city?: string;
    company?: string;
    index?: number;
    isEducationSection?: boolean;
    isWorkExperienceSection?: boolean;
    duration?: string;
    jobType?: string;
  };
  handleSave: () => void;
  handleCancel: () => void;
}

const bulletPoint = "\u2022";

const CardEditHeader: React.FC<EditHeaderDetails> = ({
  details,
  handleSave,
  handleCancel,
}) => {
  const {
    heading,
    subHeading,
    startDate,
    endDate,
    city,
    company,
    index,
    isEducationSection,
    isWorkExperienceSection,
    duration,
    jobType,
  } = details;

  return (
    <Box>
      <Card
        className="education-card-container"
        sx={{ width: "100%", boxShadow: "none" }}
      >
        <CardContent
          className="card-content edit-header"
          sx={{
            "&:last-child": {
              paddingBottom: 0, // Remove padding from last child
            },
          }}
        >
          <Box className="top-section">
            <Box className="top-section-left">
              <CardIcon />
            </Box>
            {isEducationSection ? (
              <Box
                sx={{
                  paddingLeft: "0.7rem",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <EducationCardIcon />
              </Box>
            ) : isWorkExperienceSection ? (
              <Box
                sx={{
                  paddingLeft: "0.7rem",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <ExperienceCardIcon />
              </Box>
            ) : null}
            <Box className="top-section-right">
              <Box className="upper">
                <Box className="top-heading">
                  <Typography variant="h5" component="div" className="heading">
                    {heading}
                  </Typography>
                  {company && (
                    <Typography className="middle-heading">
                      {company}{" "}
                      {jobType && (
                        <span>
                          <span style={{ padding: "0 0.5rem" }}>
                            {bulletPoint}
                          </span>
                          <span>{jobType}</span>
                        </span>
                      )}
                    </Typography>
                  )}
                </Box>

                {/* <Typography className="edit-button" onClick={()=>handleEditClick && handleEditClick(index ?? 1)}>Edit</Typography> */}
              </Box>
              <Box className="lower">
                {startDate || endDate ? (
                  <Typography variant="h6" className="sub-heading" gutterBottom>
                    {startDate} to {endDate}{" "}
                    <span style={{ padding: "0 0.5rem" }}>{bulletPoint}</span>
                    <span>{duration}</span>
                  </Typography>
                ) : (
                  <Typography variant="h6" className="sub-heading">
                    {""}
                  </Typography>
                )}
                {/* <Typography className="delete-button"  onClick={()=>handleDeleteClick && handleDeleteClick(index ?? 1)}>
              Delete
            </Typography> */}
              </Box>
            </Box>
          </Box>

          {/* {aboutText && <Box className="middle-section">
            <Box className="about-section">
                <Typography variant="body1" component="div" className="about-text ui-text-3-color">
                {aboutText}
                </Typography>
            </Box>
        </Box>} */}
        </CardContent>
        {/* <CardActions className="bottom-section">
        <Box className="action-container">
            <Box className="edit-button" onClick={handleEditClick}><EditOutlinedIcon className="edit-icon"/></Box>
            <Box className="delete-button" onClick={handleDeleteClick}><DeleteOutlineOutlinedIcon className="delete-icon"/></Box>
        </Box>
      </CardActions> */}
      </Card>
    </Box>
  );
};

export default CardEditHeader;
