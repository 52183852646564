import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5077_85488)">
        <rect width="596" height="842" fill="white" />
        <rect
          x="-159"
          y="348"
          width="318"
          height="318"
          rx="159"
          fill="#FFB800"
        />
        <g
          clip-path="url(#bgblur_1_5077_85488_clip_path)"
          data-figma-skip-parse="true"
        ></g>
        <rect
          data-figma-bg-blur-radius="1"
          width="595"
          height="842"
          fill="#C4C4C4"
          fill-opacity="0.01"
        />
        <g
          clip-path="url(#bgblur_2_5077_85488_clip_path)"
          data-figma-skip-parse="true"
        ></g>
        <rect
          data-figma-bg-blur-radius="150"
          width="595"
          height="842"
          fill="#C4C4C4"
          fill-opacity="0.01"
        />
      </g>
      <defs>
        <clipPath id="bgblur_1_5077_85488_clip_path">
          <rect width="595" height="842" />
        </clipPath>
        <clipPath id="bgblur_2_5077_85488_clip_path">
          <rect width="595" height="842" />
        </clipPath>
        <clipPath id="clip0_5077_85488">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5077_85488)">
        <Rect width="596" height="842" fill="white" />
        <Rect
          x="-159"
          y="348"
          width="318"
          height="318"
          rx="159"
          fill={props?.c1 || "#FFB800"}
        />
        <G
          clip-path="url(#bgblur_1_5077_85488_clip_path)"
          data-figma-skip-parse="true"
        ></G>
        <Rect
          data-figma-bg-blur-radius="1"
          width="595"
          height="842"
          fill={props?.c2 || "#C4C4C4"}
          fill-opacity="0.01"
        />
        <G
          clip-path="url(#bgblur_2_5077_85488_clip_path)"
          data-figma-skip-parse="true"
        ></G>
        <Rect
          data-figma-bg-blur-radius="150"
          width="595"
          height="842"
          fill={props?.c2 || "#C4C4C4"}
          fill-opacity="0.01"
        />
      </G>
      <Defs>
        <ClipPath id="bgblur_1_5077_85488_clip_path">
          <Rect width="595" height="842" />
        </ClipPath>
        <ClipPath id="bgblur_2_5077_85488_clip_path">
          <Rect width="595" height="842" />
        </ClipPath>
        <ClipPath id="clip0_5077_85488">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture12 = {
  ViewTexture,
  PdfTexture,
};

export default Texture12;
