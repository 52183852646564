import { Box, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getUsernameFromToken } from "../../services/getUserFromToken";
import { ReactComponent as PersonalDetailEditIcon } from "../../assets/SVG/personal-edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const CoverLetterName = () => {
  let username = getUsernameFromToken();
  const [name, setName] = useState<string>(username || "");
  const [edit, setEdit] = useState<boolean>(false);
  const initialName = useRef<string>(name);
  const initialRef = useRef<HTMLElement>(null);
  const [textWidth, setTextWidth] = useState(0);
  const nameRef = useRef<HTMLElement>(null);

  const handleCloseEditor = () => {
    // setName(initialName.current);
    setEdit(false);
  };

  const handleEditDone = () => {
    // initialName.current = name;
    setEdit(false);
    // dispatch(setExtractedData({ ...extractedData, title: name }));
  };

  useEffect(() => {
    if (nameRef?.current || initialRef?.current) {
      const initialWidth = Math.max(
        nameRef?.current?.offsetWidth ?? 1,
        initialRef?.current?.offsetWidth ?? 1
      );
      setTextWidth(initialWidth); // Set the width of the container to text width
    }
  }, [name, edit]);

  console.log(name, "from editor");

  return (
    <Box className="name-score-wrapper-container">
      <Box className="name-container" sx={{ padding: "1rem 0" }}>
        {!edit ? (
          <Box className="name-wrapper">
            <Typography
              ref={initialRef}
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                "@media(min-height:1070px)": {
                  fontSize: "22px",
                },
              }}
            >
              {name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <PersonalDetailEditIcon onClick={() => setEdit(true)} />
            </Box>
          </Box>
        ) : (
          <Box className="edit-wrapper">
            {/* Hidden span element to measure the width of the text */}
            <Typography
              ref={nameRef}
              sx={{
                position: "absolute",
                top: "-9999px",
                left: "-9999px",
                visibility: "hidden",
                whiteSpace: "pre",
                fontSize: "20px",
                fontWeight: "600",
                "@media(min-height:1070px)": {
                  fontSize: "22px",
                },
              }}
            >
              {name}
            </Typography>

            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              size="small"
              sx={{
                width: `${Math.max(10, Math.min(textWidth + 20, 290))}px`,
                "  @media (max-height: 1450px)": {
                  width: `${Math.max(10, Math.min(textWidth + 20, 700))}px`, // Further reduce width for smaller screens
                },
                "@media (max-height: 1090px)": {
                  width: `${Math.max(10, Math.min(textWidth + 20, 530))}px`, // Further reduce width for smaller screens
                },
                "@media (max-height: 910px)": {
                  width: `${Math.max(10, Math.min(textWidth + 20, 305))}px`, // Further reduce width for smaller screens
                },
                "@media (max-height: 730px)": {
                  width: `${Math.max(10, Math.min(textWidth + 20, 235))}px`, // Adjust width for small screens
                },

                "& .MuiInputBase-input": {
                  fontSize: "20px !important",
                  fontWeight: "600 !important",
                },
              }}
            />
            <CloseIcon
              sx={{ color: "#EF4444", cursor: "pointer" }}
              onClick={handleCloseEditor}
            />
            <IconButton
              disabled={!name}
              sx={{
                "&:hover": {
                  background: "none",
                },
                cursor: !name ? "not-allowed" : "pointer",
              }}
            >
              <DoneIcon
                sx={{ color: name ? "#19A44C" : "lightgray" }}
                onClick={handleEditDone}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CoverLetterName;
