import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const ProfessionalSummaryTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
  SectionBody,
  containerClass,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
  containerClass?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
  });

  return (
    <>
      {extractedData.professionalSummary !== "NA" &&
        extractedData.professionalSummary && (
          <>
            <>
              {SectionHeader ? (
                <SectionHeader>PROFESSIONAL SUMMARY</SectionHeader>
              ) : (
                <Text style={styles.sectionHeader}>PROFESSIONAL SUMMARY</Text>
              )}
              <BodyContainer>
                <Text style={{ ...styles.sectionContent }}>
                  {extractedData.professionalSummary?.replace(
                    /<\/?[^>]+(>|$)/g,
                    ""
                  )}
                </Text>
              </BodyContainer>
            </>
          </>
        )}
    </>
  );
};

export default ProfessionalSummaryTemplate;
