import { Box, Typography } from "@mui/material";
import { ReactComponent as GoalIcon } from "../../../../assets/SVG/goals_icon.svg";
import GoalEdit from "./GoalEdit";
import { useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { useDispatch } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
interface CoursesListProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
  handleDeleteEmptySection: (section: string) => void;
}

const GoalsList: React.FC<CoursesListProps> = ({
  handleSectionDelete,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const dispatch = useDispatch();
  return (
    <Box className="courses-section">
      <Box className="heading-box">
        {
          <Box className="icon-heading-cont">
            <Box className="icon-container">
              <GoalIcon />
            </Box>
            <Typography
              variant="h5"
              color="color.primary"
              className="heading"
              sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              Goals
            </Typography>
          </Box>
        }

        <Typography
          className="sub-heading"
          onClick={() => {
            handleSectionDelete(sectionName);
            dispatch(
              setExtractedData({
                ...extractedData,
                goals: "",
              })
            );
          }}
        >
          Remove section
        </Typography>
      </Box>

      <GoalEdit />
    </Box>
  );
};

export default GoalsList;
