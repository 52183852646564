import { Box, Typography } from "@mui/material";
import { IJobDetail } from "./job-types";

type JobCardProps = {
  job: IJobDetail;
};
const JobCard = ({ job }: JobCardProps) => {
  return (
    <Box
      className="job-card"
      sx={{
        p: 2,
        border: "1px solid #EAECF0",
        borderRadius: "12px",
      }}
    >
      <Box sx={{ display: "flex", gap: "20px" }}>
        <img
          src={job.companyLogo}
          width="74px"
          height="74px"
          alt="company-logo"
          style={{ flexGrow: "0", flexShrink: "0" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography variant="h6">{job.companyName}</Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography>
              {job.city}, {job.country}
            </Typography>
            <Typography>{job.jobType}</Typography>
            <Typography>{job.salary}</Typography>
            <Typography>{job.experience}</Typography>
            <Typography>{job.posted}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobCard;
