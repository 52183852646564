import { styled, Tab, Tabs } from "@mui/material";

interface StyledTabProps {
  label: string;
}

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #E2E8F0",
  "& .MuiTabs-indicator": {
    backgroundColor: "#5594FF",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  fontSize: "1rem",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(1),
  color: "#525866",
  "&.Mui-selected": {
    color: "#0A0D14",
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: "1em",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export { StyledTabs, StyledTab };
