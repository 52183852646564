import { Box, Paper } from "@mui/material";
import SideDrawer from "../../ProcessResume/SideDrawer";
import Layout from "../Layout";
import CustomizedAlert from "../CustomizedAlert";
import { useState } from "react";
import CoverLetterEdit from "./CoverLetterEdit";
import CoverLetterName from "./CoverLettername";

const CoverLetterFeatures = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveAsDraftModal, setSaveAsDraftModal] = useState<boolean>(false);

  return (
    <Layout>
      <CustomizedAlert
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        alertState={openSnackbar}
        title={"Error"}
        description={"Failed to fetch resume data"}
        severity="error"
        handleCloseSnackbar={() => setOpenSnackbar(false)}
      />
      <Box className="process-resume-page-container">
        <Box className="side-drawer-container">
          <SideDrawer
            saveAsDraftModal={saveAsDraftModal}
            setSaveAsDraftModal={setSaveAsDraftModal}
          />
        </Box>

        <Box>
          <Box className="main-content">
            <Paper className="left-edit-container" sx={{ flex: 1.6 }}>
              <CoverLetterName />
              <CoverLetterEdit />
            </Paper>

            <Box
              className="right-preview-container"
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              }}
            >
              Right Preview
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default CoverLetterFeatures;
