import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5032_16625)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M141.706 48.1973C141.181 48.7217 141.181 49.5739 141.706 50.0983C142.23 50.6228 143.082 50.6228 143.607 50.0983C144.131 49.5739 144.131 48.7217 143.607 48.1973C143.082 47.6729 142.23 47.6729 141.706 48.1973Z"
          fill="#B3B2D8"
        />
        <path
          d="M132.659 57.2435C132.135 57.7679 132.135 58.6201 132.659 59.1445C133.183 59.669 134.036 59.669 134.56 59.1445C135.084 58.6201 135.084 57.7679 134.56 57.2435C134.036 56.7191 133.183 56.7191 132.659 57.2435Z"
          fill="#B3B2D8"
        />
        <path
          d="M123.614 66.2897C123.09 66.8142 123.09 67.6663 123.614 68.1908C124.138 68.7152 124.991 68.7152 125.515 68.1908C126.039 67.6663 126.039 66.8142 125.515 66.2897C124.991 65.7653 124.138 65.7653 123.614 66.2897Z"
          fill="#B3B2D8"
        />
        <path
          d="M114.567 75.336C114.043 75.8604 114.043 76.7125 114.567 77.237C115.092 77.7614 115.944 77.7614 116.468 77.237C116.993 76.7125 116.993 75.8604 116.468 75.336C115.944 74.8115 115.092 74.8115 114.567 75.336Z"
          fill="#B3B2D8"
        />
        <path
          d="M105.521 84.3822C104.997 84.9066 104.997 85.7588 105.521 86.2832C106.046 86.8076 106.898 86.8076 107.422 86.2832C107.947 85.7588 107.947 84.9066 107.422 84.3822C106.898 83.8577 106.046 83.8577 105.521 84.3822Z"
          fill="#B3B2D8"
        />
        <path
          d="M96.4744 93.4284C95.95 93.9528 95.95 94.805 96.4744 95.3294C96.9988 95.8538 97.851 95.8538 98.3754 95.3294C98.8998 94.805 98.8998 93.9528 98.3754 93.4284C97.851 92.904 96.9988 92.904 96.4744 93.4284Z"
          fill="#B3B2D8"
        />
        <path
          d="M87.4285 102.475C86.9041 102.999 86.9041 103.851 87.4285 104.376C87.9529 104.9 88.8051 104.9 89.3295 104.376C89.8539 103.851 89.8539 102.999 89.3295 102.475C88.8051 101.95 87.9529 101.95 87.4285 102.475Z"
          fill="#B3B2D8"
        />
        <path
          d="M78.3826 111.521C77.8582 112.045 77.8582 112.897 78.3826 113.422C78.907 113.946 79.7592 113.946 80.2836 113.422C80.808 112.897 80.808 112.045 80.2836 111.521C79.7592 110.996 78.907 110.996 78.3826 111.521Z"
          fill="#B3B2D8"
        />
        <path
          d="M69.3357 120.567C68.8113 121.091 68.8113 121.944 69.3357 122.468C69.8601 122.992 70.7123 122.992 71.2367 122.468C71.7611 121.944 71.7611 121.091 71.2367 120.567C70.7123 120.043 69.8601 120.043 69.3357 120.567Z"
          fill="#B3B2D8"
        />
        <path
          d="M60.2908 129.613C59.7664 130.138 59.7664 130.99 60.2908 131.514C60.8152 132.039 61.6674 132.039 62.1918 131.514C62.7162 130.99 62.7162 130.138 62.1918 129.613C61.6674 129.089 60.8152 129.089 60.2908 129.613Z"
          fill="#B3B2D8"
        />
        <path
          d="M51.2439 138.659C50.7195 139.184 50.7195 140.036 51.2439 140.56C51.7683 141.085 52.6205 141.085 53.1449 140.56C53.6693 140.036 53.6693 139.184 53.1449 138.659C52.6205 138.135 51.7683 138.135 51.2439 138.659Z"
          fill="#B3B2D8"
        />
        <path
          d="M42.197 147.706C41.6726 148.23 41.6726 149.082 42.197 149.607C42.7214 150.131 43.5736 150.131 44.098 149.607C44.6225 149.082 44.6225 148.23 44.098 147.706C43.5736 147.181 42.7214 147.181 42.197 147.706Z"
          fill="#B3B2D8"
        />
        <path
          d="M133.511 40.0688C132.987 40.5932 132.987 41.4454 133.511 41.9698C134.036 42.4943 134.888 42.4943 135.412 41.9698C135.937 41.4454 135.937 40.5932 135.412 40.0688C134.888 39.5444 134.036 39.5444 133.511 40.0688Z"
          fill="#B3B2D8"
        />
        <path
          d="M124.466 49.115C123.941 49.6395 123.941 50.4916 124.466 51.0161C124.99 51.5405 125.842 51.5405 126.367 51.0161C126.891 50.4916 126.891 49.6395 126.367 49.115C125.842 48.5906 124.99 48.5906 124.466 49.115Z"
          fill="#B3B2D8"
        />
        <path
          d="M115.42 58.1613C114.895 58.6857 114.895 59.5378 115.42 60.0623C115.944 60.5867 116.796 60.5867 117.321 60.0623C117.845 59.5378 117.845 58.6857 117.321 58.1613C116.796 57.6368 115.944 57.6368 115.42 58.1613Z"
          fill="#B3B2D8"
        />
        <path
          d="M106.373 67.2075C105.848 67.7319 105.848 68.5841 106.373 69.1085C106.897 69.6329 107.749 69.6329 108.274 69.1085C108.798 68.5841 108.798 67.7319 108.274 67.2075C107.749 66.6831 106.897 66.6831 106.373 67.2075Z"
          fill="#B3B2D8"
        />
        <path
          d="M97.3279 76.2537C96.8035 76.7781 96.8035 77.6303 97.3279 78.1547C97.8523 78.6791 98.7045 78.6791 99.2289 78.1547C99.7533 77.6303 99.7533 76.7781 99.2289 76.2537C98.7045 75.7293 97.8523 75.7293 97.3279 76.2537Z"
          fill="#B3B2D8"
        />
        <path
          d="M88.281 85.2999C87.7566 85.8243 87.7566 86.6765 88.281 87.2009C88.8054 87.7253 89.6576 87.7253 90.182 87.2009C90.7064 86.6765 90.7064 85.8243 90.182 85.2999C89.6576 84.7755 88.8054 84.7755 88.281 85.2999Z"
          fill="#B3B2D8"
        />
        <path
          d="M79.2341 94.3461C78.7097 94.8705 78.7097 95.7227 79.2341 96.2471C79.7586 96.7715 80.6107 96.7715 81.1351 96.2471C81.6596 95.7227 81.6596 94.8705 81.1351 94.3461C80.6107 93.8217 79.7586 93.8217 79.2341 94.3461Z"
          fill="#B3B2D8"
        />
        <path
          d="M70.1882 103.392C69.6638 103.917 69.6638 104.769 70.1882 105.293C70.7127 105.818 71.5648 105.818 72.0893 105.293C72.6792 104.769 72.6792 103.917 72.0893 103.392C71.5648 102.868 70.7127 102.868 70.1882 103.392Z"
          fill="#B3B2D8"
        />
        <path
          d="M61.1423 112.439C60.6179 112.963 60.6179 113.815 61.1423 114.34C61.6668 114.864 62.5189 114.864 63.0434 114.34C63.6333 113.815 63.6333 112.963 63.0434 112.439C62.5189 111.914 61.6668 111.914 61.1423 112.439Z"
          fill="#B3B2D8"
        />
        <path
          d="M52.0964 121.485C51.572 122.009 51.572 122.861 52.0964 123.386C52.6209 123.91 53.473 123.91 54.063 123.386C54.5874 122.861 54.5874 122.009 54.063 121.485C53.473 120.96 52.6209 120.96 52.0964 121.485Z"
          fill="#B3B2D8"
        />
        <path
          d="M43.0496 130.531C42.5251 131.055 42.5251 131.908 43.0496 132.432C43.574 132.956 44.4262 132.956 45.0161 132.432C45.5405 131.908 45.5405 131.055 45.0161 130.531C44.4262 130.007 43.574 130.007 43.0496 130.531Z"
          fill="#B3B2D8"
        />
        <path
          d="M34.0027 139.577C33.4783 140.102 33.4783 140.954 34.0027 141.478C34.5271 142.003 35.4448 142.003 35.9693 141.478C36.4937 140.954 36.4937 140.102 35.9693 139.577C35.4448 139.053 34.5271 139.053 34.0027 139.577Z"
          fill="#B3B2D8"
        />
        <path
          d="M125.384 31.9403C124.859 32.4648 124.859 33.3169 125.384 33.8414C125.908 34.3658 126.76 34.3658 127.285 33.8414C127.809 33.3169 127.809 32.4648 127.285 31.8748C126.76 31.3504 125.908 31.3504 125.384 31.8748V31.9403Z"
          fill="#B3B2D8"
        />
        <path
          d="M116.338 40.921C115.813 41.511 115.813 42.3632 116.338 42.8876C116.862 43.412 117.714 43.412 118.239 42.8876C118.763 42.3632 118.763 41.511 118.239 40.921C117.714 40.3966 116.862 40.3966 116.338 40.921Z"
          fill="#B3B2D8"
        />
        <path
          d="M107.291 49.9672C106.766 50.4916 106.766 51.4094 107.291 51.9338C107.815 52.4582 108.667 52.4582 109.192 51.9338C109.716 51.4094 109.716 50.4916 109.192 49.9672C108.667 49.4428 107.815 49.4428 107.291 49.9672Z"
          fill="#B3B2D8"
        />
        <path
          d="M98.2439 59.0134C97.7195 59.5379 97.7195 60.39 98.2439 60.98C98.7683 61.5044 99.6205 61.5044 100.145 60.98C100.669 60.39 100.669 59.5379 100.145 59.0134C99.6205 58.489 98.7683 58.489 98.2439 59.0134Z"
          fill="#B3B2D8"
        />
        <path
          d="M89.199 68.0596C88.6746 68.5841 88.6746 69.4362 89.199 70.0262C89.7234 70.5506 90.5756 70.5506 91.1 70.0262C91.6244 69.4362 91.6244 68.5841 91.1 68.0596C90.5756 67.5352 89.7234 67.5352 89.199 68.0596Z"
          fill="#B3B2D8"
        />
        <path
          d="M80.1521 77.1059C79.6277 77.6303 79.6277 78.4825 80.1521 79.0069C80.6765 79.5969 81.5287 79.5969 82.0531 79.0069C82.5775 78.4825 82.5775 77.6303 82.0531 77.1059C81.5287 76.5815 80.6765 76.5815 80.1521 77.1059Z"
          fill="#B3B2D8"
        />
        <path
          d="M71.1062 86.1521C70.5818 86.6765 70.5818 87.5287 71.1062 88.0531C71.6306 88.5775 72.4828 88.5775 73.0072 88.0531C73.5316 87.5287 73.5316 86.6765 73.0072 86.1521C72.4828 85.6277 71.6306 85.6277 71.1062 86.1521Z"
          fill="#B3B2D8"
        />
        <path
          d="M62.0603 95.1983C61.5359 95.7227 61.5359 96.5749 62.0603 97.0993C62.5847 97.6237 63.4369 97.6237 63.9613 97.0993C64.4857 96.5749 64.4857 95.7227 63.9613 95.1983C63.4369 94.6739 62.5847 94.6739 62.0603 95.1983Z"
          fill="#B3B2D8"
        />
        <path
          d="M53.0134 104.244C52.489 104.769 52.489 105.621 53.0134 106.146C53.5378 106.67 54.39 106.67 54.9144 106.146C55.4389 105.621 55.4389 104.769 54.9144 104.244C54.39 103.72 53.5378 103.72 53.0134 104.244Z"
          fill="#B3B2D8"
        />
        <path
          d="M43.9675 113.291C43.4431 113.815 43.4431 114.667 43.9675 115.192C44.492 115.716 45.3441 115.716 45.8685 115.192C46.393 114.667 46.393 113.815 45.8685 113.291C45.3441 112.766 44.492 112.766 43.9675 113.291Z"
          fill="#B3B2D8"
        />
        <path
          d="M34.9207 122.337C34.3962 122.861 34.3962 123.714 34.9207 124.238C35.4451 124.762 36.2973 124.762 36.8217 124.238C37.3461 123.714 37.3461 122.861 36.8217 122.337C36.2973 121.813 35.4451 121.813 34.9207 122.337Z"
          fill="#B3B2D8"
        />
        <path
          d="M25.8757 131.383C25.3513 131.908 25.3513 132.76 25.8757 133.284C26.4002 133.809 27.2523 133.809 27.7768 133.284C28.3012 132.76 28.3012 131.908 27.7768 131.383C27.2523 130.859 26.4002 130.859 25.8757 131.383Z"
          fill="#B3B2D8"
        />
        <path
          d="M117.255 23.7463C116.73 24.2707 116.73 25.1229 117.255 25.6473C117.779 26.1717 118.631 26.1717 119.156 25.6473C119.68 25.1229 119.68 24.2707 119.156 23.7463C118.631 23.2219 117.779 23.2219 117.255 23.7463Z"
          fill="#B3B2D8"
        />
        <path
          d="M108.209 32.7925C107.684 33.317 107.684 34.1691 108.209 34.6936C108.733 35.218 109.585 35.218 110.11 34.6936C110.634 34.1691 110.634 33.317 110.11 32.7925C109.585 32.2681 108.733 32.2681 108.209 32.7925Z"
          fill="#B3B2D8"
        />
        <path
          d="M99.1619 41.8387C98.6375 42.3632 98.6375 43.2153 99.1619 43.7398C99.6863 44.2642 100.538 44.2642 101.063 43.7398C101.587 43.2153 101.587 42.3632 101.063 41.8387C100.538 41.3143 99.6863 41.3143 99.1619 41.8387Z"
          fill="#B3B2D8"
        />
        <path
          d="M90.1169 50.885C89.5925 51.4094 89.5925 52.2616 90.1169 52.786C90.6414 53.3104 91.4935 53.3104 92.018 52.786C92.5424 52.2616 92.5424 51.4094 92.018 50.885C91.4935 50.3605 90.6414 50.3605 90.1169 50.885Z"
          fill="#B3B2D8"
        />
        <path
          d="M81.0701 59.9312C80.5457 60.4556 80.5457 61.3078 81.0701 61.8322C81.5945 62.3566 82.4467 62.3566 82.9711 61.8322C83.4955 61.3078 83.4955 60.4556 82.9711 59.9312C82.4467 59.4068 81.5945 59.4068 81.0701 59.9312Z"
          fill="#B3B2D8"
        />
        <path
          d="M72.0232 68.9774C71.4988 69.5018 71.4988 70.354 72.0232 70.8784C72.5476 71.4028 73.3998 71.4028 73.9242 70.8784C74.4486 70.354 74.4486 69.5018 73.9242 68.9774C73.3998 68.453 72.5476 68.453 72.0232 68.9774Z"
          fill="#B3B2D8"
        />
        <path
          d="M62.9783 78.0236C62.4539 78.548 62.4539 79.4002 62.9783 79.9246C63.5027 80.449 64.3549 80.449 64.8793 79.9246C65.4037 79.4002 65.4037 78.548 64.8793 78.0236C64.3549 77.4992 63.5027 77.4992 62.9783 78.0236Z"
          fill="#B3B2D8"
        />
        <path
          d="M53.9314 87.0698C53.407 87.5942 53.407 88.4464 53.9314 88.9708C54.4558 89.4952 55.308 89.4952 55.8324 88.9708C56.3568 88.4464 56.3568 87.5942 55.8324 87.0698C55.308 86.5454 54.4558 86.5454 53.9314 87.0698Z"
          fill="#B3B2D8"
        />
        <path
          d="M44.8855 96.116C44.3611 96.6404 44.3611 97.4926 44.8855 98.017C45.4099 98.5414 46.2621 98.5414 46.7865 98.017C47.3109 97.4926 47.3109 96.6404 46.7865 96.116C46.2621 95.5916 45.4099 95.5916 44.8855 96.116Z"
          fill="#B3B2D8"
        />
        <path
          d="M35.8386 105.162C35.3142 105.687 35.3142 106.539 35.8386 107.063C36.363 107.588 37.2152 107.588 37.7396 107.063C38.2641 106.539 38.2641 105.687 37.7396 105.162C37.2152 104.638 36.363 104.638 35.8386 105.162Z"
          fill="#B3B2D8"
        />
        <path
          d="M26.7918 114.208C26.2673 114.733 26.2673 115.585 26.7918 116.109C27.3162 116.634 28.1683 116.634 28.6928 116.109C29.2172 115.585 29.2172 114.733 28.6928 114.208C28.1683 113.684 27.3162 113.684 26.7918 114.208Z"
          fill="#B3B2D8"
        />
        <path
          d="M17.7468 123.255C17.2224 123.779 17.2224 124.631 17.7468 125.156C18.2712 125.68 19.1234 125.68 19.6478 125.156C20.1723 124.631 20.1723 123.779 19.6478 123.255C19.1234 122.73 18.2712 122.73 17.7468 123.255Z"
          fill="#B3B2D8"
        />
        <path
          d="M109.06 15.6178C108.536 16.1422 108.536 16.9944 109.06 17.5188C109.585 18.0433 110.437 18.0433 110.961 17.5188C111.486 16.9944 111.486 16.1422 110.961 15.6178C110.437 15.0934 109.585 15.0934 109.06 15.6178Z"
          fill="#B3B2D8"
        />
        <path
          d="M100.014 24.664C99.49 25.1885 99.49 26.0406 100.014 26.5651C100.539 27.0895 101.391 27.0895 101.915 26.5651C102.44 26.0406 102.44 25.1885 101.915 24.664C101.391 24.1396 100.539 24.1396 100.014 24.664Z"
          fill="#B3B2D8"
        />
        <path
          d="M90.9685 33.7103C90.4441 34.2347 90.4441 35.0869 90.9685 35.6113C91.4929 36.1357 92.3451 36.1357 92.8695 35.6113C93.3939 35.0869 93.3939 34.2347 92.8695 33.7103C92.3451 33.1858 91.4929 33.1858 90.9685 33.7103Z"
          fill="#B3B2D8"
        />
        <path
          d="M81.9226 42.7565C81.3982 43.2809 81.3982 44.1331 81.9226 44.6575C82.447 45.1819 83.2992 45.1819 83.8236 44.6575C84.348 44.1331 84.348 43.2809 83.8236 42.7565C83.2992 42.232 82.447 42.232 81.9226 42.7565Z"
          fill="#B3B2D8"
        />
        <path
          d="M72.8757 51.8027C72.3513 52.3271 72.3513 53.1793 72.8757 53.7037C73.4002 54.2281 74.2523 54.2281 74.7768 53.7037C75.3667 53.1793 75.3667 52.3271 74.7768 51.8027C74.2523 51.2783 73.4002 51.2783 72.8757 51.8027Z"
          fill="#B3B2D8"
        />
        <path
          d="M63.8289 60.8489C63.3044 61.3733 63.3044 62.2255 63.8289 62.7499C64.3533 63.2743 65.2055 63.2743 65.7299 62.7499C66.3198 62.2255 66.3198 61.3733 65.7299 60.8489C65.2055 60.3245 64.3533 60.3245 63.8289 60.8489Z"
          fill="#B3B2D8"
        />
        <path
          d="M54.7839 69.8951C54.2595 70.4195 54.2595 71.2717 54.7839 71.7961C55.3084 72.3205 56.1605 72.3205 56.7505 71.7961C57.2749 71.2717 57.2749 70.4195 56.7505 69.8951C56.1605 69.3707 55.3084 69.3707 54.7839 69.8951Z"
          fill="#B3B2D8"
        />
        <path
          d="M45.7371 78.9413C45.2126 79.4658 45.2126 80.3179 45.7371 80.8423C46.2615 81.3668 47.1137 81.3668 47.7036 80.8423C48.228 80.3179 48.228 79.4658 47.7036 78.9413C47.1137 78.4169 46.2615 78.4169 45.7371 78.9413Z"
          fill="#B3B2D8"
        />
        <path
          d="M36.6912 87.9875C36.1667 88.512 36.1667 89.3641 36.6912 89.8886C37.2156 90.413 38.1333 90.413 38.6577 89.8886C39.1822 89.3641 39.1822 88.512 38.6577 87.9875C38.1333 87.4631 37.2156 87.4631 36.6912 87.9875Z"
          fill="#B3B2D8"
        />
        <path
          d="M27.6453 97.0337C27.1208 97.5582 27.1208 98.4103 27.6453 98.9348C28.2352 99.4592 29.0874 99.4592 29.6118 98.9348C30.1363 98.4103 30.1363 97.5582 29.6118 97.0337C29.0874 96.5093 28.2352 96.5093 27.6453 97.0337Z"
          fill="#B3B2D8"
        />
        <path
          d="M18.6642 106.08C18.0742 106.604 18.0742 107.457 18.6642 107.981C19.1886 108.505 20.0408 108.505 20.5652 107.981C21.0896 107.457 21.0896 106.604 20.5652 106.08C20.0408 105.556 19.1886 105.556 18.6642 106.08Z"
          fill="#B3B2D8"
        />
        <path
          d="M9.61826 115.126C9.02829 115.651 9.02829 116.503 9.61826 117.027C10.1427 117.552 10.9949 117.552 11.5193 117.027C12.0437 116.503 12.0437 115.651 11.5193 115.126C10.9949 114.602 10.1427 114.602 9.61826 115.126Z"
          fill="#B3B2D8"
        />
        <path
          d="M100.932 7.48935C100.408 8.01377 100.408 8.86595 100.932 9.39037C101.457 9.91479 102.309 9.91479 102.833 9.39037C103.358 8.86595 103.358 8.01377 102.833 7.48935C102.309 6.96493 101.457 6.96493 100.932 7.48935Z"
          fill="#B3B2D8"
        />
        <path
          d="M91.8865 16.5356C91.3621 17.06 91.3621 17.9122 91.8865 18.4366C92.4109 18.961 93.2631 18.961 93.7875 18.4366C94.3119 17.9122 94.3119 17.06 93.7875 16.5356C93.2631 16.0111 92.4109 16.0111 91.8865 16.5356Z"
          fill="#B3B2D8"
        />
        <path
          d="M82.8396 25.5818C82.3152 26.1062 82.3152 26.9584 82.8396 27.4828C83.364 28.0072 84.2162 28.0072 84.7406 27.4828C85.265 26.9584 85.265 26.1062 84.7406 25.5818C84.2162 24.9918 83.364 24.9918 82.8396 25.5818Z"
          fill="#B3B2D8"
        />
        <path
          d="M73.7937 34.5624C73.2693 35.1524 73.2693 36.0046 73.7937 36.529C74.3181 37.0534 75.1703 37.0534 75.6947 36.529C76.2191 36.0046 76.2191 35.1524 75.6947 34.5624C75.1703 34.038 74.3181 34.038 73.7937 34.5624Z"
          fill="#B3B2D8"
        />
        <path
          d="M64.7468 43.6087C64.2224 44.1986 64.2224 45.0508 64.7468 45.5752C65.2712 46.0996 66.1234 46.0996 66.6478 45.5752C67.1723 45.0508 67.1723 44.1986 66.6478 43.6087C66.1234 43.0842 65.2712 43.0842 64.7468 43.6087Z"
          fill="#B3B2D8"
        />
        <path
          d="M55.7019 52.6549C55.1775 53.1793 55.1775 54.097 55.7019 54.6214C56.2263 55.1458 57.0785 55.1458 57.6029 54.6214C58.1273 54.097 58.1273 53.1793 57.6029 52.6549C57.0785 52.1304 56.2263 52.1304 55.7019 52.6549Z"
          fill="#B3B2D8"
        />
        <path
          d="M46.655 61.7011C46.1306 62.2255 46.1306 63.0777 46.655 63.6677C47.1795 64.1921 48.0316 64.1921 48.556 63.6677C49.0805 63.0777 49.0805 62.2255 48.556 61.7011C48.0316 61.1767 47.1795 61.1767 46.655 61.7011Z"
          fill="#B3B2D8"
        />
        <path
          d="M37.6082 70.7473C37.0837 71.2717 37.0837 72.1239 37.6082 72.7139C38.1326 73.2383 38.9848 73.2383 39.5092 72.7139C40.0336 72.1239 40.0336 71.2717 39.5092 70.7473C38.9848 70.2229 38.1326 70.2229 37.6082 70.7473Z"
          fill="#B3B2D8"
        />
        <path
          d="M28.5632 79.7935C28.0388 80.3179 28.0388 81.1701 28.5632 81.6945C29.0877 82.2845 29.9398 82.2845 30.4643 81.6945C30.9887 81.1701 30.9887 80.3179 30.4643 79.7935C29.9398 79.2691 29.0877 79.2691 28.5632 79.7935Z"
          fill="#B3B2D8"
        />
        <path
          d="M19.5164 88.8397C18.9919 89.3641 18.9919 90.2163 19.5164 90.7407C20.0408 91.2652 20.893 91.2652 21.4174 90.7407C21.9418 90.2163 21.9418 89.3641 21.4174 88.8397C20.893 88.3153 20.0408 88.3153 19.5164 88.8397Z"
          fill="#B3B2D8"
        />
        <path
          d="M10.4705 97.8859C9.94604 98.4104 9.94604 99.2625 10.4705 99.787C10.9949 100.311 11.8471 100.311 12.3715 99.787C12.8959 99.2625 12.8959 98.4104 12.3715 97.8859C11.8471 97.3615 10.9949 97.3615 10.4705 97.8859Z"
          fill="#B3B2D8"
        />
        <path
          d="M1.42359 106.932C0.899169 107.457 0.899169 108.309 1.42359 108.833C1.94801 109.358 2.80019 109.358 3.3246 108.833C3.84902 108.309 3.84902 107.457 3.3246 106.932C2.80019 106.408 1.94801 106.408 1.42359 106.932Z"
          fill="#B3B2D8"
        />
        <path
          d="M92.8044 -0.704682C92.28 -0.180264 92.28 0.671916 92.8044 1.19633C93.3289 1.72075 94.181 1.72075 94.7055 1.19633C95.2299 0.671916 95.2299 -0.180264 94.7055 -0.704682C94.181 -1.2291 93.3289 -1.2291 92.8044 -0.704682Z"
          fill="#B3B2D8"
        />
        <path
          d="M83.7576 8.34152C83.2332 8.86594 83.2332 9.71812 83.7576 10.2425C84.282 10.767 85.1342 10.767 85.6586 10.2425C86.183 9.71812 86.183 8.86594 85.6586 8.34152C85.1342 7.8171 84.282 7.8171 83.7576 8.34152Z"
          fill="#B3B2D8"
        />
        <path
          d="M74.7117 17.3877C74.1873 17.9122 74.1873 18.7643 74.7117 19.2888C75.2361 19.8132 76.0883 19.8132 76.6127 19.2888C77.1371 18.7643 77.1371 17.9122 76.6127 17.3877C76.0883 16.8633 75.2361 16.8633 74.7117 17.3877Z"
          fill="#B3B2D8"
        />
        <path
          d="M65.6648 26.434C65.1404 26.9584 65.1404 27.8106 65.6648 28.335C66.1892 28.8594 67.0414 28.8594 67.5658 28.335C68.0902 27.8106 68.0902 26.9584 67.5658 26.434C67.0414 25.9095 66.1892 25.9095 65.6648 26.434Z"
          fill="#B3B2D8"
        />
        <path
          d="M56.6189 35.4802C56.0945 36.0046 56.0945 36.8568 56.6189 37.3812C57.1433 37.9056 57.9955 37.9056 58.5199 37.3812C59.0443 36.8568 59.0443 36.0046 58.5199 35.4802C57.9955 34.9558 57.1433 34.9558 56.6189 35.4802Z"
          fill="#B3B2D8"
        />
        <path
          d="M47.573 44.5264C47.0486 45.0508 47.0486 45.903 47.573 46.4274C48.0974 46.9518 48.9496 46.9518 49.474 46.4274C49.9984 45.903 49.9984 45.0508 49.474 44.5264C48.9496 44.002 48.0974 44.002 47.573 44.5264Z"
          fill="#B3B2D8"
        />
        <path
          d="M38.5261 53.5726C38.0017 54.097 38.0017 54.9492 38.5261 55.4736C39.0505 55.998 39.9027 55.998 40.4271 55.4736C40.9516 54.9492 40.9516 54.097 40.4271 53.5726C39.9027 53.0482 39.0505 53.0482 38.5261 53.5726Z"
          fill="#B3B2D8"
        />
        <path
          d="M29.4812 62.6188C28.9568 63.1432 28.9568 63.9954 29.4812 64.5198C30.0056 65.0443 30.8578 65.0443 31.3822 64.5198C31.9066 63.9954 31.9066 63.1432 31.3822 62.6188C30.8578 62.0944 30.0056 62.0944 29.4812 62.6188Z"
          fill="#B3B2D8"
        />
        <path
          d="M20.4343 71.665C19.9099 72.1894 19.9099 73.0416 20.4343 73.566C20.9587 74.0905 21.8109 74.0905 22.3353 73.566C22.8598 73.0416 22.8598 72.1894 22.3353 71.665C21.8109 71.1406 20.9587 71.1406 20.4343 71.665Z"
          fill="#B3B2D8"
        />
        <path
          d="M11.3875 80.7112C10.863 81.2357 10.863 82.0878 11.3875 82.6123C11.9119 83.1367 12.7641 83.1367 13.2885 82.6123C13.8129 82.0878 13.8129 81.2357 13.2885 80.7112C12.7641 80.1868 11.9119 80.1868 11.3875 80.7112Z"
          fill="#B3B2D8"
        />
        <path
          d="M2.34156 89.7575C1.81714 90.2819 1.81714 91.1341 2.34156 91.6585C2.86597 92.1829 3.71815 92.1829 4.24257 91.6585C4.76699 91.1341 4.76699 90.2819 4.24257 89.7575C3.71815 89.233 2.86597 89.233 2.34156 89.7575Z"
          fill="#B3B2D8"
        />
        <path
          d="M75.5632 0.213043C75.0388 0.737461 75.0388 1.58964 75.5632 2.11406C76.0877 2.63848 76.9398 2.63848 77.4643 2.11406C77.9887 1.58964 77.9887 0.737461 77.4643 0.213043C76.9398 -0.311376 76.0877 -0.311376 75.5632 0.213043Z"
          fill="#B3B2D8"
        />
        <path
          d="M66.5183 9.25926C65.9939 9.78368 65.9939 10.6359 66.5183 11.1603C67.0427 11.6847 67.8949 11.6847 68.4193 11.1603C69.0093 10.6359 69.0093 9.78368 68.4193 9.25926C67.8949 8.73484 67.0427 8.73484 66.5183 9.25926Z"
          fill="#B3B2D8"
        />
        <path
          d="M57.4714 18.3055C56.947 18.8299 56.947 19.6821 57.4714 20.2065C57.9959 20.7309 58.848 20.7309 59.438 20.2065C59.9624 19.6821 59.9624 18.8299 59.438 18.3055C58.848 17.7811 57.9959 17.7811 57.4714 18.3055Z"
          fill="#B3B2D8"
        />
        <path
          d="M48.4246 27.3517C47.9001 27.8761 47.9001 28.7283 48.4246 29.2527C48.949 29.7771 49.8012 29.7771 50.3911 29.2527C50.9155 28.7283 50.9155 27.8761 50.3911 27.3517C49.8012 26.8273 48.949 26.8273 48.4246 27.3517Z"
          fill="#B3B2D8"
        />
        <path
          d="M39.3787 36.3979C38.8542 36.9223 38.8542 37.7745 39.3787 38.2989C39.9031 38.8233 40.8208 38.8233 41.3452 38.2989C41.8697 37.7745 41.8697 36.9223 41.3452 36.3979C40.8208 35.8735 39.9031 35.8735 39.3787 36.3979Z"
          fill="#B3B2D8"
        />
        <path
          d="M30.3328 45.4441C29.8083 45.9685 29.8083 46.8207 30.3328 47.3451C30.9227 47.8696 31.7749 47.8696 32.2993 47.3451C32.8238 46.8207 32.8238 45.9685 32.2993 45.4441C31.7749 44.9197 30.9227 44.9197 30.3328 45.4441Z"
          fill="#B3B2D8"
        />
        <path
          d="M21.2869 54.4903C20.7625 55.0147 20.7625 55.8669 21.2869 56.3913C21.8768 56.9158 22.729 56.9158 23.2534 56.3913C23.7779 55.8669 23.7779 55.0147 23.2534 54.4903C22.729 53.9659 21.8768 53.9659 21.2869 54.4903Z"
          fill="#B3B2D8"
        />
        <path
          d="M12.3058 63.5365C11.7158 64.061 11.7158 64.9131 12.3058 65.4376C12.8302 65.962 13.6824 65.962 14.2068 65.4376C14.7312 64.9131 14.7312 64.061 14.2068 63.5365C13.6824 63.0121 12.8302 63.0121 12.3058 63.5365Z"
          fill="#B3B2D8"
        />
        <path
          d="M3.25952 72.5828C2.73511 73.1072 2.73511 73.9594 3.25952 74.4838C3.78394 75.0082 4.63612 75.0082 5.16054 74.4838C5.68496 73.9594 5.68496 73.1072 5.16054 72.5828C4.63612 72.0583 3.78394 72.0583 3.25952 72.5828Z"
          fill="#B3B2D8"
        />
        <path
          d="M58.3894 1.13077C57.865 1.65519 57.865 2.50737 58.3894 3.03179C58.9138 3.5562 59.766 3.5562 60.2904 3.03179C60.8148 2.50737 60.8148 1.65519 60.2904 1.13077C59.766 0.606353 58.9138 0.606353 58.3894 1.13077Z"
          fill="#B3B2D8"
        />
        <path
          d="M49.3425 10.177C48.8181 10.7014 48.8181 11.5536 49.3425 12.078C49.867 12.6024 50.7191 12.6024 51.2435 12.078C51.768 11.5536 51.768 10.7014 51.2435 10.177C50.7191 9.65258 49.867 9.65258 49.3425 10.177Z"
          fill="#B3B2D8"
        />
        <path
          d="M40.2966 19.2232C39.7722 19.7476 39.7722 20.5998 40.2966 21.1242C40.8211 21.6486 41.6732 21.6486 42.1976 21.1242C42.7221 20.5998 42.7221 19.7476 42.1976 19.2232C41.6732 18.6988 40.8211 18.6988 40.2966 19.2232Z"
          fill="#B3B2D8"
        />
        <path
          d="M31.2507 28.2694C30.7263 28.7938 30.7263 29.646 31.2507 30.1704C31.7752 30.6949 32.6273 30.6949 33.1518 30.1704C33.6762 29.646 33.6762 28.7938 33.1518 28.2694C32.6273 27.6794 31.7752 27.6794 31.2507 28.2694Z"
          fill="#B3B2D8"
        />
        <path
          d="M22.2039 37.2501C21.6794 37.84 21.6794 38.6922 22.2039 39.2166C22.7283 39.7411 23.5805 39.7411 24.1049 39.2166C24.6293 38.6922 24.6293 37.84 24.1049 37.2501C23.5805 36.7257 22.7283 36.7257 22.2039 37.2501Z"
          fill="#B3B2D8"
        />
        <path
          d="M13.158 46.2963C12.6335 46.8863 12.6335 47.7384 13.158 48.2629C13.6824 48.7873 14.5346 48.7873 15.059 48.2629C15.5834 47.7384 15.5834 46.8863 15.059 46.2963C14.5346 45.7719 13.6824 45.7719 13.158 46.2963Z"
          fill="#B3B2D8"
        />
        <path
          d="M4.11109 55.3425C3.58667 55.8669 3.58667 56.7847 4.11109 57.3091C4.63551 57.8335 5.48768 57.8335 6.0121 57.3091C6.53652 56.7847 6.53652 55.8669 6.0121 55.3425C5.48768 54.8181 4.63551 54.8181 4.11109 55.3425Z"
          fill="#B3B2D8"
        />
        <path
          d="M41.2136 1.98296C40.6892 2.50738 40.6892 3.35956 41.2136 3.88398C41.738 4.4084 42.5902 4.4084 43.1146 3.88398C43.6391 3.35956 43.6391 2.50738 43.1146 1.98296C42.5902 1.45854 41.738 1.45854 41.2136 1.98296Z"
          fill="#B3B2D8"
        />
        <path
          d="M32.1687 11.0292C31.6443 11.5536 31.6443 12.4058 32.1687 12.9302C32.6931 13.4546 33.5453 13.4546 34.0697 12.9302C34.5941 12.4058 34.5941 11.5536 34.0697 11.0292C33.5453 10.5048 32.6931 10.5048 32.1687 11.0292Z"
          fill="#B3B2D8"
        />
        <path
          d="M23.1218 20.0754C22.5974 20.5998 22.5974 21.452 23.1218 21.9764C23.6462 22.5008 24.4984 22.5008 25.0228 21.9764C25.5473 21.452 25.5473 20.5998 25.0228 20.0754C24.4984 19.551 23.6462 19.551 23.1218 20.0754Z"
          fill="#B3B2D8"
        />
        <path
          d="M14.0759 29.1216C13.5515 29.646 13.5515 30.4982 14.0759 31.0226C14.6003 31.547 15.4525 31.547 15.9769 31.0226C16.5014 30.4982 16.5014 29.646 15.9769 29.1216C15.4525 28.5972 14.6003 28.5972 14.0759 29.1216Z"
          fill="#B3B2D8"
        />
        <path
          d="M5.02906 38.1678C4.50464 38.6922 4.50464 39.5444 5.02906 40.0688C5.55347 40.5932 6.40565 40.5932 6.93007 40.0688C7.45449 39.5444 7.45449 38.6922 6.93007 38.1678C6.40565 37.6434 5.55347 37.6434 5.02906 38.1678Z"
          fill="#B3B2D8"
        />
        <path
          d="M23.9744 2.90069C23.45 3.42511 23.45 4.27729 23.9744 4.80171C24.5643 5.32613 25.4165 5.32613 25.9409 4.80171C26.4654 4.27729 26.4654 3.42511 25.9409 2.90069C25.4165 2.37627 24.5643 2.37627 23.9744 2.90069Z"
          fill="#B3B2D8"
        />
        <path
          d="M14.9933 11.9469C14.4033 12.4713 14.4033 13.3235 14.9933 13.8479C15.5177 14.3723 16.3699 14.3723 16.8943 13.8479C17.4187 13.3235 17.4187 12.4713 16.8943 11.9469C16.3699 11.4225 15.5177 11.4225 14.9933 11.9469Z"
          fill="#B3B2D8"
        />
        <path
          d="M5.94702 20.9931C5.42261 21.5175 5.42261 22.3697 5.94702 22.8941C6.47144 23.4185 7.32362 23.4185 7.84804 22.8941C8.37246 22.3697 8.37246 21.5175 7.84804 20.9931C7.32362 20.4687 6.47144 20.4687 5.94702 20.9931Z"
          fill="#B3B2D8"
        />
        <path
          d="M6.79859 3.81842C6.27417 4.34284 6.27417 5.19502 6.79859 5.71944C7.32301 6.24385 8.17519 6.24385 8.6996 5.71944C9.22402 5.19502 9.22402 4.34284 8.6996 3.81842C8.17519 3.294 7.32301 3.294 6.79859 3.81842Z"
          fill="#B3B2D8"
        />
        <path
          d="M-2.24633 12.8646C-2.77075 13.3891 -2.77075 14.2412 -2.24633 14.7657C-1.72192 15.2901 -0.869736 15.2901 -0.345318 14.7657C0.1791 14.2412 0.1791 13.3891 -0.345318 12.8646C-0.869736 12.3402 -1.72192 12.3402 -2.24633 12.8646Z"
          fill="#B3B2D8"
        />
        <path
          d="M595.28 835.3C594.756 835.824 594.756 836.677 595.28 837.201C595.804 837.725 596.657 837.725 597.181 837.201C597.705 836.677 597.705 835.824 597.181 835.3C596.657 834.776 595.804 834.776 595.28 835.3Z"
          fill="#B3B2D8"
        />
        <path
          d="M596.198 818.06C595.674 818.584 595.674 819.436 596.198 820.026C596.722 820.551 597.575 820.551 598.099 820.026C598.623 819.436 598.623 818.584 598.099 818.06C597.575 817.535 596.722 817.535 596.198 818.06Z"
          fill="#B3B2D8"
        />
        <path
          d="M587.151 827.106C586.627 827.63 586.627 828.482 587.151 829.007C587.676 829.597 588.528 829.597 589.052 829.007C589.577 828.482 589.577 827.63 589.052 827.106C588.528 826.581 587.676 826.581 587.151 827.106Z"
          fill="#B3B2D8"
        />
        <path
          d="M578.106 836.152C577.582 836.677 577.582 837.529 578.106 838.053C578.631 838.578 579.483 838.578 580.007 838.053C580.532 837.529 580.532 836.677 580.007 836.152C579.483 835.628 578.631 835.628 578.106 836.152Z"
          fill="#B3B2D8"
        />
        <path
          d="M588.069 809.931C587.545 810.456 587.545 811.308 588.069 811.832C588.594 812.357 589.446 812.357 589.97 811.832C590.495 811.308 590.495 810.456 589.97 809.931C589.446 809.407 588.594 809.407 588.069 809.931Z"
          fill="#B3B2D8"
        />
        <path
          d="M579.024 818.977C578.5 819.502 578.5 820.354 579.024 820.878C579.549 821.403 580.401 821.403 580.925 820.878C581.45 820.354 581.45 819.502 580.925 818.977C580.401 818.453 579.549 818.453 579.024 818.977Z"
          fill="#B3B2D8"
        />
        <path
          d="M569.977 828.024C569.453 828.548 569.453 829.4 569.977 829.925C570.502 830.449 571.354 830.449 571.878 829.925C572.403 829.4 572.403 828.548 571.878 828.024C571.354 827.499 570.502 827.499 569.977 828.024Z"
          fill="#B3B2D8"
        />
        <path
          d="M560.93 837.07C560.406 837.594 560.406 838.446 560.93 838.971C561.455 839.495 562.307 839.495 562.831 838.971C563.356 838.446 563.356 837.594 562.831 837.07C562.307 836.545 561.455 836.545 560.93 837.07Z"
          fill="#B3B2D8"
        />
        <path
          d="M588.923 792.756C588.398 793.281 588.398 794.133 588.923 794.657C589.447 795.182 590.299 795.182 590.824 794.657C591.348 794.133 591.348 793.281 590.824 792.756C590.299 792.232 589.447 792.232 588.923 792.756Z"
          fill="#B3B2D8"
        />
        <path
          d="M579.876 801.803C579.351 802.327 579.351 803.179 579.876 803.704C580.4 804.228 581.252 804.228 581.777 803.704C582.367 803.179 582.367 802.327 581.777 801.803C581.252 801.278 580.4 801.278 579.876 801.803Z"
          fill="#B3B2D8"
        />
        <path
          d="M570.829 810.849C570.304 811.373 570.304 812.225 570.829 812.75C571.353 813.274 572.205 813.274 572.73 812.75C573.32 812.225 573.32 811.373 572.73 810.849C572.205 810.324 571.353 810.324 570.829 810.849Z"
          fill="#B3B2D8"
        />
        <path
          d="M561.784 819.895C561.26 820.42 561.26 821.272 561.784 821.796C562.308 822.321 563.161 822.321 563.751 821.796C564.275 821.272 564.275 820.42 563.751 819.895C563.161 819.371 562.308 819.371 561.784 819.895Z"
          fill="#B3B2D8"
        />
        <path
          d="M552.737 828.941C552.213 829.466 552.213 830.318 552.737 830.842C553.261 831.367 554.114 831.367 554.704 830.842C555.228 830.318 555.228 829.466 554.704 828.941C554.114 828.417 553.261 828.417 552.737 828.941Z"
          fill="#B3B2D8"
        />
        <path
          d="M543.692 837.988C543.168 838.512 543.168 839.364 543.692 839.889C544.217 840.413 545.134 840.413 545.659 839.889C546.183 839.364 546.183 838.512 545.659 837.988C545.134 837.463 544.217 837.463 543.692 837.988Z"
          fill="#B3B2D8"
        />
        <path
          d="M589.839 775.582C589.314 776.106 589.314 776.958 589.839 777.483C590.363 778.007 591.215 778.007 591.74 777.483C592.264 776.958 592.264 776.106 591.74 775.582C591.215 774.992 590.363 774.992 589.839 775.582Z"
          fill="#B3B2D8"
        />
        <path
          d="M580.794 784.562C580.269 785.152 580.269 786.005 580.794 786.529C581.318 787.053 582.17 787.053 582.695 786.529C583.219 786.005 583.219 785.152 582.695 784.562C582.17 784.038 581.318 784.038 580.794 784.562Z"
          fill="#B3B2D8"
        />
        <path
          d="M571.747 793.609C571.222 794.199 571.222 795.051 571.747 795.575C572.271 796.1 573.123 796.1 573.648 795.575C574.172 795.051 574.172 794.199 573.648 793.609C573.123 793.084 572.271 793.084 571.747 793.609Z"
          fill="#B3B2D8"
        />
        <path
          d="M562.702 802.655C562.177 803.179 562.177 804.097 562.702 804.621C563.226 805.146 564.079 805.146 564.603 804.621C565.127 804.097 565.127 803.179 564.603 802.655C564.079 802.13 563.226 802.13 562.702 802.655Z"
          fill="#B3B2D8"
        />
        <path
          d="M553.655 811.701C553.131 812.225 553.131 813.078 553.655 813.668C554.179 814.192 555.032 814.192 555.556 813.668C556.08 813.078 556.08 812.225 555.556 811.701C555.032 811.177 554.179 811.177 553.655 811.701Z"
          fill="#B3B2D8"
        />
        <path
          d="M544.608 820.747C544.084 821.272 544.084 822.124 544.608 822.714C545.133 823.238 545.985 823.238 546.509 822.714C547.034 822.124 547.034 821.272 546.509 820.747C545.985 820.223 545.133 820.223 544.608 820.747Z"
          fill="#B3B2D8"
        />
        <path
          d="M535.563 829.794C535.039 830.318 535.039 831.17 535.563 831.695C536.088 832.285 536.94 832.285 537.464 831.695C537.989 831.17 537.989 830.318 537.464 829.794C536.94 829.269 536.088 829.269 535.563 829.794Z"
          fill="#B3B2D8"
        />
        <path
          d="M526.516 838.84C525.992 839.364 525.992 840.216 526.516 840.741C527.041 841.265 527.893 841.265 528.417 840.741C528.942 840.216 528.942 839.364 528.417 838.84C527.893 838.315 527.041 838.315 526.516 838.84Z"
          fill="#B3B2D8"
        />
        <path
          d="M590.757 758.341C590.232 758.866 590.232 759.718 590.757 760.243C591.281 760.767 592.133 760.767 592.658 760.243C593.182 759.718 593.182 758.866 592.658 758.341C592.133 757.817 591.281 757.817 590.757 758.341Z"
          fill="#B3B2D8"
        />
        <path
          d="M581.712 767.388C581.187 767.912 581.187 768.764 581.712 769.289C582.236 769.813 583.088 769.813 583.613 769.289C584.137 768.764 584.137 767.912 583.613 767.388C583.088 766.863 582.236 766.863 581.712 767.388Z"
          fill="#B3B2D8"
        />
        <path
          d="M572.665 776.434C572.14 776.958 572.14 777.811 572.665 778.335C573.189 778.859 574.041 778.859 574.566 778.335C575.09 777.811 575.09 776.958 574.566 776.434C574.041 775.91 573.189 775.91 572.665 776.434Z"
          fill="#B3B2D8"
        />
        <path
          d="M563.618 785.48C563.094 786.005 563.094 786.857 563.618 787.381C564.142 787.906 564.995 787.906 565.519 787.381C566.043 786.857 566.043 786.005 565.519 785.48C564.995 784.956 564.142 784.956 563.618 785.48Z"
          fill="#B3B2D8"
        />
        <path
          d="M554.573 794.526C554.049 795.051 554.049 795.903 554.573 796.427C555.097 796.952 555.95 796.952 556.474 796.427C556.998 795.903 556.998 795.051 556.474 794.526C555.95 794.002 555.097 794.002 554.573 794.526Z"
          fill="#B3B2D8"
        />
        <path
          d="M545.526 803.573C545.002 804.097 545.002 804.949 545.526 805.474C546.051 805.998 546.903 805.998 547.427 805.474C547.952 804.949 547.952 804.097 547.427 803.573C546.903 803.048 546.051 803.048 545.526 803.573Z"
          fill="#B3B2D8"
        />
        <path
          d="M536.481 812.619C535.957 813.143 535.957 813.995 536.481 814.52C537.006 815.044 537.858 815.044 538.382 814.52C538.907 813.995 538.907 813.143 538.382 812.619C537.858 812.094 537.006 812.094 536.481 812.619Z"
          fill="#B3B2D8"
        />
        <path
          d="M527.434 821.665C526.91 822.189 526.91 823.042 527.434 823.566C527.959 824.09 528.811 824.09 529.335 823.566C529.86 823.042 529.86 822.189 529.335 821.665C528.811 821.141 527.959 821.141 527.434 821.665Z"
          fill="#B3B2D8"
        />
        <path
          d="M518.387 830.711C517.863 831.236 517.863 832.088 518.387 832.612C518.912 833.137 519.764 833.137 520.288 832.612C520.813 832.088 520.813 831.236 520.288 830.711C519.764 830.187 518.912 830.187 518.387 830.711Z"
          fill="#B3B2D8"
        />
        <path
          d="M509.343 839.757C508.818 840.282 508.818 841.134 509.343 841.658C509.867 842.183 510.719 842.183 511.244 841.658C511.768 841.134 511.768 840.282 511.244 839.757C510.719 839.233 509.867 839.233 509.343 839.757Z"
          fill="#B3B2D8"
        />
        <path
          d="M591.61 741.167C591.086 741.691 591.086 742.543 591.61 743.068C592.135 743.592 592.987 743.592 593.511 743.068C594.036 742.543 594.036 741.691 593.511 741.167C592.987 740.642 592.135 740.642 591.61 741.167Z"
          fill="#B3B2D8"
        />
        <path
          d="M582.563 750.213C582.039 750.737 582.039 751.59 582.563 752.114C583.088 752.638 583.94 752.638 584.464 752.114C584.989 751.59 584.989 750.737 584.464 750.213C583.94 749.689 583.088 749.689 582.563 750.213Z"
          fill="#B3B2D8"
        />
        <path
          d="M573.518 759.259C572.994 759.784 572.994 760.636 573.518 761.16C574.043 761.685 574.895 761.685 575.419 761.16C576.009 760.636 576.009 759.784 575.419 759.259C574.895 758.735 574.043 758.735 573.518 759.259Z"
          fill="#B3B2D8"
        />
        <path
          d="M564.471 768.305C563.947 768.83 563.947 769.682 564.471 770.206C564.996 770.731 565.848 770.731 566.438 770.206C566.962 769.682 566.962 768.83 566.438 768.305C565.848 767.781 564.996 767.781 564.471 768.305Z"
          fill="#B3B2D8"
        />
        <path
          d="M555.425 777.352C554.9 777.876 554.9 778.728 555.425 779.253C555.949 779.777 556.801 779.777 557.391 779.253C557.916 778.728 557.916 777.876 557.391 777.352C556.801 776.827 555.949 776.827 555.425 777.352Z"
          fill="#B3B2D8"
        />
        <path
          d="M546.38 786.398C545.855 786.922 545.855 787.774 546.38 788.299C546.904 788.823 547.822 788.823 548.346 788.299C548.871 787.774 548.871 786.922 548.346 786.398C547.822 785.873 546.904 785.873 546.38 786.398Z"
          fill="#B3B2D8"
        />
        <path
          d="M537.333 795.444C536.808 795.969 536.808 796.821 537.333 797.345C537.923 797.87 538.775 797.87 539.299 797.345C539.824 796.821 539.824 795.969 539.299 795.444C538.775 794.92 537.923 794.92 537.333 795.444Z"
          fill="#B3B2D8"
        />
        <path
          d="M528.286 804.49C527.761 805.015 527.761 805.867 528.286 806.391C528.876 806.916 529.728 806.916 530.252 806.391C530.777 805.867 530.777 805.015 530.252 804.49C529.728 803.966 528.876 803.966 528.286 804.49Z"
          fill="#B3B2D8"
        />
        <path
          d="M519.306 813.537C518.716 814.061 518.716 814.913 519.306 815.438C519.83 815.962 520.682 815.962 521.207 815.438C521.731 814.913 521.731 814.061 521.207 813.537C520.682 813.012 519.83 813.012 519.306 813.537Z"
          fill="#B3B2D8"
        />
        <path
          d="M510.261 822.583C509.736 823.107 509.736 823.959 510.261 824.484C510.785 825.008 511.637 825.008 512.162 824.484C512.686 823.959 512.686 823.107 512.162 822.583C511.637 822.058 510.785 822.058 510.261 822.583Z"
          fill="#B3B2D8"
        />
        <path
          d="M501.214 831.629C500.689 832.153 500.689 833.006 501.214 833.53C501.738 834.054 502.59 834.054 503.115 833.53C503.639 833.006 503.639 832.153 503.115 831.629C502.59 831.105 501.738 831.105 501.214 831.629Z"
          fill="#B3B2D8"
        />
        <path
          d="M492.167 840.675C491.642 841.2 491.642 842.052 492.167 842.576C492.691 843.101 493.543 843.101 494.068 842.576C494.592 842.052 494.592 841.2 494.068 840.675C493.543 840.151 492.691 840.151 492.167 840.675Z"
          fill="#B3B2D8"
        />
        <path
          d="M583.481 733.038C582.957 733.563 582.957 734.415 583.481 734.939C584.006 735.464 584.858 735.464 585.382 734.939C585.907 734.415 585.907 733.563 585.382 733.038C584.858 732.514 584.006 732.514 583.481 733.038Z"
          fill="#B3B2D8"
        />
        <path
          d="M574.434 742.085C573.91 742.609 573.91 743.461 574.434 743.986C574.959 744.51 575.811 744.51 576.335 743.986C576.86 743.461 576.86 742.609 576.335 742.085C575.811 741.56 574.959 741.56 574.434 742.085Z"
          fill="#B3B2D8"
        />
        <path
          d="M565.389 751.131C564.865 751.655 564.865 752.507 565.389 753.032C565.914 753.556 566.766 753.556 567.29 753.032C567.815 752.507 567.815 751.655 567.29 751.131C566.766 750.606 565.914 750.606 565.389 751.131Z"
          fill="#B3B2D8"
        />
        <path
          d="M556.343 760.177C555.818 760.701 555.818 761.554 556.343 762.078C556.867 762.602 557.719 762.602 558.244 762.078C558.768 761.554 558.768 760.701 558.244 760.177C557.719 759.653 556.867 759.653 556.343 760.177Z"
          fill="#B3B2D8"
        />
        <path
          d="M547.298 769.223C546.773 769.748 546.773 770.6 547.298 771.124C547.822 771.649 548.674 771.649 549.199 771.124C549.723 770.6 549.723 769.748 549.199 769.223C548.674 768.699 547.822 768.699 547.298 769.223Z"
          fill="#B3B2D8"
        />
        <path
          d="M538.251 778.269C537.726 778.794 537.726 779.646 538.251 780.17C538.775 780.695 539.627 780.695 540.152 780.17C540.676 779.646 540.676 778.794 540.152 778.269C539.627 777.679 538.775 777.679 538.251 778.269Z"
          fill="#B3B2D8"
        />
        <path
          d="M529.204 787.25C528.679 787.84 528.679 788.692 529.204 789.217C529.728 789.741 530.58 789.741 531.105 789.217C531.629 788.692 531.629 787.84 531.105 787.25C530.58 786.726 529.728 786.726 529.204 787.25Z"
          fill="#B3B2D8"
        />
        <path
          d="M520.159 796.296C519.635 796.886 519.635 797.738 520.159 798.263C520.683 798.787 521.536 798.787 522.06 798.263C522.584 797.738 522.584 796.886 522.06 796.296C521.536 795.772 520.683 795.772 520.159 796.296Z"
          fill="#B3B2D8"
        />
        <path
          d="M511.112 805.343C510.588 805.867 510.588 806.785 511.112 807.309C511.636 807.834 512.489 807.834 513.013 807.309C513.537 806.785 513.537 805.867 513.013 805.343C512.489 804.818 511.636 804.818 511.112 805.343Z"
          fill="#B3B2D8"
        />
        <path
          d="M502.065 814.389C501.541 814.913 501.541 815.765 502.065 816.355C502.59 816.88 503.442 816.88 503.966 816.355C504.491 815.765 504.491 814.913 503.966 814.389C503.442 813.864 502.59 813.864 502.065 814.389Z"
          fill="#B3B2D8"
        />
        <path
          d="M493.018 823.435C492.494 823.959 492.494 824.812 493.018 825.336C493.543 825.926 494.395 825.926 494.919 825.336C495.444 824.812 495.444 823.959 494.919 823.435C494.395 822.911 493.543 822.911 493.018 823.435Z"
          fill="#B3B2D8"
        />
        <path
          d="M483.973 832.481C483.449 833.006 483.449 833.858 483.973 834.382C484.498 834.972 485.35 834.972 485.874 834.382C486.399 833.858 486.399 833.006 485.874 832.481C485.35 831.957 484.498 831.957 483.973 832.481Z"
          fill="#B3B2D8"
        />
        <path
          d="M575.352 724.844C574.828 725.369 574.828 726.221 575.352 726.745C575.877 727.27 576.729 727.27 577.253 726.745C577.778 726.221 577.778 725.369 577.253 724.844C576.729 724.32 575.877 724.32 575.352 724.844Z"
          fill="#B3B2D8"
        />
        <path
          d="M566.307 733.891C565.783 734.415 565.783 735.267 566.307 735.792C566.832 736.316 567.684 736.316 568.208 735.792C568.733 735.267 568.733 734.415 568.208 733.891C567.684 733.366 566.832 733.366 566.307 733.891Z"
          fill="#B3B2D8"
        />
        <path
          d="M557.261 742.937C556.736 743.461 556.736 744.313 557.261 744.838C557.785 745.362 558.637 745.362 559.162 744.838C559.686 744.313 559.686 743.461 559.162 742.937C558.637 742.412 557.785 742.412 557.261 742.937Z"
          fill="#B3B2D8"
        />
        <path
          d="M548.214 751.983C547.689 752.507 547.689 753.36 548.214 753.884C548.738 754.408 549.59 754.408 550.115 753.884C550.639 753.36 550.639 752.507 550.115 751.983C549.59 751.459 548.738 751.459 548.214 751.983Z"
          fill="#B3B2D8"
        />
        <path
          d="M539.169 761.029C538.644 761.554 538.644 762.406 539.169 762.93C539.693 763.455 540.545 763.455 541.07 762.93C541.594 762.406 541.594 761.554 541.07 761.029C540.545 760.505 539.693 760.505 539.169 761.029Z"
          fill="#B3B2D8"
        />
        <path
          d="M530.122 770.075C529.597 770.6 529.597 771.452 530.122 771.976C530.646 772.501 531.498 772.501 532.023 771.976C532.547 771.452 532.547 770.6 532.023 770.075C531.498 769.551 530.646 769.551 530.122 770.075Z"
          fill="#B3B2D8"
        />
        <path
          d="M521.077 779.122C520.552 779.646 520.552 780.498 521.077 781.023C521.601 781.547 522.454 781.547 522.978 781.023C523.502 780.498 523.502 779.646 522.978 779.122C522.454 778.597 521.601 778.597 521.077 779.122Z"
          fill="#B3B2D8"
        />
        <path
          d="M512.03 788.168C511.506 788.692 511.506 789.544 512.03 790.069C512.554 790.593 513.407 790.593 513.931 790.069C514.455 789.544 514.455 788.692 513.931 788.168C513.407 787.643 512.554 787.643 512.03 788.168Z"
          fill="#B3B2D8"
        />
        <path
          d="M502.983 797.214C502.459 797.738 502.459 798.591 502.983 799.115C503.508 799.639 504.36 799.639 504.884 799.115C505.409 798.591 505.409 797.738 504.884 797.214C504.36 796.69 503.508 796.69 502.983 797.214Z"
          fill="#B3B2D8"
        />
        <path
          d="M493.936 806.26C493.412 806.785 493.412 807.637 493.936 808.161C494.461 808.686 495.313 808.686 495.837 808.161C496.362 807.637 496.362 806.785 495.837 806.26C495.313 805.736 494.461 805.736 493.936 806.26Z"
          fill="#B3B2D8"
        />
        <path
          d="M484.891 815.306C484.367 815.831 484.367 816.683 484.891 817.207C485.416 817.732 486.268 817.732 486.792 817.207C487.317 816.683 487.317 815.831 486.792 815.306C486.268 814.782 485.416 814.782 484.891 815.306Z"
          fill="#B3B2D8"
        />
        <path
          d="M475.844 824.353C475.32 824.877 475.32 825.729 475.844 826.254C476.369 826.778 477.221 826.778 477.746 826.254C478.27 825.729 478.27 824.877 477.746 824.353C477.221 823.828 476.369 823.828 475.844 824.353Z"
          fill="#B3B2D8"
        />
        <path
          d="M567.159 716.716C566.635 717.24 566.635 718.092 567.159 718.617C567.683 719.141 568.536 719.141 569.126 718.617C569.65 718.092 569.65 717.24 569.126 716.716C568.536 716.191 567.683 716.191 567.159 716.716Z"
          fill="#B3B2D8"
        />
        <path
          d="M558.114 725.762C557.59 726.286 557.59 727.139 558.114 727.663C558.638 728.187 559.491 728.187 560.081 727.663C560.605 727.139 560.605 726.286 560.081 725.762C559.491 725.238 558.638 725.238 558.114 725.762Z"
          fill="#B3B2D8"
        />
        <path
          d="M549.067 734.808C548.543 735.333 548.543 736.185 549.067 736.709C549.592 737.234 550.509 737.234 551.034 736.709C551.558 736.185 551.558 735.333 551.034 734.808C550.509 734.284 549.592 734.284 549.067 734.808Z"
          fill="#B3B2D8"
        />
        <path
          d="M540.02 743.854C539.496 744.379 539.496 745.231 540.02 745.756C540.61 746.28 541.462 746.28 541.987 745.756C542.511 745.231 542.511 744.379 541.987 743.854C541.462 743.33 540.61 743.33 540.02 743.854Z"
          fill="#B3B2D8"
        />
        <path
          d="M530.973 752.901C530.449 753.425 530.449 754.277 530.973 754.802C531.563 755.326 532.416 755.326 532.94 754.802C533.464 754.277 533.464 753.425 532.94 752.901C532.416 752.376 531.563 752.376 530.973 752.901Z"
          fill="#B3B2D8"
        />
        <path
          d="M521.993 761.947C521.403 762.471 521.403 763.324 521.993 763.848C522.518 764.372 523.37 764.372 523.894 763.848C524.419 763.324 524.419 762.471 523.894 761.947C523.37 761.422 522.518 761.422 521.993 761.947Z"
          fill="#B3B2D8"
        />
        <path
          d="M512.948 770.993C512.424 771.518 512.424 772.37 512.948 772.894C513.472 773.419 514.325 773.419 514.849 772.894C515.373 772.37 515.373 771.518 514.849 770.993C514.325 770.469 513.472 770.469 512.948 770.993Z"
          fill="#B3B2D8"
        />
        <path
          d="M503.901 780.039C503.377 780.564 503.377 781.416 503.901 781.94C504.426 782.465 505.278 782.465 505.802 781.94C506.327 781.416 506.327 780.564 505.802 780.039C505.278 779.515 504.426 779.515 503.901 780.039Z"
          fill="#B3B2D8"
        />
        <path
          d="M494.854 789.086C494.33 789.61 494.33 790.462 494.854 790.987C495.379 791.511 496.231 791.511 496.755 790.987C497.28 790.462 497.28 789.61 496.755 789.086C496.231 788.561 495.379 788.561 494.854 789.086Z"
          fill="#B3B2D8"
        />
        <path
          d="M485.809 798.132C485.285 798.656 485.285 799.508 485.809 800.033C486.334 800.557 487.186 800.557 487.71 800.033C488.235 799.508 488.235 798.656 487.71 798.132C487.186 797.607 486.334 797.607 485.809 798.132Z"
          fill="#B3B2D8"
        />
        <path
          d="M476.762 807.178C476.238 807.702 476.238 808.555 476.762 809.079C477.287 809.603 478.139 809.603 478.663 809.079C479.188 808.555 479.188 807.702 478.663 807.178C478.139 806.654 477.287 806.654 476.762 807.178Z"
          fill="#B3B2D8"
        />
        <path
          d="M467.716 816.224C467.191 816.749 467.191 817.601 467.716 818.125C468.24 818.65 469.092 818.65 469.617 818.125C470.141 817.601 470.141 816.749 469.617 816.224C469.092 815.7 468.24 815.7 467.716 816.224Z"
          fill="#B3B2D8"
        />
        <path
          d="M559.03 708.587C558.506 709.112 558.506 709.964 559.03 710.488C559.554 711.013 560.407 711.013 560.931 710.488C561.455 709.964 561.455 709.112 560.931 708.587C560.407 708.063 559.554 708.063 559.03 708.587Z"
          fill="#B3B2D8"
        />
        <path
          d="M549.985 717.634C549.461 718.158 549.461 719.01 549.985 719.535C550.51 720.059 551.362 720.059 551.886 719.535C552.411 719.01 552.411 718.158 551.886 717.634C551.362 717.109 550.51 717.109 549.985 717.634Z"
          fill="#B3B2D8"
        />
        <path
          d="M540.938 726.68C540.414 727.204 540.414 728.056 540.938 728.581C541.463 729.105 542.315 729.105 542.839 728.581C543.364 728.056 543.364 727.204 542.839 726.68C542.315 726.155 541.463 726.155 540.938 726.68Z"
          fill="#B3B2D8"
        />
        <path
          d="M531.891 735.726C531.367 736.25 531.367 737.103 531.891 737.627C532.416 738.151 533.268 738.151 533.792 737.627C534.317 737.103 534.317 736.25 533.792 735.726C533.268 735.202 532.416 735.202 531.891 735.726Z"
          fill="#B3B2D8"
        />
        <path
          d="M522.846 744.772C522.322 745.297 522.322 746.149 522.846 746.673C523.371 747.198 524.223 747.198 524.747 746.673C525.272 746.149 525.272 745.297 524.747 744.772C524.223 744.248 523.371 744.248 522.846 744.772Z"
          fill="#B3B2D8"
        />
        <path
          d="M513.8 753.818C513.275 754.343 513.275 755.195 513.8 755.719C514.324 756.244 515.176 756.244 515.701 755.719C516.225 755.195 516.225 754.343 515.701 753.818C515.176 753.294 514.324 753.294 513.8 753.818Z"
          fill="#B3B2D8"
        />
        <path
          d="M504.753 762.865C504.228 763.389 504.228 764.241 504.753 764.766C505.277 765.29 506.129 765.29 506.654 764.766C507.178 764.241 507.178 763.389 506.654 762.865C506.129 762.34 505.277 762.34 504.753 762.865Z"
          fill="#B3B2D8"
        />
        <path
          d="M495.706 771.911C495.181 772.435 495.181 773.287 495.706 773.812C496.23 774.336 497.082 774.336 497.607 773.812C498.131 773.287 498.131 772.435 497.607 771.911C497.082 771.321 496.23 771.321 495.706 771.911Z"
          fill="#B3B2D8"
        />
        <path
          d="M486.661 780.957C486.136 781.481 486.136 782.334 486.661 782.858C487.185 783.382 488.037 783.382 488.562 782.858C489.086 782.334 489.086 781.481 488.562 780.957C488.037 780.367 487.185 780.367 486.661 780.957Z"
          fill="#B3B2D8"
        />
        <path
          d="M477.614 789.938C477.09 790.528 477.09 791.38 477.614 791.904C478.138 792.429 478.991 792.429 479.515 791.904C480.039 791.38 480.039 790.528 479.515 789.938C478.991 789.413 478.138 789.413 477.614 789.938Z"
          fill="#B3B2D8"
        />
        <path
          d="M468.569 798.984C468.045 799.574 468.045 800.426 468.569 800.951C469.094 801.475 469.946 801.475 470.47 800.951C470.995 800.426 470.995 799.574 470.47 798.984C469.946 798.46 469.094 798.46 468.569 798.984Z"
          fill="#B3B2D8"
        />
        <path
          d="M459.522 808.03C458.998 808.555 458.998 809.472 459.522 809.997C460.047 810.521 460.899 810.521 461.423 809.997C461.948 809.472 461.948 808.555 461.423 808.03C460.899 807.506 460.047 807.506 459.522 808.03Z"
          fill="#B3B2D8"
        />
        <path
          d="M550.903 700.393C550.379 700.918 550.379 701.77 550.903 702.294C551.427 702.884 552.28 702.884 552.804 702.294C553.329 701.77 553.329 700.918 552.804 700.393C552.28 699.869 551.427 699.869 550.903 700.393Z"
          fill="#B3B2D8"
        />
        <path
          d="M541.856 709.44C541.332 709.964 541.332 710.816 541.856 711.341C542.381 711.865 543.233 711.865 543.757 711.341C544.282 710.816 544.282 709.964 543.757 709.44C543.233 708.915 542.381 708.915 541.856 709.44Z"
          fill="#B3B2D8"
        />
        <path
          d="M532.809 718.486C532.285 719.01 532.285 719.862 532.809 720.387C533.334 720.911 534.186 720.911 534.71 720.387C535.235 719.862 535.235 719.01 534.71 718.486C534.186 717.961 533.334 717.961 532.809 718.486Z"
          fill="#B3B2D8"
        />
        <path
          d="M523.764 727.532C523.24 728.056 523.24 728.909 523.764 729.433C524.289 729.957 525.141 729.957 525.665 729.433C526.19 728.909 526.19 728.056 525.665 727.532C525.141 727.008 524.289 727.008 523.764 727.532Z"
          fill="#B3B2D8"
        />
        <path
          d="M514.718 736.578C514.193 737.103 514.193 737.955 514.718 738.479C515.242 739.004 516.094 739.004 516.619 738.479C517.143 737.955 517.143 737.103 516.619 736.578C516.094 736.054 515.242 736.054 514.718 736.578Z"
          fill="#B3B2D8"
        />
        <path
          d="M505.671 745.624C505.146 746.149 505.146 747.001 505.671 747.525C506.195 748.05 507.047 748.05 507.572 747.525C508.096 747.001 508.096 746.149 507.572 745.624C507.047 745.1 506.195 745.1 505.671 745.624Z"
          fill="#B3B2D8"
        />
        <path
          d="M496.624 754.671C496.099 755.195 496.099 756.047 496.624 756.572C497.148 757.096 498 757.096 498.525 756.572C499.049 756.047 499.049 755.195 498.525 754.671C498 754.146 497.148 754.146 496.624 754.671Z"
          fill="#B3B2D8"
        />
        <path
          d="M487.579 763.717C487.054 764.241 487.054 765.093 487.579 765.618C488.103 766.142 488.955 766.142 489.48 765.618C490.004 765.093 490.004 764.241 489.48 763.717C488.955 763.192 488.103 763.192 487.579 763.717Z"
          fill="#B3B2D8"
        />
        <path
          d="M478.532 772.763C478.008 773.287 478.008 774.14 478.532 774.664C479.056 775.188 479.909 775.188 480.433 774.664C480.957 774.14 480.957 773.287 480.433 772.763C479.909 772.239 479.056 772.239 478.532 772.763Z"
          fill="#B3B2D8"
        />
        <path
          d="M469.485 781.809C468.961 782.334 468.961 783.186 469.485 783.71C470.01 784.235 470.862 784.235 471.386 783.71C471.911 783.186 471.911 782.334 471.386 781.809C470.862 781.285 470.01 781.285 469.485 781.809Z"
          fill="#B3B2D8"
        />
        <path
          d="M460.44 790.855C459.916 791.38 459.916 792.232 460.44 792.756C460.965 793.281 461.817 793.281 462.341 792.756C462.866 792.232 462.866 791.38 462.341 790.855C461.817 790.331 460.965 790.331 460.44 790.855Z"
          fill="#B3B2D8"
        />
        <path
          d="M451.393 799.902C450.869 800.426 450.869 801.278 451.393 801.803C451.918 802.327 452.77 802.327 453.294 801.803C453.819 801.278 453.819 800.426 453.294 799.902C452.77 799.377 451.918 799.377 451.393 799.902Z"
          fill="#B3B2D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5032_16625">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5032_16625)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M141.706 48.1973C141.181 48.7217 141.181 49.5739 141.706 50.0983C142.23 50.6228 143.082 50.6228 143.607 50.0983C144.131 49.5739 144.131 48.7217 143.607 48.1973C143.082 47.6729 142.23 47.6729 141.706 48.1973Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M132.659 57.2435C132.135 57.7679 132.135 58.6201 132.659 59.1445C133.183 59.669 134.036 59.669 134.56 59.1445C135.084 58.6201 135.084 57.7679 134.56 57.2435C134.036 56.7191 133.183 56.7191 132.659 57.2435Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M123.614 66.2897C123.09 66.8142 123.09 67.6663 123.614 68.1908C124.138 68.7152 124.991 68.7152 125.515 68.1908C126.039 67.6663 126.039 66.8142 125.515 66.2897C124.991 65.7653 124.138 65.7653 123.614 66.2897Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M114.567 75.336C114.043 75.8604 114.043 76.7125 114.567 77.237C115.092 77.7614 115.944 77.7614 116.468 77.237C116.993 76.7125 116.993 75.8604 116.468 75.336C115.944 74.8115 115.092 74.8115 114.567 75.336Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M105.521 84.3822C104.997 84.9066 104.997 85.7588 105.521 86.2832C106.046 86.8076 106.898 86.8076 107.422 86.2832C107.947 85.7588 107.947 84.9066 107.422 84.3822C106.898 83.8577 106.046 83.8577 105.521 84.3822Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M96.4744 93.4284C95.95 93.9528 95.95 94.805 96.4744 95.3294C96.9988 95.8538 97.851 95.8538 98.3754 95.3294C98.8998 94.805 98.8998 93.9528 98.3754 93.4284C97.851 92.904 96.9988 92.904 96.4744 93.4284Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M87.4285 102.475C86.9041 102.999 86.9041 103.851 87.4285 104.376C87.9529 104.9 88.8051 104.9 89.3295 104.376C89.8539 103.851 89.8539 102.999 89.3295 102.475C88.8051 101.95 87.9529 101.95 87.4285 102.475Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M78.3826 111.521C77.8582 112.045 77.8582 112.897 78.3826 113.422C78.907 113.946 79.7592 113.946 80.2836 113.422C80.808 112.897 80.808 112.045 80.2836 111.521C79.7592 110.996 78.907 110.996 78.3826 111.521Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M69.3357 120.567C68.8113 121.091 68.8113 121.944 69.3357 122.468C69.8601 122.992 70.7123 122.992 71.2367 122.468C71.7611 121.944 71.7611 121.091 71.2367 120.567C70.7123 120.043 69.8601 120.043 69.3357 120.567Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M60.2908 129.613C59.7664 130.138 59.7664 130.99 60.2908 131.514C60.8152 132.039 61.6674 132.039 62.1918 131.514C62.7162 130.99 62.7162 130.138 62.1918 129.613C61.6674 129.089 60.8152 129.089 60.2908 129.613Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M51.2439 138.659C50.7195 139.184 50.7195 140.036 51.2439 140.56C51.7683 141.085 52.6205 141.085 53.1449 140.56C53.6693 140.036 53.6693 139.184 53.1449 138.659C52.6205 138.135 51.7683 138.135 51.2439 138.659Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M42.197 147.706C41.6726 148.23 41.6726 149.082 42.197 149.607C42.7214 150.131 43.5736 150.131 44.098 149.607C44.6225 149.082 44.6225 148.23 44.098 147.706C43.5736 147.181 42.7214 147.181 42.197 147.706Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M133.511 40.0688C132.987 40.5932 132.987 41.4454 133.511 41.9698C134.036 42.4943 134.888 42.4943 135.412 41.9698C135.937 41.4454 135.937 40.5932 135.412 40.0688C134.888 39.5444 134.036 39.5444 133.511 40.0688Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M124.466 49.115C123.941 49.6395 123.941 50.4916 124.466 51.0161C124.99 51.5405 125.842 51.5405 126.367 51.0161C126.891 50.4916 126.891 49.6395 126.367 49.115C125.842 48.5906 124.99 48.5906 124.466 49.115Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M115.42 58.1613C114.895 58.6857 114.895 59.5378 115.42 60.0623C115.944 60.5867 116.796 60.5867 117.321 60.0623C117.845 59.5378 117.845 58.6857 117.321 58.1613C116.796 57.6368 115.944 57.6368 115.42 58.1613Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M106.373 67.2075C105.848 67.7319 105.848 68.5841 106.373 69.1085C106.897 69.6329 107.749 69.6329 108.274 69.1085C108.798 68.5841 108.798 67.7319 108.274 67.2075C107.749 66.6831 106.897 66.6831 106.373 67.2075Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M97.3279 76.2537C96.8035 76.7781 96.8035 77.6303 97.3279 78.1547C97.8523 78.6791 98.7045 78.6791 99.2289 78.1547C99.7533 77.6303 99.7533 76.7781 99.2289 76.2537C98.7045 75.7293 97.8523 75.7293 97.3279 76.2537Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M88.281 85.2999C87.7566 85.8243 87.7566 86.6765 88.281 87.2009C88.8054 87.7253 89.6576 87.7253 90.182 87.2009C90.7064 86.6765 90.7064 85.8243 90.182 85.2999C89.6576 84.7755 88.8054 84.7755 88.281 85.2999Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M79.2341 94.3461C78.7097 94.8705 78.7097 95.7227 79.2341 96.2471C79.7586 96.7715 80.6107 96.7715 81.1351 96.2471C81.6596 95.7227 81.6596 94.8705 81.1351 94.3461C80.6107 93.8217 79.7586 93.8217 79.2341 94.3461Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M70.1882 103.392C69.6638 103.917 69.6638 104.769 70.1882 105.293C70.7127 105.818 71.5648 105.818 72.0893 105.293C72.6792 104.769 72.6792 103.917 72.0893 103.392C71.5648 102.868 70.7127 102.868 70.1882 103.392Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M61.1423 112.439C60.6179 112.963 60.6179 113.815 61.1423 114.34C61.6668 114.864 62.5189 114.864 63.0434 114.34C63.6333 113.815 63.6333 112.963 63.0434 112.439C62.5189 111.914 61.6668 111.914 61.1423 112.439Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M52.0964 121.485C51.572 122.009 51.572 122.861 52.0964 123.386C52.6209 123.91 53.473 123.91 54.063 123.386C54.5874 122.861 54.5874 122.009 54.063 121.485C53.473 120.96 52.6209 120.96 52.0964 121.485Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M43.0496 130.531C42.5251 131.055 42.5251 131.908 43.0496 132.432C43.574 132.956 44.4262 132.956 45.0161 132.432C45.5405 131.908 45.5405 131.055 45.0161 130.531C44.4262 130.007 43.574 130.007 43.0496 130.531Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M34.0027 139.577C33.4783 140.102 33.4783 140.954 34.0027 141.478C34.5271 142.003 35.4448 142.003 35.9693 141.478C36.4937 140.954 36.4937 140.102 35.9693 139.577C35.4448 139.053 34.5271 139.053 34.0027 139.577Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M125.384 31.9403C124.859 32.4648 124.859 33.3169 125.384 33.8414C125.908 34.3658 126.76 34.3658 127.285 33.8414C127.809 33.3169 127.809 32.4648 127.285 31.8748C126.76 31.3504 125.908 31.3504 125.384 31.8748V31.9403Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M116.338 40.921C115.813 41.511 115.813 42.3632 116.338 42.8876C116.862 43.412 117.714 43.412 118.239 42.8876C118.763 42.3632 118.763 41.511 118.239 40.921C117.714 40.3966 116.862 40.3966 116.338 40.921Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M107.291 49.9672C106.766 50.4916 106.766 51.4094 107.291 51.9338C107.815 52.4582 108.667 52.4582 109.192 51.9338C109.716 51.4094 109.716 50.4916 109.192 49.9672C108.667 49.4428 107.815 49.4428 107.291 49.9672Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M98.2439 59.0134C97.7195 59.5379 97.7195 60.39 98.2439 60.98C98.7683 61.5044 99.6205 61.5044 100.145 60.98C100.669 60.39 100.669 59.5379 100.145 59.0134C99.6205 58.489 98.7683 58.489 98.2439 59.0134Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M89.199 68.0596C88.6746 68.5841 88.6746 69.4362 89.199 70.0262C89.7234 70.5506 90.5756 70.5506 91.1 70.0262C91.6244 69.4362 91.6244 68.5841 91.1 68.0596C90.5756 67.5352 89.7234 67.5352 89.199 68.0596Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M80.1521 77.1059C79.6277 77.6303 79.6277 78.4825 80.1521 79.0069C80.6765 79.5969 81.5287 79.5969 82.0531 79.0069C82.5775 78.4825 82.5775 77.6303 82.0531 77.1059C81.5287 76.5815 80.6765 76.5815 80.1521 77.1059Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M71.1062 86.1521C70.5818 86.6765 70.5818 87.5287 71.1062 88.0531C71.6306 88.5775 72.4828 88.5775 73.0072 88.0531C73.5316 87.5287 73.5316 86.6765 73.0072 86.1521C72.4828 85.6277 71.6306 85.6277 71.1062 86.1521Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M62.0603 95.1983C61.5359 95.7227 61.5359 96.5749 62.0603 97.0993C62.5847 97.6237 63.4369 97.6237 63.9613 97.0993C64.4857 96.5749 64.4857 95.7227 63.9613 95.1983C63.4369 94.6739 62.5847 94.6739 62.0603 95.1983Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M53.0134 104.244C52.489 104.769 52.489 105.621 53.0134 106.146C53.5378 106.67 54.39 106.67 54.9144 106.146C55.4389 105.621 55.4389 104.769 54.9144 104.244C54.39 103.72 53.5378 103.72 53.0134 104.244Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M43.9675 113.291C43.4431 113.815 43.4431 114.667 43.9675 115.192C44.492 115.716 45.3441 115.716 45.8685 115.192C46.393 114.667 46.393 113.815 45.8685 113.291C45.3441 112.766 44.492 112.766 43.9675 113.291Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M34.9207 122.337C34.3962 122.861 34.3962 123.714 34.9207 124.238C35.4451 124.762 36.2973 124.762 36.8217 124.238C37.3461 123.714 37.3461 122.861 36.8217 122.337C36.2973 121.813 35.4451 121.813 34.9207 122.337Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M25.8757 131.383C25.3513 131.908 25.3513 132.76 25.8757 133.284C26.4002 133.809 27.2523 133.809 27.7768 133.284C28.3012 132.76 28.3012 131.908 27.7768 131.383C27.2523 130.859 26.4002 130.859 25.8757 131.383Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M117.255 23.7463C116.73 24.2707 116.73 25.1229 117.255 25.6473C117.779 26.1717 118.631 26.1717 119.156 25.6473C119.68 25.1229 119.68 24.2707 119.156 23.7463C118.631 23.2219 117.779 23.2219 117.255 23.7463Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M108.209 32.7925C107.684 33.317 107.684 34.1691 108.209 34.6936C108.733 35.218 109.585 35.218 110.11 34.6936C110.634 34.1691 110.634 33.317 110.11 32.7925C109.585 32.2681 108.733 32.2681 108.209 32.7925Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M99.1619 41.8387C98.6375 42.3632 98.6375 43.2153 99.1619 43.7398C99.6863 44.2642 100.538 44.2642 101.063 43.7398C101.587 43.2153 101.587 42.3632 101.063 41.8387C100.538 41.3143 99.6863 41.3143 99.1619 41.8387Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M90.1169 50.885C89.5925 51.4094 89.5925 52.2616 90.1169 52.786C90.6414 53.3104 91.4935 53.3104 92.018 52.786C92.5424 52.2616 92.5424 51.4094 92.018 50.885C91.4935 50.3605 90.6414 50.3605 90.1169 50.885Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M81.0701 59.9312C80.5457 60.4556 80.5457 61.3078 81.0701 61.8322C81.5945 62.3566 82.4467 62.3566 82.9711 61.8322C83.4955 61.3078 83.4955 60.4556 82.9711 59.9312C82.4467 59.4068 81.5945 59.4068 81.0701 59.9312Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M72.0232 68.9774C71.4988 69.5018 71.4988 70.354 72.0232 70.8784C72.5476 71.4028 73.3998 71.4028 73.9242 70.8784C74.4486 70.354 74.4486 69.5018 73.9242 68.9774C73.3998 68.453 72.5476 68.453 72.0232 68.9774Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M62.9783 78.0236C62.4539 78.548 62.4539 79.4002 62.9783 79.9246C63.5027 80.449 64.3549 80.449 64.8793 79.9246C65.4037 79.4002 65.4037 78.548 64.8793 78.0236C64.3549 77.4992 63.5027 77.4992 62.9783 78.0236Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M53.9314 87.0698C53.407 87.5942 53.407 88.4464 53.9314 88.9708C54.4558 89.4952 55.308 89.4952 55.8324 88.9708C56.3568 88.4464 56.3568 87.5942 55.8324 87.0698C55.308 86.5454 54.4558 86.5454 53.9314 87.0698Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M44.8855 96.116C44.3611 96.6404 44.3611 97.4926 44.8855 98.017C45.4099 98.5414 46.2621 98.5414 46.7865 98.017C47.3109 97.4926 47.3109 96.6404 46.7865 96.116C46.2621 95.5916 45.4099 95.5916 44.8855 96.116Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M35.8386 105.162C35.3142 105.687 35.3142 106.539 35.8386 107.063C36.363 107.588 37.2152 107.588 37.7396 107.063C38.2641 106.539 38.2641 105.687 37.7396 105.162C37.2152 104.638 36.363 104.638 35.8386 105.162Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M26.7918 114.208C26.2673 114.733 26.2673 115.585 26.7918 116.109C27.3162 116.634 28.1683 116.634 28.6928 116.109C29.2172 115.585 29.2172 114.733 28.6928 114.208C28.1683 113.684 27.3162 113.684 26.7918 114.208Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M17.7468 123.255C17.2224 123.779 17.2224 124.631 17.7468 125.156C18.2712 125.68 19.1234 125.68 19.6478 125.156C20.1723 124.631 20.1723 123.779 19.6478 123.255C19.1234 122.73 18.2712 122.73 17.7468 123.255Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M109.06 15.6178C108.536 16.1422 108.536 16.9944 109.06 17.5188C109.585 18.0433 110.437 18.0433 110.961 17.5188C111.486 16.9944 111.486 16.1422 110.961 15.6178C110.437 15.0934 109.585 15.0934 109.06 15.6178Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M100.014 24.664C99.49 25.1885 99.49 26.0406 100.014 26.5651C100.539 27.0895 101.391 27.0895 101.915 26.5651C102.44 26.0406 102.44 25.1885 101.915 24.664C101.391 24.1396 100.539 24.1396 100.014 24.664Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M90.9685 33.7103C90.4441 34.2347 90.4441 35.0869 90.9685 35.6113C91.4929 36.1357 92.3451 36.1357 92.8695 35.6113C93.3939 35.0869 93.3939 34.2347 92.8695 33.7103C92.3451 33.1858 91.4929 33.1858 90.9685 33.7103Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M81.9226 42.7565C81.3982 43.2809 81.3982 44.1331 81.9226 44.6575C82.447 45.1819 83.2992 45.1819 83.8236 44.6575C84.348 44.1331 84.348 43.2809 83.8236 42.7565C83.2992 42.232 82.447 42.232 81.9226 42.7565Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M72.8757 51.8027C72.3513 52.3271 72.3513 53.1793 72.8757 53.7037C73.4002 54.2281 74.2523 54.2281 74.7768 53.7037C75.3667 53.1793 75.3667 52.3271 74.7768 51.8027C74.2523 51.2783 73.4002 51.2783 72.8757 51.8027Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M63.8289 60.8489C63.3044 61.3733 63.3044 62.2255 63.8289 62.7499C64.3533 63.2743 65.2055 63.2743 65.7299 62.7499C66.3198 62.2255 66.3198 61.3733 65.7299 60.8489C65.2055 60.3245 64.3533 60.3245 63.8289 60.8489Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M54.7839 69.8951C54.2595 70.4195 54.2595 71.2717 54.7839 71.7961C55.3084 72.3205 56.1605 72.3205 56.7505 71.7961C57.2749 71.2717 57.2749 70.4195 56.7505 69.8951C56.1605 69.3707 55.3084 69.3707 54.7839 69.8951Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M45.7371 78.9413C45.2126 79.4658 45.2126 80.3179 45.7371 80.8423C46.2615 81.3668 47.1137 81.3668 47.7036 80.8423C48.228 80.3179 48.228 79.4658 47.7036 78.9413C47.1137 78.4169 46.2615 78.4169 45.7371 78.9413Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M36.6912 87.9875C36.1667 88.512 36.1667 89.3641 36.6912 89.8886C37.2156 90.413 38.1333 90.413 38.6577 89.8886C39.1822 89.3641 39.1822 88.512 38.6577 87.9875C38.1333 87.4631 37.2156 87.4631 36.6912 87.9875Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M27.6453 97.0337C27.1208 97.5582 27.1208 98.4103 27.6453 98.9348C28.2352 99.4592 29.0874 99.4592 29.6118 98.9348C30.1363 98.4103 30.1363 97.5582 29.6118 97.0337C29.0874 96.5093 28.2352 96.5093 27.6453 97.0337Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M18.6642 106.08C18.0742 106.604 18.0742 107.457 18.6642 107.981C19.1886 108.505 20.0408 108.505 20.5652 107.981C21.0896 107.457 21.0896 106.604 20.5652 106.08C20.0408 105.556 19.1886 105.556 18.6642 106.08Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M9.61826 115.126C9.02829 115.651 9.02829 116.503 9.61826 117.027C10.1427 117.552 10.9949 117.552 11.5193 117.027C12.0437 116.503 12.0437 115.651 11.5193 115.126C10.9949 114.602 10.1427 114.602 9.61826 115.126Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M100.932 7.48935C100.408 8.01377 100.408 8.86595 100.932 9.39037C101.457 9.91479 102.309 9.91479 102.833 9.39037C103.358 8.86595 103.358 8.01377 102.833 7.48935C102.309 6.96493 101.457 6.96493 100.932 7.48935Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M91.8865 16.5356C91.3621 17.06 91.3621 17.9122 91.8865 18.4366C92.4109 18.961 93.2631 18.961 93.7875 18.4366C94.3119 17.9122 94.3119 17.06 93.7875 16.5356C93.2631 16.0111 92.4109 16.0111 91.8865 16.5356Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M82.8396 25.5818C82.3152 26.1062 82.3152 26.9584 82.8396 27.4828C83.364 28.0072 84.2162 28.0072 84.7406 27.4828C85.265 26.9584 85.265 26.1062 84.7406 25.5818C84.2162 24.9918 83.364 24.9918 82.8396 25.5818Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M73.7937 34.5624C73.2693 35.1524 73.2693 36.0046 73.7937 36.529C74.3181 37.0534 75.1703 37.0534 75.6947 36.529C76.2191 36.0046 76.2191 35.1524 75.6947 34.5624C75.1703 34.038 74.3181 34.038 73.7937 34.5624Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M64.7468 43.6087C64.2224 44.1986 64.2224 45.0508 64.7468 45.5752C65.2712 46.0996 66.1234 46.0996 66.6478 45.5752C67.1723 45.0508 67.1723 44.1986 66.6478 43.6087C66.1234 43.0842 65.2712 43.0842 64.7468 43.6087Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M55.7019 52.6549C55.1775 53.1793 55.1775 54.097 55.7019 54.6214C56.2263 55.1458 57.0785 55.1458 57.6029 54.6214C58.1273 54.097 58.1273 53.1793 57.6029 52.6549C57.0785 52.1304 56.2263 52.1304 55.7019 52.6549Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M46.655 61.7011C46.1306 62.2255 46.1306 63.0777 46.655 63.6677C47.1795 64.1921 48.0316 64.1921 48.556 63.6677C49.0805 63.0777 49.0805 62.2255 48.556 61.7011C48.0316 61.1767 47.1795 61.1767 46.655 61.7011Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M37.6082 70.7473C37.0837 71.2717 37.0837 72.1239 37.6082 72.7139C38.1326 73.2383 38.9848 73.2383 39.5092 72.7139C40.0336 72.1239 40.0336 71.2717 39.5092 70.7473C38.9848 70.2229 38.1326 70.2229 37.6082 70.7473Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M28.5632 79.7935C28.0388 80.3179 28.0388 81.1701 28.5632 81.6945C29.0877 82.2845 29.9398 82.2845 30.4643 81.6945C30.9887 81.1701 30.9887 80.3179 30.4643 79.7935C29.9398 79.2691 29.0877 79.2691 28.5632 79.7935Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M19.5164 88.8397C18.9919 89.3641 18.9919 90.2163 19.5164 90.7407C20.0408 91.2652 20.893 91.2652 21.4174 90.7407C21.9418 90.2163 21.9418 89.3641 21.4174 88.8397C20.893 88.3153 20.0408 88.3153 19.5164 88.8397Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M10.4705 97.8859C9.94604 98.4104 9.94604 99.2625 10.4705 99.787C10.9949 100.311 11.8471 100.311 12.3715 99.787C12.8959 99.2625 12.8959 98.4104 12.3715 97.8859C11.8471 97.3615 10.9949 97.3615 10.4705 97.8859Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M1.42359 106.932C0.899169 107.457 0.899169 108.309 1.42359 108.833C1.94801 109.358 2.80019 109.358 3.3246 108.833C3.84902 108.309 3.84902 107.457 3.3246 106.932C2.80019 106.408 1.94801 106.408 1.42359 106.932Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M92.8044 -0.704682C92.28 -0.180264 92.28 0.671916 92.8044 1.19633C93.3289 1.72075 94.181 1.72075 94.7055 1.19633C95.2299 0.671916 95.2299 -0.180264 94.7055 -0.704682C94.181 -1.2291 93.3289 -1.2291 92.8044 -0.704682Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M83.7576 8.34152C83.2332 8.86594 83.2332 9.71812 83.7576 10.2425C84.282 10.767 85.1342 10.767 85.6586 10.2425C86.183 9.71812 86.183 8.86594 85.6586 8.34152C85.1342 7.8171 84.282 7.8171 83.7576 8.34152Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M74.7117 17.3877C74.1873 17.9122 74.1873 18.7643 74.7117 19.2888C75.2361 19.8132 76.0883 19.8132 76.6127 19.2888C77.1371 18.7643 77.1371 17.9122 76.6127 17.3877C76.0883 16.8633 75.2361 16.8633 74.7117 17.3877Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M65.6648 26.434C65.1404 26.9584 65.1404 27.8106 65.6648 28.335C66.1892 28.8594 67.0414 28.8594 67.5658 28.335C68.0902 27.8106 68.0902 26.9584 67.5658 26.434C67.0414 25.9095 66.1892 25.9095 65.6648 26.434Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M56.6189 35.4802C56.0945 36.0046 56.0945 36.8568 56.6189 37.3812C57.1433 37.9056 57.9955 37.9056 58.5199 37.3812C59.0443 36.8568 59.0443 36.0046 58.5199 35.4802C57.9955 34.9558 57.1433 34.9558 56.6189 35.4802Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M47.573 44.5264C47.0486 45.0508 47.0486 45.903 47.573 46.4274C48.0974 46.9518 48.9496 46.9518 49.474 46.4274C49.9984 45.903 49.9984 45.0508 49.474 44.5264C48.9496 44.002 48.0974 44.002 47.573 44.5264Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M38.5261 53.5726C38.0017 54.097 38.0017 54.9492 38.5261 55.4736C39.0505 55.998 39.9027 55.998 40.4271 55.4736C40.9516 54.9492 40.9516 54.097 40.4271 53.5726C39.9027 53.0482 39.0505 53.0482 38.5261 53.5726Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M29.4812 62.6188C28.9568 63.1432 28.9568 63.9954 29.4812 64.5198C30.0056 65.0443 30.8578 65.0443 31.3822 64.5198C31.9066 63.9954 31.9066 63.1432 31.3822 62.6188C30.8578 62.0944 30.0056 62.0944 29.4812 62.6188Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M20.4343 71.665C19.9099 72.1894 19.9099 73.0416 20.4343 73.566C20.9587 74.0905 21.8109 74.0905 22.3353 73.566C22.8598 73.0416 22.8598 72.1894 22.3353 71.665C21.8109 71.1406 20.9587 71.1406 20.4343 71.665Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M11.3875 80.7112C10.863 81.2357 10.863 82.0878 11.3875 82.6123C11.9119 83.1367 12.7641 83.1367 13.2885 82.6123C13.8129 82.0878 13.8129 81.2357 13.2885 80.7112C12.7641 80.1868 11.9119 80.1868 11.3875 80.7112Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M2.34156 89.7575C1.81714 90.2819 1.81714 91.1341 2.34156 91.6585C2.86597 92.1829 3.71815 92.1829 4.24257 91.6585C4.76699 91.1341 4.76699 90.2819 4.24257 89.7575C3.71815 89.233 2.86597 89.233 2.34156 89.7575Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M75.5632 0.213043C75.0388 0.737461 75.0388 1.58964 75.5632 2.11406C76.0877 2.63848 76.9398 2.63848 77.4643 2.11406C77.9887 1.58964 77.9887 0.737461 77.4643 0.213043C76.9398 -0.311376 76.0877 -0.311376 75.5632 0.213043Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M66.5183 9.25926C65.9939 9.78368 65.9939 10.6359 66.5183 11.1603C67.0427 11.6847 67.8949 11.6847 68.4193 11.1603C69.0093 10.6359 69.0093 9.78368 68.4193 9.25926C67.8949 8.73484 67.0427 8.73484 66.5183 9.25926Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M57.4714 18.3055C56.947 18.8299 56.947 19.6821 57.4714 20.2065C57.9959 20.7309 58.848 20.7309 59.438 20.2065C59.9624 19.6821 59.9624 18.8299 59.438 18.3055C58.848 17.7811 57.9959 17.7811 57.4714 18.3055Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M48.4246 27.3517C47.9001 27.8761 47.9001 28.7283 48.4246 29.2527C48.949 29.7771 49.8012 29.7771 50.3911 29.2527C50.9155 28.7283 50.9155 27.8761 50.3911 27.3517C49.8012 26.8273 48.949 26.8273 48.4246 27.3517Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M39.3787 36.3979C38.8542 36.9223 38.8542 37.7745 39.3787 38.2989C39.9031 38.8233 40.8208 38.8233 41.3452 38.2989C41.8697 37.7745 41.8697 36.9223 41.3452 36.3979C40.8208 35.8735 39.9031 35.8735 39.3787 36.3979Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M30.3328 45.4441C29.8083 45.9685 29.8083 46.8207 30.3328 47.3451C30.9227 47.8696 31.7749 47.8696 32.2993 47.3451C32.8238 46.8207 32.8238 45.9685 32.2993 45.4441C31.7749 44.9197 30.9227 44.9197 30.3328 45.4441Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M21.2869 54.4903C20.7625 55.0147 20.7625 55.8669 21.2869 56.3913C21.8768 56.9158 22.729 56.9158 23.2534 56.3913C23.7779 55.8669 23.7779 55.0147 23.2534 54.4903C22.729 53.9659 21.8768 53.9659 21.2869 54.4903Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M12.3058 63.5365C11.7158 64.061 11.7158 64.9131 12.3058 65.4376C12.8302 65.962 13.6824 65.962 14.2068 65.4376C14.7312 64.9131 14.7312 64.061 14.2068 63.5365C13.6824 63.0121 12.8302 63.0121 12.3058 63.5365Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M3.25952 72.5828C2.73511 73.1072 2.73511 73.9594 3.25952 74.4838C3.78394 75.0082 4.63612 75.0082 5.16054 74.4838C5.68496 73.9594 5.68496 73.1072 5.16054 72.5828C4.63612 72.0583 3.78394 72.0583 3.25952 72.5828Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M58.3894 1.13077C57.865 1.65519 57.865 2.50737 58.3894 3.03179C58.9138 3.5562 59.766 3.5562 60.2904 3.03179C60.8148 2.50737 60.8148 1.65519 60.2904 1.13077C59.766 0.606353 58.9138 0.606353 58.3894 1.13077Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M49.3425 10.177C48.8181 10.7014 48.8181 11.5536 49.3425 12.078C49.867 12.6024 50.7191 12.6024 51.2435 12.078C51.768 11.5536 51.768 10.7014 51.2435 10.177C50.7191 9.65258 49.867 9.65258 49.3425 10.177Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M40.2966 19.2232C39.7722 19.7476 39.7722 20.5998 40.2966 21.1242C40.8211 21.6486 41.6732 21.6486 42.1976 21.1242C42.7221 20.5998 42.7221 19.7476 42.1976 19.2232C41.6732 18.6988 40.8211 18.6988 40.2966 19.2232Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M31.2507 28.2694C30.7263 28.7938 30.7263 29.646 31.2507 30.1704C31.7752 30.6949 32.6273 30.6949 33.1518 30.1704C33.6762 29.646 33.6762 28.7938 33.1518 28.2694C32.6273 27.6794 31.7752 27.6794 31.2507 28.2694Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M22.2039 37.2501C21.6794 37.84 21.6794 38.6922 22.2039 39.2166C22.7283 39.7411 23.5805 39.7411 24.1049 39.2166C24.6293 38.6922 24.6293 37.84 24.1049 37.2501C23.5805 36.7257 22.7283 36.7257 22.2039 37.2501Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M13.158 46.2963C12.6335 46.8863 12.6335 47.7384 13.158 48.2629C13.6824 48.7873 14.5346 48.7873 15.059 48.2629C15.5834 47.7384 15.5834 46.8863 15.059 46.2963C14.5346 45.7719 13.6824 45.7719 13.158 46.2963Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M4.11109 55.3425C3.58667 55.8669 3.58667 56.7847 4.11109 57.3091C4.63551 57.8335 5.48768 57.8335 6.0121 57.3091C6.53652 56.7847 6.53652 55.8669 6.0121 55.3425C5.48768 54.8181 4.63551 54.8181 4.11109 55.3425Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M41.2136 1.98296C40.6892 2.50738 40.6892 3.35956 41.2136 3.88398C41.738 4.4084 42.5902 4.4084 43.1146 3.88398C43.6391 3.35956 43.6391 2.50738 43.1146 1.98296C42.5902 1.45854 41.738 1.45854 41.2136 1.98296Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M32.1687 11.0292C31.6443 11.5536 31.6443 12.4058 32.1687 12.9302C32.6931 13.4546 33.5453 13.4546 34.0697 12.9302C34.5941 12.4058 34.5941 11.5536 34.0697 11.0292C33.5453 10.5048 32.6931 10.5048 32.1687 11.0292Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M23.1218 20.0754C22.5974 20.5998 22.5974 21.452 23.1218 21.9764C23.6462 22.5008 24.4984 22.5008 25.0228 21.9764C25.5473 21.452 25.5473 20.5998 25.0228 20.0754C24.4984 19.551 23.6462 19.551 23.1218 20.0754Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M14.0759 29.1216C13.5515 29.646 13.5515 30.4982 14.0759 31.0226C14.6003 31.547 15.4525 31.547 15.9769 31.0226C16.5014 30.4982 16.5014 29.646 15.9769 29.1216C15.4525 28.5972 14.6003 28.5972 14.0759 29.1216Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M5.02906 38.1678C4.50464 38.6922 4.50464 39.5444 5.02906 40.0688C5.55347 40.5932 6.40565 40.5932 6.93007 40.0688C7.45449 39.5444 7.45449 38.6922 6.93007 38.1678C6.40565 37.6434 5.55347 37.6434 5.02906 38.1678Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M23.9744 2.90069C23.45 3.42511 23.45 4.27729 23.9744 4.80171C24.5643 5.32613 25.4165 5.32613 25.9409 4.80171C26.4654 4.27729 26.4654 3.42511 25.9409 2.90069C25.4165 2.37627 24.5643 2.37627 23.9744 2.90069Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M14.9933 11.9469C14.4033 12.4713 14.4033 13.3235 14.9933 13.8479C15.5177 14.3723 16.3699 14.3723 16.8943 13.8479C17.4187 13.3235 17.4187 12.4713 16.8943 11.9469C16.3699 11.4225 15.5177 11.4225 14.9933 11.9469Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M5.94702 20.9931C5.42261 21.5175 5.42261 22.3697 5.94702 22.8941C6.47144 23.4185 7.32362 23.4185 7.84804 22.8941C8.37246 22.3697 8.37246 21.5175 7.84804 20.9931C7.32362 20.4687 6.47144 20.4687 5.94702 20.9931Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M6.79859 3.81842C6.27417 4.34284 6.27417 5.19502 6.79859 5.71944C7.32301 6.24385 8.17519 6.24385 8.6996 5.71944C9.22402 5.19502 9.22402 4.34284 8.6996 3.81842C8.17519 3.294 7.32301 3.294 6.79859 3.81842Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M-2.24633 12.8646C-2.77075 13.3891 -2.77075 14.2412 -2.24633 14.7657C-1.72192 15.2901 -0.869736 15.2901 -0.345318 14.7657C0.1791 14.2412 0.1791 13.3891 -0.345318 12.8646C-0.869736 12.3402 -1.72192 12.3402 -2.24633 12.8646Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M595.28 835.3C594.756 835.824 594.756 836.677 595.28 837.201C595.804 837.725 596.657 837.725 597.181 837.201C597.705 836.677 597.705 835.824 597.181 835.3C596.657 834.776 595.804 834.776 595.28 835.3Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M596.198 818.06C595.674 818.584 595.674 819.436 596.198 820.026C596.722 820.551 597.575 820.551 598.099 820.026C598.623 819.436 598.623 818.584 598.099 818.06C597.575 817.535 596.722 817.535 596.198 818.06Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M587.151 827.106C586.627 827.63 586.627 828.482 587.151 829.007C587.676 829.597 588.528 829.597 589.052 829.007C589.577 828.482 589.577 827.63 589.052 827.106C588.528 826.581 587.676 826.581 587.151 827.106Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M578.106 836.152C577.582 836.677 577.582 837.529 578.106 838.053C578.631 838.578 579.483 838.578 580.007 838.053C580.532 837.529 580.532 836.677 580.007 836.152C579.483 835.628 578.631 835.628 578.106 836.152Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M588.069 809.931C587.545 810.456 587.545 811.308 588.069 811.832C588.594 812.357 589.446 812.357 589.97 811.832C590.495 811.308 590.495 810.456 589.97 809.931C589.446 809.407 588.594 809.407 588.069 809.931Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M579.024 818.977C578.5 819.502 578.5 820.354 579.024 820.878C579.549 821.403 580.401 821.403 580.925 820.878C581.45 820.354 581.45 819.502 580.925 818.977C580.401 818.453 579.549 818.453 579.024 818.977Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M569.977 828.024C569.453 828.548 569.453 829.4 569.977 829.925C570.502 830.449 571.354 830.449 571.878 829.925C572.403 829.4 572.403 828.548 571.878 828.024C571.354 827.499 570.502 827.499 569.977 828.024Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M560.93 837.07C560.406 837.594 560.406 838.446 560.93 838.971C561.455 839.495 562.307 839.495 562.831 838.971C563.356 838.446 563.356 837.594 562.831 837.07C562.307 836.545 561.455 836.545 560.93 837.07Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M588.923 792.756C588.398 793.281 588.398 794.133 588.923 794.657C589.447 795.182 590.299 795.182 590.824 794.657C591.348 794.133 591.348 793.281 590.824 792.756C590.299 792.232 589.447 792.232 588.923 792.756Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M579.876 801.803C579.351 802.327 579.351 803.179 579.876 803.704C580.4 804.228 581.252 804.228 581.777 803.704C582.367 803.179 582.367 802.327 581.777 801.803C581.252 801.278 580.4 801.278 579.876 801.803Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M570.829 810.849C570.304 811.373 570.304 812.225 570.829 812.75C571.353 813.274 572.205 813.274 572.73 812.75C573.32 812.225 573.32 811.373 572.73 810.849C572.205 810.324 571.353 810.324 570.829 810.849Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M561.784 819.895C561.26 820.42 561.26 821.272 561.784 821.796C562.308 822.321 563.161 822.321 563.751 821.796C564.275 821.272 564.275 820.42 563.751 819.895C563.161 819.371 562.308 819.371 561.784 819.895Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M552.737 828.941C552.213 829.466 552.213 830.318 552.737 830.842C553.261 831.367 554.114 831.367 554.704 830.842C555.228 830.318 555.228 829.466 554.704 828.941C554.114 828.417 553.261 828.417 552.737 828.941Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M543.692 837.988C543.168 838.512 543.168 839.364 543.692 839.889C544.217 840.413 545.134 840.413 545.659 839.889C546.183 839.364 546.183 838.512 545.659 837.988C545.134 837.463 544.217 837.463 543.692 837.988Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M589.839 775.582C589.314 776.106 589.314 776.958 589.839 777.483C590.363 778.007 591.215 778.007 591.74 777.483C592.264 776.958 592.264 776.106 591.74 775.582C591.215 774.992 590.363 774.992 589.839 775.582Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M580.794 784.562C580.269 785.152 580.269 786.005 580.794 786.529C581.318 787.053 582.17 787.053 582.695 786.529C583.219 786.005 583.219 785.152 582.695 784.562C582.17 784.038 581.318 784.038 580.794 784.562Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M571.747 793.609C571.222 794.199 571.222 795.051 571.747 795.575C572.271 796.1 573.123 796.1 573.648 795.575C574.172 795.051 574.172 794.199 573.648 793.609C573.123 793.084 572.271 793.084 571.747 793.609Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M562.702 802.655C562.177 803.179 562.177 804.097 562.702 804.621C563.226 805.146 564.079 805.146 564.603 804.621C565.127 804.097 565.127 803.179 564.603 802.655C564.079 802.13 563.226 802.13 562.702 802.655Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M553.655 811.701C553.131 812.225 553.131 813.078 553.655 813.668C554.179 814.192 555.032 814.192 555.556 813.668C556.08 813.078 556.08 812.225 555.556 811.701C555.032 811.177 554.179 811.177 553.655 811.701Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M544.608 820.747C544.084 821.272 544.084 822.124 544.608 822.714C545.133 823.238 545.985 823.238 546.509 822.714C547.034 822.124 547.034 821.272 546.509 820.747C545.985 820.223 545.133 820.223 544.608 820.747Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M535.563 829.794C535.039 830.318 535.039 831.17 535.563 831.695C536.088 832.285 536.94 832.285 537.464 831.695C537.989 831.17 537.989 830.318 537.464 829.794C536.94 829.269 536.088 829.269 535.563 829.794Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M526.516 838.84C525.992 839.364 525.992 840.216 526.516 840.741C527.041 841.265 527.893 841.265 528.417 840.741C528.942 840.216 528.942 839.364 528.417 838.84C527.893 838.315 527.041 838.315 526.516 838.84Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M590.757 758.341C590.232 758.866 590.232 759.718 590.757 760.243C591.281 760.767 592.133 760.767 592.658 760.243C593.182 759.718 593.182 758.866 592.658 758.341C592.133 757.817 591.281 757.817 590.757 758.341Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M581.712 767.388C581.187 767.912 581.187 768.764 581.712 769.289C582.236 769.813 583.088 769.813 583.613 769.289C584.137 768.764 584.137 767.912 583.613 767.388C583.088 766.863 582.236 766.863 581.712 767.388Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M572.665 776.434C572.14 776.958 572.14 777.811 572.665 778.335C573.189 778.859 574.041 778.859 574.566 778.335C575.09 777.811 575.09 776.958 574.566 776.434C574.041 775.91 573.189 775.91 572.665 776.434Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M563.618 785.48C563.094 786.005 563.094 786.857 563.618 787.381C564.142 787.906 564.995 787.906 565.519 787.381C566.043 786.857 566.043 786.005 565.519 785.48C564.995 784.956 564.142 784.956 563.618 785.48Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M554.573 794.526C554.049 795.051 554.049 795.903 554.573 796.427C555.097 796.952 555.95 796.952 556.474 796.427C556.998 795.903 556.998 795.051 556.474 794.526C555.95 794.002 555.097 794.002 554.573 794.526Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M545.526 803.573C545.002 804.097 545.002 804.949 545.526 805.474C546.051 805.998 546.903 805.998 547.427 805.474C547.952 804.949 547.952 804.097 547.427 803.573C546.903 803.048 546.051 803.048 545.526 803.573Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M536.481 812.619C535.957 813.143 535.957 813.995 536.481 814.52C537.006 815.044 537.858 815.044 538.382 814.52C538.907 813.995 538.907 813.143 538.382 812.619C537.858 812.094 537.006 812.094 536.481 812.619Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M527.434 821.665C526.91 822.189 526.91 823.042 527.434 823.566C527.959 824.09 528.811 824.09 529.335 823.566C529.86 823.042 529.86 822.189 529.335 821.665C528.811 821.141 527.959 821.141 527.434 821.665Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M518.387 830.711C517.863 831.236 517.863 832.088 518.387 832.612C518.912 833.137 519.764 833.137 520.288 832.612C520.813 832.088 520.813 831.236 520.288 830.711C519.764 830.187 518.912 830.187 518.387 830.711Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M509.343 839.757C508.818 840.282 508.818 841.134 509.343 841.658C509.867 842.183 510.719 842.183 511.244 841.658C511.768 841.134 511.768 840.282 511.244 839.757C510.719 839.233 509.867 839.233 509.343 839.757Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M591.61 741.167C591.086 741.691 591.086 742.543 591.61 743.068C592.135 743.592 592.987 743.592 593.511 743.068C594.036 742.543 594.036 741.691 593.511 741.167C592.987 740.642 592.135 740.642 591.61 741.167Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M582.563 750.213C582.039 750.737 582.039 751.59 582.563 752.114C583.088 752.638 583.94 752.638 584.464 752.114C584.989 751.59 584.989 750.737 584.464 750.213C583.94 749.689 583.088 749.689 582.563 750.213Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M573.518 759.259C572.994 759.784 572.994 760.636 573.518 761.16C574.043 761.685 574.895 761.685 575.419 761.16C576.009 760.636 576.009 759.784 575.419 759.259C574.895 758.735 574.043 758.735 573.518 759.259Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M564.471 768.305C563.947 768.83 563.947 769.682 564.471 770.206C564.996 770.731 565.848 770.731 566.438 770.206C566.962 769.682 566.962 768.83 566.438 768.305C565.848 767.781 564.996 767.781 564.471 768.305Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M555.425 777.352C554.9 777.876 554.9 778.728 555.425 779.253C555.949 779.777 556.801 779.777 557.391 779.253C557.916 778.728 557.916 777.876 557.391 777.352C556.801 776.827 555.949 776.827 555.425 777.352Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M546.38 786.398C545.855 786.922 545.855 787.774 546.38 788.299C546.904 788.823 547.822 788.823 548.346 788.299C548.871 787.774 548.871 786.922 548.346 786.398C547.822 785.873 546.904 785.873 546.38 786.398Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M537.333 795.444C536.808 795.969 536.808 796.821 537.333 797.345C537.923 797.87 538.775 797.87 539.299 797.345C539.824 796.821 539.824 795.969 539.299 795.444C538.775 794.92 537.923 794.92 537.333 795.444Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M528.286 804.49C527.761 805.015 527.761 805.867 528.286 806.391C528.876 806.916 529.728 806.916 530.252 806.391C530.777 805.867 530.777 805.015 530.252 804.49C529.728 803.966 528.876 803.966 528.286 804.49Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M519.306 813.537C518.716 814.061 518.716 814.913 519.306 815.438C519.83 815.962 520.682 815.962 521.207 815.438C521.731 814.913 521.731 814.061 521.207 813.537C520.682 813.012 519.83 813.012 519.306 813.537Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M510.261 822.583C509.736 823.107 509.736 823.959 510.261 824.484C510.785 825.008 511.637 825.008 512.162 824.484C512.686 823.959 512.686 823.107 512.162 822.583C511.637 822.058 510.785 822.058 510.261 822.583Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M501.214 831.629C500.689 832.153 500.689 833.006 501.214 833.53C501.738 834.054 502.59 834.054 503.115 833.53C503.639 833.006 503.639 832.153 503.115 831.629C502.59 831.105 501.738 831.105 501.214 831.629Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M492.167 840.675C491.642 841.2 491.642 842.052 492.167 842.576C492.691 843.101 493.543 843.101 494.068 842.576C494.592 842.052 494.592 841.2 494.068 840.675C493.543 840.151 492.691 840.151 492.167 840.675Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M583.481 733.038C582.957 733.563 582.957 734.415 583.481 734.939C584.006 735.464 584.858 735.464 585.382 734.939C585.907 734.415 585.907 733.563 585.382 733.038C584.858 732.514 584.006 732.514 583.481 733.038Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M574.434 742.085C573.91 742.609 573.91 743.461 574.434 743.986C574.959 744.51 575.811 744.51 576.335 743.986C576.86 743.461 576.86 742.609 576.335 742.085C575.811 741.56 574.959 741.56 574.434 742.085Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M565.389 751.131C564.865 751.655 564.865 752.507 565.389 753.032C565.914 753.556 566.766 753.556 567.29 753.032C567.815 752.507 567.815 751.655 567.29 751.131C566.766 750.606 565.914 750.606 565.389 751.131Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M556.343 760.177C555.818 760.701 555.818 761.554 556.343 762.078C556.867 762.602 557.719 762.602 558.244 762.078C558.768 761.554 558.768 760.701 558.244 760.177C557.719 759.653 556.867 759.653 556.343 760.177Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M547.298 769.223C546.773 769.748 546.773 770.6 547.298 771.124C547.822 771.649 548.674 771.649 549.199 771.124C549.723 770.6 549.723 769.748 549.199 769.223C548.674 768.699 547.822 768.699 547.298 769.223Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M538.251 778.269C537.726 778.794 537.726 779.646 538.251 780.17C538.775 780.695 539.627 780.695 540.152 780.17C540.676 779.646 540.676 778.794 540.152 778.269C539.627 777.679 538.775 777.679 538.251 778.269Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M529.204 787.25C528.679 787.84 528.679 788.692 529.204 789.217C529.728 789.741 530.58 789.741 531.105 789.217C531.629 788.692 531.629 787.84 531.105 787.25C530.58 786.726 529.728 786.726 529.204 787.25Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M520.159 796.296C519.635 796.886 519.635 797.738 520.159 798.263C520.683 798.787 521.536 798.787 522.06 798.263C522.584 797.738 522.584 796.886 522.06 796.296C521.536 795.772 520.683 795.772 520.159 796.296Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M511.112 805.343C510.588 805.867 510.588 806.785 511.112 807.309C511.636 807.834 512.489 807.834 513.013 807.309C513.537 806.785 513.537 805.867 513.013 805.343C512.489 804.818 511.636 804.818 511.112 805.343Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M502.065 814.389C501.541 814.913 501.541 815.765 502.065 816.355C502.59 816.88 503.442 816.88 503.966 816.355C504.491 815.765 504.491 814.913 503.966 814.389C503.442 813.864 502.59 813.864 502.065 814.389Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M493.018 823.435C492.494 823.959 492.494 824.812 493.018 825.336C493.543 825.926 494.395 825.926 494.919 825.336C495.444 824.812 495.444 823.959 494.919 823.435C494.395 822.911 493.543 822.911 493.018 823.435Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M483.973 832.481C483.449 833.006 483.449 833.858 483.973 834.382C484.498 834.972 485.35 834.972 485.874 834.382C486.399 833.858 486.399 833.006 485.874 832.481C485.35 831.957 484.498 831.957 483.973 832.481Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M575.352 724.844C574.828 725.369 574.828 726.221 575.352 726.745C575.877 727.27 576.729 727.27 577.253 726.745C577.778 726.221 577.778 725.369 577.253 724.844C576.729 724.32 575.877 724.32 575.352 724.844Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M566.307 733.891C565.783 734.415 565.783 735.267 566.307 735.792C566.832 736.316 567.684 736.316 568.208 735.792C568.733 735.267 568.733 734.415 568.208 733.891C567.684 733.366 566.832 733.366 566.307 733.891Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M557.261 742.937C556.736 743.461 556.736 744.313 557.261 744.838C557.785 745.362 558.637 745.362 559.162 744.838C559.686 744.313 559.686 743.461 559.162 742.937C558.637 742.412 557.785 742.412 557.261 742.937Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M548.214 751.983C547.689 752.507 547.689 753.36 548.214 753.884C548.738 754.408 549.59 754.408 550.115 753.884C550.639 753.36 550.639 752.507 550.115 751.983C549.59 751.459 548.738 751.459 548.214 751.983Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M539.169 761.029C538.644 761.554 538.644 762.406 539.169 762.93C539.693 763.455 540.545 763.455 541.07 762.93C541.594 762.406 541.594 761.554 541.07 761.029C540.545 760.505 539.693 760.505 539.169 761.029Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M530.122 770.075C529.597 770.6 529.597 771.452 530.122 771.976C530.646 772.501 531.498 772.501 532.023 771.976C532.547 771.452 532.547 770.6 532.023 770.075C531.498 769.551 530.646 769.551 530.122 770.075Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M521.077 779.122C520.552 779.646 520.552 780.498 521.077 781.023C521.601 781.547 522.454 781.547 522.978 781.023C523.502 780.498 523.502 779.646 522.978 779.122C522.454 778.597 521.601 778.597 521.077 779.122Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M512.03 788.168C511.506 788.692 511.506 789.544 512.03 790.069C512.554 790.593 513.407 790.593 513.931 790.069C514.455 789.544 514.455 788.692 513.931 788.168C513.407 787.643 512.554 787.643 512.03 788.168Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M502.983 797.214C502.459 797.738 502.459 798.591 502.983 799.115C503.508 799.639 504.36 799.639 504.884 799.115C505.409 798.591 505.409 797.738 504.884 797.214C504.36 796.69 503.508 796.69 502.983 797.214Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M493.936 806.26C493.412 806.785 493.412 807.637 493.936 808.161C494.461 808.686 495.313 808.686 495.837 808.161C496.362 807.637 496.362 806.785 495.837 806.26C495.313 805.736 494.461 805.736 493.936 806.26Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M484.891 815.306C484.367 815.831 484.367 816.683 484.891 817.207C485.416 817.732 486.268 817.732 486.792 817.207C487.317 816.683 487.317 815.831 486.792 815.306C486.268 814.782 485.416 814.782 484.891 815.306Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M475.844 824.353C475.32 824.877 475.32 825.729 475.844 826.254C476.369 826.778 477.221 826.778 477.746 826.254C478.27 825.729 478.27 824.877 477.746 824.353C477.221 823.828 476.369 823.828 475.844 824.353Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M567.159 716.716C566.635 717.24 566.635 718.092 567.159 718.617C567.683 719.141 568.536 719.141 569.126 718.617C569.65 718.092 569.65 717.24 569.126 716.716C568.536 716.191 567.683 716.191 567.159 716.716Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M558.114 725.762C557.59 726.286 557.59 727.139 558.114 727.663C558.638 728.187 559.491 728.187 560.081 727.663C560.605 727.139 560.605 726.286 560.081 725.762C559.491 725.238 558.638 725.238 558.114 725.762Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M549.067 734.808C548.543 735.333 548.543 736.185 549.067 736.709C549.592 737.234 550.509 737.234 551.034 736.709C551.558 736.185 551.558 735.333 551.034 734.808C550.509 734.284 549.592 734.284 549.067 734.808Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M540.02 743.854C539.496 744.379 539.496 745.231 540.02 745.756C540.61 746.28 541.462 746.28 541.987 745.756C542.511 745.231 542.511 744.379 541.987 743.854C541.462 743.33 540.61 743.33 540.02 743.854Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M530.973 752.901C530.449 753.425 530.449 754.277 530.973 754.802C531.563 755.326 532.416 755.326 532.94 754.802C533.464 754.277 533.464 753.425 532.94 752.901C532.416 752.376 531.563 752.376 530.973 752.901Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M521.993 761.947C521.403 762.471 521.403 763.324 521.993 763.848C522.518 764.372 523.37 764.372 523.894 763.848C524.419 763.324 524.419 762.471 523.894 761.947C523.37 761.422 522.518 761.422 521.993 761.947Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M512.948 770.993C512.424 771.518 512.424 772.37 512.948 772.894C513.472 773.419 514.325 773.419 514.849 772.894C515.373 772.37 515.373 771.518 514.849 770.993C514.325 770.469 513.472 770.469 512.948 770.993Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M503.901 780.039C503.377 780.564 503.377 781.416 503.901 781.94C504.426 782.465 505.278 782.465 505.802 781.94C506.327 781.416 506.327 780.564 505.802 780.039C505.278 779.515 504.426 779.515 503.901 780.039Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M494.854 789.086C494.33 789.61 494.33 790.462 494.854 790.987C495.379 791.511 496.231 791.511 496.755 790.987C497.28 790.462 497.28 789.61 496.755 789.086C496.231 788.561 495.379 788.561 494.854 789.086Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M485.809 798.132C485.285 798.656 485.285 799.508 485.809 800.033C486.334 800.557 487.186 800.557 487.71 800.033C488.235 799.508 488.235 798.656 487.71 798.132C487.186 797.607 486.334 797.607 485.809 798.132Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M476.762 807.178C476.238 807.702 476.238 808.555 476.762 809.079C477.287 809.603 478.139 809.603 478.663 809.079C479.188 808.555 479.188 807.702 478.663 807.178C478.139 806.654 477.287 806.654 476.762 807.178Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M467.716 816.224C467.191 816.749 467.191 817.601 467.716 818.125C468.24 818.65 469.092 818.65 469.617 818.125C470.141 817.601 470.141 816.749 469.617 816.224C469.092 815.7 468.24 815.7 467.716 816.224Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M559.03 708.587C558.506 709.112 558.506 709.964 559.03 710.488C559.554 711.013 560.407 711.013 560.931 710.488C561.455 709.964 561.455 709.112 560.931 708.587C560.407 708.063 559.554 708.063 559.03 708.587Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M549.985 717.634C549.461 718.158 549.461 719.01 549.985 719.535C550.51 720.059 551.362 720.059 551.886 719.535C552.411 719.01 552.411 718.158 551.886 717.634C551.362 717.109 550.51 717.109 549.985 717.634Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M540.938 726.68C540.414 727.204 540.414 728.056 540.938 728.581C541.463 729.105 542.315 729.105 542.839 728.581C543.364 728.056 543.364 727.204 542.839 726.68C542.315 726.155 541.463 726.155 540.938 726.68Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M531.891 735.726C531.367 736.25 531.367 737.103 531.891 737.627C532.416 738.151 533.268 738.151 533.792 737.627C534.317 737.103 534.317 736.25 533.792 735.726C533.268 735.202 532.416 735.202 531.891 735.726Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M522.846 744.772C522.322 745.297 522.322 746.149 522.846 746.673C523.371 747.198 524.223 747.198 524.747 746.673C525.272 746.149 525.272 745.297 524.747 744.772C524.223 744.248 523.371 744.248 522.846 744.772Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M513.8 753.818C513.275 754.343 513.275 755.195 513.8 755.719C514.324 756.244 515.176 756.244 515.701 755.719C516.225 755.195 516.225 754.343 515.701 753.818C515.176 753.294 514.324 753.294 513.8 753.818Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M504.753 762.865C504.228 763.389 504.228 764.241 504.753 764.766C505.277 765.29 506.129 765.29 506.654 764.766C507.178 764.241 507.178 763.389 506.654 762.865C506.129 762.34 505.277 762.34 504.753 762.865Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M495.706 771.911C495.181 772.435 495.181 773.287 495.706 773.812C496.23 774.336 497.082 774.336 497.607 773.812C498.131 773.287 498.131 772.435 497.607 771.911C497.082 771.321 496.23 771.321 495.706 771.911Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M486.661 780.957C486.136 781.481 486.136 782.334 486.661 782.858C487.185 783.382 488.037 783.382 488.562 782.858C489.086 782.334 489.086 781.481 488.562 780.957C488.037 780.367 487.185 780.367 486.661 780.957Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M477.614 789.938C477.09 790.528 477.09 791.38 477.614 791.904C478.138 792.429 478.991 792.429 479.515 791.904C480.039 791.38 480.039 790.528 479.515 789.938C478.991 789.413 478.138 789.413 477.614 789.938Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M468.569 798.984C468.045 799.574 468.045 800.426 468.569 800.951C469.094 801.475 469.946 801.475 470.47 800.951C470.995 800.426 470.995 799.574 470.47 798.984C469.946 798.46 469.094 798.46 468.569 798.984Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M459.522 808.03C458.998 808.555 458.998 809.472 459.522 809.997C460.047 810.521 460.899 810.521 461.423 809.997C461.948 809.472 461.948 808.555 461.423 808.03C460.899 807.506 460.047 807.506 459.522 808.03Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M550.903 700.393C550.379 700.918 550.379 701.77 550.903 702.294C551.427 702.884 552.28 702.884 552.804 702.294C553.329 701.77 553.329 700.918 552.804 700.393C552.28 699.869 551.427 699.869 550.903 700.393Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M541.856 709.44C541.332 709.964 541.332 710.816 541.856 711.341C542.381 711.865 543.233 711.865 543.757 711.341C544.282 710.816 544.282 709.964 543.757 709.44C543.233 708.915 542.381 708.915 541.856 709.44Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M532.809 718.486C532.285 719.01 532.285 719.862 532.809 720.387C533.334 720.911 534.186 720.911 534.71 720.387C535.235 719.862 535.235 719.01 534.71 718.486C534.186 717.961 533.334 717.961 532.809 718.486Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M523.764 727.532C523.24 728.056 523.24 728.909 523.764 729.433C524.289 729.957 525.141 729.957 525.665 729.433C526.19 728.909 526.19 728.056 525.665 727.532C525.141 727.008 524.289 727.008 523.764 727.532Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M514.718 736.578C514.193 737.103 514.193 737.955 514.718 738.479C515.242 739.004 516.094 739.004 516.619 738.479C517.143 737.955 517.143 737.103 516.619 736.578C516.094 736.054 515.242 736.054 514.718 736.578Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M505.671 745.624C505.146 746.149 505.146 747.001 505.671 747.525C506.195 748.05 507.047 748.05 507.572 747.525C508.096 747.001 508.096 746.149 507.572 745.624C507.047 745.1 506.195 745.1 505.671 745.624Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M496.624 754.671C496.099 755.195 496.099 756.047 496.624 756.572C497.148 757.096 498 757.096 498.525 756.572C499.049 756.047 499.049 755.195 498.525 754.671C498 754.146 497.148 754.146 496.624 754.671Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M487.579 763.717C487.054 764.241 487.054 765.093 487.579 765.618C488.103 766.142 488.955 766.142 489.48 765.618C490.004 765.093 490.004 764.241 489.48 763.717C488.955 763.192 488.103 763.192 487.579 763.717Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M478.532 772.763C478.008 773.287 478.008 774.14 478.532 774.664C479.056 775.188 479.909 775.188 480.433 774.664C480.957 774.14 480.957 773.287 480.433 772.763C479.909 772.239 479.056 772.239 478.532 772.763Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M469.485 781.809C468.961 782.334 468.961 783.186 469.485 783.71C470.01 784.235 470.862 784.235 471.386 783.71C471.911 783.186 471.911 782.334 471.386 781.809C470.862 781.285 470.01 781.285 469.485 781.809Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M460.44 790.855C459.916 791.38 459.916 792.232 460.44 792.756C460.965 793.281 461.817 793.281 462.341 792.756C462.866 792.232 462.866 791.38 462.341 790.855C461.817 790.331 460.965 790.331 460.44 790.855Z"
          fill={props?.c1 || "#B3B2D8"}
        />
        <Path
          d="M451.393 799.902C450.869 800.426 450.869 801.278 451.393 801.803C451.918 802.327 452.77 802.327 453.294 801.803C453.819 801.278 453.819 800.426 453.294 799.902C452.77 799.377 451.918 799.377 451.393 799.902Z"
          fill={props?.c1 || "#B3B2D8"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5032_16625">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture20 = {
  ViewTexture,
  PdfTexture,
};

export default Texture20;
