import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  Ellipse,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4983_23679)">
        <rect width="596" height="842" fill="white" />
        <ellipse
          cx="58.7439"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="58.7439"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="58.7439"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="58.7439"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="58.7439"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="58.7439"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 58.7439 11.7457)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="87.2322"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 87.2322 11.7457)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="115.719"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 115.719 11.7457)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="144.208"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 144.208 11.7456)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="172.695"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 172.695 11.7457)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="201.183"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 201.183 11.7456)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 117.36)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 96.2368)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 75.114)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 53.9912)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 32.8685)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="229.671"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 229.671 11.7456)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="366.744"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 366.744 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="395.232"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 395.232 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="423.719"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 423.719 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="452.208"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 452.208 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="480.695"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 480.695 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="509.183"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 509.183 720.64)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 826.254)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 805.132)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 784.009)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 762.886)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 741.763)"
          fill="#F8C5D8"
        />
        <ellipse
          cx="537.671"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          transform="rotate(-90 537.671 720.64)"
          fill="#F8C5D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_4983_23679">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_4983_23679)">
        <Rect width="596" height="842" fill="white" />
        <Ellipse
          cx="58.7439"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="58.7439"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="58.7439"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="58.7439"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="58.7439"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="58.7439"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="87.2322"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="115.719"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="144.208"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="172.695"
          cy="11.7457"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="201.183"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="117.36"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="96.2368"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="75.114"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="53.9912"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="32.8685"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="229.671"
          cy="11.7456"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="366.744"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="395.232"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="423.719"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="452.208"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="480.695"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="509.183"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="826.254"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="805.132"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="784.009"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="762.886"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="741.763"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
        <Ellipse
          cx="537.671"
          cy="720.64"
          rx="2.64035"
          ry="2.7439"
          fill={props?.c1 || "#F8C5D8"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4983_23679">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture17 = {
  ViewTexture,
  PdfTexture,
};

export default Texture17;
