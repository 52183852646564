import { Box, TextField, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useRef, useState } from "react";
import { ReactComponent as CustomEditLinkIcon } from "../../../assets/SVG/link-edit-icon.svg";

interface ICustomlink {
  name: string;
  id: string;
  setName: (name: string) => void;
  deleteCustomLink?: (id: string, name: string) => void;
  error?: boolean;
}

const Customlink: React.FC<ICustomlink> = ({
  name,
  id,
  setName,
  deleteCustomLink,
  error = false,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditStart = () => {
    setEdit(true);
    inputRef.current?.focus();
  };

  const handleDone = () => {
    setEdit(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <TextField
        className="editable-label"
        onChange={(e) => setName(e.target.value)}
        value={name}
        size="small"
        sx={{
          flexGrow: 1,
          maxWidth: "140px",
          "& .MuiInputBase-input": {
            fontSize: "14px !important", // Adjust font size of input text
          },
          "& .MuiOutlinedInput-root": {
            padding: "0.2rem 0.2rem 0.2rem 0.5rem  !important",
            border: 0,
            "& fieldset": {
              paddingLeft: "0.5rem !important",
              borderRadius: "4px !important",
              border: error ? "1px solid red" : 0,
            },
          },
        }}
        inputRef={inputRef}
      />

      <Box sx={{ cursor: "pointer" }}>
        {edit ? (
          <DoneIcon onClick={handleDone} sx={{ width: "15px", pt: "8px" }} />
        ) : (
          <CustomEditLinkIcon onClick={handleEditStart} />
        )}
      </Box>

      {deleteCustomLink && (
        <Typography
          sx={{
            flex: "1",
            textAlign: "right",
            paddingRight: "0.5rem",
            cursor: "pointer",
            fontSize: "14px",
            color: "#98a2b3",
          }}
          onClick={() => deleteCustomLink(id, name)}
        >
          Delete
        </Typography>
      )}
    </Box>
  );
};

export default Customlink;
