import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getToken } from "./auth/storeToken";
import { getUserProfile, withAuthHandling } from "../services/apiService";
import { setLoggedInUserData } from "../redux/actions";

const InitialLoadActions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchWithAuthHandling = withAuthHandling(navigate);

  useEffect(() => {
    location.pathname === "/settings" && navigate("/settings/profile");
  }, []);

  const fetchUserDetails = async () => {
    if (getToken()) {
      const userDetails = await getUserProfile(fetchWithAuthHandling);
      if (userDetails.resumeOptimization === null) {
        userDetails.resumeOptimization = true;
      }
      dispatch(setLoggedInUserData(userDetails));
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return <></>;
};

export default InitialLoadActions;
