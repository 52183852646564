import { id } from "date-fns/locale";
import Texture11 from "../templates/textures/Texture11";
import Texture4 from "../templates/textures/Texture4";
import Texture1 from "../templates/textures/Texture1";
import Texture2 from "../templates/textures/Texture2";
import Texture3 from "../templates/textures/Texture3";
import Texture7 from "../templates/textures/Texture7";
import Texture8 from "../templates/textures/Texture8";
import Texture10 from "../templates/textures/Texture10";
import Texture12 from "../templates/textures/Texture12";
import Texture14 from "../templates/textures/Texture14";
import Texture15 from "../templates/textures/Texture15";
import Texture16 from "../templates/textures/Texture16";
import Texture17 from "../templates/textures/Texture17";
import Texture18 from "../templates/textures/Texture18";
import Texture19 from "../templates/textures/Texture19";
import Texture20 from "../templates/textures/Texture20";
import Texture22 from "../templates/textures/Texture22";
import Texture23 from "../templates/textures/Texture23";
import Texture24 from "../templates/textures/Texture24";
import Texture25 from "../templates/textures/Texture25";
import Texture26 from "../templates/textures/Texture26";
import Texture27 from "../templates/textures/Texture27";
import Texture28 from "../templates/textures/Texture28";
import Texture30 from "../templates/textures/Texture30";
import Texture31 from "../templates/textures/Texture31";

export const TEXTURES = [
  {
    id: "5",
    name: "Texture 4",
    path: require("../templates/textures/tttwinkle.jpg"),
  },
  {
    id: "1",
    name: "Texture 1",
    svg: Texture1,
    colorScheme: ["#D1D1D1", "#D1D1D1"],
  },
  {
    id: "2",
    name: "Texture 2",
    svg: Texture2,
    colorScheme: ["#F7B546", "#364675"],
  },
  {
    id: "3",
    name: "Texture 3",
    svg: Texture3,
    colorScheme: ["#EAF9F8", "#12B8B2"],
  },
  // {
  //   id: "2",
  //   name: "Texture 2",
  //   path: require("../templates/textures/oooscillate.jpg"),
  // },
  // {
  //   id: "3",
  //   name: "Texture 3",
  //   path: require("../templates/textures/ttten.jpg"),
  // },
  {
    id: "4",
    name: "Texture 4",
    svg: Texture4,
    colorScheme: ["#20D1E0", "#20D1E0"],
  },
  {
    id: "7",
    name: "Texture 7",
    svg: Texture7,
    colorScheme: ["#20D1E0", "#003669"],
  },
  {
    id: "8",
    name: "Texture 8",
    svg: Texture8,
    colorScheme: ["#DAC825"],
  },
  {
    id: "10",
    name: "Texture 10",
    svg: Texture10,
    colorScheme: ["#C6D9DF"],
  },
  {
    id: "11",
    name: "Texture 11",
    svg: Texture11,
    colorScheme: ["#F78048", "#85DBD2", "#8AE7DF"],
  },
  {
    id: "12",
    name: "Texture 12",
    svg: Texture12,
    colorScheme: ["#FFB800", "#C4C4C4"],
  },
  {
    id: "14",
    name: "Texture 14",
    svg: Texture14,
    colorScheme: ["#9DDBE6", "#7D408E", "#E6588A"],
  },
  {
    id: "15",
    name: "Texture 15",
    svg: Texture15,
    colorScheme: ["#FFD799", "#FBEA81"],
  },
  {
    id: "16",
    name: "Texture 16",
    svg: Texture16,
    colorScheme: ["#F8C5D8", "#E5D9EA", "#FDE911"],
  },
  {
    id: "17",
    name: "Texture 17",
    svg: Texture17,
    colorScheme: ["#F8C5D8"],
  },
  {
    id: "18",
    name: "Texture 18",
    svg: Texture18,
    colorScheme: ["#F2CF31"],
  },
  {
    id: "19",
    name: "Texture 19",
    svg: Texture19,
    colorScheme: ["#F49B97", "#F9B840"],
  },
  {
    id: "20",
    name: "Texture 20",
    svg: Texture20,
    colorScheme: ["#B3B2D8"],
  },
  {
    id: "22",
    name: "Texture 22",
    svg: Texture22,
    colorScheme: ["#CC81EA", "#4442FF", "#85EAFF"],
  },
  {
    id: "23",
    name: "Texture 23",
    svg: Texture23,
    colorScheme: ["#6AA1F8", "#043680"],
  },
  {
    id: "24",
    name: "Texture 24",
    svg: Texture24,
    colorScheme: ["#365673"],
  },
  {
    id: "25",
    name: "Texture 25",
    svg: Texture25,
    colorScheme: ["#CCCCCC"],
  },
  {
    id: "26",
    name: "Texture 26",
    svg: Texture26,
    colorScheme: ["#B6E3F3", "#58BEE3", "#B8E3F3", "#E0F3FA"],
  },
  {
    id: "27",
    name: "Texture 27",
    svg: Texture27,
    colorScheme: ["#8AE9FB", "#32EF96", "#FF90C3"],
  },
  {
    id: "28",
    name: "Texture 28",
    svg: Texture28,
    colorScheme: ["#6DBAB7"],
  },
  {
    id: "30",
    name: "Texture 30",
    svg: Texture30,
    colorScheme: [],
  },
  {
    id: "31",
    name: "Texture 31",
    svg: Texture31,
    colorScheme: [],
  },
];
