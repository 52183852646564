import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { CourseType } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";
import * as allBrandIcons from "@fortawesome/free-brands-svg-icons";
import {
  hasObjTruthyValue,
  isPhoneNumberExists,
} from "../../components/common/CommonFunctions";
import PdfFontAwesome from "../../components/PdfFontAwesome/PdfFontAwesome";

const ContactTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { primaryColor, fontFamily, relativeFontSize },
  SectionHeader,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
}) => {
  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    iconTextContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    mt5: {
      marginTop: 5,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    contact: {
      fontSize: 12 + relativeFontSize,
    },
  });

  const showContactIcon = extractedData?.metaInformation?.showContactFieldsIcon;
  const showSocialLinkIcon =
    extractedData?.metaInformation?.showSocialLinksIcon;

  const iconsMapper = {
    personal_Website: allIcons.faLink,
    linkedIn_URL: allBrandIcons.faLinkedin,
    github_URL: allBrandIcons.faGithub,
    twitter_X_URL: allBrandIcons.faTwitter,
  };

  return (
    <>
      {(isPhoneNumberExists(extractedData.phone) ||
        extractedData.email !== "" ||
        extractedData.location !== "" ||
        hasObjTruthyValue(extractedData?.socialLinks)) && (
        <View>
          {SectionHeader ? (
            <SectionHeader>CONTACT</SectionHeader>
          ) : (
            <Text style={styles.sectionHeader}>CONTACT</Text>
          )}
          {extractedData.phone && (
            <View style={{ ...styles.iconTextContainer, ...styles.mt5 }}>
              {showContactIcon && (
                <PdfFontAwesome
                  faIcon={allIcons.faPhone}
                  style={{ color: primaryColor || "#000", width: "12px" }}
                />
              )}
              <Text style={styles.contact}>
                {Array.isArray(extractedData.phone)
                  ? extractedData.phone.join(", ")
                  : extractedData.phone}
              </Text>
            </View>
          )}
          {extractedData.email && (
            <View style={{ ...styles.iconTextContainer, ...styles.mt5 }}>
              {showContactIcon && (
                <PdfFontAwesome
                  faIcon={allIcons.faEnvelope}
                  style={{ color: primaryColor || "#000", width: "12px" }}
                />
              )}
              <Text style={styles.contact}>{extractedData.email}</Text>
            </View>
          )}
          {extractedData?.isShowAddressLocation && (
            <Text style={styles.contact}>{extractedData.location}</Text>
          )}
          {Object.keys(extractedData?.socialLinks)?.map((key) => {
            const socialLink = extractedData?.socialLinks?.[key];
            if (socialLink && socialLink !== "NA") {
              return (
                <View style={{ ...styles.iconTextContainer, ...styles.mt5 }}>
                  {showSocialLinkIcon && (
                    <PdfFontAwesome
                      faIcon={(iconsMapper as any)[key] || allIcons.faGlobe}
                      style={{ color: primaryColor || "#000", width: "12px" }}
                    />
                  )}
                  <Text style={{ ...styles.contact }}>{socialLink}</Text>
                </View>
              );

              // return <Text style={styles.contact}>{socialLink}</Text>;
            }
          })}
        </View>
      )}
    </>
  );
};

export default ContactTemplate;
