import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Defs,
  RadialGradient,
  Rect,
  Stop,
} from "@react-pdf/renderer";
import { defaultProfilePicture } from "../components/utils";
// import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import { loadFonts, loadSpecificFont } from "../utils/googleFontsHandler";
import WorkExperienceTemplate from "./components/WorkExperienceTemplate";
import EducationTemplate from "./components/EducationTemplate";
import ProjectsTemplate from "./components/ProjectsTemplate";
import CoursesTemplate from "./components/CoursesTemplate";
import AwardsTemplate from "./components/AwardsTemplate";
import ReferencesTemplate from "./components/ReferencesTemplate";
import VolunteerWorkTemplate from "./components/VolunteerWorkTemplate";
import ExtraCATemplate from "./components/ExtraCATemplate";
import CustomSectionTemplate from "./components/CustomSectionTemplate";
import ContactTemplate from "./components/ContactTemplate";
import SkillsTemplate from "./components/SkillsTemplate";
import ProgrammingLangTemplate from "./components/ProgrammingLangTemplate";
import LanguagesTemplate from "./components/LanguagesTemplate";
import HobbiesTemplate from "./components/HobbiesTemplate";
import ProfessionalSummaryTemplate from "./components/ProfessionalSummaryTemplate";
import { useEffect } from "react";
import TextureHandler from "./components/TextureHandler";
import { EXTRACTED_DUMMY_DATA } from "../utils/dummyData";

Font.register({
  family: "Aptos",
  src: `${process.env.REACT_APP_API_URL}/Aptos.ttf`,
});

Font.register({
  family: "Aptos Bold",
  src: `${process.env.REACT_APP_API_URL}/Aptos-Bold.ttf`,
});

loadFonts();

const Template_5 = (props: any) => {
  let {
    extractedData,
    pageWidth,
    isATSPreview = false,
  }: { extractedData: any; pageWidth: any; isATSPreview: boolean } = props;
  // extractedData = EXTRACTED_DUMMY_DATA;
  const relativeFontSize = extractedData.metaInformation?.relativeFontSize || 0;
  const {
    graphColor = "#f9ddc0",
    primaryColor = "#000000",
    secondaryColor = "#4f6071",
    altBackgroundColor = "#f6f7f8",
    textureId = "",
  } = extractedData?.metaInformation || {};
  const fontFamily = extractedData.metaInformation?.fontFamily || "";

  // useEffect(() => {
  //   console.log("fontFamily:", fontFamily);

  //   loadSpecificFont(fontFamily);
  // }, [fontFamily]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      // Set the page to horizontal layout
      fontFamily,
    },
    header: {
      // backgroundColor: "#4f6071",
      height: 180,
      width: "100%",
      backgroundColor: "#f9ddc0",
      flexDirection: "row",
      alignItems: "center",
      gap: 30,
      padding: "40px 60px",
      // background:
      //   "linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    },
    headerLeft: {
      // width: "35%",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    headerRight: {
      flex: 1,
    },
    mainContainer: {
      flexDirection: "row",
      padding: 0,
    },
    rightColumn: {
      flex: 1, // Occupy half of the page width
      marginLeft: 40, // Add a margin between columns
      paddingRight: 30,
    },
    leftColumn: {
      flex: 1,
      color: secondaryColor || "rgb(78, 96, 113)",
      paddingLeft: 30,
    },
    designation: {
      fontSize: 20 + relativeFontSize,
      fontWeight: "bold",
      marginBottom: 20,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    name: {
      fontSize: 40 + relativeFontSize,
      fontWeight: "bold",
      // marginBottom: 10,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    image: {
      width: 120, // Adjust the width of the image
      height: 120, // Adjust the height of the image
      marginBottom: 10, // Add a margin between columns
      borderRadius: "100%",
    },
    jobTitle: {
      fontSize: 14 + relativeFontSize,
      color: "rgb(89, 89, 89)",
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 15,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },

    contact: {
      fontSize: 12 + relativeFontSize,
      marginTop: 5,
    },
    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
    svgContainer: {
      width: 100,
      height: 100,
    },
  });

  const nameParts = extractedData?.fullName?.split(" ");
  if (!nameParts?.length) return null;

  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");

  const styleVariables = {
    primaryColor,
    secondaryColor,
    graphColor,
    altBackgroundColor,
    fontFamily,
    relativeFontSize,
    textureId,
  };

  const SectionHeader = ({ children }: { children: any }) => (
    <Text style={styles.sectionHeader}>{children}</Text>
  );
  const NoSectionHeader = ({ children }: { children: any }) => <></>;

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, width: pageWidth }}>
        {/* <TextureHandler
          extractedData={extractedData}
          isATSPreview={isATSPreview}
          styleVariables={styleVariables}
        /> */}

        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {extractedData?.profilePicture &&
              extractedData?.profilePicture !== "NA" &&
              extractedData.profilePicture !== defaultProfilePicture && (
                <Image
                  style={styles.image}
                  src={extractedData?.profilePicture}
                />
              )}
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.name}>
              {firstName} {lastName}
            </Text>
            <ProfessionalSummaryTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={NoSectionHeader}
            />
          </View>
        </View>
        <View style={styles.mainContainer}>
          <View style={styles.leftColumn}>
            <WorkExperienceTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <EducationTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <ContactTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <ProgrammingLangTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
            />

            <LanguagesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
            />
          </View>

          <View style={styles.rightColumn}>
            <SkillsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <HobbiesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
            />

            {/* <Text style={styles.designation}>{extractedData.designation}</Text> */}

            {(extractedData?.country || extractedData?.city_state) && (
              <Text style={styles.sectionContent}>
                {extractedData?.city_state}
                {extractedData?.city_state && ","} {extractedData?.country}
              </Text>
            )}

            {/* <Text style={styles.jobTitle}>{extractedData.jobTitle}</Text> */}

            <ProjectsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <CoursesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <AwardsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <ReferencesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <VolunteerWorkTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <ExtraCATemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />

            <CustomSectionTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Template_5;
