import { ILoggedinUserData, IResume } from "../components/utils";
import {
  CLOSE_POPOVER,
  LOGOUT_USER,
  OPEN_POPOVER,
  SET_ACCESS_TOKEN,
  SET_ACTIVE_ATS_CHECK_RESULT_ID,
  SET_COLOR_DATA,
  SET_EXTRACTED_DATA,
  SET_FIXED_SECTIONS,
  SET_LOGGED_IN_USER_DATA,
  SET_PREVIEW_RESUME_SCREENSHOT,
  SET_PERSONALDETAILS_VALIDATION,
  SET_PRIOR_TOGGLE_DETAILS,
  SET_RESUME_HEIGHT,
  SET_SELECTED_TEMPLATE_ID,
  SET_SIDEDRAWER_STATE,
  UPDATE_POPOVER_VALUE,
  SET_DRAFT_STATE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SET_SAVE_IN_PROGRESS_RESUME,
  SET_ISPERSONALDETAILS_LOADING,
  SET_IS_TEMPLATE_CHANGED,
  SET_IS_FULL_NAME_CURSOR_ACTIVE,
} from "./actionTypes";
import { IPersonalDetailsValidation, IPriorToggle } from "./actions";

export interface StateProps {
  extractedData: IResume;
  color: string;
  sideDrawer: boolean;
  loggedInUserData: ILoggedinUserData;
  popover: {
    anchorEl: HTMLElement | null;
    open: boolean;
    section: string;
    sectionValue: string;
    sectionIndex: number;
  };
  fixedSections: any;
  previewResumeData: {
    resumeHeight: null;
    multiplicationFactor: number;
  };
  activeATSCheckResultID: string | null;
  PersonalDetailsValidation: IPersonalDetailsValidation;
  priorToggle: IPriorToggle;
  previewResumeScreenshot: string | null;
  isDraft: boolean;
  selectedTemplateId: string;
  snackbarState: {
    open: boolean;
    message: string;
    severity: string;
  };
  isSaveInProgressResume: boolean;
  isPersonalDetailsLoading: boolean;
  isShowAddressLocation: boolean;
  isTemplateChanged: boolean;
}

export const initialState: StateProps = {
  extractedData: {
    fullName: "",
    title: "",
    jobTitle: "",
    profilePicture: null,
    email: "",
    phone: [],
    country: "",
    city_state: "",
    location: "",
    isShowAddressLocation: true,
    socialLinks: {
      personal_Website: "",
      linkedIn_URL: "",
      github_URL: "",
      twitter_X_URL: "",
    },
    careerObjective: "",
    educationalDetails: [],
    metaInformation: {
      label: "Work Experience",
      icon: "faBriefcase",
      graphColor: "#FFC107",
      primaryColor: "#4f6071",
      secondaryColor: "#4f6071",
      altBackgroundColor: "#f6f7f8",
      relativeFontSize: 0,
      fontFamily: "",
      textureId: "",
      showContactFieldsIcon: false,
      showSocialLinksIcon: false,
      showHobbiesIcon: true,
      workExperienceLabel: "Work Experience",
      workExperienceIcon: "faBriefcase",
      contactLabel: "Contact",
      contactIcon: "faPhone",
      svgColor1: "#F78048",
      svgColor2: "#F78048",
      svgColor3: "#F78048",
      svgColor4: "#F78048",
    },
    workExperiences: [],
    projects: [],
    courses: [],
    awards: [],
    references: [],
    volunteerWorks: [],
    hobbies: [],
    languages: [],
    internships: [],
    publications: [],
    goals: "",
    keyAchievements: "",
    customSections: {},
    certifications: [],
    toolsTechnicalSkills: [],
    nonTechnicalSkills: [],
    programmingLanguages: [],
    interests: [],
    tailoredResumeObject: {
      resumeObjectWithChanges: {} as any,
      atsScore: 0,
      keywordsToAdd: [],
      missingKeywords: [],
      jobTitle: "",
      companyName: "",
    },
  } as IResume,
  color: "default",
  sideDrawer: false,
  loggedInUserData: {
    fullName: "",
    email: "",
    phoneNumber: "",
    country: "",
    password: null,
    dateOfBirth: "",
    address: "",
    stateCity: "",
    profilePic: "",
    socialLinks: "",
    activelyLookingForJobs: false,
    preferredJobTypes: "",
    workArrangement: "",
    preferredWorkLocation: "",
    salaryCurrency: "",
    fromSalary: "",
    toSalary: "",
    companySize: "",
    preferredIndustries: "",
    resumeOptimization: true,
    enableJobRecommondations: false,
    enableJobApplicationUpdates: false,
  },
  popover: {
    anchorEl: null,
    open: false,
    section: "",
    sectionValue: "",
    sectionIndex: -1,
  },
  fixedSections: {},
  previewResumeData: {
    resumeHeight: null,
    multiplicationFactor: 0.7,
  },
  previewResumeScreenshot: null,
  activeATSCheckResultID: null,
  PersonalDetailsValidation: {
    isNextAllowed: false,
    runValidation: false,
  },
  priorToggle: {
    workExpFlag: true,
    workExpList: [],
    educationFlag: true,
    educationList: [],
  },
  isDraft: true,
  selectedTemplateId: "5f1851f3-ec5d-488a-bfad-d314fff2ba24",
  snackbarState: {
    open: false,
    message: "",
    severity: "info",
  },
  isSaveInProgressResume: false,
  isPersonalDetailsLoading: false,
  isShowAddressLocation: true,
  isTemplateChanged: false,
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SIDEDRAWER_STATE:
      return {
        ...state,
        sideDrawer: action.payload,
      };
    case SET_EXTRACTED_DATA:
      return {
        ...state,
        extractedData: action.payload,
      };
    case SET_COLOR_DATA:
      return {
        ...state,
        color: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_LOGGED_IN_USER_DATA:
      return {
        ...state,
        loggedInUserData: action.payload,
      };
    case SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loggedInUserData: null,
      };
    case OPEN_POPOVER:
      return {
        ...state,
        popover: {
          anchorEl: action.payload.anchorEl,
          open: true,
          section: action.payload.section,
          sectionValue: action.payload.sectionValue,
          sectionIndex: action.payload.sectionIndex,
        },
      };
    case CLOSE_POPOVER:
      return {
        ...state,
        popover: {
          anchorEl: null,
          open: false,
          section: "",
          sectionValue: "",
          sectionIndex: null,
        },
      };
    case UPDATE_POPOVER_VALUE:
      return {
        ...state,
        popover: {
          ...state.popover,
          sectionValue: action.payload,
        },
      };
    case SET_FIXED_SECTIONS:
      return {
        ...state,
        fixedSections: action.payload,
      };
    case SET_RESUME_HEIGHT:
      return {
        ...state,
        previewResumeData: action.payload,
      };
    case SET_ACTIVE_ATS_CHECK_RESULT_ID:
      return {
        ...state,
        activeATSCheckResultID: action.payload,
      };
    case SET_PERSONALDETAILS_VALIDATION:
      return {
        ...state,
        PersonalDetailsValidation: action.payload,
      };
    case SET_PRIOR_TOGGLE_DETAILS:
      return {
        ...state,
        priorToggle: action.payload,
      };
    case SET_PREVIEW_RESUME_SCREENSHOT:
      return {
        ...state,
        previewResumeScreenshot: action.payload,
      };
    case SET_DRAFT_STATE:
      return {
        ...state,
        isDraft: action.payload,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarState: {
          open: true,
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbarState: {
          open: false,
          message: "",
          severity: "info",
        },
      };
    case SET_SAVE_IN_PROGRESS_RESUME:
      return {
        ...state,
        isSaveInProgressResume: action.payload,
      };
    case SET_ISPERSONALDETAILS_LOADING:
      return {
        ...state,
        isPersonalDetailsLoading: action.payload,
      };
    case SET_IS_TEMPLATE_CHANGED:
      return {
        ...state,
        isTemplateChanged: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
