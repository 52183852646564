import React, { useState } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import SideDrawer from "../ProcessResume/SideDrawer";
import { HandWaveIcon } from "./Icons";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  title?: React.ReactNode | string;
  sx?: SxProps<Theme>; // Optional sx prop for custom styles
  children: React.ReactNode;
  isHandSymbol?: boolean;
}
const iconLessTitlePaths = ["/settings", "/job-board"];
const Layout: React.FC<LayoutProps> = ({
  children,
  sx,
  title,
  isHandSymbol = true,
}) => {
  const location = useLocation();

  const getIcon = () => {
    return iconLessTitlePaths.find((path) =>
      location.pathname.includes(path)
    ) ? null : (
      <HandWaveIcon style={{ marginLeft: "5px" }} />
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box className="side-drawer-container">
        <SideDrawer />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {title && (
          <Box>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold", m: 2, fontSize: "24px" }}
            >
              {title} {isHandSymbol && getIcon()}
            </Typography>
          </Box>
        )}
        <Box sx={{ ...sx }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
