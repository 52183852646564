import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <svg
    width="596"
    height="842"
    viewBox="0 0 596 842"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5077_85484)">
      <rect width="596" height="842" fill="white" />
      <path
        d="M0 239.131V0H299C251.914 22.6205 151.536 91.9038 126.711 188.073C101.886 284.243 31.8933 262.182 0 239.131Z"
        fill="#F78048"
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
      <path
        d="M-20 862H113.978C117.144 845.489 114.778 805.664 79.9838 778.446C36.4909 744.424 84.4831 699.896 50.9885 666.374C17.4939 632.853 -11.0015 652.865 -20 666.374V862Z"
        fill="#85DBD2"
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
      <path
        d="M595.514 228.857V511.857C516.515 511.857 487.514 372.357 486.014 270.357C484.814 188.757 558.514 208.691 595.514 228.857Z"
        fill="#8AE7DF"
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5077_85484">
        <rect width="596" height="842" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PdfTexture = (props: any) => (
  <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
    <G clip-path="url(#clip0_5077_85484)">
      <Rect width="596" height="842" fill="white" />
      <Path
        d="M0 239.131V0H299C251.914 22.6205 151.536 91.9038 126.711 188.073C101.886 284.243 31.8933 262.182 0 239.131Z"
        fill={props?.c1 || "#F78048"}
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
      <Path
        d="M-20 862H113.978C117.144 845.489 114.778 805.664 79.9838 778.446C36.4909 744.424 84.4831 699.896 50.9885 666.374C17.4939 632.853 -11.0015 652.865 -20 666.374V862Z"
        fill={props?.c2 || "#F78048"}
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
      <Path
        d="M595.514 228.857V511.857C516.515 511.857 487.514 372.357 486.014 270.357C484.814 188.757 558.514 208.691 595.514 228.857Z"
        fill={props?.c3 || "#F78048"}
        stroke="#DFE1EA"
        stroke-width="0.5"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5077_85484">
        <Rect width="596" height="842" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

const Texture11 = {
  ViewTexture,
  PdfTexture,
};

export default Texture11;
