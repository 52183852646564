import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { defaultProfilePicture } from "../components/utils";
// import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import { loadFonts, loadSpecificFont } from "../utils/googleFontsHandler";
import WorkExperienceTemplate from "./components/WorkExperienceTemplate";
import EducationTemplate from "./components/EducationTemplate";
import ProjectsTemplate from "./components/ProjectsTemplate";
import CoursesTemplate from "./components/CoursesTemplate";
import AwardsTemplate from "./components/AwardsTemplate";
import ReferencesTemplate from "./components/ReferencesTemplate";
import VolunteerWorkTemplate from "./components/VolunteerWorkTemplate";
import ExtraCATemplate from "./components/ExtraCATemplate";
import CustomSectionTemplate from "./components/CustomSectionTemplate";
import ContactTemplate from "./components/ContactTemplate";
import SkillsTemplate from "./components/SkillsTemplate";
import ProgrammingLangTemplate from "./components/ProgrammingLangTemplate";
import LanguagesTemplate from "./components/LanguagesTemplate";
import HobbiesTemplate from "./components/HobbiesTemplate";
import ProfessionalSummaryTemplate from "./components/ProfessionalSummaryTemplate";
import { useEffect } from "react";
import TextureHandler from "./components/TextureHandler";
import { EXTRACTED_DUMMY_DATA } from "../utils/dummyData";
import { EXTRACTED_DUMMY_DATA_TEMPLATE6 } from "../utils/dummyData_template6";
import TechnicalSkillsTemplate from "./components/TechnicalSkillsTemplate";
import NonTechnicalSkillsTemplate from "./components/NonTechnicalSkillsTemplate";
import PdfFontAwesome from "../components/PdfFontAwesome/PdfFontAwesome";

Font.register({
  family: "Aptos",
  src: `${process.env.REACT_APP_API_URL}/Aptos.ttf`,
});

Font.register({
  family: "Aptos Bold",
  src: `${process.env.REACT_APP_API_URL}/Aptos-Bold.ttf`,
});

loadFonts();

const Template_7 = (props: any) => {
  let {
    extractedData,
    pageWidth,
    isATSPreview = false,
  }: { extractedData: any; pageWidth: any; isATSPreview: boolean } = props;
  // extractedData = EXTRACTED_DUMMY_DATA_TEMPLATE6;
  const relativeFontSize = extractedData.metaInformation?.relativeFontSize || 0;
  const {
    graphColor = "#66dec3",
    primaryColor = "#66dec3",
    secondaryColor = "#1a1a1a",
    altBackgroundColor = "#1a1a1a",
    textureId = "",
  } = extractedData?.metaInformation || {};
  const fontFamily = extractedData.metaInformation?.fontFamily || "";

  // useEffect(() => {
  //   console.log("fontFamily:", fontFamily);

  //   loadSpecificFont(fontFamily);
  // }, [fontFamily]);

  const styles = StyleSheet.create({
    page: {
      // flexDirection: "row",
      // Set the page to horizontal layout
      fontFamily,
    },
    leftRightContainer: {
      flexDirection: "row",
      flex: 1,
    },
    leftColumnHeader: {
      width: 200, // Occupy half of the page width
      padding: "30px 20px 20px 20px",
      backgroundColor: altBackgroundColor,
      alignItems: "center",
    },
    rightColumnHeader: {
      flex: 1,
      padding: "30px 20px 20px 20px",
    },
    leftRightContainerGeneric: {
      display: "flex",
      flexDirection: "row",
      // flex: 1,
      marginTop: "-2px",
      // alignItems: "flex-start",
    },
    leftColumnGeneric: {
      width: "200px", // Occupy half of the page width
      padding: "0px 20px 20px 20px",
      backgroundColor: altBackgroundColor,
      // alignItems: "center",

      // justifyContent: "flex-end",
    },
    leftColumnTitleContainer: {
      alignItems: "center",
      flexDirection: "row",
      gap: 5,
      justifyContent: "flex-end",
      borderTop: "1px solid #888",
    },
    rightColumnGeneric: {
      flex: 1,
      // padding: "10px 20px 20px 20px",
      paddingTop: "10px",
      paddingBottom: "20px",
      marginLeft: "20px",
      marginRight: "20px",
      borderTop: "1px solid #888",
    },
    rightColumn: {
      flex: 1, // Occupy half of the page width
      marginLeft: 10, // Add a margin between columns
      marginTop: 40,
    },
    leftColumn: {
      flex: 1,
      padding: 40,
      color: secondaryColor || "rgb(78, 96, 113)",
    },
    designation: {
      fontSize: 20 + relativeFontSize,
      fontWeight: "bold",
      marginBottom: 20,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    nameContainer: {
      paddingTop: 15,
      border: `1px solid ${primaryColor}`,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    name: {
      fontSize: 30 + relativeFontSize,
      fontWeight: "bold",
      // marginBottom: 10,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    image: {
      width: 100, // Adjust the width of the image
      height: 100, // Adjust the height of the image
      borderRadius: "100%",
      marginBottom: 10, // Add a margin between columns
    },
    jobTitle: {
      fontSize: 14 + relativeFontSize,
      color: "rgb(89, 89, 89)",
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 5,

      paddingTop: "15",
      // marginTop: 20,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },

    contact: {
      fontSize: 12 + relativeFontSize,
      marginTop: 5,
    },
    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
    svgContainer: {
      width: 100,
      height: 100,
    },
  });

  const nameParts = extractedData?.fullName?.split(" ");
  if (!nameParts?.length) return null;

  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");

  const styleVariables = {
    primaryColor,
    secondaryColor,
    graphColor,
    altBackgroundColor,
    fontFamily,
    relativeFontSize,
    textureId,
  };

  const SectionHeader = ({ children, icon }: { children: any; icon: any }) => {
    return (
      <View style={styles.leftColumnGeneric}>
        <View style={styles.leftColumnTitleContainer}>
          {icon && (
            <PdfFontAwesome
              faIcon={icon}
              style={{ color: primaryColor, width: "20px" }}
            />
          )}

          <Text style={styles.sectionHeader}>{children}</Text>
        </View>
      </View>
    );
  };

  const SectionBody = ({ children }: { children: any }) => (
    <View style={styles.rightColumnGeneric}>{children}</View>
  );

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, width: pageWidth }}>
        <TextureHandler
          extractedData={extractedData}
          isATSPreview={isATSPreview}
          styleVariables={styleVariables}
        />

        <View style={styles.leftRightContainer}>
          <View style={styles.leftColumnHeader}>
            {extractedData?.profilePicture &&
              extractedData?.profilePicture !== "NA" &&
              extractedData.profilePicture !== defaultProfilePicture && (
                <Image
                  style={styles.image}
                  src={extractedData?.profilePicture}
                />
              )}
          </View>

          <View style={styles.rightColumnHeader}>
            <Text style={styles.name}>
              {firstName} {lastName}
            </Text>
            <Text style={styles.jobTitle}>{extractedData.jobTitle}</Text>
            <ContactTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={() => <></>}
            />
            {(extractedData?.country || extractedData?.city_state) && (
              <Text style={styles.sectionContent}>
                {extractedData?.city_state}
                {extractedData?.city_state && ","} {extractedData?.country}
              </Text>
            )}
          </View>
        </View>

        <View>
          <View style={styles.leftRightContainerGeneric}>
            <ProfessionalSummaryTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <WorkExperienceTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <EducationTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <ProgrammingLangTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <LanguagesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <TechnicalSkillsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <NonTechnicalSkillsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <HobbiesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <ProjectsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <View style={styles.leftRightContainerGeneric}>
            <CoursesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>
          <View style={styles.leftRightContainerGeneric}>
            <AwardsTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>
          <View style={styles.leftRightContainerGeneric}>
            <ReferencesTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>
          <View style={styles.leftRightContainerGeneric}>
            <VolunteerWorkTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>
          <View style={styles.leftRightContainerGeneric}>
            <ExtraCATemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
              SectionBody={SectionBody}
            />
          </View>

          <CustomSectionTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />
        </View>
      </Page>
    </Document>
  );
};

export default Template_7;
