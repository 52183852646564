import { Box, Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { InputFieldCustomeStyle } from "../ProcessResume/EditForms/ProcessResumeUtils";

const Section5 = () => {
  return (
    <Box className="section-5-wrapper">
      <Box className="section-5-content">
        <Typography
          component="h3"
          variant="h4"
          className="heading"
          gutterBottom
        >
          Still thinking about it?
        </Typography>
        <Typography className="sub-heading" gutterBottom>
          Stay ahead with our newsletter featuring exclussive product updates
          and expert tips. Sign up today!
        </Typography>
        <Box className="form-wrapper">
          <TextField
            required
            fullWidth
            name="email"
            type="email"
            id="email"
            placeholder="Enter your email"
            sx={{ ...InputFieldCustomeStyle, width: "22rem" }}
            inputProps={{
              sx: {
                height: "1rem",
              },
            }}
          />
          <Button variant="contained" className="subscribe-button">
            Subscribe
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Section5;
