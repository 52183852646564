import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5077_85612)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M77.4537 708.481C90.8484 710.826 101.482 721.389 110.622 731.459C119.1 740.8 123.627 752.74 128.27 764.47C132.528 775.231 137.037 786.151 136.846 797.722C136.661 808.918 128.987 818.461 127.197 829.514C125.034 842.865 132.801 858.406 125.204 869.597C117.899 880.358 102.476 882.269 89.706 884.739C78.0475 886.994 66.2278 885.146 54.5024 883.27C43.8179 881.56 33.8607 877.926 23.7097 874.179C12.9339 870.201 1.53125 867.541 -7.46232 860.396C-17.0715 852.761 -24.456 842.689 -29.8188 831.65C-35.5745 819.802 -41.173 806.958 -39.7863 793.859C-38.3977 780.742 -31.2269 768.476 -22.1208 758.933C-13.5083 749.907 0.00709534 748.151 10.4053 741.258C20.4829 734.577 27.5321 724.124 38.3947 718.814C50.6275 712.833 64.0414 706.133 77.4537 708.481Z"
          fill="#F49B97"
        />
        <path
          d="M572.827 -28.2871C587.53 -26.3481 599.745 -45.2216 614.374 -42.7856C627.699 -40.5666 642.671 -29.7346 644.506 -16.3509C646.689 -0.422642 629.098 11.4597 623.896 26.6723C621.064 34.9563 619.605 43.8366 620.969 52.4847C623.429 68.0817 641.043 82.5871 635.016 97.1813C629.942 109.468 610.605 107.158 597.491 109.334C586.356 111.18 575.286 108.665 564.003 108.96C552.802 109.253 541.596 114.16 530.823 111.078C519.86 107.942 510.213 100.539 503.182 91.5612C496.335 82.8173 493.885 71.6054 491.431 60.774C489.15 50.7063 490.78 40.4331 489.285 30.219C487.413 17.4275 478.944 5.34465 481.465 -7.33487C484.017 -20.1689 492.827 -31.5038 503.156 -39.5379C513.482 -47.5706 526.784 -54.7193 539.7 -52.6337C553.378 -50.4248 559.091 -30.0987 572.827 -28.2871Z"
          fill="#F9B840"
        />
      </g>
      <defs>
        <clipPath id="clip0_5077_85612">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5077_85612)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M77.4537 708.481C90.8484 710.826 101.482 721.389 110.622 731.459C119.1 740.8 123.627 752.74 128.27 764.47C132.528 775.231 137.037 786.151 136.846 797.722C136.661 808.918 128.987 818.461 127.197 829.514C125.034 842.865 132.801 858.406 125.204 869.597C117.899 880.358 102.476 882.269 89.706 884.739C78.0475 886.994 66.2278 885.146 54.5024 883.27C43.8179 881.56 33.8607 877.926 23.7097 874.179C12.9339 870.201 1.53125 867.541 -7.46232 860.396C-17.0715 852.761 -24.456 842.689 -29.8188 831.65C-35.5745 819.802 -41.173 806.958 -39.7863 793.859C-38.3977 780.742 -31.2269 768.476 -22.1208 758.933C-13.5083 749.907 0.00709534 748.151 10.4053 741.258C20.4829 734.577 27.5321 724.124 38.3947 718.814C50.6275 712.833 64.0414 706.133 77.4537 708.481Z"
          fill={props?.c1 || "#F49B97"}
        />
        <Path
          d="M572.827 -28.2871C587.53 -26.3481 599.745 -45.2216 614.374 -42.7856C627.699 -40.5666 642.671 -29.7346 644.506 -16.3509C646.689 -0.422642 629.098 11.4597 623.896 26.6723C621.064 34.9563 619.605 43.8366 620.969 52.4847C623.429 68.0817 641.043 82.5871 635.016 97.1813C629.942 109.468 610.605 107.158 597.491 109.334C586.356 111.18 575.286 108.665 564.003 108.96C552.802 109.253 541.596 114.16 530.823 111.078C519.86 107.942 510.213 100.539 503.182 91.5612C496.335 82.8173 493.885 71.6054 491.431 60.774C489.15 50.7063 490.78 40.4331 489.285 30.219C487.413 17.4275 478.944 5.34465 481.465 -7.33487C484.017 -20.1689 492.827 -31.5038 503.156 -39.5379C513.482 -47.5706 526.784 -54.7193 539.7 -52.6337C553.378 -50.4248 559.091 -30.0987 572.827 -28.2871Z"
          fill={props?.c2 || "#F9B840"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5077_85612">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture19 = {
  ViewTexture,
  PdfTexture,
};

export default Texture19;
