import {
  Avatar,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";

import { ReactComponent as DatePickerIcon } from "../../../assets/SVG/date-picker.svg";
import { AuthInputFieldStyle } from "../../../ProcessResume/EditForms/ProcessResumeUtils";
import { countries } from "../../helpers/countries";
import { SecondaryButton } from "../../button/Buttons";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import { responseHandler } from "../../../utils/responseHandler";
import { tecknoApiBaseUrl } from "../../../utils/config";
import { generateInitials } from "../../../utils/commonFunctions";
import ZoomProfilePictureModal from "../../CommonComponents/ZoomProfilePictureModal";
import { StateProps } from "../../../redux/reducer";
import { setExtractedData, setLoggedInUserData } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { base64ToFile } from "../../utils";
import isToday from "date-fns/isToday";
import { StyledBadge } from "../../../ProcessResume/EditForms/WorkExperience/WorkExperienceEdit";
import CustomizedAlert from "../../CustomizedAlert";
import { SnackBarState } from "../../auth/Login";

interface IProfileFormValues {
  fullName: string;
  email: string;
  dob: string;
  country: string;
  phone: string;
  state: string;
  address: string;
  profilePicFile?: string;
  profilePicUrl?: string;
}

const PersonalDetails = () => {
  const { loggedInUserData, extractedData } = useSelector(
    (state: StateProps) => state
  );
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState<SnackBarState>({
    open: false,
    message: "",
    severity: "success",
  });

  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [showEditProfilePicModal, setShowEditProfilePicModal] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<IProfileFormValues>({
    defaultValues: {
      fullName: "",
      email: "",
      dob: "",
      country: "",
      phone: "",
      state: "",
      address: "",
      profilePicUrl: "",
    },
  });

  const formValues = watch();

  const handleProfilePicUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            dispatch(
              setExtractedData({
                ...extractedData,
                profilePicture: reader.result.toString(),
              })
            );
            /**
             * Below dispatch is to update recent uploded image to the side drawer bottom Avatar
             */
            dispatch(
              setLoggedInUserData({
                ...loggedInUserData,
                profilePic: reader.result.toString(),
              })
            );
            setValue("profilePicFile", reader.result.toString());
          } else {
            console.error("Failed to read file");
          }
        };

        reader.readAsDataURL(file);
        setShowEditProfilePicModal(true);
      }
    }
  };

  const onSubmit = async (data: IProfileFormValues) => {
    try {
      setLoading(true);

      const body = new FormData();
      body.append("FullName", data.fullName);
      body.append("PhoneNumber", data.phone);
      body.append("Country", data.country);
      body.append("DateOfBirth", data.dob);
      body.append("Address", data.address);
      body.append("StateCity", data.state);

      if (extractedData.profilePicture) {
        body.append(
          "ProfilePictureFile",
          base64ToFile(extractedData.profilePicture, "profilePic")
        );
      }
      //API call to update the user
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user`,
        {
          method: "PUT",
          body,
        },
        false
      );

      if (response.ok) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Profile updated successfully",
        });

        dispatch(
          setLoggedInUserData({
            ...loggedInUserData,
            fullName: data.fullName,
            email: data.email,
            phoneNumber: data.phone,
            country: data.country,
            dateOfBirth: data.dob,
            address: data.address,
            stateCity: data.state,
          })
        );
      } else {
        responseHandler(response);
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Fialed to updated Profile",
      });
      console.error(
        "An unexpected error occurred while saving the data:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("fullName", loggedInUserData.fullName);
    setValue("email", loggedInUserData.email);
    setValue("country", loggedInUserData.country);
    setValue("phone", loggedInUserData.phoneNumber);
    setValue(
      "dob",
      loggedInUserData.dateOfBirth
        ? new Date(loggedInUserData.dateOfBirth).toISOString()
        : ""
    );
    setValue("state", loggedInUserData.stateCity);
    setValue("address", loggedInUserData.address);
    setValue("profilePicUrl", loggedInUserData.profilePic);
  }, [loggedInUserData]);

  useEffect(() => {
    return () => {
      dispatch(setExtractedData({ ...extractedData, profilePicture: "" }));
    };
  }, []);

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${month}-${day}-${year}`;
    setValue("dob", formattedDate);
  };

  const handleCloseSnackbar = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2} sx={{ mt: 0.5, maxWidth: "870px" }}>
        <Grid item container sm={8} columnSpacing={2} rowSpacing={0.8}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="fullName"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Full Name
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="fullName"
              placeholder="John Doe"
              {...register("fullName", {
                setValueAs: (value: string) => value.trim(),
                required: "Name is required",
              })}
              margin="normal"
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.fullName?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="email"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Email
            </Typography>
            <TextField
              fullWidth
              disabled
              id="email"
              placeholder="johndoe@microsoft.com"
              margin="normal"
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              {...register("email", {
                setValueAs: (value: string) => value.trim(),
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email",
                },
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.email?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="phone"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Phone
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="phone"
              placeholder="Eg: +1 (465) 775 7754"
              margin="normal"
              {...register("phone", {
                setValueAs: (value: string) => value.trim(),
                required: "Phone is required",
                pattern: {
                  value: /^\+?\d{10,13}$/,
                  message: "Invalid phone number",
                },
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.phone?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="dateOfBirth"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Date of Birth
            </Typography>

            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{ width: "100%" }}>
                  <DatePicker
                    sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
                    value={formValues.dob ? new Date(formValues.dob) : null}
                    onChange={handleStartDateChange}
                    slots={{
                      openPickerIcon: DatePickerIcon,
                    }}
                    slotProps={{
                      day: ({ day, selected, ...props }) => {
                        const isTodayDate = isToday(day); // Check if the day is today's date
                        return (
                          <div {...props}>
                            {isTodayDate ? (
                              <StyledBadge badgeContent="" overlap="circular">
                                {day.getDate()}
                              </StyledBadge>
                            ) : (
                              day.getDate()
                            )}
                          </div>
                        );
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="stateCity"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              State/City
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="stateCity"
              margin="normal"
              {...register("state", {
                required: "State/City is required",
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.state?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="country"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Country
            </Typography>

            <Box>
              <Select
                sx={{
                  mt: 0.5,
                  width: "100%",
                  ".MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                value={formValues.country}
                {...register("country", {
                  required: "Country is required",
                })}
                MenuProps={{
                  sx: {
                    maxHeight: "400px",
                    width: "100%",
                    "&& .MuiMenuItem-root:hover": {
                      backgroundColor: "#FAFAFA",
                    },
                  },
                }}
              >
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.label}>
                    <img
                      src={country.flag}
                      width="20"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <span>{country.label}</span>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.country?.message}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="address"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Address
            </Typography>
            <TextField
              sx={{
                ...AuthInputFieldStyle,
                mt: 0.5,
                textbox: {
                  padding: 0,
                },
              }}
              fullWidth
              id="address"
              placeholder="Flat no 205, 2nd Floor, Homeland Apartments, 7th Street, 110041 - Nangloi Chowk."
              margin="normal"
              multiline
              rows={2}
              {...register("address")}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            flexGrow: 1,
            position: "relative",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            {formValues.profilePicUrl || extractedData.profilePicture ? (
              <img
                src={extractedData.profilePicture || formValues.profilePicUrl}
                alt=""
                width="120"
                height="120"
              />
            ) : (
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  bgcolor: "#C2D6FF",
                  boxShadow: "0px -8px 16px 0px rgba(55, 93, 251, 0.24) inset",
                  color: "#162664",
                  fontSize: "2.5em",
                }}
              >
                {formValues.fullName
                  ? generateInitials(formValues.fullName)
                  : ""}
              </Avatar>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              mt={1}
              sx={{ fontWeight: "bold", cursor: "pointer", color: "#5594FF" }}
              onClick={() => fileInputRef.current?.click()}
            >
              {/* {formValues.profilePicFile
                ? formValues.profilePicFile.name
                : "Change Photo"} */}
              Change Photo
            </Typography>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleProfilePicUpload}
            />
            <Typography variant="caption" color="textSecondary" align="center">
              <span style={{ whiteSpace: "nowrap" }}>Min Size: 400x400px</span>
              <br />
              <span style={{ whiteSpace: "nowrap" }}>
                File Types: PNG or JPEG
              </span>
            </Typography>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              ml: 10,
              position: "absolute",
              bottom: -100,
            }}
          >
            <SecondaryButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              // disabled={!Object.keys(dirtyFields).length}
            >
              Save
            </SecondaryButton>
          </Box>

          {showEditProfilePicModal && extractedData?.profilePicture && (
            <ZoomProfilePictureModal
              pictureModal={showEditProfilePicModal}
              setPictureModal={setShowEditProfilePicModal}
            />
          )}
        </Box>

        <CustomizedAlert
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          alertState={snackBar.open}
          title={
            snackBar.severity === "error"
              ? "Error"
              : snackBar.severity === "success"
              ? "Success"
              : "Warning"
          }
          description={snackBar.message}
          severity={snackBar.severity}
          handleCloseSnackbar={handleCloseSnackbar}
        />
      </Grid>
    </Box>
  );
};

export default PersonalDetails;
