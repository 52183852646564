import { Box, List } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { setColorData } from "../redux/actions";

const ColorPickerAlt = ({
  color,
  onChange,
}: {
  color: string;
  onChange: any;
}) => {
  const handleColorClick = (colorValue: string) => {
    onChange(colorValue);
  };

  return (
    <Box className="template-color-picker-container">
      <Box className="template-color-picker-wrapper">
        <List className="template-color-picker-ul">
          {[
            "rgba(255,255,255,1)",
            "rgba(52,57,61,1)",
            "rgba(175,155,148,1)",
            "rgba(20,65,129,1)",
            "rgba(69,132,221,1)",
            "rgba(0,164,193,1)",
            "rgba(44,128,110,1)",
            "rgba(245,146,30,1)",
            "rgba(203,69,78,1)",
            "rgba(128,0,0,1)",
          ].map((colorValue) => (
            <li className="list-item-color-0">
              <button
                onClick={() => handleColorClick(colorValue)}
                style={{
                  backgroundColor: colorValue,
                }}
              >
                {colorValue === color && <TiTick color="silver" size={18} />}
              </button>
            </li>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ColorPickerAlt;
