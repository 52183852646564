import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { TPublications } from "../../../../components/utils";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import CardEditHeader from "../../../../components/common/CardEditHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { usePickerPopperOffset } from "../../../../components/Hooks/usePickerPopperOffeset";
import classNames from "classnames";
import { ReactComponent as DatePickerIcon } from "../../../../assets/SVG/date-picker.svg";
import ReactQuill from "react-quill";
import { quillModules } from "../../../../App";

interface CourseProps {
  courseData: TPublications;
  coursesList: TPublications[];
  setCoursesList: Dispatch<SetStateAction<TPublications[]>>;
  index: number;
  sectionName: string;
  handleDeleteEmptySection: (sectionName: string) => void;
}

const PublicationEdit: React.FC<CourseProps> = ({
  courseData,
  coursesList,
  setCoursesList,
  index,
  sectionName,
  handleDeleteEmptySection,
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  //formData handles local form state
  const [formData, setFormData] = useState<TPublications>(courseData);
  const [errors, setErrors] = useState({
    publicationTitle: "",
    publicationDate: "",
    publisher: "",
  });
  /**
   * Date Piciker popper position
   */
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const startDateOffset = usePickerPopperOffset(startDateRef);
  const endDateOffset = usePickerPopperOffset(endDateRef);

  const handleChange = (newValue: any, key: string) => {
    // if(key === "courseDescription") {
    //     const wordLimit = 50
    //     const wordCount = newValue.trim().split(/\s+/).filter(Boolean).length;
    //     if(wordCount<=wordLimit){
    //         setFormData({
    //             ...formData,
    //             [key]: newValue,
    //           });
    //     }
    // }
    // else {
    setFormData({
      ...formData,
      [key]: newValue,
    });
    //If already showing error for the field to clear that
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
  };
  // };

  const handleEndDateError = (error: any) => {
    if (error) {
      setErrors((prev) => ({ ...prev, endDate: "Invalid Date" }));
    } else {
      setErrors((prev) => ({ ...prev, endDate: "" }));
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue === null) return;
    const date = new Date(newValue);
    let year = date.getFullYear();
    if (year < 100) {
      year += 2000;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}-${day}-${year}`;
    handleChange(formattedDate, "publicationDate");
  };

  const handleCancel = () => {
    const updatedCourses = !!formData?.isNewEntry
      ? coursesList.slice(0, -1)
      : coursesList;
    if (!!formData?.isNewEntry) {
      setCoursesList(updatedCourses);
    } else {
      setCoursesList((prev) =>
        prev.map((courEle) => {
          if (courEle?.publicationTitle === courseData.publicationTitle) {
            return { ...courEle, isEdit: false };
          }
          return courEle;
        })
      );
    }

    //  Below Method remove +Add More when there is no element present
    if (!updatedCourses?.[0]) {
      handleDeleteEmptySection(sectionName);
    }
  };

  const handleSave = () => {
    if (!formData?.publicationTitle) {
      setErrors((prev) => ({
        ...prev,
        publicationTitle: "Publication Title is required",
      }));
    }
    if (!formData?.publicationDate) {
      setErrors((prev) => ({
        ...prev,
        publicationDate: "Publication Date is required",
      }));
    }
    if (!formData?.publisher) {
      setErrors((prev) => ({
        ...prev,
        publisher: "Publisher is required",
      }));
    }
    // if(!isValidUrl(addLink,formData?.link ?? "")) {
    //     setErrors((prev)=>({
    //         ...prev,
    //         url:"Invalid URL"
    //       }))
    //   }
    if (
      formData?.publicationTitle &&
      formData?.publicationDate &&
      formData?.publisher
    ) {
      //checking if link is present then It must contain the valid url
      // if(!isValidUrl(addLink,formData?.link ?? "")) {
      //   setErrors((prev)=>({
      //       ...prev,
      //       url:"Invalid URL"
      //     }))
      // }
      // else {
      //perform new course entry
      if (formData?.isNewEntry) {
        /**
         * New Entry
         */
        delete formData?.isNewEntry;
        delete formData?.index;
        dispatch(
          setExtractedData({
            ...extractedData,
            publications: [
              ...(extractedData.publications ?? []),
              { ...formData, isEdit: false },
            ],
          })
        );
      } else {
        const updatedCourses = coursesList?.map((course) => {
          if (course?.index === formData?.index) {
            delete formData?.index;
            return { ...formData, isEdit: false };
          }
          delete course?.index;
          return course;
        });
        dispatch(
          setExtractedData({
            ...extractedData,
            publications: updatedCourses,
          })
        );
      }
      //both cases remove error
      setErrors({
        publicationTitle: "",
        publicationDate: "",
        publisher: "",
      });
      // }
    }
  };

  return (
    <Box className="section-item-container">
      <Box
        className="courses-edit-section common-edit-section"
        sx={{
          border:
            index === 0 && courseData?.isNewEntry
              ? "none !important"
              : "inherit",
          margin:
            index === 0 && courseData?.isNewEntry
              ? "0 0 1rem 0 !important"
              : "1rem 0",
        }}
      >
        {!courseData?.isNewEntry && (
          <CardEditHeader
            details={{
              heading: courseData?.publicationTitle,
            }}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        <Box className="input-box-container">
          <InputLabel htmlFor="company">Publishers*</InputLabel>
          <TextField
            fullWidth
            id="location"
            name="location"
            placeholder="Eg: Publisher Name"
            value={formData?.publisher}
            onChange={(e) => handleChange(e.target.value, "publisher")}
            error={!!errors.publisher}
            helperText={errors.publisher ? errors.publisher : ""}
          />
        </Box>

        <Box className="input-box-container">
          <InputLabel htmlFor="company">Title*</InputLabel>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Eg: Paper Name"
            value={formData?.publicationTitle}
            onChange={(e) => handleChange(e.target.value, "publicationTitle")}
            error={!!errors.publicationTitle}
            helperText={errors.publicationTitle ? errors.publicationTitle : ""}
          />
        </Box>

        <Box className="courses-dates-container">
          <Box className="input-box-container" ref={endDateRef}>
            <InputLabel htmlFor="endDate">Publication Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                error={!!errors.publicationDate}
                className={classNames(
                  { "date-error": !!errors.publicationDate },
                  "date-form-control"
                )}
              >
                <DatePicker
                  value={
                    formData?.publicationDate
                      ? new Date(formData.publicationDate)
                      : null
                  }
                  onChange={handleEndDateChange}
                  onError={handleEndDateError}
                  // minDate={formData?.startDate ? new Date(formData?.startDate) : undefined}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: endDateOffset, // Adjust the offset as needed
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            mainAxis: true, // Adjust on the main axis (up/down)
                            altAxis: false,
                            boundariesElement: "viewport",
                            tether: false,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: [
                              "bottom-end",
                              "top-end",
                              "top-start",
                            ], // Position at bottom-end
                            flipVariations: true,
                          },
                        },
                      ],
                    },
                  }}
                />
                {errors?.publicationDate && (
                  <FormHelperText>{errors?.publicationDate}</FormHelperText>
                )}
              </FormControl>
            </LocalizationProvider>
          </Box>

          <Box className="input-box-container">
            <InputLabel htmlFor="company">URL</InputLabel>
            <TextField
              fullWidth
              id="location"
              name="location"
              placeholder="Eg: Publisher Name"
              value={formData?.url}
              onChange={(e) => handleChange(e.target.value, "url")}
              // error={!!errors.publisher}
              // helperText={errors.publisher ? errors.publisher : ''}
            />
          </Box>
        </Box>

        <Box className="input-box-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <InputLabel htmlFor="address">Description</InputLabel>
          </Box>
          <Box className="quill-container">
            <ReactQuill
              value={formData?.description}
              onChange={(e: any) => handleChange(e, "description")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>

        <Box className="section-action">
          <Box className="link-container">
            {/* {!addLink && <Typography className="add-link-text" onClick={()=>setAddLink(true)}>+ Add Link</Typography>} */}
          </Box>
          {
            <Box className="button-container">
              {/* courseData?.isNewEntry && */}
              <Button
                className="cancel-button"
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="add-button"
                variant="contained"
                size="medium"
                onClick={handleSave}
              >
                Add
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default PublicationEdit;
