import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5029_15957)">
        <rect width="596" height="842" fill="white" />
        <path
          opacity="0.5"
          d="M164.25 77H134.75L120 102L134.75 127H164.25L179 102L164.25 77Z"
          fill="#C6D9DF"
        />
        <path
          d="M51.7155 -12H-6.76151L-36 39L-6.76151 90H51.7155L81 39L51.7155 -12Z"
          fill="#C6D9DF"
        />
        <path
          d="M601.765 742H531.235L496 803.531L531.235 865H601.765L637 803.531L601.765 742Z"
          fill="#C6D9DF"
        />
        <path
          opacity="0.5"
          d="M30.4631 786H-2.53691L-19 814L-2.53691 842H30.4631L47 814L30.4631 786Z"
          fill="#C6D9DF"
        />
        <path
          d="M599.773 -16H517.273L476 55.5226L517.273 127H599.773L641 55.5226L599.773 -16Z"
          fill="#C6D9DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5029_15957">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5029_15957)">
        <Rect width="596" height="842" fill="white" />
        <Path
          opacity="0.5"
          d="M164.25 77H134.75L120 102L134.75 127H164.25L179 102L164.25 77Z"
          fill={props?.c1 || "#C6D9DF"}
        />
        <Path
          d="M51.7155 -12H-6.76151L-36 39L-6.76151 90H51.7155L81 39L51.7155 -12Z"
          fill={props?.c1 || "#C6D9DF"}
        />
        <Path
          d="M601.765 742H531.235L496 803.531L531.235 865H601.765L637 803.531L601.765 742Z"
          fill={props?.c1 || "#C6D9DF"}
        />
        <Path
          opacity="0.5"
          d="M30.4631 786H-2.53691L-19 814L-2.53691 842H30.4631L47 814L30.4631 786Z"
          fill={props?.c1 || "#C6D9DF"}
        />
        <Path
          d="M599.773 -16H517.273L476 55.5226L517.273 127H599.773L641 55.5226L599.773 -16Z"
          fill={props?.c1 || "#C6D9DF"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5029_15957">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture10 = {
  ViewTexture,
  PdfTexture,
};

export default Texture10;
