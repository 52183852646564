import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="596" height="842" fill="white" />
      <rect
        x="436.049"
        y="367.986"
        width="233.469"
        height="449.826"
        rx="110"
        transform="rotate(53.1701 436.049 367.986)"
        fill="#8AE9FB"
      />
      <rect
        x="280"
        y="134.859"
        width="220"
        height="420"
        rx="110"
        fill="#32EF96"
      />
      <rect
        x="164.994"
        y="17"
        width="220"
        height="469.778"
        rx="110"
        transform="rotate(17.9776 164.994 17)"
        fill="#FF90C3"
      />
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <Rect width="596" height="842" fill="white" />
      <Rect
        x="436.049"
        y="367.986"
        width="233.469"
        height="449.826"
        rx="110"
        ry="110"
        transform="rotate(53.1701 436.049 367.986)"
        fill={props?.c1 || "#8AE9FB"}
      />
      <Rect
        x="280"
        y="134.859"
        width="220"
        height="420"
        rx="110"
        ry="110"
        fill={props?.c2 || "#32EF96"}
      />
      <Rect
        x="164.994"
        y="17"
        width="220"
        height="469.778"
        rx="110"
        ry="110"
        transform="rotate(17.9776 164.994 17)"
        fill={props?.c3 || "#FF90C3"}
      />
    </Svg>
  </>
);

const Texture27 = {
  ViewTexture,
  PdfTexture,
};

export default Texture27;
