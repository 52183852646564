import React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

interface SecondaryButtonProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const SecondaryButton = styled(
  ({ loading, children, ...props }: SecondaryButtonProps & ButtonProps) => (
    <Button {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {loading && (
          <div
            style={{
              width: "18px",
              height: "18px",
              marginRight: "8px",
            }}
          >
            <CircularProgress size={18} color="inherit" />
          </div>
        )}
        <span>{children}</span>
      </div>
    </Button>
  ),
)(({ theme }) => ({
  padding: "8px 16px",
  backgroundColor: "#5598FF",
  color: "#fff",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
