import { Box, Dialog, DialogTitle, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IoMdClose } from "react-icons/io";

import { ReactComponent as SubmittedIllustration } from "../../../assets/SVG/support-ticket-submitted-illustration.svg";
import { on } from "events";

export interface SimpleDialogProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const SubmittedDialog = (props: SimpleDialogProps) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ ".MuiPaper-root": { borderRadius: "12px" } }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>Help & Support</span>
          <IoMdClose
            size="1.5rem"
            style={{ cursor: "pointer" }}
            onClick={() => onClose(false)}
          />
        </Box>
      </DialogTitle>
      <Box
        sx={{
          padding: "1rem",
          width: "500px",
          height: "350px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <SubmittedIllustration />
        <Typography variant="h6">
          Your support ticket has been submitted
        </Typography>
        <Typography variant="body1">
          We will get back to you soon on your registered email.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default SubmittedDialog;
