import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const ProgrammingLangTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
    languages: {
      fontSize: 12 + relativeFontSize,
      marginBottom: 10,
    },
  });

  return (
    <>
      {extractedData.programmingLanguages.length > 0 && (
        // <View
        //   style={
        //     isATSPreview
        //       ? { ...styles.atsIssueLow, marginTop: 20 }
        //       : { marginTop: 20 }
        //   }
        // >
        <>
          {SectionHeader ? (
            <SectionHeader>LANGUAGES</SectionHeader>
          ) : (
            <Text style={styles.sectionHeader}>LANGUAGES</Text>
          )}
          <BodyContainer>
            {isATSPreview && (
              <View style={styles.fixATSButton}>
                <Image
                  src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                  style={styles.fixATSButtonImg}
                />
                <View
                  style={{
                    ...styles.fixATSButtonImg,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <Text style={{ fontSize: "0.1px", color: "transparent" }}>
                    ATS_ISSUE-LANGUAGES
                  </Text>
                </View>
              </View>
            )}
            {extractedData.programmingLanguages &&
              extractedData.programmingLanguages.map(
                (language: string, index: number) => (
                  <Text key={index} style={styles.languages}>
                    • {language}
                  </Text>
                )
              )}
          </BodyContainer>
          {/* </View> */}
        </>
      )}
    </>
  );
};

export default ProgrammingLangTemplate;
