import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Chip,
  Tooltip,
} from "@mui/material";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import {
  avatarColors,
  IFetchedJobDetails,
  IResume,
  ITailoredResume,
} from "../utils";
import { Search, Close } from "@mui/icons-material";
import Lottie from "react-lottie";
import * as ATSCheckScreen from "./../../assets/lottie/ATSCheckScreen.json";
import { ReactComponent as UploadingIcon } from "./../../assets/SVG/uploading-icon.svg";
import { ReactComponent as UploadedResumeIcon } from "../../assets/SVG/uploaded-resume-icon.svg";
import { FetchJobDescriptionIcon } from "../Icons";
import { useNavigate } from "react-router-dom";
import {
  setActiveATSCheckResultID,
  setExtractedData,
  setFixedSections,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../auth/storeToken";
import {
  extractJobDetails,
  jobDescriptionATSChecker,
  tailorResume,
  withAuthHandling,
} from "../../services/apiService";
import {
  JobTitleIcon,
  ExperienceIcon,
  CompanyIcon,
  JobTypeIcon,
  LocationIcon,
  SalaryIcon,
} from "./ATSIcons";
import { skillsData } from "../../Data/skillsData";
import { StateProps } from "../../redux/reducer";

interface ProcessingPopupProps {
  open: boolean;
  onClose: () => void;
  popupDetails: {
    title: string;
    subTitle: string;
    progress: number;
    page: number;
    resumeObject: IResume;
  };
  setPopupDetails: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      title: string;
      subTitle: string;
      progress: number;
      page: number;
      resumeObject: IResume;
    }>
  >;
  isATSChecker?: boolean;
}

interface JobDetailCardProps {
  title: string;
  content: string | string[];
  icon?: React.ReactNode;
}

const JobDetailCard: React.FC<JobDetailCardProps> = ({
  title,
  content,
  icon,
}) => {
  return (
    <Card
      sx={{
        boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "stretch" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1.5,
          }}
        >
          {icon}
        </Box>
        <CardContent
          sx={{ px: 1.4, flex: 1, "&:last-child": { paddingBottom: 0 } }}
        >
          <Typography
            variant="subtitle2"
            color={
              title === "Job Description" || title === "Required Skills"
                ? "#242424"
                : "textSecondary"
            }
            sx={{
              fontWeight:
                title === "Job Description" || title === "Required Skills"
                  ? "normal"
                  : "bold",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight:
                title === "Job Description" || title === "Required Skills"
                  ? "normal"
                  : "bold",
              fontSize: "16px",
            }}
          >
            {Array.isArray(content)
              ? content.join(", ")
              : content !== null
              ? content
              : "N/A"}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

const ProcessingPopup: React.FC<ProcessingPopupProps> = ({
  open,
  onClose,
  popupDetails,
  setPopupDetails,
  isATSChecker,
}) => {
  const [formDetails, setFormDetails] = useState({
    jobUrl: "",
    fetchedJobDetails: {} as any,
    jobDescription: "",
    tailoredResumeObject: {} as ITailoredResume,
    loading: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchWithAuthHandling = withAuthHandling(navigate);

  const extractedData = useSelector(
    (state: StateProps) => state?.extractedData
  );

  const handleFetch = async () => {
    try {
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle:
          "We are fetching the job details we will show preview here once its done.",
        progress: 20,
        loading: true,
      }));
      setFormDetails((prevState) => ({ ...prevState, loading: true }));

      const jobData = await extractJobDetails(formDetails.jobUrl);

      setFormDetails((prevState) => ({
        ...prevState,
        fetchedJobDetails: jobData.result,
        loading: false,
      }));
    } catch (error) {
      console.error("Error extracting job details:", error);
    } finally {
      setFormDetails((prevState) => ({ ...prevState, loading: false }));
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle: "",
        progress: 100,
        loading: false,
      }));
    }
  };

  const handleATSChecker = async () => {
    try {
      // if(formDetails.jobUrl !== "" && Object.keys(formDetails.fetchedJobDetails).length === 0) {
      //   handleFetch();
      // }
      setPopupDetails((prevState) => ({ ...prevState, page: 3 }));

      if (isATSChecker) {
        const data = await jobDescriptionATSChecker(
          formDetails.jobDescription !== "" &&
            Object.keys(formDetails.fetchedJobDetails).length === 0
            ? formDetails.jobDescription
            : formDetails.fetchedJobDetails,
          popupDetails.resumeObject
        );

        setFormDetails((prevState) => ({
          ...prevState,
          fetchedJobDetails: formDetails.fetchedJobDetails,
          tailoredResumeObject: data,
        }));

        // dispatch(setExtractedData(popupDetails.resumeObject));
        // const savedATSResult = await fetch("https://testapi.tekno.ai/api/add-ats-checker",{
        //   method: 'POST',
        //   headers: {
        //       'accept': '*/*',
        //       'Authorization': 'Bearer ' + getToken(),
        //       'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     tailoredResumeObject: {
        //       atsScore: data.tailoredResumeObject.atsScore,
        //       resumeObjectWithChanges: {
        //         professionalSummary: data.tailoredResumeObject.resumeObjectWithChanges.professionalSummary,
        //         jobTitle: data.tailoredResumeObject.resumeObjectWithChanges.jobTitle,
        //         certifications: data.tailoredResumeObject.resumeObjectWithChanges.certifications?.join(","),
        //         toolsTechnicalSkills: data.tailoredResumeObject.resumeObjectWithChanges.toolsTechnicalSkills,
        //         programmingLanguages: data.tailoredResumeObject.resumeObjectWithChanges.programmingLanguages,
        //         interests: data.tailoredResumeObject.resumeObjectWithChanges.interests?.join(","),
        //         workExperiences: data.tailoredResumeObject.resumeObjectWithChanges.workExperiences
        //       },
        //       jobTitle: data.tailoredResumeObject.jobTitle,
        //       companyName: data.tailoredResumeObject.companyName,
        //       keywordsToAdd: data.tailoredResumeObject.keywordsToAdd,
        //     },
        //     resume: JSON.stringify(popupDetails.resumeObject)
        //   })
        // });

        // const savedATSResultData = await savedATSResult.json();
        dispatch(setExtractedData(popupDetails.resumeObject));
        dispatch(setFixedSections({}));

        navigate(`/ats-checker-result`, {
          state: {
            atsResult: {
              fetchedJobDetails: formDetails.fetchedJobDetails,
              resumeObject: popupDetails.resumeObject,
              tailoredResumeObject: data,
            },
          },
        });
      } else {
        let jobDetails: any = {};

        setPopupDetails((prevState) => ({ ...prevState, title: "Job Description" }));
        if (
          formDetails.jobDescription !== "" &&
          Object.keys(formDetails.fetchedJobDetails).length === 0
        ) {
          jobDetails = await extractJobDetails(formDetails.jobDescription);
        } else {
          jobDetails = formDetails.fetchedJobDetails;
        }

         /*  jobDetails = {
            "jobTitle": "Machine Learning Engineer",
            "companyName": "athenahealth",
            "jobLocation": "Bengaluru",
            "jobType": "Full-Time",
            "yearsOfexperience": {
                "totalYears": "4 - 7",
                "industrySpecific": {
                    "Machine Learning": "4 - 7 years"
                }
            },
            "requiredSkills": {
                "Machine Learning": "4 - 7 years",
                "Python": "NA",
                "SQL": "NA",
                "Unix": "NA",
                "Excellent verbal communication and writing skills": "NA"
            },
            "preferredSkills": {
                "Deep Learning Models with complex neural network architectures": "NA",
                "LLMs & GenAI models": "NA",
                "Natural Language Processing or computer vision techniques": "NA",
                "AWS ecosystem, including Kubernetes, Kubeflow or EKS experience": "NA"
            },
            "responsibilities": [
                "Identify opportunities for different machine learning techniques and evaluate which are best",
                "Assist in developing and deploying ML-based production services to our clients",
                "Understand and follow conventions and best practices for modeling, coding, architecture, and statistics; and hold other team members accountable for doing so",
                "Apply rigorous testing of statistics, models, and code",
                "Contribute to the development of internal tools and data science team standards"
            ],
            "educationLevel": {
                "required_education_level": "Bachelors or Masters",
                "required_education_major": "Math, Computer Science, Data Science, Statistics, or related field",
                "required_graduation_year": "NA",
                "required_gpa": "NA",
                "required_remaining_education_details": "NA"
            },
            "certifications": {
                "required": [],
                "preferred": []
            },
            "jobDescription": null,
            "visaSponsorship": null,
            "isWorkFromHome": "Hybrid",
            "executionTime": "13.26"
        }; */

       /* let tailoredResumeData: any = {
        "matchingScore": 32,
        "tailoredResumeFeedback": {
            "professionalSummary": {
                "missingRequiredSkills": [
                    "Unix",
                    "Excellent verbal communication and writing skills",
                    "Deep Learning Models with complex neural network architectures",
                    "LLMs & GenAI models",
                    "Natural Language Processing or computer vision techniques",
                    "AWS ecosystem, including Kubernetes, Kubeflow or EKS experience"
                ],
                "missingPreferredSkills": [],
                "yearsOfExperienceMatch": {
                    "totalYearsMet": true,
                    "industrySpecificYearsMet": true,
                    "missingTotalYears": "0",
                    "missingIndustrySpecificYears": "0"
                },
                "educationMatch": {
                    "degreeRequirementsMet": true,
                    "missingDegrees": []
                },
                "certificationsMatch": {
                    "requiredCertificationsMet": true,
                    "missingRequiredCertifications": []
                },
                "suggestionsForImprovement": [
                    "Specifically highlight experience in developing and deploying machine learning models for real-world problems.",
                    "Quantify achievements in the development and implementation of specific ML projects or solutions, showcasing the impact on business outcomes.",
                    "Include keywords related to the preferred skills mentioned in the job description, such as deep learning, natural language processing, or AWS ecosystem."
                ],
                "strengths": [],
                "score": 9,
                "maxScore": 15
            },
            "technicalSkills": {
                "maxScore": 15,
                "score": 11,
                "missingRequired": [],
                "missingPreferred": [
                    "Deep Learning",
                    "Neural Network Architectures",
                    "LLMs",
                    "GenAI models",
                    "Natural Language Processing",
                    "computer vision techniques",
                    "AWS ecosystem",
                    "Kubernetes",
                    "Kubeflow or EKS experience"
                ]
            },
            "softSkills": {
                "maxScore": 15,
                "score": 12,
                "missingRequired": [
                    "verbal communication",
                    "writing skills"
                ],
                "missingPreferred": []
            }
        },
        "jobDetails": {
            "jobTitle": "Machine Learning Engineer",
            "companyName": "athenahealth",
            "jobLocation": "Bengaluru",
            "jobType": "Full-Time",
            "yearsOfexperience": {
                "totalYears": "4 - 7",
                "industrySpecific": {
                    "Machine Learning": "4 - 7 years"
                }
            },
            "requiredSkills": {
                "Machine Learning": "4 - 7 years",
                "Python": "NA",
                "SQL": "NA",
                "Unix": "NA",
                "Excellent verbal communication and writing skills": "NA"
            },
            "preferredSkills": {
                "Deep Learning Models with complex neural network architectures": "NA",
                "LLMs & GenAI models": "NA",
                "Natural Language Processing or computer vision techniques": "NA",
                "AWS ecosystem, including Kubernetes, Kubeflow or EKS experience": "NA"
            },
            "responsibilities": [
                "Identify opportunities for different machine learning techniques and evaluate which are best",
                "Assist in developing and deploying ML-based production services to our clients",
                "Understand and follow conventions and best practices for modeling, coding, architecture, and statistics; and hold other team members accountable for doing so",
                "Apply rigorous testing of statistics, models, and code",
                "Contribute to the development of internal tools and data science team standards"
            ],
            "educationLevel": {
                "required_education_level": "Bachelors or Masters",
                "required_education_major": "Math, Computer Science, Data Science, Statistics, or related field",
                "required_graduation_year": "NA",
                "required_gpa": "NA",
                "required_remaining_education_details": "NA"
            },
            "certifications": {
                "required": [],
                "preferred": []
            },
            "jobDescription": null,
            "visaSponsorship": null,
            "isWorkFromHome": "Hybrid",
            "executionTime": "13.26"
        }
    }; */

        setPopupDetails((prevState) => ({ ...prevState, title: "Tailoring Resume" }));
        let tailoredResumeData: any = await tailorResume(jobDetails, extractedData);
        
        tailoredResumeData = { ...tailoredResumeData, jobDetails: jobDetails };

        setFormDetails((prevState) => ({
          ...prevState,
          fetchedJobDetails: formDetails.fetchedJobDetails,
          tailoredResumeObject: tailoredResumeData,
        }));

        dispatch(
          setExtractedData({
            ...extractedData,
            tailoredResumeObject: tailoredResumeData,
          })
        );

        setPopupDetails((prevState) => ({
          ...prevState,
          open: false,
          title: "",
          subTitle: "",
          progress: 100,
          page: 0,
          resumeObject: {} as IResume,
        }));
      }
    } catch (error) {
      setPopupDetails((prevState) => ({ ...prevState, page: 2 }));
      throw new Error("Network response was not ok");
    } finally {
      setFormDetails((prevState) => ({ ...prevState, loading: false }));
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle: "",
        progress: 100,
        loading: false,
      }));
    }
  };

  const moveToSecondScreen = () => {
    setPopupDetails((prevState) => ({ ...prevState, page: 2 }));
    setFormDetails({
      jobUrl: "",
      fetchedJobDetails: {} as IFetchedJobDetails,
      jobDescription: "",
      tailoredResumeObject: {} as ITailoredResume,
      loading: false,
    });
  };

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: ATSCheckScreen,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "20px", // Apply 20px border radius to the dialog
          maxWidth: "none",
        },
      }}
    >
      <Box>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {isATSChecker ? "ATS Checker" : "Tailor Your Resume"}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "500px",
            width: "750px",
          }}
        >
          {popupDetails.page === 1 && (
            <Box
              sx={{
                py: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mb: 1 }}>
                {popupDetails.progress < 100 ? (
                  <UploadingIcon />
                ) : (
                  <UploadedResumeIcon />
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  display: popupDetails.progress < 100 ? "block" : "none",
                }}
                gutterBottom
              >
                {popupDetails.progress}%
              </Typography>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "bold" }}
                gutterBottom
              >
                {popupDetails.title}
              </Typography>
              <Typography sx={{ fontSize: "16px" }} color="text.secondary">
                {popupDetails.subTitle}
              </Typography>
            </Box>
          )}
          {popupDetails.page === 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: formDetails.loading ? "center" : "flex-start",
              }}
            >
              <Grid container spacing={2}>
                {!formDetails.loading &&
                  Object.keys(formDetails.fetchedJobDetails).length === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          component="label"
                          htmlFor="email"
                          gutterBottom
                        >
                          Job URL
                        </Typography>
                        <TextField
                          placeholder="Job URL"
                          value={formDetails.jobUrl}
                          onChange={(e) =>
                            setFormDetails({
                              ...formDetails,
                              jobUrl: e.target.value,
                            })
                          }
                          fullWidth
                          sx={{
                            ...AuthInputFieldStyle,
                            mt: 0.5,
                            "& .MuiOutlinedInput-root": {
                              border: "1px solid #E2E8F0",
                              background: "#F8FAFC",
                              color: "#667085",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                              paddingRight: 0,
                            },
                          }}
                          margin="normal"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  onClick={handleFetch}
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    padding: "0.6em 0px",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                >
                                  Fetch
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <Divider sx={{ width: "15%" }} />
                          <Typography
                            variant="body2"
                            sx={{ mx: 2, fontWeight: "bold" }}
                          >
                            OR
                          </Typography>
                          <Divider sx={{ width: "15%" }} />
                        </Box>
                      </Grid>
                    </>
                  )}
                <Grid item xs={12}>
                  {formDetails.loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ mb: 1 }}>
                        <FetchJobDescriptionIcon />
                      </Box>
                      {/* <Typography sx={{ fontSize: "24px", fontWeight: "bold", display: popupDetails.progress < 100 ? "block" : "none" }} gutterBottom>{popupDetails.progress}%</Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }} gutterBottom>{popupDetails.title}</Typography> */}
                      <Typography
                        sx={{ fontSize: "16px" }}
                        color="text.secondary"
                      >
                        {popupDetails.subTitle}
                      </Typography>
                    </Box>
                  ) : Object.keys(formDetails.fetchedJobDetails).length > 0 ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        overflow: "auto",
                        mt: 2,
                        pt: 0,
                        pr: 1,
                        width: "100%",
                      }}
                    >
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Job Title"
                          content={formDetails.fetchedJobDetails.jobTitle}
                          icon={<JobTitleIcon />}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Experience Level"
                          content={
                            formDetails.fetchedJobDetails.experienceLevel
                          }
                          icon={<ExperienceIcon />}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Company"
                          content={formDetails.fetchedJobDetails.companyName}
                          icon={<CompanyIcon />}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Job Type"
                          content={formDetails.fetchedJobDetails.jobType}
                          icon={<JobTypeIcon />}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Location"
                          content={formDetails.fetchedJobDetails.location}
                          icon={<LocationIcon />}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                        <JobDetailCard
                          title="Salary"
                          content={formDetails.fetchedJobDetails.salary}
                          icon={<SalaryIcon />}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ pt: 3 }}>
                        <Card
                          sx={{
                            boxShadow:
                              "0px 1px 2px 0px rgba(228, 229, 231, 0.24)",
                            borderRadius: "8px",
                          }}
                        >
                          <CardContent
                            sx={{
                              px: 1.4,
                              py: 1,
                              flex: 1,
                              "&:last-child": { paddingBottom: 1 },
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#242424"}
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Required Skills
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1.4,
                                mt: 1,
                              }}
                            >
                              {Array.isArray(
                                formDetails.fetchedJobDetails.mainTechSkills
                              ) ? (
                                formDetails.fetchedJobDetails.mainTechSkills.map(
                                  (skill: string, index: number) => {
                                    const existingSkill = skillsData.find(
                                      (s) =>
                                        s.label.toLowerCase() ===
                                        skill.toLowerCase()
                                    );

                                    return (
                                      <Tooltip title={skill} key={index}>
                                        <Chip
                                          sx={{
                                            height: "2.2rem",
                                            maxWidth: "150px",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            padding: "0.3rem",
                                            border: "1px solid #e2e8f0",
                                            borderRadius: "8px",
                                            "& .MuiChip-label": {
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              paddingLeft: "12px",
                                              paddingRight: "8px",
                                            },
                                          }}
                                          avatar={
                                            existingSkill?.icon ? (
                                              <existingSkill.icon />
                                            ) : (
                                              <Avatar
                                                sx={{
                                                  background: `linear-gradient(90deg, ${
                                                    avatarColors[
                                                      index %
                                                        avatarColors.length
                                                    ].primary
                                                  } 0%, ${
                                                    avatarColors[
                                                      index %
                                                        avatarColors.length
                                                    ].secondary
                                                  } 100%)`,
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontSize: "11px",
                                                    color: `${
                                                      avatarColors[
                                                        index %
                                                          avatarColors.length
                                                      ].text
                                                    }`,
                                                  }}
                                                >
                                                  {skill[0]?.toUpperCase()}
                                                </Typography>
                                              </Avatar>
                                            )
                                          }
                                          label={skill}
                                          variant="outlined"
                                        />
                                      </Tooltip>
                                    );
                                  }
                                )
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal",
                                    fontSize: "16px",
                                  }}
                                >
                                  {formDetails.fetchedJobDetails
                                    .mainTechSkills !== null
                                    ? formDetails.fetchedJobDetails
                                        .mainTechSkills
                                    : "N/A"}
                                </Typography>
                              )}
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card
                          sx={{
                            boxShadow:
                              "0px 1px 2px 0px rgba(228, 229, 231, 0.24)",
                            borderRadius: "8px",
                          }}
                        >
                          <CardContent
                            sx={{
                              p: 0,
                              px: 1.4,
                              flex: 1,
                              "&:last-child": { paddingBottom: 0 },
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#242424"}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                pb: 1,
                              }}
                            >
                              Job Description
                            </Typography>
                            <Typography variant="body1">
                              {formDetails.fetchedJobDetails.jobDescription !==
                                null &&
                                formDetails.fetchedJobDetails.jobDescription}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        component="label"
                        htmlFor="email"
                        gutterBottom
                      >
                        Job Description
                      </Typography>
                      <TextField
                        multiline
                        rows={12}
                        placeholder="Job Description"
                        value={formDetails.jobDescription}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            jobDescription: e.target.value,
                          })
                        }
                        fullWidth
                        sx={{
                          ...AuthInputFieldStyle,
                          mt: 0.5,
                          "& .MuiOutlinedInput-root": {
                            border: "1px solid #E2E8F0",
                            background: "#F8FAFC",
                            color: "#667085",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          },
                        }}
                        margin="normal"
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {popupDetails.page === 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Lottie options={defaultLottieOptions} height={400} width={400} />
              <Box sx={{ py: 2, textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ opacity: 0.85, color: "#344054", fontWeight: "bold" }}
                >
                  {popupDetails.title === "Job Description" ? (
                    "Analyzing the given Job Description for tailoring the resume"
                  ) : (
                    <>
                      Analyzing Your Resume for{" "}
                      {isATSChecker ? "ATS Score" : "Tailoring Your Resume"}
                    </>
                  )}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ opacity: 0.85, color: "#5A697E" }}
                >
                  Ensuring your resume is optimized and{" "}
                  {isATSChecker
                    ? "ready for ATS"
                    : "ready to apply for the job"}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 3,
            visibility: popupDetails.page === 3 ? "hidden" : "visible",
          }}
        >
          <Typography color="text.secondary">
            {popupDetails.page} of 2
          </Typography>
          <Box>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              onClick={onClose}
              sx={{
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                mr: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={
                popupDetails.progress < 100 ||
                (popupDetails.page === 2 &&
                  formDetails.jobDescription === "" &&
                  formDetails.jobUrl === "" &&
                  Object.keys(formDetails.fetchedJobDetails).length === 0)
              }
              onClick={() =>
                popupDetails.page === 2
                  ? handleATSChecker()
                  : moveToSecondScreen()
              }
            >
              Continue
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ProcessingPopup;
