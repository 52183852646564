import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactComponent as SelectFontIcon } from "../assets/SVG/tabler_text-size.svg";
import { ReactComponent as ChosseTemplatetIcon } from "../assets/SVG/templates-icon.svg";
import { ReactComponent as DoewnloadIcon } from "../assets/SVG/download-icon.svg";
import { ReactComponent as FontPlusIcon } from "../assets/SVG/plus.svg";
import { ReactComponent as FontMinusIcon } from "../assets/SVG/minus.svg";
import { ReactComponent as WordIcon } from "../assets/SVG/doc-icon.svg";
import { ReactComponent as PdfIcon } from "../assets/SVG/pdf-icon.svg";
import Button from "@mui/material/Button";
import ColorPicker from "./ColorPicker";
import { useNavigate } from "react-router-dom";
import { IconButton, Select } from "@mui/material";
import { useSelector } from "react-redux";
// import { TemplateHandler } from 'easy-template-x';
// import { saveAs } from 'file-saver';
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setExtractedData } from "../redux/actions";
import { getToken } from "../components/auth/storeToken";
import {
  flattenExtractedData,
  generateDocument,
  handleDownloadPdf,
  IResume,
  TemplatesById,
} from "../components/utils";
import { Save } from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { StateProps } from "../redux/reducer";
import ColorPickerAlt from "./ColorPickerAlt";
import { FONTS_LIST } from "../utils/fontsList";
import TextureHandler from "./TextureHandler";
import { TEXTURES } from "../utils/textures";

const ProcessResumePreviewHeader = (props: any) => {
  const { currentPage, numPages, handleNextPage, handlePrevPage } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const extractedData = useSelector((state: any) => state.extractedData);
  // const primaryColor = extractedData.metaInformation.primaryColor;
  const {
    graphColor = "#FFC107",
    primaryColor = "#4f6071",
    secondaryColor = "#4f6071",
    altBackgroundColor = "#f6f7f8",
    textureId = "",
  } = extractedData?.metaInformation || {};
  const relativeFontSize = extractedData.metaInformation?.relativeFontSize || 0;
  const fontFamily = extractedData.metaInformation?.fontFamily || "";
  const onChangeValue = (key: string, value: string) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        metaInformation: { ...extractedData.metaInformation, [key]: value },
      })
    );
  };
  const onChangeMultipleValue = (obj: any) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        metaInformation: { ...extractedData.metaInformation, ...obj },
      })
    );
  };
  const handleRelativeFontChange = (type: "INC" | "DEC") => {
    const newRelativeFontSize = relativeFontSize + (type === "INC" ? 1 : -1);
    dispatch(
      setExtractedData({
        ...extractedData,
        metaInformation: {
          ...extractedData.metaInformation,
          relativeFontSize: newRelativeFontSize,
        },
      })
    );
  };
  const selectedTemplateId = useSelector(
    (state: StateProps) => state?.selectedTemplateId
  );
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleDownload = () => {
    generateDocument(extractedData);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false);
    if (index === 0) {
      const TemplateComponent = TemplatesById[selectedTemplateId].jsx;
      handleDownloadPdf(extractedData, TemplateComponent);
    } else if (index === 1) {
      handleDownload();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("toggle");
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const fonts = [
    {
      label: "Select Font",
      value: "",
    },
    {
      label: "Courier",
      value: "Courier",
    },
    {
      label: "Courier-Bold",
      value: "Courier-Bold",
    },
    {
      label: "Courier-Oblique",
      value: "Courier-Oblique",
    },
    {
      label: "Courier-BoldOblique",
      value: "Courier-BoldOblique",
    },
    {
      label: "Helvetica",
      value: "Helvetica",
    },
    {
      label: "Helvetica-Bold",
      value: "Helvetica-Bold",
    },
    {
      label: "Helvetica-Oblique",
      value: "Helvetica-Oblique",
    },
    {
      label: "Helvetica-BoldOblique",
      value: "Helvetica-BoldOblique",
    },
    {
      label: "Times-Roman",
      value: "Times-Roman",
    },
    {
      label: "Times-Bold",
      value: "Times-Bold",
    },
    {
      label: "Times-Italic",
      value: "Times-Italic",
    },
    {
      label: "Times-BoldItalic",
      value: "Times-BoldItalic",
    },
    ...FONTS_LIST.map((font) => ({
      label: font.family,
      value: font.family,
    })),
  ];

  const foundSvgTexture = TEXTURES.find((el) => el.svg && el.id === textureId);

  return (
    <Box className="content">
      <Box className="left">
        <Box className="icons-container">
          <Box className="icon-box header-font-container">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fontFamily}
              label="Font Family"
              onChange={(e) => {
                onChangeValue("fontFamily", e.target.value);
              }}
            >
              {fonts.map((font) => {
                return <MenuItem value={font.value}>{font.label}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box className="icon-box">
            <IconButton onClick={() => handleRelativeFontChange("DEC")}>
              <FontMinusIcon />
            </IconButton>
            <SelectFontIcon />
            <IconButton onClick={() => handleRelativeFontChange("INC")}>
              <FontPlusIcon />
            </IconButton>
          </Box>

          <Box className="icon-box">
            <div className="div-as-color-picker-icon">
              <div
                className="template-color"
                style={{ backgroundColor: primaryColor || "#fff" }}
              ></div>
              <Box className="picker-box">
                <ColorPickerAlt
                  color={primaryColor}
                  onChange={(color: string) =>
                    onChangeValue("primaryColor", color)
                  }
                />
              </Box>
            </div>
          </Box>
          <Box className="icon-box">
            <div className="div-as-color-picker-icon">
              <div
                className="template-color"
                style={{ backgroundColor: secondaryColor || "#fff" }}
              ></div>
              <Box className="picker-box">
                <ColorPickerAlt
                  color={secondaryColor}
                  onChange={(color: string) =>
                    onChangeValue("secondaryColor", color)
                  }
                />
              </Box>
            </div>
          </Box>
          <Box className="icon-box">
            <div className="div-as-color-picker-icon">
              <div
                className="template-color"
                style={{ backgroundColor: altBackgroundColor || "#fff" }}
              ></div>
              <Box className="picker-box">
                <ColorPickerAlt
                  color={altBackgroundColor}
                  onChange={(color: string) =>
                    onChangeValue("altBackgroundColor", color)
                  }
                />
              </Box>
            </div>
          </Box>
          <Box className="icon-box">
            <div className="div-as-color-picker-icon">
              <div
                className="template-color"
                style={{ backgroundColor: graphColor || "#fff" }}
              ></div>
              <Box className="picker-box">
                <ColorPickerAlt
                  color={graphColor}
                  onChange={(color: string) =>
                    onChangeValue("graphColor", color)
                  }
                />
              </Box>
            </div>
          </Box>

          {foundSvgTexture &&
            foundSvgTexture.colorScheme &&
            foundSvgTexture.colorScheme.map((colorId, colorIndex) => (
              <Box className="icon-box">
                <div className="div-as-color-picker-icon">
                  <div
                    className="template-color"
                    style={{
                      backgroundColor:
                        extractedData?.metaInformation?.[
                          `svgColor${colorIndex + 1}`
                        ] || "#fff",
                    }}
                  ></div>
                  <Box className="picker-box">
                    <ColorPickerAlt
                      color={
                        extractedData?.metaInformation?.[
                          `svgColor${colorIndex + 1}`
                        ]
                      }
                      onChange={(color: string) =>
                        onChangeValue(`svgColor${colorIndex + 1}`, color)
                      }
                    />
                  </Box>
                </div>
              </Box>
            ))}
          <TextureHandler
            onChange={onChangeMultipleValue}
            textureId={textureId}
          />
        </Box>
      </Box>
      <Box
        className="middle"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
        }}
      ></Box>
      <Box className="right">
        <Box className="button-controls">
          <Button
            variant="outlined"
            startIcon={<ChosseTemplatetIcon color="#fff" />}
            size="medium"
            className="tekno-white-outline-button"
            onClick={() => {
              if (!extractedData.id) {
                navigate(`/create-resume/new/select-template`);
              } else {
                navigate(`/create-resume/${extractedData.id}/select-template`);
              }
            }}

            //  sx={{backgroundColor: "white",
            //     "&:hover":{
            //         backgroundColor:"#f1f1f1",
            //     }
            //  }}
          >
            Templates
          </Button>
          <ButtonGroup ref={anchorRef} variant="contained">
            <Button
              startIcon={<DoewnloadIcon />}
              endIcon={
                <ArrowDropDownIcon
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                />
              }
              onClick={handleToggle}
            >
              Download
            </Button>
          </ButtonGroup>
          <Popper
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Box
                  sx={{
                    background: "#fff",
                    marginTop: "0.5rem",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="split-button-menu"
                      autoFocusItem
                      sx={{ border: "0.5px solid #e2e4e9" }}
                    >
                      <MenuItem
                        onClick={(event) => handleMenuItemClick(event, 0)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <PdfIcon />
                          <Typography
                            sx={{
                              color: "#0A0D14",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Export to PDF
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <WordIcon />
                          <Typography
                            sx={{
                              color: "#0A0D14",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Export to Word
                          </Typography>
                        </Box>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Box>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
    </Box>
  );
};
export default ProcessResumePreviewHeader;
