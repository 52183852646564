import {
  Autocomplete,
  Box,
  Button,
  Chip,
  createFilterOptions,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
// import ReactQuill from "react-quill";
// import LexicalEditor from '../../components/LexicalEditor';
import ReactQuill from "react-quill";
import { useEffect, useRef, useState, useCallback } from "react";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { hobbiesData, languagesData, skillOption } from "../../Data/skillsData";
import { ReactComponent as CourseIcon } from "../../assets/SVG/courses-icon.svg";
import { ReactComponent as ProjectIcon } from "../../assets/SVG/project-icon.svg";
import { ReactComponent as ReferencessIcon } from "../../assets/SVG/preferencess-icon.svg";
import { ReactComponent as LanguagesIcon } from "../../assets/SVG/languages-icon.svg";
import { ReactComponent as AwardsIcon } from "../../assets/SVG/award-icon.svg";
import { ReactComponent as HobbiesIcon } from "../../assets/SVG/hobbies-icon.svg";
import { ReactComponent as VoulnteerIcon } from "../../assets/SVG/volunteer-work-icon.svg";
import { ReactComponent as ExtarCurricularActivitiesIcon } from "../../assets/SVG/ExtraCurricularActivities-icon.svg";
import { ReactComponent as CustomSection } from "../../assets/SVG/custom-icon.svg";
import { ReactComponent as AddSectionIcon } from "../../assets/SVG/add-section-icon.svg";
import { ReactComponent as RemoveSectionIcon } from "../../assets/SVG/new-delete-section.svg";
import { ReactComponent as CancelModalIcon } from "../../assets/SVG/cancel-modal.svg";
import { ReactComponent as AiIcon } from "../../assets/SVG/ai-description-icon.svg";
import { ReactComponent as InternshipIcon } from "../../assets/SVG/internship_icon.svg";
import { ReactComponent as GoalIcon } from "../../assets/SVG/goals_icon.svg";
import { ReactComponent as PublicationIcon } from "../../assets/SVG/publication_icon.svg";
import { ReactComponent as KeyAchievementIcon } from "../../assets/SVG/key_achievements.svg";
import { ReactComponent as CertificationIcon } from "../../assets/SVG/certifications_icon.svg";
import Languages from "./SummarySections/Languages";
import Hobbies from "./SummarySections/Hobbies";
import CoursesList from "./SummarySections/Courses/CoursesList";
import ProjectsList from "./SummarySections/Projects/ProjectsList";
import AwardsList from "./SummarySections/Awards/AwardsList";
import ReferencesList from "./SummarySections/Referencess/ReferencesList";
import VolunteerWorksList from "./SummarySections/VolunteerWork/VolunterrWorksList";
import InternshipList from "./SummarySections/Interships/InternshipList";
import CustomSectionList from "./SummarySections/CustomSection/CustomSectionList";
import ImproveResumeBtn from "../ImproveResumeBtn";
import GetHelpWithWriting from "../GetHelpWithWriting";
import ProfessionalSummaryModal from "./SummarySections/ProfessionalSummaryModal";
import { quillModules } from "../../App";
import { cleanSummaryHTMLstring } from "../../components/common/CommonFunctions";
import debounce from "lodash.debounce";
import { StateProps } from "../../redux/reducer";
import PublicationsList from "./SummarySections/Publications/PulicationsList";
import GoalsList from "./SummarySections/Goals/GoalsList";
import KeyAchievementList from "./SummarySections/KeyAchievements/KeyAchievementList";
import CertificationList from "./SummarySections/Certifications/CertificationList";

export const summarSectionModalstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 0,
};

const Summary = () => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const [removeableSections, setRemoveableSections] = useState<
    { label: string; value: string; isEditable?: boolean }[]
  >([]);
  const careerObjectiveInputRef = useRef(null);
  const [otherSectionList, setOtherSectionList] = useState([
    {
      label: "Courses",
      value: "courses",
      icon: <CourseIcon />,
      visible: true,
    },
    {
      label: "Projects",
      value: "projects",
      icon: <ProjectIcon />,
      visible: true,
    },
    {
      label: "References",
      value: "references",
      icon: <ReferencessIcon />,
      visible: true,
    },
    {
      label: "Languages",
      value: "languages",
      icon: <LanguagesIcon />,
      visible: true,
    },
    {
      label: "Awards",
      value: "awards",
      icon: <AwardsIcon />,
      visible: true,
    },
    {
      label: "Certifications",
      value: "certifications",
      icon: <CertificationIcon />,
      visible: true,
    },
    {
      label: "Hobbies",
      value: "hobbies",
      icon: <HobbiesIcon />,
      visible: true,
    },
    {
      label: "Volunteer Work",
      value: "volunteerWorks",
      icon: <VoulnteerIcon />,
      visible: true,
    },
    {
      label: "Internships",
      value: "internships",
      icon: <InternshipIcon />,
      visible: true,
    },
    {
      label: "Publications",
      value: "publications",
      icon: <PublicationIcon />,
      visible: true,
    },
    {
      label: "Goals",
      value: "goals",
      icon: <GoalIcon />,
      visible: true,
    },
    {
      label: "Key Achievements",
      value: "keyAchievements",
      icon: <KeyAchievementIcon />,
      visible: true,
    },
    {
      label: "Custom section",
      value: "customSection",
      icon: <CustomSection />,
      visible: true,
    },
  ]);
  const [summarySectionModal, setSummarySectionModal] = useState({
    modalState: false,
    sectionName: "",
    sectionDelete: false,
  });
  const [professionalModal, setProfessionalModal] = useState<boolean>(false);
  const [summary, setSummary] = useState<string>("");

  /**
   * Below useEffect is for setting local summary value to global resume state
   */
  useEffect(() => {
    if (summary) {
      dispatch(
        setExtractedData({ ...extractedData, professionalSummary: summary })
      );
    }
  }, [summary]);

  /**
   * Below useEffect is to set the summary value initially
   */
  useEffect(() => {
    if (extractedData?.professionalSummary) {
      setSummary(extractedData?.professionalSummary);
    }

    let alreadyAvailableSections: {
      label: string;
      value: string;
      isEditable?: boolean;
    }[] = [];
    if (extractedData?.courses && extractedData?.courses?.length > 0) {
      alreadyAvailableSections.push({ label: "Courses", value: "courses" });
    }
    if (extractedData?.projects && extractedData?.projects?.length > 0) {
      alreadyAvailableSections.push({ label: "Projects", value: "projects" });
    }
    if (extractedData?.awards && extractedData?.awards?.length > 0) {
      alreadyAvailableSections.push({ label: "Awards", value: "awards" });
    }
    if (
      extractedData?.certifications &&
      extractedData?.certifications?.length > 0
    ) {
      alreadyAvailableSections.push({
        label: "Certifications",
        value: "certifications",
      });
    }
    if (extractedData?.references && extractedData?.references?.length > 0) {
      alreadyAvailableSections.push({
        label: "References",
        value: "references",
      });
    }
    if (
      extractedData?.volunteerWorks &&
      extractedData?.volunteerWorks?.length > 0
    ) {
      alreadyAvailableSections.push({
        label: "Volunteer Work",
        value: "volunteerWorks",
      });
    }
    if (extractedData?.internships && extractedData?.internships?.length > 0) {
      alreadyAvailableSections.push({
        label: "Internships",
        value: "internships",
      });
    }
    if (
      extractedData?.publications &&
      extractedData?.publications?.length > 0
    ) {
      alreadyAvailableSections.push({
        label: "Publications",
        value: "publications",
      });
    }
    if (extractedData?.goals) {
      alreadyAvailableSections.push({ label: "Goals", value: "goals" });
    }
    if (extractedData?.keyAchievements) {
      alreadyAvailableSections.push({
        label: "Key Achievements",
        value: "keyAchievements",
      });
    }
    if (extractedData?.languages && extractedData?.languages?.length > 0) {
      alreadyAvailableSections.push({ label: "Languages", value: "languages" });
    }
    if (extractedData?.hobbies && extractedData?.hobbies?.length > 0) {
      alreadyAvailableSections.push({ label: "Hobbies", value: "hobbies" });
    }

    if (
      extractedData?.customSections &&
      Object.keys(extractedData?.customSections)?.length > 0
    ) {
      // alreadyAvailableSections.push({label: "Custom Section", value:"customSection"});
      const customSectionObj = extractedData?.customSections;
      for (const key in customSectionObj) {
        const lastChar =
          customSectionObj?.[key]?.value?.[
            customSectionObj?.[key]?.value?.length - 1
          ];
        if (isNaN(Number(lastChar))) {
          alreadyAvailableSections.push({
            label: customSectionObj?.[key]?.label,
            value: "customSection0",
            isEditable: false,
          });
        } else {
          alreadyAvailableSections.push({
            label: customSectionObj?.[key]?.label,
            value: `customSection${Number(lastChar) + 1}`,
            isEditable: false,
          });
        }
      }
    }

    if (alreadyAvailableSections?.length > 0) {
      setRemoveableSections([
        ...removeableSections,
        ...alreadyAvailableSections,
      ]);
      const alreadyAvailableSectionsValues = alreadyAvailableSections?.map(
        (s) => s.value
      );
      const updatedOtherSection = otherSectionList?.map((ots) => {
        if (alreadyAvailableSectionsValues?.includes(ots.value)) {
          return {
            ...ots,
            visible: false,
          };
        }
        return ots;
      });
      setOtherSectionList(updatedOtherSection);
    }
  }, []);

  const handleSummaryChange = (e: any) => {
    setSummary(e);
  };

  //to delete section
  useEffect(() => {
    if (summarySectionModal?.modalState && summarySectionModal?.sectionDelete) {
      setRemoveableSections((prev) =>
        prev.filter((sec) => sec.value !== summarySectionModal?.sectionName)
      );
      let updatedOtherSection = otherSectionList?.map((ots) => {
        if (ots.value === summarySectionModal?.sectionName) {
          return {
            ...ots,
            visible: true,
          };
        }
        return ots;
      });

      /**
       * If It's a custom section then remove it
       */
      if (summarySectionModal?.sectionName?.includes("customSection")) {
        updatedOtherSection = updatedOtherSection?.filter(
          (sec) => sec?.value !== summarySectionModal?.sectionName
        );
        const updatedCustomSectionObj = extractedData.customSections;
        delete updatedCustomSectionObj?.[summarySectionModal?.sectionName];
        dispatch(
          setExtractedData({
            ...extractedData,
            customSections: {
              ...updatedCustomSectionObj,
            },
          })
        );
      } else {
        dispatch(
          setExtractedData({
            ...extractedData,
            [summarySectionModal?.sectionName]: [],
          })
        );
      }
      setOtherSectionList(updatedOtherSection);
    }
    setSummarySectionModal({
      modalState: false,
      sectionName: "",
      sectionDelete: false,
    });
  }, [summarySectionModal?.sectionDelete]);

  const handleDeleteEmptySection = (sectionName: string) => {
    setRemoveableSections((prev) =>
      prev.filter((sec) => sec.value !== sectionName)
    );
    let updatedOtherSection = otherSectionList?.map((ots) => {
      if (ots.value === sectionName) {
        return {
          ...ots,
          visible: true,
        };
      }
      return ots;
    });

    /**
     * If It's a custom section then remove it
     */
    if (sectionName?.includes("customSection")) {
      updatedOtherSection = updatedOtherSection?.filter(
        (sec) => sec?.value !== sectionName
      );
      const updatedCustomSectionObj = extractedData.customSections;
      delete updatedCustomSectionObj?.[summarySectionModal?.sectionName];
      dispatch(
        setExtractedData({
          ...extractedData,
          customSections: {
            ...updatedCustomSectionObj,
          },
        })
      );
    } else {
      dispatch(
        setExtractedData({
          ...extractedData,
          [summarySectionModal?.sectionName]: [],
        })
      );
    }
    setOtherSectionList(updatedOtherSection);
  };

  const handleChange = (newValue: any, key: string) => {
    dispatch(setExtractedData({ ...extractedData, [key]: newValue }));
  };

  const handleAddOtherSectionAction = (sectionName: {
    label: string;
    value: string;
  }) => {
    if (sectionName?.value.includes("customSection")) {
      const lastChar = sectionName?.value?.[sectionName?.value?.length - 1];
      if (isNaN(Number(lastChar))) {
        setRemoveableSections([
          ...removeableSections,
          { label: sectionName?.label, value: "customSection0" },
        ]);
      } else {
        setRemoveableSections([
          ...removeableSections,
          {
            label: sectionName?.label,
            value: `customSection${Number(lastChar) + 1}`,
          },
        ]);
      }
    } else {
      setRemoveableSections([...removeableSections, sectionName]);
    }

    const updatedOtherSection = otherSectionList?.map((ots) => {
      if (ots.value === sectionName?.value) {
        return {
          ...ots,
          visible: false,
        };
      }
      return ots;
    });

    //Adding custom section persistancy always
    if (sectionName?.value.includes("customSection")) {
      const lastChar = sectionName?.value?.[sectionName?.value?.length - 1];
      if (isNaN(Number(lastChar))) {
        updatedOtherSection.push({
          label: "Custom section",
          value: "customSection0",
          icon: <CustomSection />,
          visible: true,
        });
      } else {
        updatedOtherSection.push({
          label: `Custom section`,
          value: `customSection${Number(lastChar) + 1}`,
          icon: <CustomSection />,
          visible: true,
        });
      }
    }
    setOtherSectionList(updatedOtherSection);
  };

  const handleSectionDelete = (section: string) => {
    setSummarySectionModal((prev) => ({
      ...prev,
      modalState: true,
      sectionName: section,
    }));
  };

  const sectionNameEditClick = (sectioName: string, sectionLabel: string) => {
    const updatedRemoveableSection = removeableSections?.map((section) => {
      if (section?.value === sectioName) {
        return { ...section, isEditable: true };
      }
      return section;
    });
    console.log(
      { updatedRemoveableSection, sectioName },
      "from Edit click List Component"
    );
    setRemoveableSections(updatedRemoveableSection);
  };

  const sectionNameDoneClick = (sectionName: string, sectionLabel: string) => {
    const updatedRemoveableSection = removeableSections?.map((section) => {
      if (section?.value === sectionName) {
        return { ...section, label: sectionLabel, isEditable: false };
      }
      return section;
    });
    setRemoveableSections(updatedRemoveableSection);
    extractedData.customSections &&
      dispatch(
        setExtractedData({
          ...extractedData,
          customSections: {
            ...extractedData.customSections,
            [sectionName]: {
              ...extractedData.customSections?.[sectionName],
              label: sectionLabel,
            },
          },
        })
      );
  };

  return (
    <Box className="summary-container">
      <Box className="professional-summary-section">
        <Box
          className="heading-box"
          sx={{ justifyContent: "space-arround", alignItems: "center" }}
        >
          <Box className="icon-heading-cont">
            <Typography variant="h5" color="color.primary" className="heading">
              Professional Summary
            </Typography>
          </Box>

          <ImproveResumeBtn
            section={"professionalSummary"}
            value={extractedData.professionalSummary}
            handleGenerateWithAI={() => setProfessionalModal(true)}
          />
          {/* <GetHelpWithWriting section={"careerObjective"} value={extractedData.careerObjective} inputRef={careerObjectiveInputRef}/> */}
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 2,
              background: "linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
              borderRadius: "5.71px",
              cursor: "pointer",
              color: "#FFF",
              px: 1,
              py: 0.5,
            }}
            // onClick={handleClick}
          >
            <AiIcon />{" "}
            <Typography
              variant="caption"
              sx={{ marginLeft: "0.3rem", fontSize: "1rem", fontWeight: "500" }}
              onClick={() => setProfessionalModal(true)}
            >
              Generate with AI
            </Typography>
          </Box>
        </Box>

        <Box className="professional-summary">
          <Box className="quill-container">
            <ReactQuill
              ref={careerObjectiveInputRef}
              placeholder="Add the relevant description."
              value={summary}
              onChange={(e) => handleSummaryChange(e)}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>
      </Box>

      {removeableSections.map((section) => {
        if (section?.value === "languages") {
          return (
            <Languages
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
            />
          );
        } else if (section?.value === "hobbies") {
          return (
            <Hobbies
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
            />
          );
        } else if (section?.value === "courses") {
          return (
            <CoursesList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "projects") {
          return (
            <ProjectsList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "awards") {
          return (
            <AwardsList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "certifications") {
          return (
            <CertificationList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "references") {
          return (
            <ReferencesList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "volunteerWorks") {
          return (
            <VolunteerWorksList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "internships") {
          return (
            <InternshipList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "publications") {
          return (
            <PublicationsList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "goals") {
          return (
            <GoalsList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else if (section?.value === "keyAchievements") {
          return (
            <KeyAchievementList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        } else {
          /**
           * Below else block is to render all custom Sections
           */
          return (
            <CustomSectionList
              handleSectionDelete={handleSectionDelete}
              sectionName={section?.value}
              sectionLabel={section?.label}
              sectionNameEditClick={sectionNameEditClick}
              isEditable={section?.isEditable || false}
              sectionNameDoneClick={sectionNameDoneClick}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          );
        }
      })}

      <Box className="other-section-container">
        <Box className="heading-box">
          <Box className="icon-heading-cont">
            <Typography
              variant="h5"
              color="color.primary"
              className="heading"
              paddingTop="1.75rem"
            >
              Other
            </Typography>
          </Box>
        </Box>

        <Box className="other-section-content">
          {otherSectionList?.map((ots) => {
            return ots.visible ? (
              <Box
                className="chip-wrapper"
                onClick={() =>
                  handleAddOtherSectionAction({
                    label: ots.label,
                    value: ots.value,
                  })
                }
              >
                <Box className="icon-container">{ots.icon}</Box>

                <Box className="text-cont">
                  <Typography className="other-name">{ots.label}</Typography>
                </Box>
                <Box className="action-cont">
                  <Box sx={{ paddingRight: "0.2rem" }}>
                    <AddSectionIcon />
                  </Box>
                </Box>
              </Box>
            ) : null;
          })}
        </Box>
      </Box>

      <Modal
        onClose={() =>
          setSummarySectionModal((prev) => ({
            ...prev,
            modalState: false,
            sectionDelete: false,
          }))
        }
        open={summarySectionModal?.modalState}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={summarSectionModalstyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              pt: 3,
              pl: 3,
              pr: 3,
              pb: 3,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1.75rem",
              }}
            >
              <RemoveSectionIcon />
              <Typography
                sx={{
                  flex: "1",
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "#344054",
                }}
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Remove Section
              </Typography>
              <Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    setSummarySectionModal((prev) => ({
                      ...prev,
                      modalState: false,
                      sectionDelete: false,
                    }))
                  }
                >
                  <CancelModalIcon />
                </Box>
              </Box>
            </Box>

            <Typography
              sx={{ fontSize: "18px", fontWeight: "400", color: "#475569" }}
              id="keep-mounted-modal-description"
            >
              Are you sure you want to delete this section ?
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                padding: "0.5rem 0",
                color: "#344054",
              }}
            >
              "{summarySectionModal?.sectionName}"
            </Typography> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              gap: "1rem",
              pt: 0.5,
              pr: 3,
              pb: 3,
            }}
          >
            <Button
              onClick={() =>
                setSummarySectionModal((prev) => ({
                  ...prev,
                  sectionDelete: true,
                }))
              }
              sx={{ border: "1px solid #cbd5e1", color: "#182230" }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                setSummarySectionModal((prev) => ({
                  ...prev,
                  modalState: false,
                  sectionDelete: false,
                }))
              }
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {
        <ProfessionalSummaryModal
          professionalModal={professionalModal}
          setProfessionalModal={setProfessionalModal}
          summary={summary}
          setSummary={setSummary}
          tooltip={
            extractedData.tailoredResumeObject?.tailoredResumeFeedback?.professionalSummary?.suggestionsForImprovement
          }
        />
      }
    </Box>
  );
};

export default Summary;
