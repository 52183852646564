import { View, Text, StyleSheet } from "@react-pdf/renderer";
import {
  CourseType,
  IReference,
  IResume,
  TCertifications,
} from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const CertificationsTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
}: {
  extractedData: IResume;
  isATSPreview: boolean;
  styleVariables: any;
}) => {
  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
  });

  return (
    <>
      {extractedData.certifications &&
        extractedData.certifications?.length > 0 && (
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>CERTIFICATIONS</Text>
            {extractedData.certifications.map(
              (ca: TCertifications, index: number) => (
                <View key={index}>
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                    }}
                  >
                    {ca.title}
                  </Text>
                  <Text style={styles.sectionContent}>{ca?.authority}</Text>
                  <Text style={styles.sectionContent}>{ca?.date}</Text>
                  <Text style={styles.sectionContent}>{ca?.url}</Text>
                  {ca?.description && (
                    <Text style={styles.sectionContent}>{ca?.description}</Text>
                  )}
                </View>
              )
            )}
          </View>
        )}
    </>
  );
};

export default CertificationsTemplate;
