import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import {
  capitalizeFirstLetter,
  CourseType,
  defaultProfilePicture,
  EducationTypes,
  IReference,
  IResume,
  isValueExist,
  TAwards,
  TCertifications,
  TInternships,
  TPublications,
  TReference,
  TVolunterrWorks,
  WorkExperienceTypes,
} from "../components/utils";
import parse, { DOMNode, domToReact, Element } from "html-react-parser";
import {
  convertChildNodesToDOMNodes,
  hasObjTruthyValue,
  isPhoneNumberExists,
} from "../components/common/CommonFunctions";

Font.register({
  family: "Calibri",
  src: `${process.env.REACT_APP_API_URL}/Calibri.ttf`,
});

Font.register({
  family: "Raleway",
  src: `${process.env.REACT_APP_API_URL}/Raleway.ttf`,
});

Font.register({
  family: "Raleway Bold",
  src: `${process.env.REACT_APP_API_URL}/Raleway Bold.ttf`,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  leftColumn: {
    flex: 0.5,
    padding: 20,
    backgroundColor: "#fff2cc", // Set the background color
  },
  rightColumn: {
    flex: 1, // Occupy half of the page width
    marginLeft: 20, // Add a margin between columns
  },
  imageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  name: {
    fontSize: 30,
    fontFamily: "Raleway Bold",
    marginBottom: 10,
    color: "#434343",
  },
  jobTitle: {
    fontSize: 14,
    fontFamily: "Calibri",
    color: "#000000",
  },
  mt20: {
    marginTop: 20,
  },
  mb0: {
    marginBottom: 0,
  },
  mb10: {
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 16,
    color: "#434343",
    textTransform: "uppercase",
    fontFamily: "Raleway Bold",
    marginTop: 10,
    marginBottom: 10,
  },
  customSubSection: {
    fontSize: 14,
  },
  sectionSubHeader: {
    color: "#000000",
    fontFamily: "Calibri",
    fontSize: 14,
    textTransform: "capitalize",
  },
  sectionContent: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  skills: {
    fontSize: 12,
    marginBottom: 10,
  },
  languages: {
    fontSize: 12,
    marginBottom: 10,
  },
  contact: {
    fontSize: 12,
    marginTop: 20,
  },
  atsIssue: {
    position: "relative",
  },
  atsIssueHigh: {
    padding: 5,
    border: "1.5px solid #EF4444",
    backgroundColor: "rgba(255, 182, 182, 0.20)",
  },
  atsIssueMedium: {
    padding: 5,
    border: "1.5px solid #FF8A1C",
    backgroundColor: "rgba(255, 224, 156, 0.20)",
  },
  atsIssueLow: {
    padding: 5,
    border: "1.5px solid #E9CB33",
    backgroundColor: "rgba(233, 203, 51, 0.20)",
  },
  atsIssueFixed: {
    padding: 5,
    border: "1px solid #19A44C",
  },
  fixATSButton: {
    width: 20,
    height: 20,
    position: "absolute",
    right: -10,
    top: -10,
  },
  fixATSButtonImg: {
    width: 20,
    height: 20,
  },
  flexCenter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginLeft: 30,
  },
});

const Template_3 = (props: any) => {
  let {
    extractedData,
    pageWidth,
    isATSPreview = false,
  }: { extractedData: any; pageWidth: any; isATSPreview: boolean } = props;

  /* if(isATSPreview)
  extractedD
  } */

  const renderToolsTechnicalSkills = (skills: any[]) => {
    // Return null for falsy values or empty arrays
    if (!skills || skills.length === 0) {
      return null;
    }

    // Filter out null/undefined values
    const validSkills = skills.filter(
      (item) => item !== null && item !== undefined
    );

    // Return null if no valid skills remain
    if (validSkills.length === 0) {
      return null;
    }

    // Check the type of the first valid element
    const isObjectArray = typeof validSkills[0] === "object";

    return validSkills.map((item: any, index: number) => {
      if (isObjectArray) {
        return (
          <Text key={index} style={styles.skills}>
            {item.label}
          </Text>
        );
      }
      return (
        <Text key={index} style={styles.skills}>
          {item}
        </Text>
      );
    });
  };

  console.log(extractedData, "from PDF template_3");

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, width: pageWidth }}>
        <View style={styles.leftColumn}>
          {(isPhoneNumberExists(extractedData?.phone) ||
            extractedData.email !== "" ||
            extractedData.location !== "" ||
            hasObjTruthyValue(extractedData?.socialLinks)) && (
            <View>
              {extractedData.phone && (
                <View
                  style={{
                    ...styles.contact,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/img/Template_3/phone_icon.png`}
                    style={{
                      width: "20px",
                      height: "17.5px",
                      marginRight: "10px",
                    }}
                  />
                  <Text>
                    {Array.isArray(extractedData.phone)
                      ? extractedData.phone.join(", ")
                      : extractedData.phone}
                  </Text>
                </View>
              )}
              <View
                style={{
                  ...styles.contact,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  src={`${process.env.REACT_APP_API_URL}/img/Template_3/mail_icon.png`}
                  style={{
                    width: "20px",
                    height: "17.5px",
                    marginRight: "10px",
                  }}
                />
                <Text>{extractedData.email}</Text>
              </View>
              <View
                style={{
                  ...styles.contact,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  src={`${process.env.REACT_APP_API_URL}/img/Template_3/location_icon.png`}
                  style={{
                    width: "20px",
                    height: "17.5px",
                    marginRight: "10px",
                  }}
                />
                <Text>{extractedData.location}</Text>
                {(extractedData?.country || extractedData?.city_state) && (
                  <Text style={styles.sectionContent}>
                    {extractedData?.city_state}
                    {extractedData?.city_state && ","} {extractedData?.country}
                  </Text>
                )}
              </View>
              {Object.keys(extractedData?.socialLinks)?.map((key) => {
                if (extractedData?.socialLinks?.[key]) {
                  return (
                    <Text style={styles.contact}>
                      {extractedData?.socialLinks?.[key]}
                    </Text>
                  );
                }
              })}
            </View>
          )}

          {extractedData.professionalSummary !== "NA" &&
            extractedData.professionalSummary && (
              <>
                <View
                  style={
                    isATSPreview
                      ? { ...styles.atsIssueFixed, marginTop: 10 }
                      : {}
                  }
                >
                  {/* <Image src={"https://tekno.aiats-fixed-icon.png"} style={{...styles.fixATSButton}}/> */}
                  <Text style={styles.sectionHeader}>PROFESSIONAL SUMMARY</Text>
                  <Text style={{ ...styles.sectionContent }}>
                    {extractedData.professionalSummary?.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    )}
                  </Text>
                </View>
              </>
            )}

          {extractedData.toolsTechnicalSkills &&
            extractedData.toolsTechnicalSkills.length > 0 && (
              <View
                style={
                  isATSPreview
                    ? { ...styles.atsIssueMedium, marginTop: 20 }
                    : { marginTop: 20 }
                }
              >
                <Text style={styles.sectionHeader}>SKILLS</Text>
                {renderToolsTechnicalSkills(extractedData.toolsTechnicalSkills)}
              </View>
            )}

          {extractedData.nonTechnicalSkills &&
            extractedData.nonTechnicalSkills.length > 0 && (
              <View
                style={
                  isATSPreview
                    ? { ...styles.atsIssueMedium, marginTop: 20 }
                    : { marginTop: 20 }
                }
              >
                <Text style={styles.sectionHeader}>SOFT SKILLS</Text>
                {isATSPreview && (
                  <View style={styles.fixATSButton}>
                    <Image
                      src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                      style={styles.fixATSButtonImg}
                    />
                    <View
                      style={{
                        ...styles.fixATSButtonImg,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <Text style={{ fontSize: "0.1px", color: "transparent" }}>
                        ATS_ISSUE-NON_TECHNICAL_SKILLS
                      </Text>
                    </View>
                  </View>
                )}
                {renderToolsTechnicalSkills(extractedData.nonTechnicalSkills)}
              </View>
            )}

          {extractedData.programmingLanguages?.length > 0 && (
            <View
              style={
                isATSPreview
                  ? { ...styles.atsIssueLow, marginTop: 20 }
                  : { marginTop: 20 }
              }
            >
              <Text style={styles.sectionHeader}>LANGUAGES</Text>
              {isATSPreview && (
                <View style={styles.fixATSButton}>
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                    style={styles.fixATSButtonImg}
                  />
                  <View
                    style={{
                      ...styles.fixATSButtonImg,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <Text style={{ fontSize: "0.1px", color: "transparent" }}>
                      ATS_ISSUE-LANGUAGES
                    </Text>
                  </View>
                </View>
              )}
              {extractedData.programmingLanguages &&
                extractedData.programmingLanguages.map(
                  (language: string, index: number) => (
                    <Text key={index} style={styles.languages}>
                      • {language}
                    </Text>
                  )
                )}
            </View>
          )}

          {extractedData?.languages && extractedData?.languages?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>LANGUAGES</Text>
              {extractedData?.languages?.map((language: string) => (
                <View>
                  <Text style={styles.sectionContent}>{language}</Text>
                </View>
              ))}
            </View>
          )}

          {extractedData?.hobbies && extractedData?.hobbies?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>HOBBIES</Text>
              {extractedData?.hobbies?.map((hob: string) => (
                <View>
                  <Text style={styles.sectionContent}>{hob}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
        <View style={styles.rightColumn}>
          <View
            style={{
              ...styles.imageSectionWrapper,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {extractedData?.profilePicture &&
              extractedData?.profilePicture !== "NA" &&
              extractedData.profilePicture !== defaultProfilePicture && (
                <View style={{ width: "130px", flexShrink: 0 }}>
                  <Image
                    src={extractedData.profilePicture}
                    style={{ width: 130 }}
                  />
                </View>
              )}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
                flex: 1,
              }}
            >
              <Text
                style={{
                  ...styles.name,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {extractedData.fullName}
              </Text>
              <Text style={styles.jobTitle}>{extractedData.jobTitle}</Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            {extractedData.workExperiences &&
              extractedData.workExperiences.length > 0 && (
                <Text style={styles.sectionHeader}>WORK EXPERIENCE</Text>
              )}
            {extractedData.workExperiences &&
              extractedData.workExperiences.map(
                (exp: WorkExperienceTypes, index: number) => (
                  <View
                    key={index}
                    style={
                      isATSPreview
                        ? index === 0
                          ? { ...styles.atsIssueHigh, marginBottom: 10 }
                          : index === 1
                          ? { ...styles.atsIssueFixed, marginBottom: 10 }
                          : {}
                        : {}
                    }
                  >
                    {isATSPreview && (
                      <View style={styles.fixATSButton}>
                        {index === 0 && (
                          <>
                            <Image
                              src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                              style={styles.fixATSButtonImg}
                            />
                            <View
                              style={{
                                ...styles.fixATSButtonImg,
                                position: "absolute",
                                top: 0,
                                left: 0,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: "0.1px",
                                  color: "transparent",
                                }}
                              >
                                ATS_ISSUE-WORK_EXPERIENCE-{exp.index}
                              </Text>
                            </View>
                          </>
                        )}
                        {index === 1 && (
                          <Image
                            src={`${process.env.REACT_APP_API_URL}/img/ats-fixed-icon.png`}
                            style={styles.fixATSButtonImg}
                          />
                        )}
                      </View>
                    )}
                    <Text
                      style={{
                        ...styles.sectionContent,
                        ...styles.mb0,
                        fontFamily: "Raleway Bold",
                        fontSize: "15px",
                        color: "#434343",
                        marginTop: "10px",
                        marginBottom: "8px",
                      }}
                    >
                      {exp.role}
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.sectionHeader,
                          ...styles.sectionSubHeader,
                          marginTop: 0,
                        }}
                      >
                        {exp.company}
                      </Text>
                      <Text style={styles.sectionContent}>
                        {exp.startDate as any} - {exp.endDate as any}
                      </Text>
                    </View>
                    <Text style={{ ...styles.sectionContent, ...styles.mb0 }}>
                      {parse(exp.keyResponsibilities, {
                        replace: (domNode) => {
                          // renderHTMLToPDFText(domNode)
                          // console.log(domNode,"from HTML")
                          if (domNode.type === "tag") {
                            const childrenAsDomNodes =
                              convertChildNodesToDOMNodes(
                                domNode.children as unknown as ChildNode[]
                              );
                            return (
                              <Text style={styles.sectionContent}>
                                {domToReact(childrenAsDomNodes)}
                              </Text>
                            );
                          }
                          return undefined;
                        },
                      })}
                    </Text>
                  </View>
                )
              )}
          </View>
          {extractedData.educationalDetails &&
            extractedData.educationalDetails.length > 0 && (
              <View style={{ marginTop: 20 }}>
                <Text style={styles.sectionHeader}>EDUCATION</Text>
                {extractedData.educationalDetails.map(
                  (edu: EducationTypes, index: number) => (
                    <View key={index}>
                      <Text
                        style={{
                          ...styles.sectionContent,
                          ...styles.mb0,
                          fontFamily: "Raleway Bold",
                          fontSize: "15px",
                          color: "#434343",
                          marginTop: "10px",
                          marginBottom: "8px",
                        }}
                      >
                        {edu.degree} in {edu.specialization}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.sectionHeader,
                            ...styles.sectionSubHeader,
                            marginTop: 0,
                          }}
                        >
                          {edu.universityCollege}
                        </Text>
                        <Text
                          style={{ ...styles.sectionContent, ...styles.mb0 }}
                        >
                          {edu.startDate as any} - {edu.endDate as any}
                        </Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            )}

          {extractedData.certifications &&
            extractedData.certifications?.length > 0 && (
              <View style={{ marginTop: 20 }}>
                <Text style={styles.sectionHeader}>CERTIFICATIONS</Text>
                {extractedData.certifications.map(
                  (ca: TCertifications, index: number) => (
                    <View key={index}>
                      <Text
                        style={{
                          ...styles.sectionHeader,
                          ...styles.sectionSubHeader,
                        }}
                      >
                        {ca.title}
                      </Text>
                      <Text style={styles.sectionContent}>{ca?.authority}</Text>
                      <Text style={styles.sectionContent}>{ca?.date}</Text>
                      <Text style={styles.sectionContent}>{ca?.url}</Text>
                      {ca?.description && (
                        <Text style={styles.sectionContent}>
                          {ca?.description}
                        </Text>
                      )}
                    </View>
                  )
                )}
              </View>
            )}

          {extractedData.courses && extractedData.courses?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>COURSES</Text>
              {extractedData.courses.map((cour: CourseType, index: number) => (
                <View key={index}>
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                    }}
                  >
                    {cour.organization}
                  </Text>
                  <Text style={{ ...styles.sectionContent, ...styles.mb0 }}>
                    {cour.startDate as any} - {cour.endDate as any}
                  </Text>
                  <Text style={styles.sectionContent}>
                    {cour?.courseDescription}
                  </Text>
                </View>
              ))}
            </View>
          )}

          {extractedData.projects && extractedData.projects?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>PROJECTS</Text>
              {extractedData.projects.map((proj: CourseType, index: number) => (
                <View key={index}>
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                    }}
                  >
                    {proj.organization}
                  </Text>
                  <Text style={{ ...styles.sectionContent, ...styles.mb0 }}>
                    {proj.startDate as any} - {proj.endDate as any}
                  </Text>
                  <Text style={styles.sectionContent}>
                    {proj?.courseDescription}
                  </Text>
                </View>
              ))}
            </View>
          )}

          {extractedData.awards && extractedData.awards?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>AWARDS</Text>
              {extractedData.awards.map((awd: TAwards, index: number) => (
                <View key={index}>
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                    }}
                  >
                    {awd.awardTitle}
                  </Text>
                  <Text style={styles.sectionContent}>{awd?.recievedDate}</Text>
                  {awd?.awardDescription && (
                    <Text style={styles.sectionContent}>
                      {awd?.awardDescription}
                    </Text>
                  )}
                </View>
              ))}
            </View>
          )}

          {extractedData.references && extractedData.references?.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>REFERENCES</Text>
              {extractedData.references.map(
                (ref: TReference, index: number) => (
                  <View key={index}>
                    <Text
                      style={{
                        ...styles.sectionHeader,
                        ...styles.sectionSubHeader,
                      }}
                    >
                      {ref?.referentName} at {ref.company}{" "}
                    </Text>
                    <Text style={styles.sectionContent}>{ref?.phone}</Text>
                    <Text style={styles.sectionContent}>{ref?.email}</Text>
                  </View>
                )
              )}
            </View>
          )}

          {extractedData.volunteerWorks &&
            extractedData.volunteerWorks?.length > 0 && (
              <View style={{ marginTop: 20 }}>
                <Text style={styles.sectionHeader}>VOLUNTEER WORK</Text>
                {extractedData.volunteerWorks.map(
                  (vol: TVolunterrWorks, index: number) => (
                    <View key={index}>
                      <Text
                        style={{
                          ...styles.sectionHeader,
                          ...styles.sectionSubHeader,
                        }}
                      >
                        {vol.instituitionName} at {vol?.location}
                      </Text>
                      <Text style={styles.sectionContent}>{vol?.role}</Text>
                      <Text style={styles.sectionContent}>
                        {vol?.startDate} to {vol?.endDate}
                      </Text>
                    </View>
                  )
                )}
              </View>
            )}

          {extractedData.internships &&
            extractedData.internships?.length > 0 && (
              <View style={{ marginTop: 20 }}>
                <Text style={styles.sectionHeader}>INTERNSHIPS</Text>
                {extractedData.internships.map(
                  (ca: TInternships, index: number) => (
                    <View key={index}>
                      <Text
                        style={{
                          ...styles.sectionHeader,
                          ...styles.sectionSubHeader,
                        }}
                      >
                        {ca.internshipTitle}
                      </Text>
                      <Text style={styles.sectionContent}>
                        {ca?.organization} at {ca?.location}
                      </Text>
                      <Text style={styles.sectionContent}>
                        {ca?.startDate} to {ca?.endDate}
                      </Text>
                      {ca?.description && (
                        <Text style={styles.sectionContent}>
                          {ca?.description}
                        </Text>
                      )}
                    </View>
                  )
                )}
              </View>
            )}

          {extractedData.publications &&
            extractedData.publications?.length > 0 && (
              <View style={{ marginTop: 20 }}>
                <Text style={styles.sectionHeader}>PUBLICATIONS</Text>
                {extractedData.publications.map(
                  (ca: TPublications, index: number) => (
                    <View key={index}>
                      <Text
                        style={{
                          ...styles.sectionHeader,
                          ...styles.sectionSubHeader,
                        }}
                      >
                        {ca.publicationTitle}
                      </Text>
                      <Text style={styles.sectionContent}>
                        {ca?.publisher}, {ca?.publicationDate}.
                      </Text>
                      {ca?.url && (
                        <Text style={styles.sectionContent}>{ca?.url}</Text>
                      )}
                      {ca?.description && (
                        <Text style={styles.sectionContent}>
                          {ca?.description}
                        </Text>
                      )}
                    </View>
                  )
                )}
              </View>
            )}

          {Object.keys(extractedData.customSections) &&
            Object.keys(extractedData.customSections)?.length > 0 &&
            Object.keys(extractedData.customSections)?.map(
              (section: string) => (
                <View style={{ marginTop: 20 }}>
                  <Text style={styles.sectionHeader}>
                    {extractedData.customSections?.[section]?.label}
                  </Text>
                  {extractedData.customSections?.[section]?.value?.map(
                    (cs: IReference, index: number) => (
                      <View key={index}>
                        <Text
                          style={{
                            ...styles.sectionHeader,
                            ...styles.sectionSubHeader,
                            ...styles.customSubSection,
                          }}
                        >
                          {cs.organization}
                        </Text>
                        <Text style={styles.sectionContent}>
                          {cs?.courseDescription}
                        </Text>
                        {cs?.link && (
                          <Text style={styles.sectionContent}>{cs?.link}</Text>
                        )}
                      </View>
                    )
                  )}
                </View>
              )
            )}
        </View>
      </Page>
    </Document>
  );
};

export default Template_3;
