import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5027_7677)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M142 12.1247L126.941 38.2583H96.8244L81.7659 12.1247L96.8244 -14H126.941L142 12.1247Z"
          fill="#6DBAB7"
        />
        <path
          d="M90.8565 42.6382L75.798 68.7637H45.6809L30.6216 42.6382L45.6809 16.5135H75.798L90.8565 42.6382Z"
          fill="#6DBAB7"
        />
        <path
          d="M142 72.2161L126.941 98.3497H96.8244L81.7659 72.2241V72.2161L96.8244 46.0914H126.941L142 72.2161Z"
          fill="#6DBAB7"
        />
        <path
          d="M90.8484 100.874L75.798 127H45.6809L30.6216 100.874L45.6809 74.7497H75.798L90.8484 100.874Z"
          fill="#6DBAB7"
        />
        <path
          d="M41.2261 72.1484V72.1565L26.1676 98.282H-3.94147L-19 72.1484L-3.94147 46.0237H26.1676L41.2261 72.1484Z"
          fill="#6DBAB7"
        />
        <path
          d="M557.027 771.522L537.014 806.045H496.986L476.973 771.522L496.986 737H537.014L557.027 771.522Z"
          fill="#6DBAB7"
        />
        <path
          d="M625 810.608L604.987 845.141H564.959L544.946 810.618V810.608L564.959 776.085H604.987L625 810.608Z"
          fill="#6DBAB7"
        />
        <path
          d="M557.017 848.478L537.014 883H496.986L476.973 848.478L496.986 813.955H537.014L557.017 848.478Z"
          fill="#6DBAB7"
        />
        <path
          d="M489.044 809.382V809.392L469.03 843.915H429.013L409 809.382L429.013 774.859H469.03L489.044 809.382Z"
          fill="#6DBAB7"
        />
      </g>
      <defs>
        <clipPath id="clip0_5027_7677">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5027_7677)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M142 12.1247L126.941 38.2583H96.8244L81.7659 12.1247L96.8244 -14H126.941L142 12.1247Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M90.8565 42.6382L75.798 68.7637H45.6809L30.6216 42.6382L45.6809 16.5135H75.798L90.8565 42.6382Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M142 72.2161L126.941 98.3497H96.8244L81.7659 72.2241V72.2161L96.8244 46.0914H126.941L142 72.2161Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M90.8484 100.874L75.798 127H45.6809L30.6216 100.874L45.6809 74.7497H75.798L90.8484 100.874Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M41.2261 72.1484V72.1565L26.1676 98.282H-3.94147L-19 72.1484L-3.94147 46.0237H26.1676L41.2261 72.1484Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M557.027 771.522L537.014 806.045H496.986L476.973 771.522L496.986 737H537.014L557.027 771.522Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M625 810.608L604.987 845.141H564.959L544.946 810.618V810.608L564.959 776.085H604.987L625 810.608Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M557.017 848.478L537.014 883H496.986L476.973 848.478L496.986 813.955H537.014L557.017 848.478Z"
          fill={props?.c1 || "#6DBAB7"}
        />
        <Path
          d="M489.044 809.382V809.392L469.03 843.915H429.013L409 809.382L429.013 774.859H469.03L489.044 809.382Z"
          fill={props?.c1 || "#6DBAB7"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5027_7677">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture28 = {
  ViewTexture,
  PdfTexture,
};

export default Texture28;
