import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import {
  ApplyToNewJobsIllustration,
  ATSCheckIllustration,
  CreateResumeIllustration,
  Illustration_3,
} from "../Icons";
import { useSelector } from "react-redux";
import { AutoAwesome, Close } from "@mui/icons-material";
import HomeScreenTabs from "../HomeScreenTabs/HomeScreenTabs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Layout from "../Layout";
import { GradientButton } from "../button/GradientButton";
import { getUsernameFromToken } from "../../services/getUserFromToken";
import CustomizedAlert from "../CustomizedAlert";
// import {ReactComponent as CloseIcon} from "../../assets/SVG/chip-cross-icon.svg"
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import {
  extractFaceFromPDF,
  extractText,
  fetchResumeById,
  fetchResumes,
  generateContent,
  generateContentForSinglePageResume,
  withAuthHandling,
} from "../../services/apiService";
import { useDispatch } from "react-redux";
import { WorkExperienceTypes } from "../utils";
import {
  setExtractedData,
  setSelectedTemplateId,
  showSnackbar,
} from "../../redux/actions";
import { initialState } from "../../redux/reducer";
import { seprateExtractedBulletPointsStringToArray } from "../common/CommonFunctions";
import SinglePageConversionModal from "./SinglePageConversionModal";
import * as icons from "simple-icons";

const GreenBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    background: "linear-gradient(90deg, #00D327 0%, #3D81FF 100%)",
    color: "#fff", // White text color
    transform: "translate(100%, -50%)", // Position as superscript
    paddingLeft: "8px",
    paddingRight: "10px",
  },
}));

const UserDashboard = () => {
  let loggedInUserData = useSelector((state: any) => state?.loggedInUserData);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [
    isExtractionAndGenerationInProgress,
    setIsExtractionAndGenerationInProgress,
  ] = useState(false);
  const [
    isSinglePageConversionInProgress,
    setIsSinglePageConversionInProgress,
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResumes, setFilteredResumes] = useState([]);
  const [resumes, setResumes] = useState([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchWithAuthHandling = withAuthHandling(navigate);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const processExtractedData = async (
    resumeObj: any,
    resumeActionType: string
  ) => {
    const username = getUsernameFromToken();

    resumeObj =
      resumeActionType === "single-page-conversion"
        ? {
            ...resumeObj,
            ...(await generateContentForSinglePageResume(resumeObj, dispatch)),
          }
        : { ...resumeObj, title: username + "'s Resume" || "" };

    // Formatting resume object for projects section
    let formattedResumeObject = resumeObj;
    if (resumeObj?.projects?.[0]) {
      const formattedProjects = resumeObj?.projects?.map((proj: any) => ({
        ...proj,
        organization: proj?.title,
        courseDescription: proj?.description,
      }));
      formattedResumeObject = {
        ...formattedResumeObject,
        projects: formattedProjects,
      };
    }
    if (resumeObj?.workExperiences?.[0]) {
      const formattedExperience: WorkExperienceTypes =
        resumeObj?.workExperiences?.map((exp: WorkExperienceTypes) => ({
          ...exp,
          jobType: exp?.jobType ?? "fullTime",
          location: exp?.location ?? "",
          keyResponsibilities: seprateExtractedBulletPointsStringToArray(
            exp?.keyResponsibilities
          ),
        }));
      formattedResumeObject = {
        ...formattedResumeObject,
        workExperiences: formattedExperience,
      };
    }

    dispatch(setSelectedTemplateId(initialState.selectedTemplateId));
    return formattedResumeObject;
  };

  const handleFileSelect = async (
    file: File,
    resumeActionType: string = ""
  ) => {
    if (file) {
      setIsExtractionAndGenerationInProgress(true);
      const formData = new FormData();
      formData.append("file", file);
      console.log(process.env.REACT_APP_API_URL, "from API");

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      try {
        const { textContent } = await extractText(
          formData,
          fetchWithAuthHandling
        );
        const requestData = { content: textContent } as any;
        // const endpointURL =  `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/convert-resume-to-single-page` : `${process.env.REACT_APP_BACKEND_DOTNET_API_URL}/generate-content`;
        let resumeObj = await generateContent(
          requestData.content,
          fetchWithAuthHandling,
          dispatch
        );

        setIsExtractionAndGenerationInProgress(false);
        /**
         * Converting pdf to image
         */
        const reader: any = new FileReader();
        reader.onloadend = async () => {
          const base64string = reader.result.split(",")[1];
          const extractedFace = await extractFaceFromPDF(base64string);
          if (extractedFace) {
            resumeObj.profilePicture = extractedFace;
          }
        };
        reader.readAsDataURL(file);
        console.log("extracted resumeObj", resumeObj);
        const formattedResumeObject = await processExtractedData(
          resumeObj,
          resumeActionType
        );

        // Check if values exist and aren't "NA"
        const isValidFullName =
          formattedResumeObject.fullName &&
          formattedResumeObject.fullName !== "NA";
        const isValidDesignation =
          formattedResumeObject.jobTitle &&
          formattedResumeObject.jobTitle !== "NA";

        // Create title with whichever valid parts exist
        const title =
          [
            isValidFullName ? formattedResumeObject.fullName : "",
            isValidDesignation ? formattedResumeObject.jobTitle : "",
          ]
            .filter(Boolean) // Remove empty strings
            .join("_") +
          (isValidFullName || isValidDesignation ? "_Resume" : "Resume");

        dispatch(setExtractedData({ ...formattedResumeObject, title }));
        navigate(`/create-resume/new/personal-details`);
      } catch (error: any) {
        console.error("Error uploading file:", error);
        dispatch(
          showSnackbar(
            error.message || "Failed to upload and process the resume.",
            "error"
          )
        );
      } finally {
        setIsLoading(false);
        setIsExtractionAndGenerationInProgress(false);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: any) => {
      if (acceptedFiles[0]) {
        setIsSinglePageConversionInProgress(true);
        await handleFileSelect(acceptedFiles[0], "single-page-conversion");
        setIsSinglePageConversionInProgress(false);
      }
    },
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = resumes.filter((resume: any) =>
      resume.title?.toLowerCase().includes(query)
    );
    setFilteredResumes(filtered);
  };

  const handleCancelProcessing = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsLoading(false);
    setIsSinglePageConversionInProgress(false);
    dispatch(setExtractedData(initialState.extractedData));
    navigate(`/dashboard`);
  };

  const handleSinglePageConversionClick = async (resumeId: string) => {
    setIsSinglePageConversionInProgress(true);
    setIsExtractionAndGenerationInProgress(true);
    const resumeObject = await fetchResumeById(
      resumeId,
      fetchWithAuthHandling,
      dispatch
    );
    setIsExtractionAndGenerationInProgress(false);
    const formattedResumeObject = await processExtractedData(
      resumeObject,
      "single-page-conversion"
    );
    delete formattedResumeObject?.id; // If we won't delete Id It will call update resume API instead of add resume API.
    console.log(formattedResumeObject, "before coversions");
    const singlePageName = resumeObject?.title ? resumeObject?.title : username;
    dispatch(
      setExtractedData({
        ...formattedResumeObject,
        title: singlePageName + "_SinglePage" || "",
      })
    );
    navigate(`/create-resume/new/personal-details`);
    setIsSinglePageConversionInProgress(false);
  };

  useEffect(() => {
    const loadResumes = async () => {
      resumes.length === 0 && setLoading(true);

      try {
        const data = await fetchResumes(fetchWithAuthHandling);
        setResumes(data);
        setFilteredResumes(data); // Initialize filtered resumes
      } catch (error) {
        console.error("Error fetching resumes:", error);
      } finally {
        setLoading(false);
      }
    };

    loadResumes();
  }, []);

  useEffect(() => {
    if (location.state?.isLoginSuccessful) {
      setOpenSnackbar(true);
    }
  }, [location]);

  const username = loggedInUserData?.fullName
    ? loggedInUserData?.fullName
    : getUsernameFromToken();

  return (
    <Layout sx={{ m: 2 }} title={`Welcome, ${username}`}>
      <CustomizedAlert
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        alertState={openSnackbar}
        title={"Welcome back!"}
        description={"You've successfully logged in."}
        severity="success"
        handleCloseSnackbar={handleCloseSnackbar}
      />
      {/* <Typography variant="h5" gutterBottom sx={{ fontWeight: "bolder", color: "#344054", mb: 3 }}>
         {loggedInUserData?.firstName} {loggedInUserData?.lastName}
      </Typography> */}

      <Grid container direction="column" spacing={2.5}>
        <Grid item>
          <Card
            sx={{
              borderRadius: "12px",
              border: "0.25px solid #EBEBEB",
              background: "linear-gradient(180deg, #DAE8FF 0%, #FAFCFF 100%)",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                p: 3,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <GreenBadge
                  badgeContent={
                    <>
                      <AutoAwesome sx={{ fontSize: 15, mr: 0.6 }} /> AI Powered
                    </>
                  }
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "500", color: "#002E7C", m: 0 }}
                  >
                    100% Free Resume Builder
                  </Typography>
                </GreenBadge>
                <Typography gutterBottom sx={{ color: "#002E7C", my: 2 }}>
                  Your success story begins with a resume. Discover how our
                  AI-powered platform helps you craft a standout resume and
                  cover letter, land your dream job, and earn what you deserve.
                  With the customizable templates and AI assistance, create a
                  stunning resume in minutes that will impress your future
                  employer.
                </Typography>
                <GradientButton onClick={() => navigate("/my-resumes")}>
                  Get Started
                </GradientButton>
              </Box>
              <Box>
                <Illustration_3 />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {isVisible && (
          <Grid item>
            <Box
              sx={{
                p: 3,
                py: 2,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => setIsVisible(false)}
                sx={{
                  position: "absolute",
                  top: "-1rem",
                  right: "-1rem",
                }}
              >
                <CancelIcon sx={{ color: "#b0b0b0" }} />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bolder", color: "#002E7C" }}
                >
                  Complete your profile!
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Create a brand-new resume or fine-tune that captures your
                  experience and career goals in a few clicks.
                </Typography>
              </Box>

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ mr: 1 }}
              >
                Complete Now
              </Button>
            </Box>
          </Grid>
        )}

        <Grid item container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bolder", color: "#002E7C" }}
                >
                  Create a Resume
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Create a brand-new resume that captures your experience and
                  career goals.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => {
                    navigate("/my-resumes");
                  }}
                >
                  Create Resume
                </Button>
              </Box>
              <CreateResumeIllustration
                style={{
                  maxWidth: "150px",
                  maxHeight: "160px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bolder", color: "#002E7C" }}
                >
                  Ensure ATS Compatibility
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Optimize your resume for Applicant Tracking Systems (ATS) to
                  increase your chances of getting noticed by employers.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => navigate("/ats-checker")}
                >
                  Check Now
                </Button>
              </Box>
              <ATSCheckIllustration
                style={{
                  maxWidth: "150px",
                  maxHeight: "160px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bolder", color: "#002E7C" }}
                >
                  Apply to New Jobs
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Fill in your personal details, education, and experience.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => navigate("/job-board")}
                >
                  Explore
                </Button>
              </Box>
              <ApplyToNewJobsIllustration
                style={{
                  maxWidth: "150px",
                  maxHeight: "160px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <HomeScreenTabs
            setIsPopupOpen={setIsPopupOpen}
            handleSinglePageConversionClick={handleSinglePageConversionClick}
          />
        </Grid>
      </Grid>

      <SinglePageConversionModal
        isPopupOpen={isPopupOpen}
        handleClosePopup={handleClosePopup}
        isExtractionAndGenerationInProgress={
          isExtractionAndGenerationInProgress
        }
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        isSinglePageConversionInProgress={isSinglePageConversionInProgress}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        loading={loading}
        handleCancelProcessing={handleCancelProcessing}
        filteredResumes={filteredResumes}
        handleSinglePageConversionClick={handleSinglePageConversionClick}
      />
    </Layout>
  );
};

export default UserDashboard;
