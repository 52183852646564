import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layout";
import SettingsSidebar from "./Sidebar";
import MyProfile from "./MyProfile";
import Notifications from "./Notifications";
import JobPreferences from "./JobPreferences";
import PasswordAndSecurity from "./PasswordAndSecurity";
import HelpAndSupport from "./HelpAndSupport";
import { useEffect } from "react";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    location.pathname === "/settings" && navigate("/settings/profile");
  }, []);

  return (
    <Layout
      sx={{ p: 2, backgroundColor: "#F8F9FD", flexGrow: 1 }}
      title="Settings"
    >
      <div
        className="settings-page"
        style={{
          display: "flex",
          height: "100%",
          backgroundColor: "#fff",
          flexGrow: 1,
          borderRadius: "8px",
        }}
      >
        <SettingsSidebar />
        <Routes>
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/job-preferences" element={<JobPreferences />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/password" element={<PasswordAndSecurity />} />
          <Route path="/support" element={<HelpAndSupport />} />
        </Routes>
      </div>
    </Layout>
  );
};

export default Settings;
