import Box from "@mui/material/Box";
import { ReactComponent as ProfilePhotoImage } from "../../assets/SVG/Upload-photo-big.svg";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";

const UploadProfilePhoto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const extractedData = useSelector((state: any) => state.extractedData);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          dispatch(
            setExtractedData({
              ...extractedData,
              profilePicture: reader.result.toString(),
            }),
          );
        } else {
          console.error("Failed to read file");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box className="profile-photo-container">
      <Box className="profile-photo-wrapper">
        <Box className="content">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="upload-photo"
            onChange={handleImageChange}
          />
          <label htmlFor="upload-photo">
            <Box className="icon-cont" style={{ cursor: "pointer" }}>
              {extractedData?.profilePicture ? (
                <img
                  src={extractedData?.profilePicture}
                  alt="Uploaded"
                  style={{ maxWidth: "100px" }}
                />
              ) : (
                <ProfilePhotoImage />
              )}
            </Box>
          </label>
          <Typography variant="body1" className="color-primary">
            Upload Photo
          </Typography>
          <Typography variant="h5" color="color.primary">
            Profile Information
          </Typography>
          <Typography variant="h6" className="ui-text-3-color description">
            This will be shown on resume doc/pdf file you export
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadProfilePhoto;
