import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../redux/reducer";
import { pdfjs, Document, Page } from "react-pdf";
import { flattenExtractedData } from "../components/utils";
import { Button } from "@mui/material";
import TemplatePreview from "../templates/TemplatePreview";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ProcessResumePreview = (props: any) => {
  const { currentPage, setCurrentPage, setNumPages, isATSPreview, numPages } =
    props;

  const [pageWidth, setPageWidth] = useState<number>(0);

  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const templateContainer = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //     const generatePdf = async () => {
  //         try {
  //             const payload = {
  //                 data: {
  //                     ...flattenExtractedData(extractedData),
  //                 },
  //             };

  //             const response = await fetch('https://be.tekno.ai/process-word', {
  //                 method: 'POST',
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                 },
  //                 body: JSON.stringify(payload),
  //             });

  //             if (!response.ok) {
  //                 throw new Error('Network response was not ok');
  //             }

  //             const processedBlob = await response.blob();
  //             const docxBlob = new Blob([processedBlob], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

  //             const formData = new FormData();
  //             formData.append('docFile', docxBlob, 'resume.docx');

  //             const pdfResponse = await fetch('https://testapi.tekno.ai/api/convert-doc-to-pdf', {
  //                 method: 'POST',
  //                 body: formData,
  //             });

  //             if (!pdfResponse.ok) {
  //                 throw new Error('Network response was not ok');
  //             }

  //             const pdfBlob = await pdfResponse.blob();
  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //                 setPdfContent(reader.result as string);
  //             };
  //             reader.readAsDataURL(pdfBlob);
  //         } catch (error) {
  //             console.error("Failed to generate pdf file", error);
  //         }
  //     };

  //     generatePdf();
  // }, [extractedData]);

  const handleResize = () => {
    if (templateContainer.current) {
      const containerHeight = templateContainer.current.clientHeight;
      const aspectRatio = 7.9 / 11; // Standard aspect ratio for letter size paper
      const pageWidth = containerHeight * aspectRatio;
      setPageWidth(pageWidth);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (templateContainer.current) {
      resizeObserver.observe(templateContainer.current);
    }

    return () => {
      if (templateContainer.current) {
        resizeObserver.unobserve(templateContainer.current);
      }
    };
  }, []);

  return (
    <Box
      className="preview-template-container"
      ref={templateContainer}
      sx={{
        height: "calc(100vh - 110px)",
        margin: "0px auto",
        // marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Suspense fallback={<div>Loading template...</div>}>
        <TemplatePreview
          pageWidth={pageWidth}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setNumPages={setNumPages}
          isATSPreview={isATSPreview}
          numPages={numPages}
        />
      </Suspense>
    </Box>
  );
};

export default ProcessResumePreview;
