import { NavigateFunction } from "react-router-dom";
import {
  convertToTemplateData,
  flattenExtractedData,
  IResume,
} from "../components/utils";
import {
  setIsPersonalDetailsLoading,
  setLoggedInUserData,
  setSelectedTemplateId,
  showSnackbar,
} from "../redux/actions";
import { initialState } from "../redux/reducer";
import Cookies from "js-cookie";
import { saveToken } from "../components/auth/storeToken";
import { Dispatch } from "react";
import { UnknownAction } from "redux";
import { calculateProfessionalSummaryScore, calculateSkillScores, calculateWorkExperienceScore } from "../ProcessResume/TailorResume/utils";

export const fetchWithAuth = async (
  url: string,
  options: RequestInit = {}
): Promise<Response> => {
  const accessToken = localStorage.getItem("accessToken");
  const updatedOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let response: Response;
  try {
    response = await fetch(
      `${process.env.REACT_APP_BACKEND_DOTNET_API_URL}${url}`,
      updatedOptions
    );
  } catch (error) {
    throw new Error("NetworkError");
  }

  if (response.status === 401) {
    throw new Error("Access token expired");
  } else if (response.status === 404) {
    throw new Error("NotFound");
  } else if (response.status === 500) {
    throw new Error("ServerError");
  } else if (!response.ok) {
    throw new Error("UnhandledError");
  }

  return response;
};

export const withAuthHandling =
  (navigate: NavigateFunction) =>
  async (url: string, options: RequestInit = {}) => {
    try {
      return await fetchWithAuth(url, options);
    } catch (error: any) {
      if (
        error.message === "Access token expired" /* 'Refresh token failed' */
      ) {
        navigate("/login"); // Redirect to login page
      }
      throw error;
    }
  };

export const extractFaceFromPDF = async (base64string: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/extract-face`,
      {
        method: "POST",
        credentials: "include", // Keep credentials
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pdfBase64: base64string }),
      }
    );
    const result = await response.json();
    if (response.ok) {
      return result.faceBase64;
    }
    return null;
  } catch (error) {
    console.error("Error converting PDF:", error);
  }
};

export const extractText = async (
  formData: FormData,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/extract-text", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to extract text");
  }

  return response.json();
};

export const generateContent = async (
  textContent: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>,
  dispatch: any
) => {
  const requestData = { content: textContent };
  try {
    const response = await fetchWithAuthHandling("/get-generate-content", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error("Failed to generate content");
    }

    let responseJson: IResume = await response.json();

    // if response.professionalSummary is array then convert it to string with \n separator, if it's string then return as it is
    if (Array.isArray(responseJson.professionalSummary)) {
      responseJson.professionalSummary =
        responseJson.professionalSummary.join("\n");
    }

    return responseJson;
  } catch (error) {
    dispatch(
      showSnackbar("Failed to generate content. Please try again.", "error")
    );
    throw error;
  }
};

export const generateContentForSinglePageResume = async (
  resumeObject: IResume,
  dispatch: any
) => {
  // Destructure the resumeObject to exclude unwanted properties
  const {
    id,
    title,
    name,
    profilePicture,
    email,
    phone,
    location,
    screenshot,
    tailoredResumeObject,
    templateId,
    socialLinks,
    modifiedDate,
    createdDate,
    country,
    city_state,
    educationalDetails,
    ...filteredResumeObject
  } = resumeObject;

  try {
    const response: any = await fetch(
      `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/convert-resume-to-single-page`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resumeObject: filteredResumeObject }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to generate content");
    }

    //  TODO: This below logic needs to be implemented in API response itself

    const getResponseData = await response.json();

    // Access the data here
    if (getResponseData?.toolsTechnicalSkills?.[0]) {
      const formttedToolsTechnicalSkills =
        getResponseData?.toolsTechnicalSkills?.map((sk: string) => ({
          graphType: "",
          isGraph: false,
          label: sk,
          score: 0,
          total: 0,
          value: sk,
        }));
      getResponseData.toolsTechnicalSkills = formttedToolsTechnicalSkills;
    }
    return getResponseData;
  } catch (error) {
    dispatch(
      showSnackbar("Failed to generate content. Please try again.", "error")
    );
    throw error;
  }
};

export const saveResumeData = async (
  extractedData: any,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  try {
    // Update the existing resume
    const response = await fetchWithAuthHandling(
      `/update-resume?id=${extractedData.id}`,
      {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...extractedData,
          isTailoredResume: false,
          tailoredResumeObject: {
            resumeObjectWithChanges: "",
            matchingScore: 0,
            keywordsToAdd: [],
            missingKeywords: [],
            jobTitle: "",
            companyName: "",
          },
          customSections: [],
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update resume");
    }

    console.log("Resume updated successfully");
  } catch (error) {
    console.error("Error saving data", error);
    throw error;
  }
};

export const addNewResume = async (
  extractedData: any,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  try {
    // Ensure extractedData.phone is an array of strings
    if (typeof extractedData.phone === "string") {
      extractedData.phone = [extractedData.phone];
    }

    const addedResume = await fetchWithAuthHandling("/add-resume", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...extractedData,
        tailoredResumeObject: {
          resumeObjectWithChanges: "",
          matchingScore: 0,
          keywordsToAdd: [],
          missingKeywords: [],
          jobTitle: "",
          companyName: "",
        },
        customSections: [],
      }),
    });

    if (!addedResume.ok) {
      throw new Error("Failed to save resume");
    }

    const addedResumeData = await addedResume.json();
    return addedResumeData;
  } catch (error) {
    console.error("Error adding new resume", error);
    throw error;
  }
};

export const deleteResume = async (
  resumeId: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  try {
    const response = await fetchWithAuthHandling(`/delete-resume/${resumeId}`, {
      method: "DELETE",
    });
    return response.json();
  } catch (error) {
    console.error("Error deleting resume", error);
  }
};

export const fetchResumes = async (
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/get-resumes", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch resumes");
  }

  const data = await response.json();
  const sortedData = data.sort(
    (a: IResume, b: IResume) =>
      new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime()
  );
  return sortedData;
};

export const fetchResumeById = async (
  resumeId: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>,
  dispatch: Dispatch<UnknownAction>
) => {
  try {
    dispatch(setIsPersonalDetailsLoading(true));
    const response = await fetchWithAuthHandling(`/get-resume?id=${resumeId}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch resume");
    }
    // Temp branch change to check the error
    const responseJson = await response.json();
    const res = await convertToTemplateData(responseJson);
    dispatch(setSelectedTemplateId(responseJson.templateId));
    return res;
  } catch (error) {
    console.error("Error fetching resume", error);
    throw error;
  } finally {
    dispatch(setIsPersonalDetailsLoading(false));
  }
};

export const getUserProfile = async (
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/user-profile", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch resumes");
  }

  return response.json();
};

export const processWord = async (extractedData: any) => {
  const payload = {
    data: {
      ...flattenExtractedData(extractedData),
    },
  };

  const serverResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/process-word`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );

  if (!serverResponse.ok) {
    throw new Error("Failed to process the document");
  }
  return serverResponse;
};

export const extractJobDetails = async (jobUrl: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/job-scraper`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: jobUrl }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const jobDescriptionATSChecker = async (
  jobDetails: any,
  resumeObject: any
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/job-description-ats-checker`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobDetails,
        resumeObject,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const tailorResume = async (jobDetails: any, resumeObject: any) => {
  const {
    name,
    profilePicture,
    email,
    phone,
    location,
    tailoredResumeObject,
    socialLinks,
    templateId,
    screenshot,
    modifiedDate,
    createdDate,
    country,
    city_state,
    ...filteredResumeObject
  } = resumeObject;

  const payload = {
    jobDetails,
    resumeObject: filteredResumeObject,
  };

  const endpoints = [
    'tailor-resume-professional-summary',
    'tailor-resume-skills',
    'tailor-resume-work-experiences',
    // 'tailor-resume-educational-details',
  ];

  const payloads: any = {
    'tailor-resume-professional-summary': {
      jobDetails,
      professionalSummary: filteredResumeObject.professionalSummary ? filteredResumeObject.professionalSummary : filteredResumeObject.careerObjective
    },
    'tailor-resume-skills': {
      jobDetails,
      technicalSkills: filteredResumeObject.toolsTechnicalSkills?.map((skill:any) => skill.label) || [],
      softSkills: filteredResumeObject.nonTechnicalSkills?.map((skill:any) => skill.label) || []
    },
    'tailor-resume-work-experiences': {
      jobDetails,
      workExperiences: filteredResumeObject.workExperiences
    },
  };

  const requests = endpoints.map(endpoint =>
    fetch(`${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payloads[endpoint]),
    })
  );

  const responses = await Promise.all(requests);

  responses.forEach(response => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  });

  const results = await Promise.all(responses.map(response => response.json()));

  results[0] = {
    ...results[0],
    ...calculateProfessionalSummaryScore(results[0], jobDetails.requiredSkills, jobDetails.preferredSkills)
  }
  
  results[1] = {
    ...calculateSkillScores(results[1])
  }
  
  results[2] = {
    ...results[2],
    ...calculateWorkExperienceScore(results[2], jobDetails.requiredSkills, jobDetails.preferredSkills)
  }

  return {
    matchingScore: results[0].score + results[1].technicalSkills.score + results[1].softSkills.score,
    tailoredResumeFeedback: {
      professionalSummary: results[0],
      technicalSkills: results[1].technicalSkills,
      softSkills: results[1].softSkills,
      workExperiences: results[2],
      // educationalDetails: results[2],
    }
  };
};

export const retailorProfessionalSummary = async (
  jobDetails: any,
  resumeObject: any
) => {
  const {
    name,
    profilePicture,
    email,
    phone,
    location,
    tailoredResumeObject,
    socialLinks,
    templateId,
    screenshot,
    modifiedDate,
    createdDate,
    country,
    city_state,
    ...filteredResumeObject
  } = resumeObject;

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/retailor-professional-summary`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobDetails,
        resumeObject: filteredResumeObject,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const handleLogout = async (navigate: NavigateFunction) => {
  try {
    /* const response = await fetch('https://testapi.tekno.ai/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      'credentials': 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to logout');
    } */

    // Perform any necessary cleanup actions
    localStorage.removeItem("accessToken");
    navigate("/login");
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const getHelpWithWriting = async (
  sectionToGenerate: string,
  jobDetails: string,
  comment: string[],
  resumeObject: IResume,
  dispatch: any
) => {
  // Exclude unwanted properties from resumeObject
  const {
    name,
    profilePicture,
    email,
    phone,
    location,
    tailoredResumeObject,
    socialLinks,
    templateId,
    screenshot,
    modifiedDate,
    createdDate,
    country,
    city_state,
    ...filteredResumeObject
  } = resumeObject;

  const requestData = {
    sectionToGenerate,
    resumeObject: filteredResumeObject,
    jobDetails,
    comment,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/get-help-with-writing`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to get help with writing");
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error("Error getting help with writing:", error);
    dispatch(
      showSnackbar(error.message || "Failed to get help with writing.", "error")
    );
    throw error;
  }
};
