import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const CustomSectionTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
}) => {
  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    customSubSection: {
      fontSize: 14 + relativeFontSize,
    },
  });

  return (
    <>
      {extractedData.customSections &&
        Object.keys(extractedData.customSections) &&
        Object.keys(extractedData.customSections)?.length > 0 &&
        Object.keys(extractedData.customSections)?.map((section: string) => (
          <View style={{ marginTop: 20 }}>
            {SectionHeader ? (
              <SectionHeader>
                {extractedData.customSections?.[section]?.label}
              </SectionHeader>
            ) : (
              <Text style={styles.sectionHeader}>
                {extractedData.customSections?.[section]?.label}
              </Text>
            )}
            {extractedData.customSections?.[section]?.value?.map(
              (cs: IReference, index: number) => (
                <View key={index}>
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                      ...styles.customSubSection,
                    }}
                  >
                    {cs.organization}
                  </Text>
                  <Text style={styles.sectionContent}>
                    {cs?.courseDescription}
                  </Text>
                  {cs?.link && (
                    <Text style={styles.sectionContent}>{cs?.link}</Text>
                  )}
                </View>
              )
            )}
          </View>
        ))}
    </>
  );
};

export default CustomSectionTemplate;
