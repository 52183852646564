import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { countries } from "../../helpers/countries";
import { convertLocaleStringToNumber } from "../../../utils/commonFunctions";
import TeknoSwitch from "../../CommonComponents/TeknoSwitch";
import { SecondaryButton } from "../../button/Buttons";
import TeknoToggleBtn from "../../common/TeknoToggleBtn";
import {
  companySize,
  currencies,
  industries,
  preferredJobTypes,
  workArrangement,
} from "./job-preference-data";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import { tecknoApiBaseUrl } from "../../../utils/config";
import { responseHandler } from "../../../utils/responseHandler";
import { ILoggedinUserData } from "../../utils";
import CustomizedAlert from "../../CustomizedAlert";
import { SnackBarState } from "../../auth/Login";

interface IJobPreferencesValues {
  activelyLookingForJobs: boolean;
  preferredJobTypes: string[];
  workArrangement: string[];
  preferredWorkLocation: string;
  salaryCurrency: string;
  fromSalary?: string;
  toSalary?: string;
  companySize: string[];
  preferredIndustries: string[];
}

const JobPreferences = () => {
  const loggedInUserData: ILoggedinUserData = useSelector(
    (state: any) => state.loggedInUserData
  );
  const [snackBar, setSnackBar] = useState<SnackBarState>({
    open: false,
    message: "",
    severity: "success",
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useForm<IJobPreferencesValues>({
    defaultValues: {
      activelyLookingForJobs: false,
      preferredJobTypes: ["Full-time"],
      workArrangement: ["Onsite", "Remote", "Hybrid"],
      preferredWorkLocation: "",
      salaryCurrency: "USD",
      companySize: ["All"],
      preferredIndustries: ["all"],
    },
  });

  const formValues = watch();

  const [loading, setLoading] = useState(false);

  const getSelectedChip = (
    value: string,
    index: number,
    selected: string[]
  ) => {
    if (index === 2) {
      return (
        <Chip
          key={value}
          sx={{
            backgroundColor: "#5594FF",
            color: "#fff",
            borderRadius: "6px",
          }}
          label={`+ ${selected.length - 2}`}
        />
      );
    } else if (index > 2) {
      return null;
    } else {
      return (
        <Chip
          key={value}
          sx={{
            backgroundColor: "#5594FF",
            color: "#fff",
            borderRadius: "6px",
          }}
          label={industries.find((industry) => industry.value === value)?.label}
        />
      );
    }
  };

  const onSubmit = async (data: IJobPreferencesValues) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        activelyLookingForJobs: data.activelyLookingForJobs,
        preferredJobTypes: JSON.stringify(data.preferredJobTypes),
        workArrangement: JSON.stringify(data.workArrangement),
        preferredWorkLocation: data.preferredWorkLocation,
        salaryCurrency: data.salaryCurrency,
        fromSalary: data.fromSalary && JSON.stringify(data.fromSalary),
        toSalary: data.toSalary && JSON.stringify(data.toSalary),
        companySize: JSON.stringify(data.companySize),
        preferredIndustries: JSON.stringify(data.preferredIndustries),
      });

      //API call to update the job preferences
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user-job-pref`,
        {
          method: "PUT",
          body,
        }
      );

      if (response.ok) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Job preferences updated successfully",
        });
      } else {
        responseHandler(response);
      }
    } catch (error) {
      console.log("Error updating job preferences:", error);
      setSnackBar({
        open: true,
        severity: "error",
        message: "An unexpected error occurred while updating job preferences.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue(
      "activelyLookingForJobs",
      !!loggedInUserData?.activelyLookingForJobs
    );
    setValue(
      "preferredJobTypes",
      (loggedInUserData?.preferredJobTypes &&
        JSON.parse(loggedInUserData?.preferredJobTypes)) || ["Full-time"]
    );
    setValue(
      "workArrangement",
      (loggedInUserData?.workArrangement &&
        JSON.parse(loggedInUserData?.workArrangement)) || [
        "Onsite",
        "Remote",
        "Hybrid",
      ]
    );
    setValue(
      "preferredWorkLocation",
      loggedInUserData?.preferredWorkLocation || ""
    );
    setValue("salaryCurrency", loggedInUserData?.salaryCurrency || "USD");
    setValue("fromSalary", `${loggedInUserData?.fromSalary || ""}`);
    setValue("toSalary", `${loggedInUserData?.toSalary || ""}`);
    setValue(
      "companySize",
      (loggedInUserData?.companySize &&
        JSON.parse(loggedInUserData?.companySize)) || ["All"]
    );
    setValue(
      "preferredIndustries",
      (loggedInUserData?.preferredIndustries &&
        JSON.parse(loggedInUserData?.preferredIndustries)) || ["all"]
    );
  }, [loggedInUserData]);

  const handleCloseSnackbar = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Box sx={{ flex: 1, padding: 4, maxWidth: "600px" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Job preferences
      </Typography>
      <Typography variant="body1" gutterBottom>
        Choose the roles and environments you’re interested in.
      </Typography>

      <Divider sx={{ my: 2.5 }} />

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Are you actively looking for new jobs
              </Typography>

              <TeknoSwitch
                {...register("activelyLookingForJobs")}
                checked={formValues.activelyLookingForJobs}
              />
            </Box>
            <Typography variant="body2" color="textSecondary">
              Explore new opportunities to leverage your expertise and make a
              meaningful impact in innovative and dynamic environments
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} sx={{ mt: 0.5, maxWidth: "800px" }}>
          <Grid item container xs={12} columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Preferred job types
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {preferredJobTypes.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.preferredJobTypes?.includes(type)}
                    {...register("preferredJobTypes", {
                      required: "Preferred job types is required",
                    })}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.preferredJobTypes?.message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Work Arrangement
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {workArrangement.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.workArrangement?.includes(type)}
                    {...register("workArrangement", {
                      required: "Work arrangement is required",
                    })}
                  />
                ))}
              </Box>

              <Box>
                <Select
                  sx={{
                    mt: 2,
                    width: "100%",
                    ".MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                  placeholder="Choose your preferred work location"
                  value={formValues.preferredWorkLocation}
                  {...register("preferredWorkLocation", {
                    required: "Preferred work location is required",
                  })}
                  MenuProps={{
                    sx: {
                      maxHeight: "400px",
                      width: "100%",
                      "&& .MuiMenuItem-root:hover": {
                        backgroundColor: "#FAFAFA",
                      },
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem
                      value={country.code}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={country.flag}
                        width="20"
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      <span>{country.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.workArrangement?.message ||
                  errors.preferredWorkLocation?.message}
              </Typography>
            </Grid>

            <Grid item xs={12} spacing={0}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="salary"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Salary
              </Typography>
              <Grid container spacing={0} sx={{ mt: 1 }}>
                <Grid item sx={{ width: "50px" }}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{
                      fontWeight: "100",
                      color: "transparent",
                      userSelect: "none",
                    }}
                  >
                    Currency
                  </Typography>
                  <Box>
                    <Select
                      fullWidth
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                      }}
                      value={formValues.salaryCurrency}
                      {...register("salaryCurrency")}
                      renderValue={(value) => (
                        <span>
                          {currencies.find((c) => c.label === value)?.symbol}
                        </span>
                      )}
                      MenuProps={{
                        sx: {
                          maxHeight: "400px",
                          width: "100%",
                          marginLeft: "-25px",
                          "&& .MuiMenuItem-root:hover": {
                            backgroundColor: "#FAFAFA",
                          },
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {currencies.map((curr) => (
                        <MenuItem value={curr.label}>
                          {curr.symbol} {curr.text} ({curr.label})
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item sm={3}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                    className="required-field"
                  >
                    From
                  </Typography>
                  <TextField
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                      },
                    }}
                    fullWidth
                    value={formValues.fromSalary}
                    {...register("fromSalary", {
                      setValueAs: (value) =>
                        value &&
                        convertLocaleStringToNumber(value).toLocaleString(),
                      required: "Enter a value",
                    })}
                    placeholder="100,000"
                  />
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {errors.fromSalary?.message}
                  </Typography>
                </Grid>

                <Grid item sm={3}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                    className="required-field"
                  >
                    To
                  </Typography>
                  <TextField
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                    fullWidth
                    value={formValues.toSalary}
                    {...register("toSalary", {
                      setValueAs: (value) =>
                        value &&
                        convertLocaleStringToNumber(value).toLocaleString(),
                      required: "Enter a value",
                    })}
                    placeholder="150,000"
                  />
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {errors.toSalary?.message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Select company size
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {companySize.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.companySize?.includes(type)}
                    {...register("companySize", {
                      required: "Company size is required",
                    })}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.companySize?.message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="country"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Peferred Industries
              </Typography>

              <Box sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  multiple
                  sx={{
                    mt: 0.5,
                    width: "100%",
                  }}
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected": {
                        backgroundColor: "transparent",
                      },
                      "&& .MuiMenuItem-root": {
                        backgroundColor: "transparent",
                      },
                      "&& .MuiMenuItem-root:hover": {
                        backgroundColor: "#FAFAFA",
                      },
                      maxHeight: "400px",
                      width: "100%",
                      mt: window.innerHeight > 1300 ? "0px" : "-60px",
                    },
                    transformOrigin: {
                      vertical: window.innerHeight > 1300 ? "top" : "bottom",
                      horizontal: "center",
                    },
                  }}
                  {...register("preferredIndustries", {
                    required: "Preferred industries is required",
                  })}
                  value={formValues.preferredIndustries}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, index) =>
                        getSelectedChip(value, index, selected)
                      )}
                    </Box>
                  )}
                >
                  {industries.map((industry, index) => (
                    <MenuItem key={index} value={industry.value} disableRipple>
                      <Checkbox
                        size="small"
                        checked={formValues.preferredIndustries.includes(
                          industry.value
                        )}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#5594ff" }} />
                        }
                        disableRipple
                      />
                      <ListItemText primary={industry.label} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.preferredIndustries?.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <SecondaryButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          // disabled={!Object.keys(dirtyFields).length}
        >
          Save
        </SecondaryButton>
      </Box>

      <CustomizedAlert
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        alertState={snackBar.open}
        title={
          snackBar.severity === "error"
            ? "Error"
            : snackBar.severity === "success"
            ? "Success"
            : "Warning"
        }
        description={snackBar.message}
        severity={snackBar.severity}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </Box>
  );
};

export default JobPreferences;
