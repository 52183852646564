import { Font } from "@react-pdf/renderer";
import { FONTS_LIST } from "./fontsList";
// export const FONTS_LIST = [
//   {
//     name: "Poppins",
//     files: {
//       "300":
//         "http://fonts.gstatic.com/s/poppins/v1/VIeViZ2fPtYBt3B2fQZplvesZW2xOQ-xsNqO47m55DA.ttf",
//       regular:
//         "http://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf",
//       "500":
//         "http://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf",
//       "600":
//         "http://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf",
//       "700":
//         "http://fonts.gstatic.com/s/poppins/v1/8JitanEsk5aDh7mDYs-fYfesZW2xOQ-xsNqO47m55DA.ttf",
//     },
//   },
//   {
//     name: "Roboto",
//     files: {
//       "100": "http://fonts.gstatic.com/s/roboto/v15/7MygqTe2zs9YkP0adA9QQQ.ttf",
//       "100italic":
//         "http://fonts.gstatic.com/s/roboto/v15/T1xnudodhcgwXCmZQ490TPesZW2xOQ-xsNqO47m55DA.ttf",
//       "300": "http://fonts.gstatic.com/s/roboto/v15/dtpHsbgPEm2lVWciJZ0P-A.ttf",
//       "300italic":
//         "http://fonts.gstatic.com/s/roboto/v15/iE8HhaRzdhPxC93dOdA056CWcynf_cDxXwCLxiixG1c.ttf",
//       regular:
//         "http://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf",
//       "500": "http://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf",
//       "500italic":
//         "http://fonts.gstatic.com/s/roboto/v15/daIfzbEw-lbjMyv4rMUUTqCWcynf_cDxXwCLxiixG1c.ttf",
//       "700": "http://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf",
//       "700italic":
//         "http://fonts.gstatic.com/s/roboto/v15/owYYXKukxFDFjr0ZO8NXh6CWcynf_cDxXwCLxiixG1c.ttf",
//       "900": "http://fonts.gstatic.com/s/roboto/v15/H1vB34nOKWXqzKotq25pcg.ttf",
//       "900italic":
//         "http://fonts.gstatic.com/s/roboto/v15/b9PWBSMHrT2zM5FgUdtu0aCWcynf_cDxXwCLxiixG1c.ttf",
//     },
//   },
//   {
//     name: "Montserrat",
//     files: {
//       regular:
//         "http://fonts.gstatic.com/s/montserrat/v7/Kqy6-utIpx_30Xzecmeo8_esZW2xOQ-xsNqO47m55DA.ttf",
//       "700":
//         "http://fonts.gstatic.com/s/montserrat/v7/IQHow_FEYlDC4Gzy_m8fcgJKKGfqHaYFsRG-T3ceEVo.ttf",
//     },
//   },
// ];

const weightMapper = {
  regular: "normal",
};

export const loadFonts = () => {
  FONTS_LIST.forEach((font) => {
    Font.register({
      family: font.family,
      fonts: Object.keys(font.files).map((weight) => ({
        src: (font.files as any)[weight],
        fontWeight: (weightMapper as any)[weight] || weight,
      })),
    });
  });
};

export const loadSpecificFont = (font: any) => {
  const passedFont = FONTS_LIST.find((f) => f.family === font);
  if (passedFont) {
    Font.register({
      family: passedFont.family,
      fonts: Object.keys(passedFont.files).map((weight) => ({
        src: (passedFont.files as any)[weight],
        fontWeight: (weightMapper as any)[weight] || weight,
      })),
    });
  }
};
