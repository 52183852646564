import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <g clip-path="url(#clip0_5077_85545)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M539.46 -49.0011C553.408 -56.9996 570.592 -56.9996 584.54 -49.0011L661.46 -4.88938C675.408 3.1092 684 17.8912 684 33.8883V122.112C684 138.109 675.408 152.891 661.46 160.889L584.54 205.001C570.592 213 553.408 213 539.46 205.001L462.54 160.889C448.592 152.891 440 138.109 440 122.112V33.8883C440 17.8912 448.592 3.1092 462.54 -4.88938L539.46 -49.0011Z"
          fill="#9DDBE6"
        />
        <path
          d="M-34.7112 -55.8568C-27.6626 -64.6401 -16.4557 -69.0491 -5.3119 -67.423L56.1454 -58.4551C67.2892 -56.829 76.6768 -49.4148 80.772 -39.0054L103.357 18.4017C107.452 28.8111 105.633 40.6343 98.5843 49.4175L59.7119 97.8568C52.6634 106.64 41.4565 111.049 30.3127 109.423L-31.1446 100.455C-42.2884 98.829 -51.676 91.4148 -55.7712 81.0054L-78.3562 23.5983C-82.4514 13.1889 -80.6321 1.36574 -73.5835 -7.41753L-34.7112 -55.8568Z"
          fill="#7D408E"
        />
        <path
          d="M8.37393 673.431C28.847 654.234 58.0387 647.309 84.9529 655.264L233.383 699.134C260.297 707.089 280.845 728.715 287.286 755.866L322.809 905.604C329.25 932.755 320.606 961.306 300.133 980.503L187.225 1086.37C166.752 1105.57 137.56 1112.49 110.646 1104.54L-37.7845 1060.67C-64.6986 1052.71 -85.2464 1031.09 -91.6875 1003.94L-127.21 854.197C-133.651 827.046 -125.007 798.495 -104.534 779.298L8.37393 673.431Z"
          fill="#E6588A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5077_85545">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5077_85545)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M539.46 -49.0011C553.408 -56.9996 570.592 -56.9996 584.54 -49.0011L661.46 -4.88938C675.408 3.1092 684 17.8912 684 33.8883V122.112C684 138.109 675.408 152.891 661.46 160.889L584.54 205.001C570.592 213 553.408 213 539.46 205.001L462.54 160.889C448.592 152.891 440 138.109 440 122.112V33.8883C440 17.8912 448.592 3.1092 462.54 -4.88938L539.46 -49.0011Z"
          fill={props?.c1 || "#9DDBE6"}
        />
        <Path
          d="M-34.7112 -55.8568C-27.6626 -64.6401 -16.4557 -69.0491 -5.3119 -67.423L56.1454 -58.4551C67.2892 -56.829 76.6768 -49.4148 80.772 -39.0054L103.357 18.4017C107.452 28.8111 105.633 40.6343 98.5843 49.4175L59.7119 97.8568C52.6634 106.64 41.4565 111.049 30.3127 109.423L-31.1446 100.455C-42.2884 98.829 -51.676 91.4148 -55.7712 81.0054L-78.3562 23.5983C-82.4514 13.1889 -80.6321 1.36574 -73.5835 -7.41753L-34.7112 -55.8568Z"
          fill={props?.c2 || "#7D408E"}
        />
        <Path
          d="M8.37393 673.431C28.847 654.234 58.0387 647.309 84.9529 655.264L233.383 699.134C260.297 707.089 280.845 728.715 287.286 755.866L322.809 905.604C329.25 932.755 320.606 961.306 300.133 980.503L187.225 1086.37C166.752 1105.57 137.56 1112.49 110.646 1104.54L-37.7845 1060.67C-64.6986 1052.71 -85.2464 1031.09 -91.6875 1003.94L-127.21 854.197C-133.651 827.046 -125.007 798.495 -104.534 779.298L8.37393 673.431Z"
          fill={props?.c3 || "#E6588A"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5077_85545">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture14 = {
  ViewTexture,
  PdfTexture,
};

export default Texture14;
