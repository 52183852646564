export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_LOGGED_IN_USER_DATA = "SET_LOGGED_IN_USER_DATA";
export const SET_SIDEDRAWER_STATE = "SET_SIDEDRAWER_STATE";
export const SET_EXTRACTED_DATA = "SET_EXTRACTED_DATA";
export const SET_IMPROVEMENTS_TO_MAKE_IN_RESUME =
  "SET_IMPROVEMENTS_TO_MAKE_IN_RESUME";
export const SET_COLOR_DATA = "SET_COLOR_DATA";
export const SET_SELECTED_TEMPLATE_ID = "SET_SELECTED_TEMPLATE_ID";
export const LOGOUT_USER = "LOGOUT_USER";
export const OPEN_POPOVER = "OPEN_POPOVER";
export const UPDATE_POPOVER_VALUE = "UPDATE_POPOVER_VALUE";
export const CLOSE_POPOVER = "CLOSE_POPOVER";
export const SET_FIXED_SECTIONS = "SET_FIXED_SECTIONS";
export const SET_RESUME_HEIGHT = "SET_RESUME_HEIGHT";
export const SET_ACTIVE_ATS_CHECK_RESULT_ID = "SET_ACTIVE_ATS_CHECK_RESULT_ID";
export const SET_PERSONALDETAILS_VALIDATION = "SET_PERSONALDETAILS_VALIDATION";
export const SET_PRIOR_TOGGLE_DETAILS = "SET_PRIOR_TOGGLE_DETAILS";
export const SET_PREVIEW_RESUME_SCREENSHOT = "SET_PREVIEW_RESUME_SCREENSHOT";
export const SET_DRAFT_STATE = "SET_DRAFT_STATE";
export const SET_SAVE_IN_PROGRESS_RESUME = "SET_SAVE_IN_PROGRESS_RESUME";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SET_ISPERSONALDETAILS_LOADING = "SET_ISPERSONALDETAILS_LOADING";
export const SET_IS_TEMPLATE_CHANGED = "SET_IS_TEMPLATE_CHANGED";
export const SET_IS_FULL_NAME_CURSOR_ACTIVE = "SET_IS_FULL_NAME_CURSOR_ACTIVE";
