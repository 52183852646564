import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as LinkedInIcon } from "../../assets/SVG/linkedIn-icon-blue.svg";
import { InputFieldCustomeStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";

const CreateAccount = () => {
  return (
    <Box className="create-account-container">
      <Container
        component="div"
        maxWidth="xs"
        className="create-account-wrapper"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography
            component="h3"
            variant="h6"
            sx={{ fontWeight: "600", fontSize: "1.85rem" }}
          >
            Create an account
          </Typography>
          <Typography component="h6" variant="body1" sx={{ color: "#475367" }}>
            Make your resume for free
          </Typography>
          <Box component="form" noValidate onSubmit={() => {}} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor="fullName"
                  gutterBottom
                >
                  Name*
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  sx={InputFieldCustomeStyle}
                  inputProps={{
                    sx: {
                      height: "1rem",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor="email"
                  gutterBottom
                >
                  Email*
                </Typography>
                <TextField
                  required
                  fullWidth
                  name="email"
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  sx={InputFieldCustomeStyle}
                  inputProps={{
                    sx: {
                      height: "1rem",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor="phone"
                  gutterBottom
                >
                  Phone*
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Enter your Phone Number"
                  sx={InputFieldCustomeStyle}
                  inputProps={{
                    sx: {
                      height: "1rem",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                background: "#012fff",
                fontWeight: "600",
                borderRadius: "10px",
                textTransform: "none",
                fontSize: "1rem",
              }}
            >
              Get Started
            </Button>
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                className="social-login-button"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                startIcon={<LinkedInIcon />}
              >
                Sign up with LinkedIn
              </Button>
            </Box>
            <Box
              mb={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ color: "#475367" }}>
                Already have an account?{" "}
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ cursor: "pointer", color: "#012fff" }}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateAccount;
