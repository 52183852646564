import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  LinearGradient,
  Stop,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg
      width="596"
      height="842"
      viewBox="0 0 596 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5032_51633)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M-8.5 793.5V826H569.3L579.1 793.5H-8.5Z"
          fill="url(#paint0_linear_5032_51633)"
        />
        <path
          d="M603.8 672.3V850.5H477.6L517.2 785.1H569.5L603.8 672.3Z"
          fill="url(#paint1_linear_5032_51633)"
        />
        <path
          d="M564.5 -8.4L544.6 57H603.8V-8.4H564.5Z"
          fill="url(#paint2_linear_5032_51633)"
        />
        <path
          d="M65.5 -8.4L15.5 156.1H-8.5V-8.4H65.5Z"
          fill="url(#paint3_linear_5032_51633)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5032_51633"
          x1="-5071.26"
          y1="26625.1"
          x2="340946"
          y2="26625.1"
        >
          <stop stop-color="#6AA1F8" />
          <stop offset="1" stop-color="#80E0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5032_51633"
          x1="-6709.57"
          y1="490753"
          x2="36922.8"
          y2="508529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#043680" />
          <stop offset="1" stop-color="#3F75C7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5032_51633"
          x1="32751.2"
          y1="-558.065"
          x2="36252.2"
          y2="-558.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6AA1F8" />
          <stop offset="1" stop-color="#80E0F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5032_51633"
          x1="511.133"
          y1="-3230.97"
          x2="12082.2"
          y2="10038.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#043680" />
          <stop offset="1" stop-color="#3F75C7" />
        </linearGradient>
        <clipPath id="clip0_5032_51633">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5032_51633)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M-8.5 793.5V826H569.3L579.1 793.5H-8.5Z"
          fill="url(#paint0_linear_5032_51633)"
        />
        <Path
          d="M603.8 672.3V850.5H477.6L517.2 785.1H569.5L603.8 672.3Z"
          fill="url(#paint1_linear_5032_51633)"
        />
        <Path
          d="M564.5 -8.4L544.6 57H603.8V-8.4H564.5Z"
          fill="url(#paint2_linear_5032_51633)"
        />
        <Path
          d="M65.5 -8.4L15.5 156.1H-8.5V-8.4H65.5Z"
          fill="url(#paint3_linear_5032_51633)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5032_51633"
          x1="-5071.26"
          y1="26625.1"
          x2="340946"
          y2="26625.1"
        >
          <Stop offset="0" stopColor={props?.c1 || "#6AA1F8"} />
          <Stop offset="1" stopColor="#80E0F5" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5032_51633"
          x1="-6709.57"
          y1="490753"
          x2="36922.8"
          y2="508529"
        >
          <Stop offset="0" stopColor={props?.c2 || "#043680"} />
          <Stop offset="1" stopColor="#3F75C7" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5032_51633"
          x1="32751.2"
          y1="-558.065"
          x2="36252.2"
          y2="-558.065"
        >
          <Stop offset="0" stopColor={props?.c1 || "#6AA1F8"} />
          <Stop offset="1" stopColor="#80E0F5" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5032_51633"
          x1="511.133"
          y1="-3230.97"
          x2="12082.2"
          y2="10038.6"
        >
          <Stop offset="0" stopColor={props?.c2 || "#043680"} />
          <Stop offset="1" stopColor="#3F75C7" />
        </LinearGradient>
        <ClipPath id="clip0_5032_51633">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture23 = {
  ViewTexture,
  PdfTexture,
};

export default Texture23;
