import { Box, Typography } from "@mui/material";
import CustomAccordion from "./CustomeAccordion";

const accordionContent = [
  {
    heading: "Is this resume builder 100% free?",
    content:
      "Yes, you can try us for free for 30 days, If you want, we will provide you a free, personalized 30-minutes onboarding call to get you up and running as soon as possible.",
  },
  {
    heading: "What should be included in the resume?",
    content:
      "A resume typically includes ypur contact information, a summary or objective statement, work experience, educational, skills, optionally, additional sections like certification, achievements or interests.",
  },
  {
    heading: "How long should a resume be?",
    content:
      "Yes, you can try us for free for 30 days, If you want, we will provide you a free, personalized 30-minutes onboarding call to get you up and running as soon as possible.",
  },
  {
    heading: "What is the difference between a resume and a CV?",
    content:
      "Yes, you can try us for free for 30 days, If you want, we will provide you a free, personalized 30-minutes onboarding call to get you up and running as soon as possible.",
  },
  {
    heading: "Should I customize my resume for each job application?",
    content:
      "Yes, you can try us for free for 30 days, If you want, we will provide you a free, personalized 30-minutes onboarding call to get you up and running as soon as possible.",
  },
  {
    heading: "Can I download my resume in different formats?",
    content:
      "Yes, you can try us for free for 30 days, If you want, we will provide you a free, personalized 30-minutes onboarding call to get you up and running as soon as possible.",
  },
];

const Section6 = () => {
  return (
    <Box className="section-6-wrapper">
      <Box className="section-6-content">
        <Typography
          component="h3"
          variant="h4"
          className="heading"
          gutterBottom
        >
          Frequently asked questions
        </Typography>
        <Typography className="sub-heading" gutterBottom>
          Everything you need to know about the product and billing.
        </Typography>

        <Box className="accordion-wrapper">
          {accordionContent.map((content) => (
            <CustomAccordion
              heading={content.heading}
              content={content.content}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Section6;
