import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { ReactComponent as SucessAlertIcon } from "../assets/SVG/alert-success-icon.svg";
import { ReactComponent as ErrorAlertIcon } from "../assets/SVG/alert-error-icon.svg";
import { ReactComponent as WarningAlertIcon } from "../assets/SVG/alert-warnning-icon.svg";

interface TCustomizedAlertProps {
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  alertState: boolean;
  title: string;
  description: string;
  severity: "success" | "warning" | "error";
  handleCloseSnackbar: () => void;
}

const getIcon = (severity: "success" | "warning" | "error") => {
  if (severity === "success") {
    return <SucessAlertIcon />;
  } else if (severity === "warning") {
    return <WarningAlertIcon />;
  } else if (severity === "error") {
    return <ErrorAlertIcon />;
  }
};

const getBorderColor = (severity: "success" | "warning" | "error") => {
  if (severity === "success") {
    return "#19A44C";
  } else if (severity === "warning") {
    return "#F59E0B";
  } else if (severity === "error") {
    return "#EF4444";
  }
};

const CustomizedAlert: React.FC<TCustomizedAlertProps> = ({
  alertState,
  title,
  description,
  severity,
  handleCloseSnackbar,
  anchorOrigin = { vertical: "top", horizontal: "right" },
}) => {
  return (
    <Snackbar
      open={alertState}
      anchorOrigin={anchorOrigin}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      sx={
        anchorOrigin?.vertical === "top"
          ? {
              top: 6,
              "@media (min-width: 600px)": {
                top: 6,
              },
            }
          : {}
      }
    >
      <Alert
        onClose={handleCloseSnackbar}
        icon={false}
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          borderBottom: `6px solid ${getBorderColor(severity)}`,
          boxShadow: "0px 4px 6px -2px #10182808",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.125rem 0",
            gap: "0.75rem",
          }}
        >
          <Box>{getIcon(severity)}</Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.5rem",
                color: "#242424",
                fontWeight: "500",
                marginBottom: "0.125rem",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.5rem",
                color: "#242424",
                fontWeight: "400",
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default CustomizedAlert;
