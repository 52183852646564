export const calculateProfessionalSummaryScore = (
  responseObject: any,
  totalRequiredSkills: object,
  totalPreferredSkills: object
) => {
  const requiredSkillsArray = Object.keys(totalRequiredSkills);
  const preferredSkillsArray = Object.keys(totalPreferredSkills);

  // Calculate Missing Required Skills Score
  const requiredSkillsScore = requiredSkillsArray.length > 0 
    ? (1 - responseObject.missingRequiredSkills.length / requiredSkillsArray.length) * 5
    : 0;

  // Calculate Missing Preferred Skills Score
  const preferredSkillsScore = preferredSkillsArray.length > 0
    ? (1 - responseObject.missingPreferredSkills.length / preferredSkillsArray.length) * 3
    : 0;

  // Calculate Years of Experience Score
  const yearsOfExperienceScore =
    (responseObject.yearsOfExperienceMatch?.totalYearsMet ? 1.5 : 0) +
    (responseObject.yearsOfExperienceMatch?.industrySpecificYearsMet ? 1.5 : 0);

  // Calculate Education Match Score
  const educationScore = responseObject.educationMatch?.degreeRequirementsMet
    ? 2
    : 0;

  // Calculate Certifications Match Score
  const certificationsScore = responseObject.certificationsMatch?.requiredCertificationsMet
    ? 2
    : 0;

  // Total Score
  const totalScore = Math.round(
    requiredSkillsScore +
    preferredSkillsScore +
    yearsOfExperienceScore +
    educationScore +
    certificationsScore
  );

  return {
    score: totalScore,
    maxScore: 15
  };
};



export const calculateSkillScores = (responseObject: any) => {
  // Technical Skills Score Calculation (out of 15)
  const technicalDeductions = 
      responseObject.missingRequiredTechnicalSkills?.length * 3 + // -3 pts per missing required technical skill
      responseObject.missingPreferredTechnicalSkills?.length * 1; // -1 pt per missing preferred technical skill
  
  const technicalSkillsScore = Math.max(0, 15 - technicalDeductions);

  // Soft Skills Score Calculation (out of 15)
  const softDeductions = 
      responseObject.missingRequiredSoftSkills?.length * 3 + // -3 pts per missing required soft skill
      responseObject.missingPreferredSoftSkills?.length * 1; // -1 pt per missing preferred soft skill
  
  const softSkillsScore = Math.max(0, 15 - softDeductions);

  return {
      technicalSkills: {
          maxScore: 15,
          score: technicalSkillsScore,
          missingRequired: responseObject.missingRequiredTechnicalSkills,
          missingPreferred: responseObject.missingPreferredTechnicalSkills
      },
      softSkills: {
          maxScore: 15,
          score: softSkillsScore,
          missingRequired: responseObject.missingRequiredSoftSkills,
          missingPreferred: responseObject.missingPreferredSoftSkills
      }
  };
}

export const calculateWorkExperienceScore = (responseObject: any, totalRequiredSkills: object, totalPreferredSkills: object) => {
  // Extract total number of required and preferred skills
  const totalRequiredSkillsCount = Object.keys(totalRequiredSkills).length;
  const totalPreferredSkillsCount = Object.keys(totalPreferredSkills).length;

  // Calculate weights for required and preferred skills
  const totalSkillsCount = totalRequiredSkillsCount + totalPreferredSkillsCount;

  // Calculate Matching Skills Score (out of 5)
  const matchingSkillsScore = Math.min(5, (responseObject.matchingSkills?.length / totalSkillsCount) * 5);

  // Calculate Missing Required Skills Score (out of 5)
  const missingRequiredSkillsScore = totalRequiredSkillsCount > 0
    ? (1 - responseObject.missingRequiredSkills?.length / totalRequiredSkillsCount) * 5
    : 0;

  // Calculate Missing Preferred Skills Score (out of 5)
  const missingPreferredSkillsScore = totalPreferredSkillsCount > 0
    ? (1 - responseObject.missingPreferredSkills?.length / totalPreferredSkillsCount) * 5
    : 0;

  // Total Score (out of 15)
  const totalScore = Math.min(
    15, // Ensure score does not exceed maxScore
    Math.round(
      matchingSkillsScore +
      missingRequiredSkillsScore +
      missingPreferredSkillsScore
    )
  );

  return {
    score: totalScore,
    maxScore: 15
  };  
};