import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  LinearProgress,
  Chip,
  Container,
  Button,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  linearProgressClasses,
  Avatar,
  Paper,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  DialogActions,
  Popover,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "../common/Header";
import ProcessResumePreviewHeader from "./../../ProcessResume/ProcessResumePreviewHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";
import {
  PriorityHigh,
  Info,
  Error,
  Check,
  Remove,
  Add,
} from "@mui/icons-material";
import { AI_Spark_Icon, BulbIcon } from "../Icons";
import { Template1 } from "../../templates";
import AppBarHeader from "../../ProcessResume/AppBarHeader";
import SideDrawer from "../../ProcessResume/SideDrawer";
import {
  closePopover,
  openPopover,
  setExtractedData,
  setFixedSections,
  updatePopoverValue,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { useRefContext } from "../../templates/TemplateSectionsRef";
import ProcessResumePreview from "./../../ProcessResume/ProcessResumePreview";
import IssueCard from "./IssueCard";
import { getToken } from "../auth/storeToken";
import { avatarColors, ITailoredResume, updateATSCheckResult } from "../utils";
import { GradientButton } from "../button/GradientButton";
import { StateProps } from "./../../redux/reducer";
import { skillsData } from "../../Data/skillsData";

const getScoreDetails = (score: number) => {
  if (score >= 80) {
    return { color: "#19A44C", text: "Excellent" };
  } else if (score >= 60) {
    return { color: "#5594FF", text: "Good" };
  } else if (score >= 40) {
    return { color: "#FF8A1C", text: "Average" };
  } else {
    return { color: "#EF4444", text: "Poor" };
  }
};

const ColoredLinearProgress = styled(LinearProgress)(
  ({ score }: { score: number }) => ({
    "& .MuiLinearProgress-bar": {
      backgroundColor: getScoreDetails(score).color,
    },
  })
);

const ResumeScore = ({ atsScore }: { atsScore: number }) => {
  const classes = useStyles();
  const { color, text } = getScoreDetails(atsScore);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: color }}>
            {text}
          </Typography>
          <Typography variant="body2">Resume Strength</Typography>
        </Box>
        <Box>
          <Typography variant="h4">{atsScore}%</Typography>
        </Box>
      </Box>
      <Box>
        <ColoredLinearProgress
          variant="determinate"
          value={atsScore}
          score={atsScore}
        />
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    padding: "20px",
    overflowY: "auto", // Enable vertical scrolling
    // maxHeight: 'calc(100vh - 64px)', // Adjust height to fit within the viewport
  },
  card: {
    marginBottom: "20px",
    borderRadius: "15px",
  },
  sectionTitle: {
    marginBottom: "10px",
    fontWeight: "bold",
    color: "#3f51b5",
  },
  listItem: {
    paddingLeft: "0px",
  },
  listItemText: {
    fontSize: "1rem",
  },
  divider: {
    margin: "20px 0",
  },
  chip: {
    margin: "5px",
  },
  backButton: {
    marginBottom: "20px",
  },
  resumeScorePanel: {
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    boxShadow: "none",
  },
  resumeScorePanelHeader: {
    fontWeight: "bolder",
  },
  // alert: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     backgroundColor: '#fff3e0', // Custom background color for warning
  //     color: '#ff9800', // Custom text color for warning
  //     padding: '16px', // Custom padding
  // },
  // alertIcon: {
  //     marginLeft: '16px', // Custom margin for the icon
  // },
});

const CustomButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "8px 18px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#00BF58",
  borderColor: "#00BF58",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#00BF58",
    borderColor: "#00BF58",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#00BF58",
    borderColor: "#00BF58",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

/* const CircularProgress = ({ value }: any) => (
  <Card>
    <CardContent>
      <Typography variant="h6">Progress</Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={value} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);
 */
const ATSCheckResult = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;

  const [atsCheckResult, setAtsCheckResult] = useState<any>(null);
  const extractedData = useSelector(
    (state: StateProps) => state?.extractedData
  );

  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    // setAtsCheckResult({
    //     "tailoredResumeObject": {
    //         "atsScore": 30,
    //         "resumeObjectWithChanges": {
    //             "professionalSummary": "Highly skilled Java Full Stack Developer with 8+ years of experience in designing, building, and configuring applications. Proficient in Java, JavaScript, React.js, HTML, and related technologies. Expertise in Agile methodologies and full software development life cycle.",
    //             "jobTitle": "Application Developer",
    //             "toolsTechnicalSkills": [
    //                 {
    //                     "index": 0,
    //                     "value": "RestAPI"
    //                 },
    //                 {
    //                     "index": 1,
    //                     "value": "HTML5"
    //                 },
    //                 {
    //                     "index": 2,
    //                     "value": "React"
    //                 },
    //                 {
    //                     "index": 3,
    //                     "value": "Redux"
    //                 },
    //                 {
    //                     "index": 4,
    //                     "value": "Oracle"
    //                 },
    //                 {
    //                     "index": 5,
    //                     "value": "Unix/Linux"
    //                 },
    //                 {
    //                     "index": 6,
    //                     "value": "Jira"
    //                 },
    //                 {
    //                     "index": 7,
    //                     "value": "GITlab"
    //                 },
    //                 {
    //                     "index": 8,
    //                     "value": "intellij"
    //                 },
    //                 {
    //                     "index": 9,
    //                     "value": "Cloud environment tools"
    //                 },
    //                 {
    //                     "index": 10,
    //                     "value": "DevOps"
    //                 },
    //                 {
    //                     "index": 11,
    //                     "value": "Kafka"
    //                 }
    //             ],
    //             "programmingLanguages": [
    //                 {
    //                     "index": 0,
    //                     "value": "Java"
    //                 },
    //                 {
    //                     "index": 1,
    //                     "value": "JavaScript"
    //                 },
    //                 {
    //                     "index": 2,
    //                     "value": "CORE JAVA"
    //                 },
    //                 {
    //                     "index": 3,
    //                     "value": "Java 8"
    //                 },
    //                 {
    //                     "index": 4,
    //                     "value": "Flink"
    //                 },
    //                 {
    //                     "index": 5,
    //                     "value": "Spark"
    //                 },
    //                 {
    //                     "index": 6,
    //                     "value": "Spring Boot"
    //                 },
    //                 {
    //                     "index": 7,
    //                     "value": "Spring web flux"
    //                 }
    //             ],
    //             "workExperiences": [
    //                 {
    //                     "index": 0,
    //                     "keyResponsibilities": [
    //                         "Design, build and configure applications to meet business process and application requirements.",
    //                         "Strong experience in Java Webservices.",
    //                         "Experience in CORE JAVA technologies Concurrency, Collections API, JDBC.",
    //                         "JDBC Database interaction using JDBC with an emphasis on quality and performance.",
    //                         "Multi-Threading Application development."
    //                     ]
    //                 }
    //             ]
    //         },
    //         "jobTitle": "Application Developer",
    //         "companyName": "Not specified",
    //         "keywordsToAdd": [
    //             "Agile",
    //             "Collections API",
    //             "Concurrency",
    //             "DevOps",
    //             "Flink",
    //             "Gitlab",
    //             "HTML5",
    //             "Intellij",
    //             "JDBC",
    //             "Jira",
    //             "Kafka",
    //             "Linux",
    //             "Multithreading",
    //             "Oracle",
    //             "React",
    //             "Redux",
    //             "RestAPI",
    //             "Spark",
    //             "Spring Boot",
    //             "Spring web flux",
    //             "Unix"
    //         ],
    //         "weightage": {
    //             "professionalSummary": 15,
    //             "jobTitle": 10,
    //             "toolsTechnicalSkills": 20,
    //             "programmingLanguages": 15,
    //             "workExperiences": {
    //                 "0": 10
    //             },
    //             "keywordsToAdd": 10
    //         }
    //     }
    // });
    setAtsCheckResult(state.atsResult);
    dispatch(setExtractedData(state.atsResult?.extractedData));
    console.log(state.atsResult);
  }, [state]);

  // useEffect(() => {
  //     const fetchAtsCheckResult = async () => {
  //       try {
  //         const response = await fetch(`https://testapi.tekno.ai/api/get-ats-checker/${resultId}`, {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': 'Bearer ' + getToken(),
  //           },
  //         });

  //         if (response.ok) {
  //           const atsCheckResult = await response.json();
  //           setAtsCheckResult({
  //             ...atsCheckResult,
  //             "tailoredResumeObject": {
  //                 ...atsCheckResult.tailoredResumeObject,
  //                 "weightage": {
  //                     "professionalSummary": 5,
  //                     "jobTitle": 3,
  //                     "certifications": 2,
  //                     "toolsTechnicalSkills": 4,
  //                     "programmingLanguages": 3,
  //                     "interests": 1,
  //                     "workExperiences": {
  //                         "a0ab909b-cb08-4cdd-b1a6-d51b618fffb0": 6,
  //                         "fd3fe8cf-066d-4fea-adfd-458d27996027": 4,
  //                         "64b36d99-3734-4e06-98d5-899e86cd5954": 3
  //                     },
  //                     "keywordsToAdd": 2
  //                 }
  //             },
  //             "resumeObject": {
  //                 "name": {
  //                     "firstName": "Gaia",
  //                     "lastName": "Park"
  //                 },
  //                 "profilePicture": null,
  //                 "email": "gaiaparkemailcom",
  //                 "phone": [
  //                     "1234567890"
  //                 ],
  //                 "location": "Redmond WA",
  //                 "socialLinks": {
  //                     "personal_Website": "NA",
  //                     "linkedIn_URL": "NA",
  //                     "github_URL": "NA",
  //                     "twitter_X_URL": null
  //                 },
  //                 "professionalSummary": "NA",
  //                 "jobTitle": "Principal Software Engineer",
  //                 "educationalDetails": [
  //                     {
  //                         "universityCollege": "University of California Berkeley",
  //                         "degree": "MS Computer Science",
  //                         "location": null,
  //                         "isEdit": null,
  //                         "isNewEntry": null,
  //                         "index": "1561eef5-1034-4d9d-81b2-74b2bda9b6d4",
  //                         "specialization": "NA",
  //                         "gpa": "NA",
  //                         "startDate": "2014",
  //                         "endDate": "2016"
  //                     },
  //                     {
  //                         "universityCollege": "University of California Berkeley",
  //                         "degree": "BS Electrical Engineering",
  //                         "location": null,
  //                         "isEdit": null,
  //                         "isNewEntry": null,
  //                         "index": "48f126d0-1a52-4cdb-a292-972d5e45807e",
  //                         "specialization": "NA",
  //                         "gpa": "NA",
  //                         "startDate": "2010",
  //                         "endDate": "2014"
  //                     }
  //                 ],
  //                 "workExperiences": [
  //                     {
  //                         "startDate": "October 2020",
  //                         "endDate": "current",
  //                         "company": "Microso",
  //                         "jobType": null,
  //                         "role": "Principal Software Engineer",
  //                         "location": null,
  //                         "isEdit": null,
  //                         "isNewEntry": null,
  //                         "index": "f0234f9f-c51a-495e-ab9d-9ec60adb2b7d",
  //                         "keyResponsibilities": "Led the migration of legacy Java applications to Python improving code maintainability by 12 and reducing bug rates by 16\nBuilt custom Eclipse plugin that streamlined software development process reducing build times by 24\nArchitected and deployed solutions on Red Hat OpenShift cutting timetomarket of mobile apps in half\nSpearheaded a new product launch contributing 800K to annual revenue and a 5 rise in market share",
  //                         "keyResponsibilitiesArray": [
  //                             "Led the migration of legacy Java applications to Python improving code maintainability by 12 and reducing bug rates by 16",
  //                             "Built custom Eclipse plugin that streamlined software development process reducing build times by 24",
  //                             "Architected and deployed solutions on Red Hat OpenShift cutting timetomarket of mobile apps in half",
  //                             "Spearheaded a new product launch contributing 800K to annual revenue and a 5 rise in market share"
  //                         ],
  //                         "projectDetails": "NA",
  //                         "toolsTechnologies": "NA"
  //                     },
  //                     {
  //                         "startDate": "August 2017",
  //                         "endDate": "August 2020",
  //                         "company": "3D Robotics",
  //                         "jobType": null,
  //                         "role": "Software Tester",
  //                         "location": null,
  //                         "isEdit": null,
  //                         "isNewEntry": null,
  //                         "index": "923ba643-b23f-4e19-9a59-1883485b1e29",
  //                         "keyResponsibilities": "Developed Python scripts to automate functional and regression testing increasing test coverage and reducing manual effort by 37\nT ested C applications identifying bottlenecks and optimizing code to improve processing speed by 28\nDesigned custom test automation frameworks using Java to enable faster feedback for developers\nCreated detailed test reports with actionable feedback reducing postrelease defects by 12",
  //                         "keyResponsibilitiesArray": [
  //                             "Developed Python scripts to automate functional and regression testing increasing test coverage and reducing manual effort by 37",
  //                             "T ested C applications identifying bottlenecks and optimizing code to improve processing speed by 28",
  //                             "Designed custom test automation frameworks using Java to enable faster feedback for developers",
  //                             "Created detailed test reports with actionable feedback reducing postrelease defects by 12"
  //                         ],
  //                         "projectDetails": "NA",
  //                         "toolsTechnologies": "NA"
  //                     },
  //                     {
  //                         "startDate": "June 2014",
  //                         "endDate": "July 2017",
  //                         "company": "Aduro Biotech",
  //                         "jobType": null,
  //                         "role": "Junior Software Developer",
  //                         "location": null,
  //                         "isEdit": null,
  //                         "isNewEntry": null,
  //                         "index": "e6b29b00-b89d-49d7-845b-5a3f0ad38f20",
  //                         "keyResponsibilities": "Used Eclipse IDE to write debug and maintain code improving productivity by 21 and code quality by 7\nDesigned unit tests for the development team for a 15 drop in production defects\nSupported the development of RESTful APIs contributing to a 29 increase in thirdparty integrations\nSupported database operations for both SQL and NoSQL systems improving query performance by 16",
  //                         "keyResponsibilitiesArray": [
  //                             "Used Eclipse IDE to write debug and maintain code improving productivity by 21 and code quality by 7",
  //                             "Designed unit tests for the development team for a 15 drop in production defects",
  //                             "Supported the development of RESTful APIs contributing to a 29 increase in thirdparty integrations",
  //                             "Supported database operations for both SQL and NoSQL systems improving query performance by 16"
  //                         ],
  //                         "projectDetails": "NA",
  //                         "toolsTechnologies": "NA"
  //                     }
  //                 ],
  //                 "projects": [],
  //                 "courses": null,
  //                 "certifications": "NA",
  //                 "toolsTechnicalSkills": ["Java", "Python", "JavaScript", "C", "Oracle Cloud Infrastructure", "Oracle Database", "MongoDB", "Red Hat OpenShift"],
  //                 "programmingLanguages": [
  //                     "Java",
  //                     "Python",
  //                     "JavaScript",
  //                     "C"
  //                 ],
  //                 "interests": "Coding challenges and hackathons",
  //                 "languages": [],
  //                 "hobbies": [],
  //             }
  //             });
  //         } else {
  //           console.error('Failed to fetch ATS check result');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching ATS check result:', error);
  //       }
  //     };

  //     fetchAtsCheckResult();
  //   }, [resultId]);

  const resumeDetails = useSelector((state: any) => state.extractedData);
  // console.log('ATS check result:', atsCheckResult);

  /* const data = {
        summary: {
            "ATS Compatibility Score": "75%",
            "Keyword Metrics": {
                "Keyword Density": "3.5%",
                "Keyword Relevance": "8/10",
                "Top Keywords": [
                    "Power BI",
                    "Data Analysis",
                    "Dashboards",
                    "SQL",
                    "Reporting",
                    "Visualization",
                    "Data Fetching",
                    "Requirements Gathering",
                    "Automation",
                    "Process Improvement"
                ]
            },
            "Resume Structure and Format": {
                "Clear Headings": "90%",
                "Bullet Point Usage": "80%",
                "White Space Usage": "8/10"
            },
            "Content and Relevance": {
                "Work Experience Relevance": "9/10",
                "Skill Match": "85%",
                "Education Relevance": "7/10"
            },
            "Readability and Clarity": {
                "Readability Score": "65",
                "Sentence Complexity": "16 words",
                "Jargon and Acronyms": "15%"
            },
            "Actionable Tips and Suggestions": {
                "Improvement Suggestions": [
                    "Quantify achievements in bullet points with specific numbers and results.",
                    "Use action verbs to start bullet points.",
                    "Add more keywords related to the specific job description.",
                    "Tailor the resume to each specific job application."
                ],
                "Keyword Suggestions": [
                    "Data Modeling",
                    "Data Cleansing",
                    "Data Governance",
                    "Business Intelligence",
                    "Data Warehousing",
                    "DWH",
                    "ETL",
                    "Agile Methodology",
                    "Scrum",
                    "Project Management"
                ]
            }
        }
    }; */

  const { anchorEl, open, section, sectionValue, sectionIndex } = useSelector(
    (state: any) => state.popover
  );
  const { sectionRefs } = useRefContext();
  const fixedSections = useSelector((state: any) => state.fixedSections);
  console.log("fixedSections", fixedSections);

  const resumeObjectWithChanges =
    atsCheckResult?.tailoredResumeObject?.resumeObjectWithChanges;

  const generateChangeDescription = (
    section: string,
    index: number | null = null
  ) => {
    if (section === "careerObjective") {
      return "Update your career objective.";
    } else if (section === "toolsTechnicalSkills") {
      return "Update your technical skills.";
    } else if (section === "programmingLanguages") {
      return "Update your programming languages.";
    } else if (section === "workExperiences" && index !== null) {
      return `Update work experience at index ${index + 1}.`;
    } else if (section === "projects" && index !== null) {
      return `Update project at index ${index + 1}.`;
    }
    return "";
  };

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    section: string,
    value: any,
    index: number
  ) => {
    dispatch(openPopover(event.currentTarget, section, value, index));
  };

  const handleClose = () => {
    dispatch(closePopover());
  };

  const handleFix = () => {
    debugger;
    if (sectionIndex !== -1) {
      dispatch(
        setExtractedData({
          ...resumeDetails,
          [section]: resumeDetails[section].map((item: any, index: number) =>
            index === sectionIndex ? { ...item, ...sectionValue } : item
          ),
        })
      );
      dispatch(
        setFixedSections({
          ...fixedSections,
          [section]: {
            ...(fixedSections[section] as { [index: number]: boolean }),
            [sectionIndex]: true,
          },
        })
      );
    } else {
      dispatch(setExtractedData({ ...resumeDetails, [section]: sectionValue }));
      dispatch(setFixedSections({ ...fixedSections, [section]: true }));
    }
    handleClose();
  };

  const getIconBasedOnWeightage = (weightage: number) => {
    if (weightage > 6) {
      return { color: "#EF4444", icon: <Error style={{ color: "#EF4444" }} /> };
    } else if (weightage > 3) {
      return { color: "#FF8A1C", icon: <Error style={{ color: "#FF8A1C" }} /> };
    } else if (weightage > 2) {
      return { color: "#E9CB33", icon: <Error style={{ color: "#E9CB33" }} /> };
    } else {
      return { color: "info.main", icon: <Error color="info" /> };
    }
  };

  const handleFixButton = (key: string) => {
    if (atsCheckResult) {
      const updatedAtsCheckResult = {
        ...atsCheckResult,
        resumeObject: {
          ...atsCheckResult.resumeObject,
          [key]:
            atsCheckResult.tailoredResumeObject.resumeObjectWithChanges[key],
        },
        fixedSections: {
          ...atsCheckResult.fixedSections,
          [key]: true,
        },
        atsScore:
          atsCheckResult.tailoredResumeObject.atsScore +
          atsCheckResult.tailoredResumeObject.weightage[key],
      };

      // Dispatch the updated resume object
      dispatch(setExtractedData(updatedAtsCheckResult.resumeObject));
      dispatch(
        setFixedSections({
          ...fixedSections,
          [key]: true,
        })
      );
      setAtsCheckResult(updatedAtsCheckResult);
      updateATSCheckResult(updatedAtsCheckResult);
    }
  };

  const handleFixSection = (section: string, id?: string) => {
    if (atsCheckResult) {
      switch (section) {
        case "workExperiences":
          if (!id) return;

          // Find the work experience in changes
          const updatedWorkExp =
            atsCheckResult.tailoredResumeObject.resumeObjectWithChanges.workExperiences.find(
              (we: any) => we.index === id
            );

          // Update ATS score and resume object
          const updatedAtsCheckResult = {
            ...atsCheckResult,
            resumeObject: {
              ...atsCheckResult.resumeObject,
              workExperiences: atsCheckResult.resumeObject.workExperiences.map(
                (we: any) =>
                  we.index === id ? { ...we, ...updatedWorkExp } : we
              ),
            },
            atsScore:
              atsCheckResult.tailoredResumeObject.atsScore +
              (atsCheckResult.tailoredResumeObject.weightage.workExperiences?.[
                id
              ] || 0),
          };

          // Update states
          setAtsCheckResult(updatedAtsCheckResult);
          dispatch(setExtractedData(updatedAtsCheckResult.resumeObject));
          dispatch(
            setFixedSections((prev: any) => ({
              ...prev,
              workExperiences: [...(prev.workExperiences || []), id],
            }))
          );
          updateATSCheckResult(updatedAtsCheckResult);
          break;

        case "professionalSummary":
          const updatedAtsResult = {
            ...atsCheckResult,
            resumeObject: {
              ...atsCheckResult.resumeObject,
              professionalSummary:
                atsCheckResult.tailoredResumeObject.resumeObjectWithChanges
                  .professionalSummary,
            },
            atsScore:
              atsCheckResult.tailoredResumeObject.atsScore +
              atsCheckResult.tailoredResumeObject.weightage.professionalSummary,
          };

          setAtsCheckResult(updatedAtsResult);
          dispatch(setExtractedData(updatedAtsResult.resumeObject));
          dispatch(
            setFixedSections((prev: any) => ({
              ...prev,
              professionalSummary: true,
            }))
          );
          updateATSCheckResult(updatedAtsResult);
          break;

        case "skills":
          const updatedAtsResultSkills = {
            ...atsCheckResult,
            resumeObject: {
              ...atsCheckResult.resumeObject,
              toolsTechnicalSkills:
                atsCheckResult.tailoredResumeObject.resumeObjectWithChanges
                  .toolsTechnicalSkills,
            },
            atsScore:
              atsCheckResult.tailoredResumeObject.atsScore +
              atsCheckResult.tailoredResumeObject.weightage
                .toolsTechnicalSkills,
          };

          setAtsCheckResult(updatedAtsResultSkills);
          dispatch(setExtractedData(updatedAtsResultSkills.resumeObject));
          dispatch(
            setFixedSections((prev: any) => ({
              ...prev,
              skills: true,
            }))
          );
          updateATSCheckResult(updatedAtsResultSkills);
          break;
      }
    }
  };

  const handleAddSkill = (skill: string) => {
    const updatedSkills = [
      ...(atsCheckResult.resumeObject.toolsTechnicalSkills || []),
      skill,
    ];
    dispatch(
      setExtractedData({
        ...atsCheckResult.resumeObject,
        toolsTechnicalSkills: updatedSkills,
      })
    );
  };

  const handleRemoveSkill = (skill: string) => {
    const updatedSkills =
      atsCheckResult.resumeObject.toolsTechnicalSkills.filter(
        (s: string) => s !== skill
      );
    dispatch(
      setExtractedData({
        ...atsCheckResult.resumeObject,
        toolsTechnicalSkills: updatedSkills,
      })
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box className="side-drawer-container">
          <SideDrawer />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ background: "#FFF", m: 0, overflowY: "auto", width: "100vw" }}
        >
          <Grid
            item
            xs={12}
            md={4}
            spacing={2}
            sx={{ p: 2, maxHeight: "100vh", overflowY: "auto" }}
          >
            {atsCheckResult && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: "bolder" }}
                  >
                    ATS Checker
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ color: "text.secondary" }}
                  >
                    Analyze your resume to ensure it passes Applicant Tracking
                    Systems and improves your chances with recruiters
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    px: 2,
                    py: 1.5,
                    border: "1px solid #CBD5E1",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontSize: 20 }}>
                      Resume suggestions
                    </Typography>
                    <BulbIcon />
                  </Box>
                  <Typography sx={{ fontSize: 15 }}>
                    We reviewed your resume and suggest improving the following
                    areas:
                  </Typography>
                </Box>
                <Box>
                  <Card
                    className={classes.resumeScorePanel}
                    sx={{
                      borderBottomLeftRadius: 12,
                      borderBottomRightRadius: 12,
                      boxShadow: "none",
                    }}
                  >
                    <Typography
                      variant="h6"
                      classes={classes.resumeScorePanelHeader}
                      sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}
                    >
                      Resume Check Score
                    </Typography>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        py: 1.5,
                        px: 3,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                      }}
                    >
                      <ResumeScore
                        atsScore={
                          atsCheckResult.atsScore
                            ? atsCheckResult.atsScore
                            : atsCheckResult.tailoredResumeObject.atsScore
                        }
                      />
                      {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography variant="body2">See how the score is calculated?</Typography>
                                        <CustomButton variant="contained" color="success" disableRipple>Fix Resume</CustomButton>
                                    </Box> */}
                    </CardContent>
                    <Typography
                      variant="h6"
                      classes={classes.resumeScorePanelHeader}
                      sx={{
                        p: 1.5,
                        fontSize: 16,
                        color: `#0284C7`,
                        display: "flex",
                      }}
                    >
                      <Info /> &nbsp; &nbsp; See how the score is calculated?
                    </Typography>
                  </Card>
                </Box>
                <Box>
                  <Card
                    className={classes.resumeScorePanel}
                    style={{
                      backgroundColor: "#FBFBFB",
                      borderBottomLeftRadius: 12,
                      gap: 1.5,
                      borderBottomRightRadius: 12,
                      boxShadow: "none",
                    }}
                  >
                    <Typography
                      variant="h6"
                      classes={classes.resumeScorePanelHeader}
                      sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}
                    >
                      Issues found in Resume
                    </Typography>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        py: 1.5,
                        px: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontSize: 14,
                          gap: 1.8,
                          mb: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Error
                            style={{
                              color: "#EF4444",
                              fontSize: 18,
                              marginRight: 2,
                            }}
                          />{" "}
                          High
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Error
                            style={{
                              color: "#FF8A1C",
                              fontSize: 18,
                              marginRight: 2,
                            }}
                          />{" "}
                          Medium
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Error
                            style={{
                              color: "#E9CB33",
                              fontSize: 18,
                              marginRight: 2,
                            }}
                          />{" "}
                          Low
                        </Box>
                      </Box>
                      <Typography sx={{ fontSize: 20 }} gutterBottom>
                        Work Experiences
                      </Typography>
                      {/* {atsCheckResult.tailoredResumeObject?.resumeObjectWithChanges?.workExperiences?.map((exp:any, index:number) => (
                                        <IssueCard key={index} workExperience={exp} status={fixedSections[]}/>
                                    ))} */}

                      {atsCheckResult.tailoredResumeObject?.resumeObjectWithChanges?.workExperiences?.map(
                        (exp: any, index: number) => {
                          // Find if this work experience has been fixed in fixedSections
                          const isFixed = fixedSections[
                            "workExperiences"
                          ]?.includes(exp.index);

                          const workExperience: any =
                            atsCheckResult.resumeObject?.workExperiences.find(
                              (we: any) => we.index === exp.index
                            );

                          return (
                            <IssueCard
                              key={exp.index}
                              role={workExperience.role}
                              company={workExperience.company}
                              startDate={workExperience.startDate}
                              endDate={workExperience.endDate}
                              location={workExperience.location}
                              keyResponsibilities={
                                workExperience.keyResponsibilitiesArray
                              }
                              index={workExperience.index}
                              status={isFixed ? "Fixed" : "Pending"}
                              handleFix={() =>
                                handleFixSection(
                                  "workExperiences",
                                  workExperience.index
                                )
                              }
                              level="High"
                            />
                          );
                        }
                      )}
                      <Divider sx={{ borderColor: "#E2E8F0", mb: 1.5 }} />

                      {atsCheckResult.tailoredResumeObject
                        ?.resumeObjectWithChanges?.professionalSummary && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flexGrow={1}
                            sx={{ mb: 1 }}
                          >
                            <Typography sx={{ fontSize: 20 }} gutterBottom>
                              Professional Summary
                            </Typography>
                            {fixedSections["professionalSummary"] ? (
                              <Chip
                                label="Fixed"
                                icon={<Check />}
                                sx={{
                                  color: "green",
                                  fontSize: 15,
                                  backgroundColor: "transparent",
                                  "& .MuiChip-icon": {
                                    color: "green",
                                  },
                                }}
                              />
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <GradientButton
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  onClick={() =>
                                    handleFixButton("professionalSummary")
                                  }
                                  sx={{ mt: 0, mr: 1 }}
                                >
                                  <AI_Spark_Icon />
                                  Fix it
                                </GradientButton>
                                {
                                  getIconBasedOnWeightage(
                                    atsCheckResult.tailoredResumeObject
                                      ?.weightage?.professionalSummary
                                  ).icon
                                }
                              </Box>
                            )}
                          </Box>
                          <Card
                            variant="outlined"
                            sx={{
                              borderRadius: 2,
                              mb: 2,
                              borderColor: "#E2E8F0",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            }}
                          >
                            <CardContent
                              sx={{ "&:last-child": { pb: 1.5 }, py: 1.5 }}
                            >
                              {
                                atsCheckResult.tailoredResumeObject
                                  ?.resumeObjectWithChanges?.professionalSummary
                              }
                            </CardContent>
                          </Card>
                        </>
                      )}

                      <Divider sx={{ borderColor: "#E2E8F0", mb: 1.5 }} />
                      {atsCheckResult.tailoredResumeObject
                        ?.resumeObjectWithChanges?.toolsTechnicalSkills
                        ?.length > 0 && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flexGrow={1}
                            sx={{ mb: 1 }}
                          >
                            <Typography sx={{ fontSize: 20 }} gutterBottom>
                              Skills
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                              {fixedSections["toolsTechnicalSkills"] ? (
                                <Chip
                                  label="Fixed"
                                  icon={<Check />}
                                  sx={{
                                    color: "green",
                                    fontSize: 15,
                                    backgroundColor: "transparent",
                                    "& .MuiChip-icon": {
                                      color: "green",
                                    },
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <GradientButton
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() =>
                                      handleFixButton("toolsTechnicalSkills")
                                    }
                                    sx={{ mt: 0, mr: 1 }}
                                  >
                                    <AI_Spark_Icon />
                                    Fix it
                                  </GradientButton>
                                  {
                                    getIconBasedOnWeightage(
                                      atsCheckResult.tailoredResumeObject
                                        ?.weightage?.toolsTechnicalSkills
                                    ).icon
                                  }
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Card
                            variant="outlined"
                            sx={{
                              borderRadius: 2,
                              mb: 2,
                              borderColor: "#E2E8F0",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            }}
                          >
                            <CardContent
                              sx={{ "&:last-child": { pb: 1.5 }, py: 1.5 }}
                            >
                              {/* {
                                                    atsCheckResult.tailoredResumeObject?.resumeObjectWithChanges?.toolsTechnicalSkills.map((skill: any, index: number) => (
                                                        <Chip key={index} variant="outlined" label={skill} className={classes.chip} onClick={() => {}}/>
                                                    ))
                                                } */}
                              {Array.isArray(
                                atsCheckResult.tailoredResumeObject
                                  ?.resumeObjectWithChanges
                                  ?.toolsTechnicalSkills
                              ) ? (
                                atsCheckResult.tailoredResumeObject?.resumeObjectWithChanges?.toolsTechnicalSkills.map(
                                  (skill: string, index: number) => {
                                    const existingSkill = skillsData.find(
                                      (s) =>
                                        s.label.toLowerCase() ===
                                        skill.toLowerCase()
                                    );

                                    return (
                                      <Tooltip title={skill} key={index}>
                                        <Chip
                                          sx={{
                                            height: "2.2rem",
                                            maxWidth: "150px",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            padding: "0.3rem",
                                            mb: 1,
                                            mr: 1,
                                            border: "1px solid #e2e8f0",
                                            borderRadius: "8px",
                                            "& .MuiChip-label": {
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              paddingLeft: "12px",
                                              paddingRight: "8px",
                                            },
                                          }}
                                          avatar={
                                            existingSkill?.icon ? (
                                              <existingSkill.icon />
                                            ) : (
                                              <Avatar
                                                sx={{
                                                  background: `linear-gradient(90deg, ${
                                                    avatarColors[
                                                      index %
                                                        avatarColors.length
                                                    ].primary
                                                  } 0%, ${
                                                    avatarColors[
                                                      index %
                                                        avatarColors.length
                                                    ].secondary
                                                  } 100%)`,
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontSize: "11px",
                                                    color: `${
                                                      avatarColors[
                                                        index %
                                                          avatarColors.length
                                                      ].text
                                                    }`,
                                                  }}
                                                >
                                                  {skill[0]?.toUpperCase()}
                                                </Typography>
                                              </Avatar>
                                            )
                                          }
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              {skill}
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  extractedData?.toolsTechnicalSkills?.find(
                                                    (s) => s.label === skill
                                                  )
                                                    ? handleRemoveSkill(skill)
                                                    : handleAddSkill(skill);
                                                }}
                                                sx={{ ml: 1 }}
                                              >
                                                {extractedData?.toolsTechnicalSkills?.find(
                                                  (s) => s.label === skill
                                                ) ? (
                                                  <Remove fontSize="small" />
                                                ) : (
                                                  <Add fontSize="small" />
                                                )}
                                              </IconButton>
                                            </Box>
                                          }
                                          variant="outlined"
                                        />
                                      </Tooltip>
                                    );
                                  }
                                )
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal",
                                    fontSize: "16px",
                                  }}
                                >
                                  {atsCheckResult.tailoredResumeObject
                                    ?.resumeObjectWithChanges
                                    ?.toolsTechnicalSkills !== null
                                    ? atsCheckResult.tailoredResumeObject
                                        ?.resumeObjectWithChanges
                                        ?.toolsTechnicalSkills
                                    : "N/A"}
                                </Typography>
                              )}
                            </CardContent>
                          </Card>
                        </>
                      )}
                      {/* <IssueCard section={'Skills'} title={atsCheckResult.tailoredResumeObject.resumeObjectWithChanges.toolsTechnicalSkills.join(", ")} subtitle={''} status={'Unfixed'} level={'Medium'}/> */}

                      <Divider sx={{ borderColor: "#E2E8F0", mb: 1.5 }} />

                      {/* <Typography sx={{ fontSize: 20 }} gutterBottom>Interests</Typography> */}
                      {/* <IssueCard section={'Interests'} title={'Agile, Data Analysis'} subtitle={''} status={'Unfixed'} level={'Low'}/> */}

                      {/* <List>
                                        {resumeObjectWithChanges && Object.keys(resumeObjectWithChanges).map((section: string) => {
                                            if (Array.isArray(resumeObjectWithChanges[section])) {
                                                return resumeObjectWithChanges[section].map((item: any, index: number) => (
                                                    <ListItem key={`${section}-${index}`} className={classes.listItem}>
                                                        <ListItemText primary={generateChangeDescription(section, index)} classes={{ primary: classes.listItemText }} />
                                                        {!fixedSections[section]?.[index] ? (
                                                            <Button variant="contained" color="warning" disableRipple onClick={(event) => dispatch(openPopover(sectionRefs[`${section}-${index}`]?.current, section, item, index))}>Fix it</Button>
                                                        )
                                                        : "Fixed"}
                                                    </ListItem>
                                                ));
                                            } else {
                                                return (
                                                    <ListItem key={section} className={classes.listItem}>
                                                        <ListItemText primary={generateChangeDescription(section)} classes={{ primary: classes.listItemText }} />
                                                        {!fixedSections[section] ? 
                                                            (
                                                                <Button variant="contained" color="warning" disableRipple onClick={(event) => dispatch(openPopover(event.currentTarget, section, resumeObjectWithChanges[section]))}>Fix it</Button>
                                                            )
                                                        : "Fixed"}
                                                    </ListItem>
                                                );
                                            }
                                        })}
                                    </List> */}
                    </CardContent>
                  </Card>
                </Box>
                {/* <Box>
                            <Card className={classes.resumeScorePanel}>
                                <Typography variant="h6" classes={classes.resumeScorePanelHeader} sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}>
                                    Metrics
                                </Typography>
                                <CardContent>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Resume Structure and Format</Typography>
                                        <Box sx={{ display: 'flex', a   lignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Clear Headings:</Typography>
                                            <LinearProgress variant="determinate" value={90} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>90%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Bullet Point Usage:</Typography>
                                            <LinearProgress variant="determinate" value={80} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>80%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>White Space Usage:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>8/10</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Content and Relevance</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Work Experience Relevance:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>9/10</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Skill Match:</Typography>
                                            <LinearProgress variant="determinate" value={85} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>85%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Education Relevance:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>7/10</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Readability and Clarity</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Readability Score:</Typography>
                                            <LinearProgress variant="determinate" value={65} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>65</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Sentence Complexity:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>16 words</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Jargon and Acronyms:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>15%</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1">Keyword Suggestions:</Typography>
                                            
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box> */}
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ p: 2, background: "#404040", height: "100%" }}
          >
            {/* <Box sx={{ width: "100%", p: 6, pt: 1, boxSizing: "border-box", display: "flex", flexDirection: "column", gap: 2 }}> */}
            <Box
              className="right-preview-container"
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                flexDirection: "column",
              }}
            >
              <Box className="right-preview-header-container">
                <ProcessResumePreviewHeader
                  currentPage={currentPage}
                  numPages={numPages}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                />
              </Box>
              <Box className="right-preview-content-container">
                <ProcessResumePreview
                  currentPage={currentPage}
                  setNumPages={setNumPages}
                  isATSPreview
                />
              </Box>
            </Box>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
      {/* <Container id="features" sx={{ p: { xs: 4, sm: 2 }, pb: 10 }}>
          <Button variant="contained" color="primary" className={classes.backButton} onClick={() => navigate("/")}>
              Back
          </Button>
          <Typography variant="h4" gutterBottom>
              Resume ATS Check Results
          </Typography>
          <Box className={classes.root}>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h6" className={classes.sectionTitle}>ATS Compatibility Score</Typography>
                      <LinearProgress variant="determinate" value={parseInt(data.summary["ATS Compatibility Score"])} className={classes.progress} />
                      <Typography variant="body1">{data.summary["ATS Compatibility Score"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Keyword Metrics</Typography>
                      <Typography variant="body1">Keyword Density: {data.summary["Keyword Metrics"]["Keyword Density"]}</Typography>
                      <Typography variant="body1">Keyword Relevance: {data.summary["Keyword Metrics"]["Keyword Relevance"]}</Typography>
                      <Typography variant="body1">Top Keywords:</Typography>
                      <Box>
                          {data.summary["Keyword Metrics"]["Top Keywords"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>

            <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Resume Structure and Format</Typography>
                      <Typography variant="body1">Clear Headings: {data.summary["Resume Structure and Format"]["Clear Headings"]}</Typography>
                      <Typography variant="body1">Bullet Point Usage: {data.summary["Resume Structure and Format"]["Bullet Point Usage"]}</Typography>
                      <Typography variant="body1">White Space Usage: {data.summary["Resume Structure and Format"]["White Space Usage"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Content and Relevance</Typography>
                      <Typography variant="body1">Work Experience Relevance: {data.summary["Content and Relevance"]["Work Experience Relevance"]}</Typography>
                      <Typography variant="body1">Skill Match: {data.summary["Content and Relevance"]["Skill Match"]}</Typography>
                      <Typography variant="body1">Education Relevance: {data.summary["Content and Relevance"]["Education Relevance"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Readability and Clarity</Typography>
                      <Typography variant="body1">Readability Score: {data.summary["Readability and Clarity"]["Readability Score"]}</Typography>
                      <Typography variant="body1">Sentence Complexity: {data.summary["Readability and Clarity"]["Sentence Complexity"]}</Typography>
                      <Typography variant="body1">Jargon and Acronyms: {data.summary["Readability and Clarity"]["Jargon and Acronyms"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Actionable Tips and Suggestions</Typography>
                      <Typography variant="body1">Improvement Suggestions:</Typography>
                      <List>
                          {data.summary["Actionable Tips and Suggestions"]["Improvement Suggestions"].map((tip, index) => (
                              <ListItem key={index} className={classes.listItem}>
                                  <ListItemText primary={tip} classes={{ primary: classes.listItemText }} />
                              </ListItem>
                          ))}
                      </List>
                      <Typography variant="body1">Keyword Suggestions:</Typography>
                      <Box>
                          {data.summary["Actionable Tips and Suggestions"]["Keyword Suggestions"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>
          </Box>
        </Container> */}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <DialogTitle>Edit Section</DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <DialogContentText>
            {section === "workExperiences" && sectionIndex !== null ? (
              sectionValue.keyResponsibilities.map(
                (item: string, index: number) => (
                  <TextField
                    onFocus={(event) => {
                      // debugger;
                    }}
                    key={index}
                    variant="outlined"
                    fullWidth
                    multiline
                    value={item}
                    sx={{
                      my: 1,
                    }}
                    onChange={(event) => {
                      const updatedKeyResponsibilities =
                        sectionValue.keyResponsibilities.map(
                          (val: string, i: number) =>
                            i === index ? event.target.value : val
                        );
                      const updatedValue = {
                        ...resumeDetails.workExperiences[index],
                        keyResponsibilities: updatedKeyResponsibilities,
                      };
                      dispatch(updatePopoverValue(updatedValue));
                    }}
                  />
                )
              )
            ) : section === "toolsTechnicalSkills" ||
              section === "programmingLanguages" ||
              section === "certifications" ? (
              typeof sectionValue !== "string" &&
              sectionValue.map((item: string, index: number) => (
                <Chip
                  key={index}
                  label={item}
                  className={classes.chip}
                  onDelete={(event) => {
                    const updatedSectionValue = sectionValue.filter(
                      (val: string, i: number) => i !== index
                    );
                    dispatch(updatePopoverValue(updatedSectionValue));
                  }}
                />
              ))
            ) : Array.isArray(sectionValue) ? (
              sectionValue.map((item: string, index: number) => (
                <TextField
                  key={index}
                  variant="outlined"
                  fullWidth
                  sx={{
                    my: 1,
                  }}
                  value={item}
                  onChange={(event) => {
                    const updatedValue = sectionValue.map(
                      (val: string, i: number) =>
                        i === index ? event.target.value : val
                    );
                    dispatch(updatePopoverValue(updatedValue));
                  }}
                />
              ))
            ) : (
              <TextField
                variant="outlined"
                fullWidth
                multiline
                sx={{
                  my: 1,
                }}
                value={sectionValue}
                onChange={(event) => {
                  const updatedValue = event.target.value;
                  dispatch(updatePopoverValue(updatedValue));
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFix} color="primary">
            Change
          </Button>
        </DialogActions>
      </Popover>
    </Box>
  );
};

export default ATSCheckResult;
