import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import { isValidUrl } from "../../../../components/common/CommonFunctions";
import CardEditHeader from "../../../../components/common/CardEditHeader";
import { Description } from "@mui/icons-material";
import ReactQuill from "react-quill";
import { quillModules } from "../../../../App";

const KeyAchievementEdit: React.FC = () => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const handleChange = (newValue: any, key: string) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        keyAchievements: newValue,
      })
    );
  };

  return (
    <Box className="section-item-container">
      <Box className="courses-edit-section">
        <Box className="input-box-container">
          <Box className="quill-container">
            <ReactQuill
              value={extractedData?.keyAchievements}
              onChange={(e: any) => handleChange(e, "description")}
              theme="snow"
              modules={quillModules}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default KeyAchievementEdit;
