import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  LinearGradient,
  Stop,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <svg
    width="596"
    height="842"
    viewBox="0 0 596 842"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4983_15965)">
      <rect width="596" height="842" fill="white" />
      <g opacity="0.1">
        <path
          d="M169 769.455L126.791 745.273L169 721.091V769.455Z"
          fill="#08C5D1"
        />
        <path
          d="M169 721.091L126.791 745.273V696.909L169 721.091Z"
          fill="#CC773C"
        />
        <path
          d="M169 672.727L126.791 648.546L169 624.364V672.727Z"
          fill="#FEBB79"
        />
        <path
          d="M169 672.728L126.791 696.909V648.546L169 672.728Z"
          fill="#6181B0"
        />
        <path
          d="M126.791 745.273L84.5 721.091L126.791 696.909V745.273Z"
          fill="#FFFCF6"
        />
        <path
          d="M126.791 745.273L84.5 769.455V721.091L126.791 745.273Z"
          fill="#CC773C"
        />
        <path
          d="M84.5003 817.818L42.291 793.636L84.5003 769.455V817.818Z"
          fill="#08C5D1"
        />
        <path
          d="M126.791 696.909L84.5 672.728L126.791 648.546V696.909Z"
          fill="#08C5D1"
        />
        <path
          d="M84.5003 769.455L42.291 745.273L84.5003 721.091V769.455Z"
          fill="#34486A"
        />
        <path d="M42.2907 842L0 817.818L42.2907 793.636V842Z" fill="#34486A" />
        <path
          d="M126.791 648.546L84.5 624.364L126.791 600.182V648.546Z"
          fill="#FFFCF6"
        />
        <path
          d="M126.791 648.546L84.5 672.727V624.364L126.791 648.546Z"
          fill="#34486A"
        />
        <path
          d="M84.5003 721.091L42.291 696.909L84.5003 672.728V721.091Z"
          fill="#FFFCF6"
        />
        <path
          d="M84.5003 721.091L42.291 745.273V696.909L84.5003 721.091Z"
          fill="#CC773C"
        />
        <path
          d="M42.2907 793.636L0 817.818V769.455L42.2907 793.636Z"
          fill="#08C5D1"
        />
        <path
          d="M126.791 600.182L84.5 624.364V576L126.791 600.182Z"
          fill="#FEBB79"
        />
        <path
          d="M84.5003 672.727L42.291 648.546L84.5003 624.364V672.727Z"
          fill="#6181B0"
        />
        <path
          d="M84.5003 672.728L42.291 696.909V648.546L84.5003 672.728Z"
          fill="#34486A"
        />
        <path
          d="M42.2907 745.273L0 721.091L42.2907 696.909V745.273Z"
          fill="#6181B0"
        />
        <path
          d="M42.2907 745.273L0 769.455V721.091L42.2907 745.273Z"
          fill="#FEBB79"
        />
        <path
          d="M84.5003 624.364L42.291 600.182L84.5003 576V624.364Z"
          fill="#FFFCF6"
        />
        <path
          d="M84.5003 624.364L42.291 648.546V600.182L84.5003 624.364Z"
          fill="#08C5D1"
        />
        <path
          d="M42.2907 696.909L0 672.728L42.2907 648.546V696.909Z"
          fill="#FEBB79"
        />
        <path
          d="M42.2907 696.909L0 721.091V672.728L42.2907 696.909Z"
          fill="#FEBB79"
        />
        <path
          d="M42.2907 648.546L0 624.364L42.2907 600.182V648.546Z"
          fill="#FFFCF6"
        />
        <path
          d="M42.2907 648.546L0 672.727V624.364L42.2907 648.546Z"
          fill="#6181B0"
        />
        <path
          d="M42.2907 600.182L0 624.364V576L42.2907 600.182Z"
          fill="#CC773C"
        />
      </g>
      <path
        d="M84.5003 721.091L42.291 745.273V696.909L84.5003 721.091Z"
        fill="#FFFCF6"
      />
      <path
        d="M42.2907 793.636L0 769.455L42.2907 745.273V793.636Z"
        fill="#34486A"
      />
      <path
        d="M42.2907 793.636L0 817.818V769.455L42.2907 793.636Z"
        fill="#08C5D1"
      />
      <path
        d="M84.5003 672.728L42.291 696.909V648.546L84.5003 672.728Z"
        fill="#CC773C"
      />
      <path
        d="M42.2907 745.273L0 769.455V721.091L42.2907 745.273Z"
        fill="#08C5D1"
      />
      <path
        d="M84.5003 624.364L42.291 648.546V600.182L84.5003 624.364Z"
        fill="#34486A"
      />
      <path
        d="M42.2907 696.909L0 672.728L42.2907 648.546V696.909Z"
        fill="#6181B0"
      />
      <path
        d="M42.2907 696.909L0 721.091V672.728L42.2907 696.909Z"
        fill="#FEBB79"
      />
      <path
        d="M42.2907 648.546L0 624.364L42.2907 600.182V648.546Z"
        fill="#FEBB79"
      />
      <path
        d="M42.2907 648.546L0 672.727V624.364L42.2907 648.546Z"
        fill="#34486A"
      />
      <g opacity="0.1">
        <path
          d="M384 88.6667L419.356 66.5V110.833L384 88.6667Z"
          fill="#08C5D1"
        />
        <path d="M384 133L419.356 110.833V155.167L384 133Z" fill="#FFFCF6" />
        <path d="M384 133L419.356 155.167L384 177.333V133Z" fill="#FEBB79" />
        <path
          d="M419.355 110.833L454.711 133L419.355 155.167V110.833Z"
          fill="#6181B0"
        />
        <path
          d="M419.355 110.833L454.711 88.6667V133L419.355 110.833Z"
          fill="#FEBB79"
        />
        <path
          d="M454.713 44.3333L490.001 66.5L454.713 88.6667V44.3333Z"
          fill="#CC773C"
        />
        <path
          d="M454.713 44.3333L490.001 22.1667V66.5L454.713 44.3333Z"
          fill="#6181B0"
        />
        <path
          d="M419.355 155.167L454.711 177.333L419.355 199.5V155.167Z"
          fill="#08C5D1"
        />
        <path
          d="M454.713 88.6667L490.001 110.833L454.713 133V88.6667Z"
          fill="#08C5D1"
        />
        <path
          d="M454.713 88.6667L490.001 66.5V110.833L454.713 88.6667Z"
          fill="#08C5D1"
        />
        <path
          d="M490 22.1667L525.356 44.3333L490 66.5V22.1667Z"
          fill="#FEBB79"
        />
        <path
          d="M419.355 199.5L454.711 177.333V221.667L419.355 199.5Z"
          fill="#CC773C"
        />
        <path
          d="M454.713 133L490.001 155.167L454.713 177.333V133Z"
          fill="#34486A"
        />
        <path
          d="M454.713 133L490.001 110.833V155.167L454.713 133Z"
          fill="#CC773C"
        />
        <path
          d="M454.713 177.333L490.001 199.5L454.713 221.667V177.333Z"
          fill="#FEBB79"
        />
        <path
          d="M454.713 177.333L490.001 155.167V199.5L454.713 177.333Z"
          fill="#6181B0"
        />
        <path
          d="M490 110.833L525.356 133L490 155.167V110.833Z"
          fill="#FFFCF6"
        />
        <path
          d="M490 110.833L525.356 88.6667V133L490 110.833Z"
          fill="#CC773C"
        />
        <path
          d="M525.355 44.3333L560.643 66.5L525.355 88.6667V44.3333Z"
          fill="#08C5D1"
        />
        <path
          d="M454.713 221.667L490.001 243.833L454.713 266V221.667Z"
          fill="#6181B0"
        />
        <path
          d="M490 155.167L525.356 177.333L490 199.5V155.167Z"
          fill="#08C5D1"
        />
        <path
          d="M525.355 88.6667L560.643 110.833L525.355 133V88.6667Z"
          fill="#34486A"
        />
        <path
          d="M525.355 88.6667L560.643 66.5V110.833L525.355 88.6667Z"
          fill="#FFFCF6"
        />
        <path
          d="M560.645 22.1667L596.001 44.3333L560.645 66.5V22.1667Z"
          fill="#34486A"
        />
        <path
          d="M560.645 22.1667L596.001 0V44.3333L560.645 22.1667Z"
          fill="#08C5D1"
        />
        <path
          d="M490 199.5L525.356 221.667L490 243.833V199.5Z"
          fill="#FFFCF6"
        />
        <path
          d="M490 199.5L525.356 177.333V221.667L490 199.5Z"
          fill="#34486A"
        />
        <path
          d="M525.355 133L560.643 155.167L525.355 177.333V133Z"
          fill="#FFFCF6"
        />
        <path
          d="M525.355 133L560.643 110.833V155.167L525.355 133Z"
          fill="#CC773C"
        />
        <path
          d="M560.645 66.5L596.001 44.3333V88.6667L560.645 66.5Z"
          fill="#08C5D1"
        />
        <path
          d="M490 243.833L525.356 221.667V266L490 243.833Z"
          fill="#FEBB79"
        />
        <path
          d="M525.355 177.333L560.643 199.5L525.355 221.667V177.333Z"
          fill="#6181B0"
        />
        <path
          d="M525.355 177.333L560.643 155.167V199.5L525.355 177.333Z"
          fill="#34486A"
        />
        <path
          d="M560.645 110.833L596.001 133L560.645 155.167V110.833Z"
          fill="#6181B0"
        />
        <path
          d="M560.645 110.833L596.001 88.6667V133L560.645 110.833Z"
          fill="#FEBB79"
        />
        <path
          d="M525.355 221.667L560.643 243.833L525.355 266V221.667Z"
          fill="#FFFCF6"
        />
        <path
          d="M525.355 221.667L560.643 199.5V243.833L525.355 221.667Z"
          fill="#08C5D1"
        />
        <path
          d="M560.645 155.167L596.001 177.333L560.645 199.5V155.167Z"
          fill="#FEBB79"
        />
        <path
          d="M560.645 155.167L596.001 133V177.333L560.645 155.167Z"
          fill="#FEBB79"
        />
        <path
          d="M560.645 199.5L596.001 221.667L560.645 243.833V199.5Z"
          fill="#FFFCF6"
        />
        <path
          d="M560.645 199.5L596.001 177.333V221.667L560.645 199.5Z"
          fill="#6181B0"
        />
        <path
          d="M560.645 243.833L596.001 221.667V266L560.645 243.833Z"
          fill="#CC773C"
        />
      </g>
      <path d="M384 88.6667L419.356 66.5V110.833L384 88.6667Z" fill="#08C5D1" />
      <path d="M384 133L419.356 110.833V155.167L384 133Z" fill="#FFFCF6" />
      <path d="M384 133L419.356 155.167L384 177.333V133Z" fill="#FEBB79" />
      <path
        d="M419.355 110.833L454.711 133L419.355 155.167V110.833Z"
        fill="#6181B0"
      />
      <path
        d="M419.355 110.833L454.711 88.6667V133L419.355 110.833Z"
        fill="#FEBB79"
      />
      <path
        d="M454.713 44.3333L490.001 66.5L454.713 88.6667V44.3333Z"
        fill="#CC773C"
      />
      <path
        d="M454.713 44.3333L490.001 22.1667V66.5L454.713 44.3333Z"
        fill="#6181B0"
      />
      <path
        d="M419.355 155.167L454.711 177.333L419.355 199.5V155.167Z"
        fill="#08C5D1"
      />
      <path d="M490 22.1667L525.356 44.3333L490 66.5V22.1667Z" fill="#FEBB79" />
      <path
        d="M419.355 199.5L454.711 177.333V221.667L419.355 199.5Z"
        fill="#CC773C"
      />
      <path
        d="M454.713 177.333L490.001 199.5L454.713 221.667V177.333Z"
        fill="#FEBB79"
      />
      <path
        d="M454.713 177.333L490.001 155.167V199.5L454.713 177.333Z"
        fill="#6181B0"
      />
      <path
        d="M454.713 221.667L490.001 243.833L454.713 266V221.667Z"
        fill="#6181B0"
      />
      <path d="M490 199.5L525.356 221.667L490 243.833V199.5Z" fill="#FFFCF6" />
      <path d="M490 199.5L525.356 177.333V221.667L490 199.5Z" fill="#34486A" />
      <path d="M490 243.833L525.356 221.667V266L490 243.833Z" fill="#FEBB79" />
      <path
        d="M525.355 177.333L560.643 199.5L525.355 221.667V177.333Z"
        fill="#6181B0"
      />
      <path
        d="M525.355 221.667L560.643 243.833L525.355 266V221.667Z"
        fill="#FFFCF6"
      />
      <path
        d="M525.355 221.667L560.643 199.5V243.833L525.355 221.667Z"
        fill="#08C5D1"
      />
      <path
        d="M560.645 199.5L596.001 221.667L560.645 243.833V199.5Z"
        fill="#FFFCF6"
      />
      <path
        d="M560.645 199.5L596.001 177.333V221.667L560.645 199.5Z"
        fill="#6181B0"
      />
      <path
        d="M560.645 243.833L596.001 221.667V266L560.645 243.833Z"
        fill="#CC773C"
      />
      <path
        d="M454.713 133L490.001 110.833V155.167L454.713 133Z"
        fill="#CC773C"
      />
      <path d="M490 66.5L525.356 88.6667L490 110.833V66.5Z" fill="#34486A" />
      <path d="M490 66.5L525.356 44.3333V88.6667L490 66.5Z" fill="#CC773C" />
      <path d="M490 110.833L525.356 133L490 155.167V110.833Z" fill="#FEBB79" />
      <path d="M490 110.833L525.356 88.6667V133L490 110.833Z" fill="#6181B0" />
      <path
        d="M525.355 44.3333L560.643 66.5L525.355 88.6667V44.3333Z"
        fill="#FFFCF6"
      />
      <path
        d="M490 155.167L525.356 177.333L490 199.5V155.167Z"
        fill="#6181B0"
      />
      <path
        d="M525.355 88.6667L560.643 110.833L525.355 133V88.6667Z"
        fill="#08C5D1"
      />
      <path
        d="M525.355 133L560.643 155.167L525.355 177.333V133Z"
        fill="#FFFCF6"
      />
      <path
        d="M525.355 133L560.643 110.833V155.167L525.355 133Z"
        fill="#34486A"
      />
      <path
        d="M560.645 66.5L596.001 88.6667L560.645 110.833V66.5Z"
        fill="#FFFCF6"
      />
      <path
        d="M525.355 177.333L560.643 155.167V199.5L525.355 177.333Z"
        fill="#FEBB79"
      />
      <path
        d="M560.645 110.833L596.001 133L560.645 155.167V110.833Z"
        fill="#6181B0"
      />
      <path
        d="M560.645 110.833L596.001 88.6667V133L560.645 110.833Z"
        fill="#34486A"
      />
      <path
        d="M560.645 155.167L596.001 177.333L560.645 199.5V155.167Z"
        fill="#FFFCF6"
      />
      <path
        d="M560.645 155.167L596.001 133V177.333L560.645 155.167Z"
        fill="#08C5D1"
      />
    </g>
    <defs>
      <clipPath id="clip0_4983_15965">
        <rect width="596" height="842" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_4983_15965)">
        <Rect width="596" height="842" fill="white" />
        <G opacity="0.1">
          <Path
            d="M169 769.455L126.791 745.273L169 721.091V769.455Z"
            fill="#08C5D1"
          />
          <Path
            d="M169 721.091L126.791 745.273V696.909L169 721.091Z"
            fill="#CC773C"
          />
          <Path
            d="M169 672.727L126.791 648.546L169 624.364V672.727Z"
            fill="#FEBB79"
          />
          <Path
            d="M169 672.728L126.791 696.909V648.546L169 672.728Z"
            fill="#6181B0"
          />
          <Path
            d="M126.791 745.273L84.5 721.091L126.791 696.909V745.273Z"
            fill="#FFFCF6"
          />
          <Path
            d="M126.791 745.273L84.5 769.455V721.091L126.791 745.273Z"
            fill="#CC773C"
          />
          <Path
            d="M84.5003 817.818L42.291 793.636L84.5003 769.455V817.818Z"
            fill="#08C5D1"
          />
          <Path
            d="M126.791 696.909L84.5 672.728L126.791 648.546V696.909Z"
            fill="#08C5D1"
          />
          <Path
            d="M84.5003 769.455L42.291 745.273L84.5003 721.091V769.455Z"
            fill="#34486A"
          />
          <Path
            d="M42.2907 842L0 817.818L42.2907 793.636V842Z"
            fill="#34486A"
          />
          <Path
            d="M126.791 648.546L84.5 624.364L126.791 600.182V648.546Z"
            fill="#FFFCF6"
          />
          <Path
            d="M126.791 648.546L84.5 672.727V624.364L126.791 648.546Z"
            fill="#34486A"
          />
          <Path
            d="M84.5003 721.091L42.291 696.909L84.5003 672.728V721.091Z"
            fill="#FFFCF6"
          />
          <Path
            d="M84.5003 721.091L42.291 745.273V696.909L84.5003 721.091Z"
            fill="#CC773C"
          />
          <Path
            d="M42.2907 793.636L0 817.818V769.455L42.2907 793.636Z"
            fill="#08C5D1"
          />
          <Path
            d="M126.791 600.182L84.5 624.364V576L126.791 600.182Z"
            fill="#FEBB79"
          />
          <Path
            d="M84.5003 672.727L42.291 648.546L84.5003 624.364V672.727Z"
            fill="#6181B0"
          />
          <Path
            d="M84.5003 672.728L42.291 696.909V648.546L84.5003 672.728Z"
            fill="#34486A"
          />
          <Path
            d="M42.2907 745.273L0 721.091L42.2907 696.909V745.273Z"
            fill="#6181B0"
          />
          <Path
            d="M42.2907 745.273L0 769.455V721.091L42.2907 745.273Z"
            fill="#FEBB79"
          />
          <Path
            d="M84.5003 624.364L42.291 600.182L84.5003 576V624.364Z"
            fill="#FFFCF6"
          />
          <Path
            d="M84.5003 624.364L42.291 648.546V600.182L84.5003 624.364Z"
            fill="#08C5D1"
          />
          <Path
            d="M42.2907 696.909L0 672.728L42.2907 648.546V696.909Z"
            fill="#FEBB79"
          />
          <Path
            d="M42.2907 696.909L0 721.091V672.728L42.2907 696.909Z"
            fill="#FEBB79"
          />
          <Path
            d="M42.2907 648.546L0 624.364L42.2907 600.182V648.546Z"
            fill="#FFFCF6"
          />
          <Path
            d="M42.2907 648.546L0 672.727V624.364L42.2907 648.546Z"
            fill="#6181B0"
          />
          <Path
            d="M42.2907 600.182L0 624.364V576L42.2907 600.182Z"
            fill="#CC773C"
          />
        </G>
        <Path
          d="M84.5003 721.091L42.291 745.273V696.909L84.5003 721.091Z"
          fill="#FFFCF6"
        />
        <Path
          d="M42.2907 793.636L0 769.455L42.2907 745.273V793.636Z"
          fill="#34486A"
        />
        <Path
          d="M42.2907 793.636L0 817.818V769.455L42.2907 793.636Z"
          fill="#08C5D1"
        />
        <Path
          d="M84.5003 672.728L42.291 696.909V648.546L84.5003 672.728Z"
          fill="#CC773C"
        />
        <Path
          d="M42.2907 745.273L0 769.455V721.091L42.2907 745.273Z"
          fill="#08C5D1"
        />
        <Path
          d="M84.5003 624.364L42.291 648.546V600.182L84.5003 624.364Z"
          fill="#34486A"
        />
        <Path
          d="M42.2907 696.909L0 672.728L42.2907 648.546V696.909Z"
          fill="#6181B0"
        />
        <Path
          d="M42.2907 696.909L0 721.091V672.728L42.2907 696.909Z"
          fill="#FEBB79"
        />
        <Path
          d="M42.2907 648.546L0 624.364L42.2907 600.182V648.546Z"
          fill="#FEBB79"
        />
        <Path
          d="M42.2907 648.546L0 672.727V624.364L42.2907 648.546Z"
          fill="#34486A"
        />
        <G opacity="0.1">
          <Path
            d="M384 88.6667L419.356 66.5V110.833L384 88.6667Z"
            fill="#08C5D1"
          />
          <Path d="M384 133L419.356 110.833V155.167L384 133Z" fill="#FFFCF6" />
          <Path d="M384 133L419.356 155.167L384 177.333V133Z" fill="#FEBB79" />
          <Path
            d="M419.355 110.833L454.711 133L419.355 155.167V110.833Z"
            fill="#6181B0"
          />
          <Path
            d="M419.355 110.833L454.711 88.6667V133L419.355 110.833Z"
            fill="#FEBB79"
          />
          <Path
            d="M454.713 44.3333L490.001 66.5L454.713 88.6667V44.3333Z"
            fill="#CC773C"
          />
          <Path
            d="M454.713 44.3333L490.001 22.1667V66.5L454.713 44.3333Z"
            fill="#6181B0"
          />
          <Path
            d="M419.355 155.167L454.711 177.333L419.355 199.5V155.167Z"
            fill="#08C5D1"
          />
          <Path
            d="M454.713 88.6667L490.001 110.833L454.713 133V88.6667Z"
            fill="#08C5D1"
          />
          <Path
            d="M454.713 88.6667L490.001 66.5V110.833L454.713 88.6667Z"
            fill="#08C5D1"
          />
          <Path
            d="M490 22.1667L525.356 44.3333L490 66.5V22.1667Z"
            fill="#FEBB79"
          />
          <Path
            d="M419.355 199.5L454.711 177.333V221.667L419.355 199.5Z"
            fill="#CC773C"
          />
          <Path
            d="M454.713 133L490.001 155.167L454.713 177.333V133Z"
            fill="#34486A"
          />
          <Path
            d="M454.713 133L490.001 110.833V155.167L454.713 133Z"
            fill="#CC773C"
          />
          <Path
            d="M454.713 177.333L490.001 199.5L454.713 221.667V177.333Z"
            fill="#FEBB79"
          />
          <Path
            d="M454.713 177.333L490.001 155.167V199.5L454.713 177.333Z"
            fill="#6181B0"
          />
          <Path
            d="M490 110.833L525.356 133L490 155.167V110.833Z"
            fill="#FFFCF6"
          />
          <Path
            d="M490 110.833L525.356 88.6667V133L490 110.833Z"
            fill="#CC773C"
          />
          <Path
            d="M525.355 44.3333L560.643 66.5L525.355 88.6667V44.3333Z"
            fill="#08C5D1"
          />
          <Path
            d="M454.713 221.667L490.001 243.833L454.713 266V221.667Z"
            fill="#6181B0"
          />
          <Path
            d="M490 155.167L525.356 177.333L490 199.5V155.167Z"
            fill="#08C5D1"
          />
          <Path
            d="M525.355 88.6667L560.643 110.833L525.355 133V88.6667Z"
            fill="#34486A"
          />
          <Path
            d="M525.355 88.6667L560.643 66.5V110.833L525.355 88.6667Z"
            fill="#FFFCF6"
          />
          <Path
            d="M560.645 22.1667L596.001 44.3333L560.645 66.5V22.1667Z"
            fill="#34486A"
          />
          <Path
            d="M560.645 22.1667L596.001 0V44.3333L560.645 22.1667Z"
            fill="#08C5D1"
          />
          <Path
            d="M490 199.5L525.356 221.667L490 243.833V199.5Z"
            fill="#FFFCF6"
          />
          <Path
            d="M490 199.5L525.356 177.333V221.667L490 199.5Z"
            fill="#34486A"
          />
          <Path
            d="M525.355 133L560.643 155.167L525.355 177.333V133Z"
            fill="#FFFCF6"
          />
          <Path
            d="M525.355 133L560.643 110.833V155.167L525.355 133Z"
            fill="#CC773C"
          />
          <Path
            d="M560.645 66.5L596.001 44.3333V88.6667L560.645 66.5Z"
            fill="#08C5D1"
          />
          <Path
            d="M490 243.833L525.356 221.667V266L490 243.833Z"
            fill="#FEBB79"
          />
          <Path
            d="M525.355 177.333L560.643 199.5L525.355 221.667V177.333Z"
            fill="#6181B0"
          />
          <Path
            d="M525.355 177.333L560.643 155.167V199.5L525.355 177.333Z"
            fill="#34486A"
          />
          <Path
            d="M560.645 110.833L596.001 133L560.645 155.167V110.833Z"
            fill="#6181B0"
          />
          <Path
            d="M560.645 110.833L596.001 88.6667V133L560.645 110.833Z"
            fill="#FEBB79"
          />
          <Path
            d="M525.355 221.667L560.643 243.833L525.355 266V221.667Z"
            fill="#FFFCF6"
          />
          <Path
            d="M525.355 221.667L560.643 199.5V243.833L525.355 221.667Z"
            fill="#08C5D1"
          />
          <Path
            d="M560.645 155.167L596.001 177.333L560.645 199.5V155.167Z"
            fill="#FEBB79"
          />
          <Path
            d="M560.645 155.167L596.001 133V177.333L560.645 155.167Z"
            fill="#FEBB79"
          />
          <Path
            d="M560.645 199.5L596.001 221.667L560.645 243.833V199.5Z"
            fill="#FFFCF6"
          />
          <Path
            d="M560.645 199.5L596.001 177.333V221.667L560.645 199.5Z"
            fill="#6181B0"
          />
          <Path
            d="M560.645 243.833L596.001 221.667V266L560.645 243.833Z"
            fill="#CC773C"
          />
        </G>
        <Path
          d="M384 88.6667L419.356 66.5V110.833L384 88.6667Z"
          fill="#08C5D1"
        />
        <Path d="M384 133L419.356 110.833V155.167L384 133Z" fill="#FFFCF6" />
        <Path d="M384 133L419.356 155.167L384 177.333V133Z" fill="#FEBB79" />
        <Path
          d="M419.355 110.833L454.711 133L419.355 155.167V110.833Z"
          fill="#6181B0"
        />
        <Path
          d="M419.355 110.833L454.711 88.6667V133L419.355 110.833Z"
          fill="#FEBB79"
        />
        <Path
          d="M454.713 44.3333L490.001 66.5L454.713 88.6667V44.3333Z"
          fill="#CC773C"
        />
        <Path
          d="M454.713 44.3333L490.001 22.1667V66.5L454.713 44.3333Z"
          fill="#6181B0"
        />
        <Path
          d="M419.355 155.167L454.711 177.333L419.355 199.5V155.167Z"
          fill="#08C5D1"
        />
        <Path
          d="M490 22.1667L525.356 44.3333L490 66.5V22.1667Z"
          fill="#FEBB79"
        />
        <Path
          d="M419.355 199.5L454.711 177.333V221.667L419.355 199.5Z"
          fill="#CC773C"
        />
        <Path
          d="M454.713 177.333L490.001 199.5L454.713 221.667V177.333Z"
          fill="#FEBB79"
        />
        <Path
          d="M454.713 177.333L490.001 155.167V199.5L454.713 177.333Z"
          fill="#6181B0"
        />
        <Path
          d="M454.713 221.667L490.001 243.833L454.713 266V221.667Z"
          fill="#6181B0"
        />
        <Path
          d="M490 199.5L525.356 221.667L490 243.833V199.5Z"
          fill="#FFFCF6"
        />
        <Path
          d="M490 199.5L525.356 177.333V221.667L490 199.5Z"
          fill="#34486A"
        />
        <Path
          d="M490 243.833L525.356 221.667V266L490 243.833Z"
          fill="#FEBB79"
        />
        <Path
          d="M525.355 177.333L560.643 199.5L525.355 221.667V177.333Z"
          fill="#6181B0"
        />
        <Path
          d="M525.355 221.667L560.643 243.833L525.355 266V221.667Z"
          fill="#FFFCF6"
        />
        <Path
          d="M525.355 221.667L560.643 199.5V243.833L525.355 221.667Z"
          fill="#08C5D1"
        />
        <Path
          d="M560.645 199.5L596.001 221.667L560.645 243.833V199.5Z"
          fill="#FFFCF6"
        />
        <Path
          d="M560.645 199.5L596.001 177.333V221.667L560.645 199.5Z"
          fill="#6181B0"
        />
        <Path
          d="M560.645 243.833L596.001 221.667V266L560.645 243.833Z"
          fill="#CC773C"
        />
        <Path
          d="M454.713 133L490.001 110.833V155.167L454.713 133Z"
          fill="#CC773C"
        />
        <Path d="M490 66.5L525.356 88.6667L490 110.833V66.5Z" fill="#34486A" />
        <Path d="M490 66.5L525.356 44.3333V88.6667L490 66.5Z" fill="#CC773C" />
        <Path
          d="M490 110.833L525.356 133L490 155.167V110.833Z"
          fill="#FEBB79"
        />
        <Path
          d="M490 110.833L525.356 88.6667V133L490 110.833Z"
          fill="#6181B0"
        />
        <Path
          d="M525.355 44.3333L560.643 66.5L525.355 88.6667V44.3333Z"
          fill="#FFFCF6"
        />
        <Path
          d="M490 155.167L525.356 177.333L490 199.5V155.167Z"
          fill="#6181B0"
        />
        <Path
          d="M525.355 88.6667L560.643 110.833L525.355 133V88.6667Z"
          fill="#08C5D1"
        />
        <Path
          d="M525.355 133L560.643 155.167L525.355 177.333V133Z"
          fill="#FFFCF6"
        />
        <Path
          d="M525.355 133L560.643 110.833V155.167L525.355 133Z"
          fill="#34486A"
        />
        <Path
          d="M560.645 66.5L596.001 88.6667L560.645 110.833V66.5Z"
          fill="#FFFCF6"
        />
        <Path
          d="M525.355 177.333L560.643 155.167V199.5L525.355 177.333Z"
          fill="#FEBB79"
        />
        <Path
          d="M560.645 110.833L596.001 133L560.645 155.167V110.833Z"
          fill="#6181B0"
        />
        <Path
          d="M560.645 110.833L596.001 88.6667V133L560.645 110.833Z"
          fill="#34486A"
        />
        <Path
          d="M560.645 155.167L596.001 177.333L560.645 199.5V155.167Z"
          fill="#FFFCF6"
        />
        <Path
          d="M560.645 155.167L596.001 133V177.333L560.645 155.167Z"
          fill="#08C5D1"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4983_15965">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture31 = {
  ViewTexture,
  PdfTexture,
};

export default Texture31;
