import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineUser } from "react-icons/hi2";
import { PiBellLight, PiSuitcase } from "react-icons/pi";
import { FiLock } from "react-icons/fi";
import { MdOutlineHeadsetMic } from "react-icons/md";
import { ReactComponent as MyProfileIcon } from "../../assets/SVG/my-profile-icon.svg";
import { ReactComponent as JobPreferencesIcon } from "../../assets/SVG/job-preferences-icon.svg";
import { ReactComponent as NotificationsIcon } from "../../assets/SVG/notifications-icon.svg";
import { ReactComponent as PasswordAndSecurityIcon } from "../../assets/SVG/password-and-security-icon.svg";
import { ReactComponent as HelpAndSupportIcon } from "../../assets/SVG/help-and-support-icon.svg";
import cn from "classnames";

const settingsSidebarOptions = [
  {
    title: "My Profile",
    icon: (color?: string) => <HiOutlineUser size="1.25rem" color={color} />,
    // icon: (color?: string) => <MyProfileIcon />,
    path: "/settings/profile",
  },
  {
    title: "Job Preferences",
    icon: (color?: string) => <PiSuitcase size="1.35rem" color={color} />,
    // icon: (color?: string) => <JobPreferencesIcon />,
    path: "/settings/job-preferences",
  },
  {
    title: "Notifications",
    icon: (color?: string) => <PiBellLight size="1.35rem" color={color} />,
    // icon: (color?: string) => <NotificationsIcon />,
    path: "/settings/notifications",
  },
  {
    title: "Password & Security",
    icon: (color?: string) => <FiLock size="1.35rem" color={color} />,
    // icon: (color?: string) => <PasswordAndSecurityIcon />,
    path: "/settings/password",
  },
  {
    title: "Help & Support",
    icon: (color?: string) => (
      <MdOutlineHeadsetMic size="1.35rem" color={color} />
      // <HelpAndSupportIcon />
    ),
    path: "/settings/support",
  },
];
const SettingsSidebar = () => {
  const location = useLocation();

  const isActive = (path: any) => location.pathname === path;

  return (
    <Box
      sx={{ width: 250, borderRight: "1px solid #e0e0e0", padding: 2 }}
      className="setting-side-drawer-container"
    >
      <List>
        {settingsSidebarOptions.map((option, index) => (
          <ListItem
            button
            component={Link}
            to={option.path}
            sx={{
              backgroundColor: isActive(option.path)
                ? "#fafafa"
                : "transparent",
              borderRadius: "4px",
            }}
            disableRipple
            className="element-container"
          >
            <ListItemIcon
              sx={{ minWidth: "fit-content", mr: 1 }}
              className={cn({
                "icon-container-stroke": index === 0 || index === 3,
                "icon-container-fill": index !== 3 && index !== 0,
              })}
            >
              {option.icon(isActive(option.path) ? "#1189ff" : "#94A3B8")}
            </ListItemIcon>
            <ListItemText
              className="text-element"
              primaryTypographyProps={{
                sx: {
                  fontSize: "1rem",
                  color: isActive(option.path) ? "#1189ff" : "#344054",
                  fontWeight: isActive(option.path) ? "600" : "400",
                },
              }}
              primary={option.title}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SettingsSidebar;
