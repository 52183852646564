import { View, Text, StyleSheet } from "@react-pdf/renderer";
import {
  CourseType,
  IReference,
  TVolunterrWorks,
} from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const VolunteerWorkTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
  });

  return (
    <>
      {extractedData.volunteerWorks &&
        extractedData.volunteerWorks?.length > 0 && (
          <>
            {SectionHeader ? (
              <SectionHeader>VOLUNTEER WORK</SectionHeader>
            ) : (
              <Text style={styles.sectionHeader}>VOLUNTEER WORK</Text>
            )}
            <BodyContainer>
              {extractedData.volunteerWorks.map(
                (vol: TVolunterrWorks, index: number) => (
                  <View key={index}>
                    <Text
                      style={{
                        ...styles.sectionHeader,
                        ...styles.sectionSubHeader,
                      }}
                    >
                      {vol.instituitionName} at {vol?.location}
                    </Text>
                    <Text style={styles.sectionContent}>{vol?.role}</Text>
                    <Text style={styles.sectionContent}>
                      {vol?.startDate} to {vol?.endDate}
                    </Text>
                  </View>
                )
              )}
            </BodyContainer>
          </>
        )}
    </>
  );
};

export default VolunteerWorkTemplate;
