import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { defaultProfilePicture } from "../components/utils";
// import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import { loadFonts, loadSpecificFont } from "../utils/googleFontsHandler";
import WorkExperienceTemplate from "./components/WorkExperienceTemplate";
import EducationTemplate from "./components/EducationTemplate";
import ProjectsTemplate from "./components/ProjectsTemplate";
import CoursesTemplate from "./components/CoursesTemplate";
import AwardsTemplate from "./components/AwardsTemplate";
import ReferencesTemplate from "./components/ReferencesTemplate";
import VolunteerWorkTemplate from "./components/VolunteerWorkTemplate";
import ExtraCATemplate from "./components/ExtraCATemplate";
import CustomSectionTemplate from "./components/CustomSectionTemplate";
import ContactTemplate from "./components/ContactTemplate";
import SkillsTemplate from "./components/SkillsTemplate";
import ProgrammingLangTemplate from "./components/ProgrammingLangTemplate";
import LanguagesTemplate from "./components/LanguagesTemplate";
import HobbiesTemplate from "./components/HobbiesTemplate";
import ProfessionalSummaryTemplate from "./components/ProfessionalSummaryTemplate";
import { useEffect } from "react";
import TextureHandler from "./components/TextureHandler";
import { EXTRACTED_DUMMY_DATA } from "../utils/dummyData";
import CertificationsTemplate from "./components/CertificationTemplate";
import PublicationTemplate from "./components/PublicationTemplate";
import GoalTemplate from "./components/GoalTemplate";
import KeyAchievementsTemplate from "./components/KeyAchievementsTemplate";

Font.register({
  family: "Aptos",
  src: `${process.env.REACT_APP_API_URL}/Aptos.ttf`,
});

Font.register({
  family: "Aptos Bold",
  src: `${process.env.REACT_APP_API_URL}/Aptos-Bold.ttf`,
});

loadFonts();

const Template_4 = (props: any) => {
  let {
    extractedData,
    pageWidth,
    isATSPreview = false,
  }: { extractedData: any; pageWidth: any; isATSPreview: boolean } = props;
  // extractedData = EXTRACTED_DUMMY_DATA;
  const relativeFontSize = extractedData.metaInformation?.relativeFontSize || 0;
  const {
    graphColor = "rgb(191, 60, 124)",
    primaryColor = "rgb(191, 60, 124)",
    secondaryColor = "#4f6071",
    altBackgroundColor = "#f6f7f8",
    textureId = "",
  } = extractedData?.metaInformation || {};
  const fontFamily = extractedData.metaInformation?.fontFamily || "";

  // useEffect(() => {
  //   console.log("fontFamily:", fontFamily);

  //   loadSpecificFont(fontFamily);
  // }, [fontFamily]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      // Set the page to horizontal layout
      padding: 20,
      fontFamily,
    },
    rightColumn: {
      flex: 1, // Occupy half of the page width
      marginLeft: 10, // Add a margin between columns
      marginTop: 40,
    },
    leftColumn: {
      flex: 0.5,
      padding: 40,
      color: secondaryColor || "rgb(78, 96, 113)",
    },
    designation: {
      fontSize: 20 + relativeFontSize,
      fontWeight: "bold",
      marginBottom: 20,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    name: {
      fontSize: 50 + relativeFontSize,
      fontWeight: "bold",
      marginBottom: 10,
      color: primaryColor || "rgb(78, 96, 113)",
    },
    image: {
      width: 150, // Adjust the width of the image
      height: 150, // Adjust the height of the image
      marginBottom: 10, // Add a margin between columns
    },
    jobTitle: {
      fontSize: 14 + relativeFontSize,
      color: "rgb(89, 89, 89)",
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 20,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },

    contact: {
      fontSize: 12 + relativeFontSize,
      marginTop: 5,
    },
    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
    svgContainer: {
      width: 100,
      height: 100,
    },
  });

  const nameParts = extractedData?.fullName?.split(" ");
  if (!nameParts?.length) return null;

  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");

  const styleVariables = {
    primaryColor,
    secondaryColor,
    graphColor,
    altBackgroundColor,
    fontFamily,
    relativeFontSize,
    textureId,
  };

  const SectionHeader = ({ children }: { children: any }) => (
    <Text style={styles.sectionHeader}>{children}</Text>
  );

  console.log(extractedData, "from template 4");

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, width: pageWidth }}>
        <TextureHandler
          extractedData={extractedData}
          isATSPreview={isATSPreview}
          styleVariables={styleVariables}
        />

        <View style={styles.leftColumn}>
          {extractedData?.profilePicture &&
            extractedData?.profilePicture !== "NA" &&
            extractedData.profilePicture !== defaultProfilePicture && (
              <Image style={styles.image} src={extractedData?.profilePicture} />
            )}

          <ContactTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <SkillsTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <ProgrammingLangTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <LanguagesTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <HobbiesTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />
        </View>

        <View style={styles.rightColumn}>
          <Text style={styles.designation}>{extractedData.designation}</Text>
          <Text style={styles.name}>{firstName}</Text>
          {lastName && (
            <Text style={{ ...styles.name, marginTop: "-25px" }}>
              {lastName}
            </Text>
          )}
          {(extractedData?.country || extractedData?.city_state) && (
            <Text style={styles.sectionContent}>
              {extractedData?.city_state}
              {extractedData?.city_state && ","} {extractedData?.country}
            </Text>
          )}

          <Text style={styles.jobTitle}>{extractedData.jobTitle}</Text>

          <ProfessionalSummaryTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <View style={{ marginTop: 20 }}>
            <WorkExperienceTemplate
              extractedData={extractedData}
              isATSPreview={isATSPreview}
              styleVariables={styleVariables}
              SectionHeader={SectionHeader}
            />
          </View>

          <EducationTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <CertificationsTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <ProjectsTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <CoursesTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <AwardsTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <ReferencesTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <VolunteerWorkTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <ExtraCATemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />

          <PublicationTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <GoalTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <KeyAchievementsTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
          />

          <CustomSectionTemplate
            extractedData={extractedData}
            isATSPreview={isATSPreview}
            styleVariables={styleVariables}
            SectionHeader={SectionHeader}
          />
        </View>
      </Page>
    </Document>
  );
};

export default Template_4;
