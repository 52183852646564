import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <g clip-path="url(#clip0_4983_16150)">
        <rect width="596" height="842" fill="white" />
        <g opacity="0.7">
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M586.691 747.375V751.24L583.346 753.153L580.041 751.2V747.375L583.386 745.463L586.691 747.375ZM580.36 747.574V751.001L583.346 752.714L586.332 751.001V747.574L583.346 745.861L580.36 747.574Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M596.127 750.642V752.675L586.531 758.213V774.988L596.088 780.526V782.558L584.779 775.984V757.177L596.127 750.642Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M504.386 747.096V748.371L503.311 748.969L502.236 748.371V747.096L503.311 746.499L504.386 747.096ZM502.316 747.136V748.252L503.311 748.81L504.267 748.252V747.136L503.311 746.578L502.316 747.136Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M476.194 741.837L474.761 741L473.367 741.837V743.431L474.761 744.267L476.194 743.431V741.837Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M558.697 770.206L549.857 765.106L541.018 770.206V780.407L549.817 785.507L558.697 780.407V770.206Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M511.195 774.589L510.438 774.151L509.721 774.589L509.682 775.426L510.438 775.864L511.195 775.426V774.589Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M432.434 750.802V755.344L428.452 757.655L424.51 755.344V750.802L428.492 748.491L432.434 750.802ZM424.948 751.041V755.145L428.452 757.177L431.996 755.145V751.081L428.452 749.009L424.948 751.041Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M445.813 745.821V748.252L443.702 749.487L441.592 748.252V745.821L443.702 744.586L445.813 745.821ZM441.831 745.941L441.791 748.132L443.702 749.208L445.574 748.132V745.941L443.702 744.865L441.831 745.941Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M507.889 760.604V771.202L498.691 776.502L489.533 771.202V760.564L498.731 755.264L507.889 760.604ZM490.529 761.162L490.489 770.645L498.691 775.386L506.894 770.645V761.201L498.691 756.46L490.529 761.162Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M562.081 745.981V746.857L561.325 747.295L560.568 746.857V745.981L561.325 745.582L562.081 745.981ZM560.648 746.02V746.778L561.325 747.176L562.002 746.778V746.02L561.325 745.622L560.648 746.02Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M496.502 751.479L496.462 767.577L482.525 775.625L468.629 767.537V751.439L482.565 743.431L496.502 751.479ZM470.142 752.316V766.66L482.565 773.832L494.989 766.7V752.316L482.565 745.144L470.142 752.316Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M460.427 753.87V755.902L458.675 756.898L456.963 755.902V753.87L458.675 752.874L460.427 753.87ZM457.003 753.91V755.862L458.715 756.818L460.387 755.862V753.91L458.715 752.914L457.003 753.91Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M531.105 771.641V773.713L529.273 774.749L527.441 773.713L527.481 771.601L529.273 770.565L531.105 771.641ZM527.521 771.681V773.713L529.273 774.749L531.065 773.713V771.681L529.313 770.645L527.521 771.681Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M458.634 775.785V777.219L457.4 777.896L456.205 777.219V775.785L457.4 775.107L458.634 775.785ZM456.245 775.785V777.139L457.439 777.857L458.594 777.179V775.785L457.439 775.107L456.245 775.785Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M534.011 747.136V758.611L524.056 764.349L514.102 758.611V747.096L524.056 741.359L534.011 747.136ZM514.42 747.295L514.38 758.452L524.056 764.07L533.732 758.492L533.772 747.295L524.096 741.717L514.42 747.295Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M572.595 751.28L572.555 767.816L558.221 776.103L543.926 767.816V751.24L558.26 742.992L572.595 751.28ZM544.364 751.479L544.324 767.577L558.26 775.625L572.197 767.616V751.519L558.3 743.431L544.364 751.479Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.193 772.796V773.832L423.356 774.31L422.48 773.832V772.796L423.356 772.318L424.193 772.796ZM422.48 772.796V773.792L423.317 774.271L424.153 773.792V772.796L423.317 772.318L422.48 772.796Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M450.073 766.182L450.034 778.892L439.084 785.188L428.094 778.853V766.182L439.084 759.847L450.073 766.182ZM428.412 766.341V778.653L439.084 784.829L449.755 778.693L449.795 766.341L439.123 760.165L428.412 766.341Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M486.11 768.971V779.968L476.554 785.467L467.037 779.968V768.971L476.593 763.473L486.11 768.971ZM467.316 769.091L467.276 779.809L476.554 785.148L485.831 779.809V769.091L476.593 763.751L467.316 769.091Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M548.703 746.379V754.866L541.297 759.129L533.971 754.866V746.339L541.337 742.116L548.703 746.379ZM534.17 746.459V754.747L541.337 758.89L548.504 754.747V746.459L541.337 742.315L534.17 746.459Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M510.518 801.803L490.927 790.487L471.337 801.763L471.297 824.435L490.887 835.751L510.478 824.475L510.518 801.803Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M563.953 807.9L551.331 800.608L538.669 807.9L538.629 822.483L551.291 829.814L563.913 822.523L563.953 807.9Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M503.51 776.621L503.47 782.359L498.493 785.228L493.516 782.359L493.555 776.581L498.533 773.713L503.51 776.621ZM494.073 776.94V782.08L498.493 784.63L502.953 782.08V776.94L498.533 774.39L494.073 776.94Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M549.301 791.324L549.261 819.455L524.893 833.48L500.604 819.375L500.643 791.284L524.972 777.259L549.301 791.324ZM503.271 792.798L503.232 817.861L524.932 830.412L546.633 817.901L546.673 792.838L524.972 780.287L503.271 792.798Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M380.471 776.223V778.096L378.839 779.012L377.246 778.096V776.223L378.839 775.267L380.471 776.223ZM377.405 776.342V777.976L378.879 778.813L380.312 778.016V776.342L378.879 775.506L377.405 776.342Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M354.907 780.486L352.797 779.251L350.727 780.486V782.917L352.797 784.112L354.907 782.917V780.486Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M596.088 773.673L596.048 803.796L583.943 796.783V780.646L596.088 773.673Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M591.07 772.278L587.566 770.286L584.102 772.278V776.303L587.566 778.295L591.07 776.303V772.278Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M461.621 810.729L448.441 803.078L435.221 810.689L435.182 825.949L448.401 833.6L461.621 825.989V810.729Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M390.624 817.263L389.509 816.626L388.395 817.263V818.538L389.509 819.176L390.624 818.538V817.263Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M594.255 807.421L581.314 799.931L568.373 807.382V822.323L581.274 829.774L594.215 822.323L594.255 807.421Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M385.728 796.345V812.203L371.991 820.132L358.254 812.203L358.294 796.345L372.031 788.415L385.728 796.345ZM359.727 797.181V811.326L371.951 818.419L384.215 811.366V797.221L371.991 790.129L359.727 797.181Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M535.722 774.47V778.096L532.577 779.889L529.471 778.096V774.47L532.616 772.677L535.722 774.47ZM529.789 774.629V777.857L532.577 779.49L535.364 777.896V774.669L532.577 773.035L529.789 774.629Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M466.677 774.549V775.864L465.562 776.502L464.447 775.824V774.549L465.562 773.912L466.677 774.549ZM464.567 774.589V775.745L465.562 776.342L466.558 775.745V774.589L465.562 774.031L464.567 774.589Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.3">
            <path
              d="M596.048 799.333V817.98L595.371 817.582V799.731L596.048 799.333Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M420.37 812.84V815.988L417.663 817.542L414.955 815.988V812.84L417.663 811.286L420.37 812.84ZM415.035 812.88V815.908L417.663 817.422L420.291 815.908V812.88L417.663 811.366L415.035 812.88Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.75 776.223V793.396L409.858 802.003L395.006 793.396V776.183L409.898 767.616L424.75 776.223ZM395.404 776.422V793.157L409.858 801.524L424.312 793.197L424.352 776.462L409.898 768.095L395.404 776.422Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M482.407 782.439L482.367 807.182L460.945 819.534L439.562 807.143V782.399L460.985 770.047L482.407 782.439ZM440.2 782.718L440.16 806.784L460.985 818.857L481.81 806.864L481.849 782.797L461.025 770.724L440.2 782.718Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M353.156 808.896L353.116 825.352L338.862 833.56L324.646 825.312V808.856L338.901 800.648L353.156 808.896ZM325.084 809.095L325.045 825.113L338.901 833.121L352.758 825.113L352.798 809.135L338.941 801.086L325.084 809.095Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M446.689 775.107V787.818L435.66 794.153L424.67 787.778V775.067L435.7 768.732L446.689 775.107ZM424.949 775.227V787.619L435.66 793.834L446.371 787.658V775.267L435.7 769.051L424.949 775.227Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M563.157 782.08V783.634L561.803 784.431L560.449 783.634V782.08L561.803 781.283L563.157 782.08ZM560.489 782.12V783.634L561.803 784.391L563.117 783.634V782.12L561.803 781.363L560.489 782.12Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M514.977 824.236L515.016 807.342L529.63 798.895L544.243 807.382V824.276L529.59 832.723L514.977 824.236ZM543.685 823.917V807.661L529.63 799.532L515.574 807.661L515.534 823.917L529.59 832.046L543.685 823.917Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M542.212 785.029V790.806L537.235 793.675L532.258 790.766V785.029L537.235 782.16L542.212 785.029ZM532.775 785.347V790.448L537.235 793.037L541.655 790.487V785.347L537.235 782.757L532.775 785.347Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M588.004 799.771L587.964 827.862L563.636 841.887L539.307 827.822L539.346 799.731L563.675 785.666L588.004 799.771ZM541.974 801.245V826.348L563.636 838.899L585.336 826.388L585.376 801.325L563.675 788.734L541.974 801.245Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M419.174 784.63V786.503L417.542 787.459L415.949 786.503V784.63L417.542 783.714L419.174 784.63ZM416.148 784.71L416.108 786.383L417.582 787.22L419.015 786.383V784.71L417.582 783.873L416.148 784.71Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M387.998 786.702L385.888 785.507L383.817 786.702L383.777 789.133L385.888 790.328L387.998 789.133V786.702Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.5">
            <path
              d="M500.323 819.176L487.143 811.525L473.924 819.136V834.396L487.104 842.047L500.323 834.436V819.176Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M429.369 825.67L428.254 825.033L427.139 825.67V826.985L428.254 827.623L429.369 826.985V825.67Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M331.655 782.718V786.344L328.47 788.176L325.324 786.344V782.678L328.47 780.885L331.655 782.718ZM325.683 782.877V786.144L328.51 787.778L331.337 786.144V782.917L328.51 781.283L325.683 782.877Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.432 804.792V820.65L410.694 828.579L396.957 820.61L396.997 804.752L410.734 796.863L424.432 804.792ZM398.47 805.628L398.43 819.773L410.654 826.826L422.918 819.773V805.628L410.694 798.576L398.47 805.628Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M574.425 782.917V786.543L571.319 788.336L568.174 786.503V782.917L571.319 781.084L574.425 782.917ZM568.492 783.116V786.344L571.28 787.977L574.067 786.344V783.116L571.28 781.522L568.492 783.116Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M505.422 782.997L505.382 784.272L504.267 784.909L503.152 784.272V782.997L504.267 782.319L505.422 782.997ZM503.312 783.076V784.232L504.307 784.829L505.303 784.232V783.076L504.307 782.518L503.312 783.076Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M353.474 794.791V797.779L350.886 799.293L348.258 797.779L348.298 794.751L350.886 793.276L353.474 794.791ZM348.337 794.83V797.739L350.846 799.213L353.394 797.739V794.83L350.886 793.356L348.337 794.83Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M459.073 821.288V824.395L456.366 825.989L453.658 824.395V821.288L456.366 819.694L459.073 821.288ZM453.738 821.327V824.395L456.366 825.91L458.994 824.395L459.034 821.367L456.366 819.813L453.738 821.327Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M350.768 827.543V829.615L348.976 830.691L347.145 829.615V827.543L348.976 826.467L350.768 827.543ZM347.184 827.583V829.615L348.936 830.651L350.688 829.615L350.728 827.583L348.936 826.547L347.184 827.583Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M463.453 784.67V801.843L448.561 810.41L433.709 801.803L433.749 784.63L448.601 776.063L463.453 784.67ZM434.107 784.869V801.604L448.561 809.971L463.055 801.604V784.909L448.601 776.542L434.107 784.869Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M521.11 790.886L521.07 815.63L499.648 827.942L478.266 815.55L478.305 790.846L499.728 778.494L521.11 790.886ZM478.863 791.165L478.823 815.231L499.648 827.304L520.473 815.271L520.513 791.205L499.688 779.171L478.863 791.165Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M391.859 817.343L391.82 833.799L377.605 842.007L363.35 833.759L363.389 817.303L377.605 809.095L391.859 817.343ZM363.788 817.542L363.748 833.52L377.605 841.569L391.461 833.56L391.501 817.542L377.644 809.533L363.788 817.542Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M485.392 783.515V796.265L474.363 802.6L463.373 796.225V783.515L474.403 777.139L485.392 783.515ZM463.692 783.674L463.652 796.066L474.363 802.242L485.074 796.066L485.114 783.714L474.403 777.498L463.692 783.674Z"
              fill="#365673"
            />
          </g>
        </g>
        <g opacity="0.7">
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.3672 103.997V99.795L14.0414 97.6502L17.6718 99.795V103.997L13.9976 106.098L10.3672 103.997ZM17.3219 103.778V100.014L14.0414 98.1317L10.8046 100.014L10.7609 103.778L14.0414 105.66L17.3219 103.778Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M0 100.408V98.1755L10.5414 92.0915V73.6644L0.0437414 67.5804V65.3482L12.5097 72.5702L12.466 93.2295L0 100.408Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M100.777 104.303V102.903L101.958 102.246L103.183 102.903V104.303L101.958 105.004L100.777 104.303ZM103.052 104.216V102.99L102.002 102.377L100.909 102.99V104.216L101.958 104.828L103.052 104.216Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M131.746 110.125L133.321 111L134.852 110.125V108.33L133.321 107.455L131.746 108.33V110.125Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M41.1152 78.9168L50.8256 84.5631L60.536 78.9606V67.7117L50.8693 62.1092L41.159 67.7117L41.1152 78.9168Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M93.2988 74.1459L94.1299 74.5836L94.961 74.1459V73.183L94.1299 72.7015L93.2988 73.183V74.1459Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M179.816 100.233V95.2429L184.19 92.7043L188.521 95.2429V100.276L184.147 102.771L179.816 100.233ZM188.04 99.97V95.5055L184.19 93.2295L180.298 95.4617V99.97L184.19 102.202L188.04 99.97Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M165.119 105.704V103.034L167.437 101.721L169.756 103.034V105.748L167.437 107.061L165.119 105.704ZM169.537 105.573V103.165L167.437 101.983L165.382 103.165V105.573L167.437 106.754L169.537 105.573Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M96.9277 89.4653V77.8226L107.032 72.0012L117.092 77.8663V89.5091L106.988 95.3304L96.9277 89.4653ZM115.999 88.8525L116.042 78.4353L107.032 73.2267L98.0212 78.4353V88.8087L107.032 94.0173L115.999 88.8525Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M37.3984 105.529V104.566L38.2295 104.084L39.0606 104.566V105.529L38.2295 106.01L37.3984 105.529ZM38.9731 105.485V104.61L38.2295 104.172L37.4859 104.61V105.441L38.2295 105.879L38.9731 105.485Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M109.438 99.4886L109.481 81.8056L124.79 73.0079L140.099 81.8494L140.056 99.5323L124.747 108.374L109.438 99.4886ZM138.394 98.5256V82.7685L124.79 74.89L111.1 82.7685V98.5256L124.747 106.404L138.394 98.5256Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.066 96.8624V94.6301L150.991 93.5359L152.872 94.6301V96.8624L150.991 97.9566L149.066 96.8624ZM152.828 96.8186V94.6739L150.991 93.6234L149.11 94.6739V96.8186L150.947 97.9128L152.828 96.8186Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M71.4707 77.3848V75.0651L73.439 73.927L75.4511 75.0651V77.3848L73.439 78.5229L71.4707 77.3848ZM75.4073 77.3411V75.1088L73.4828 74.0146L71.5144 75.1088V77.3411L73.439 78.4791L75.4073 77.3411Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M151.035 72.789V71.2571L152.391 70.4692L153.703 71.2571V72.789L152.391 73.5331L151.035 72.789ZM153.66 72.789V71.3009L152.347 70.5568L151.079 71.3009V72.789L152.347 73.5331L153.66 72.789Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M68.2344 104.259V91.6538L79.1695 85.3509L90.1046 91.6975V104.303L79.1695 110.606L68.2344 104.259ZM89.7546 104.128L89.7984 91.8289L79.1695 85.6573L68.5406 91.7851L68.4968 104.084L79.1257 110.256L89.7546 104.128Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.8496 99.7074L25.8933 81.543L41.6399 72.4827L57.3427 81.5868V99.7512L41.5961 108.812L25.8496 99.7074ZM56.9053 99.4886V81.8056L41.5961 72.9641L26.287 81.7618V99.4886L41.5524 108.33L56.9053 99.4886Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.871 76.0718V74.9775L189.833 74.4085L190.752 74.9775V76.0718L189.79 76.597L188.871 76.0718ZM190.752 76.0718V75.0213L189.833 74.4523L188.915 75.0213V76.0718L189.833 76.597L190.752 76.0718Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M160.439 83.3375L160.483 69.4188L172.556 62.4594L184.584 69.4188V83.3813L172.512 90.2969L160.439 83.3375ZM184.234 83.2062V69.6376L172.512 62.8533L160.789 69.5938L160.746 83.1625L172.468 89.9468L184.234 83.2062Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M120.854 80.2737L120.897 68.1932L131.351 62.153L141.805 68.1932V80.3174L131.307 86.3139L120.854 80.2737ZM141.499 80.1423L141.543 68.3683L131.351 62.5032L121.16 68.3683V80.1423L131.307 86.0075L141.499 80.1423Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M52.0957 105.135L52.1394 95.7681L60.2314 91.0848L68.2796 95.7681V105.135L60.1877 109.818L52.0957 105.135ZM68.0609 105.004V95.8994L60.1877 91.3474L52.3144 95.8994V105.004L60.1877 109.556L68.0609 105.004Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M94.041 44.2074L115.561 56.6818L137.082 44.2512L137.125 19.39L115.605 6.91562L94.0848 19.3462L94.041 44.2074Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M35.3418 37.5106L49.2512 45.5205L63.1169 37.5106L63.1607 21.4909L49.2512 13.4373L35.3855 21.4472L35.3418 37.5106Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.783 71.8699V65.567L107.251 62.4156L112.718 65.567L112.675 71.9136L107.207 75.0651L101.783 71.8699ZM112.15 71.5197V65.9172L107.294 63.0722L102.396 65.8734V71.5197L107.251 74.321L112.15 71.5197Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M51.4824 55.7188V24.8174L78.2515 9.41049L104.933 24.905V55.7626L78.164 71.1696L51.4824 55.7188ZM102.046 54.0994L102.09 26.5682L78.2515 12.7808L54.413 26.5244L54.3693 54.0556L78.2078 67.8431L102.046 54.0994Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M236.898 72.3513V70.2942L238.692 69.2437L240.485 70.2942V72.3513L238.692 73.358L236.898 72.3513ZM240.266 72.2638V70.4255L238.692 69.5063L237.073 70.4255V72.2638L238.648 73.183L240.266 72.2638Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M264.979 67.6242L267.297 68.9811L269.571 67.6242L269.615 64.9543L267.297 63.6412L264.979 64.9543V67.6242Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M0.0429688 75.1088L0.0867084 42.0189L13.3838 49.7224V67.4491L0.0429688 75.1088Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M5.55469 76.6408L9.40384 78.873L13.2092 76.6408V72.22L9.40384 70.0315L5.59843 72.22L5.55469 76.6408Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M147.756 34.403L162.234 42.8068L176.756 34.4468L176.799 17.683L162.278 9.27918L147.756 17.6392V34.403Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M225.744 27.2248L226.969 27.9688L228.194 27.2248V25.8241L226.969 25.1238L225.744 25.8241V27.2248Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M2.09961 38.0797L16.2715 46.2646L30.4871 38.0797V21.7098L16.3152 13.4811L2.09961 21.666V38.0797Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M231.123 50.2039L231.167 32.7835L246.213 24.0733L261.304 32.7835L261.26 50.2039L246.213 58.914L231.123 50.2039ZM259.642 49.2847V33.7464L246.213 25.9554L232.741 33.7464V49.2847L246.17 57.0319L259.642 49.2847Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M66.3535 74.2334V70.2504L69.809 68.2808L73.2208 70.2504V74.2334L69.7653 76.2031L66.3535 74.2334ZM72.8708 74.0584V70.513L69.809 68.7185L66.7472 70.513V74.0584L69.809 75.8091L72.8708 74.0584Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M142.199 74.1459V72.7453L143.424 72.0012L144.649 72.7453V74.1459L143.424 74.89L142.199 74.1459ZM144.517 74.0584V72.789L143.424 72.1325L142.33 72.789V74.0584L143.424 74.6711L144.517 74.0584Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.3">
            <path
              d="M0.0878906 46.9211V26.4369L0.831477 26.8746V46.4834L0.0878906 46.9211Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M193.07 32.0832V28.6254L196.045 26.9184L199.019 28.6692V32.0832L196.045 33.7902L193.07 32.0832ZM198.932 32.0394V28.7129L196.045 27.0497L193.158 28.7129V32.0394L196.045 33.7464L198.932 32.0394Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.258 72.3076V53.4428L204.617 44.0323L220.932 53.4866V72.3513L204.573 81.7618L188.258 72.3076ZM220.494 72.0887V53.7054L204.617 44.5138L188.739 53.7054L188.695 72.045L204.573 81.2366L220.494 72.0887Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M124.922 65.4795L124.966 38.2985L148.498 24.7299L171.987 38.3423V65.5233L148.454 79.0919L124.922 65.4795ZM171.287 65.1731L171.33 38.7362L148.454 25.474L125.578 38.6924L125.534 65.1293L148.41 78.3478L171.287 65.1731Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.904 36.4164L266.948 18.3395L282.607 9.32295L298.222 18.3833V36.4602L282.563 45.4767L266.904 36.4164ZM297.741 36.1976L297.785 18.6459L282.563 9.80442L267.342 18.6021L267.298 36.1976L282.52 44.9953L297.741 36.1976Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M164.158 73.5769V59.5706L176.274 52.6112L188.347 59.6143V73.5769L176.231 80.5363L164.158 73.5769ZM188.04 73.4456V59.8332L176.274 53.0489L164.508 59.8332V73.4018L176.231 80.2299L188.04 73.4456Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.2168 65.8734V64.1664L37.704 63.3348L39.1911 64.1664V65.8734L37.704 66.7488L36.2168 65.8734ZM39.1474 65.8296V64.1664L37.704 63.3348L36.2605 64.1664V65.8296L37.704 66.6613L39.1474 65.8296Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M89.1433 19.5651L89.0996 38.1234L73.0469 47.4026L56.9941 38.0797V19.5213L73.0906 10.2859L89.1433 19.5651ZM57.6065 19.8715V37.7295L73.0469 46.6585L88.4872 37.7733L88.5309 19.9152L73.0906 10.9424L57.6065 19.8715Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.2246 62.6345V56.3316L64.7359 53.1802L70.1597 56.3316V62.6345L64.6922 65.7859L59.2246 62.6345ZM69.5911 62.3281V56.6818L64.6922 53.8368L59.837 56.6818V62.2843L64.6922 65.1293L69.5911 62.3281Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.92383 46.4397L8.96757 15.582L35.7367 0.175081L62.4183 15.6258L62.3745 46.5272L35.6492 61.9342L8.92383 46.4397ZM59.4877 44.8202L59.5314 17.289L35.6929 3.50158L11.8544 17.2015L11.8107 44.7764L35.6492 58.5639L59.4877 44.8202Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M194.383 63.0722V61.015L196.176 59.9645L197.926 61.015V63.0722L196.176 64.0789L194.383 63.0722ZM197.751 62.9846V61.1463L196.132 60.2271L194.558 61.1463V62.9846L196.132 63.9038L197.751 62.9846Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M228.631 60.7961L230.949 62.153L233.267 60.7961V58.1262L230.949 56.8131L228.631 58.1262V60.7961Z"
              fill="#365673"
            />
          </g>
          <g opacity="0.5">
            <path
              d="M105.24 25.1238L119.718 33.5276L134.24 25.1676L134.284 8.40379L119.762 1.31693e-06L105.24 8.36002V25.1238Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              d="M183.229 17.9894L184.409 18.6897L185.678 17.9894V16.545L184.453 15.8446L183.229 16.545V17.9894Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M290.523 65.2169L290.567 61.1901L294.023 59.1767L297.478 61.1901V65.2169L294.023 67.2303L290.523 65.2169ZM297.084 64.998V61.4089L293.979 59.6143L290.873 61.4089V64.998L293.979 66.7926L297.084 64.998Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.609 40.9247V23.5043L203.7 14.7942L218.79 23.5481L218.746 40.9685L203.656 49.6349L188.609 40.9247ZM217.128 40.0493L217.172 24.511L203.744 16.72L190.272 24.4673V40.0055L203.7 47.7965L217.128 40.0493Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.8379 64.9543V61.015L27.2496 59.0016L30.7051 61.015V64.9543L27.2496 66.9677L23.8379 64.9543ZM30.3552 64.7354V61.1901L27.2934 59.4393L24.2316 61.1901V64.7354L27.2934 66.4862L30.3552 64.7354Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M99.6836 64.9105V63.4661L100.908 62.7658L102.133 63.4661V64.9105L100.908 65.6108L99.6836 64.9105ZM102.002 64.8229V63.5536L100.908 62.9408L99.8148 63.5536V64.8229L100.908 65.4795L102.002 64.8229Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.555 51.9547V48.6282L269.398 47.0087L272.285 48.6282V51.9547L269.398 53.5741L266.555 51.9547ZM272.197 51.9109V48.7157L269.442 47.0962L266.642 48.7157V51.9109L269.442 53.5304L272.197 51.9109Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M150.555 22.804V19.39L153.529 17.683L156.503 19.39V22.804L153.529 24.5548L150.555 22.804ZM156.416 22.7603V19.39L153.529 17.7267L150.642 19.39V22.7603L153.529 24.4235L156.416 22.7603Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M269.527 15.9322V13.6562L271.496 12.5181L273.508 13.6562V15.9759L271.496 17.114L269.527 15.9322ZM273.464 15.8884V13.6562L271.539 12.5181L269.615 13.6562V15.8884L271.539 17.0264L273.464 15.8884Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M145.742 63.0284V44.1636L162.101 34.7532L178.416 44.2074V63.0722L162.057 72.4826L145.742 63.0284ZM177.979 62.8095V44.47L162.101 35.2784L146.223 44.4263L146.18 62.7658L162.057 72.0012L177.979 62.8095Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M82.4062 56.2003L82.45 29.0631L105.982 15.4945L129.471 29.1069L129.427 56.2441L105.895 69.8127L82.4062 56.2003ZM128.815 55.8939L128.859 29.457L105.982 16.2386L83.1061 29.4133L83.0624 55.8502L105.939 69.0686L128.815 55.8939Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M224.389 27.181L224.432 9.1041L240.091 0.0875411L255.707 9.1041V27.181L240.048 36.2413L224.389 27.181ZM255.226 26.9621L255.269 9.36672L240.048 0.569007L224.826 9.32295L224.782 26.9184L240.004 35.7161L255.226 26.9621Z"
              fill="#365673"
            />
          </g>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M121.643 64.2977V50.3352L133.759 43.332L145.831 50.3352V64.2977L133.715 71.3009L121.643 64.2977ZM145.481 64.1226L145.525 50.5103L133.759 43.7259L121.993 50.5103V64.0789L133.715 70.9069L145.481 64.1226Z"
              fill="#365673"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4983_16150">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    {" "}
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_4983_16150)">
        <Rect width="596" height="842" fill="white" />
        <G opacity="0.7">
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M586.691 747.375V751.24L583.346 753.153L580.041 751.2V747.375L583.386 745.463L586.691 747.375ZM580.36 747.574V751.001L583.346 752.714L586.332 751.001V747.574L583.346 745.861L580.36 747.574Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M596.127 750.642V752.675L586.531 758.213V774.988L596.088 780.526V782.558L584.779 775.984V757.177L596.127 750.642Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M504.386 747.096V748.371L503.311 748.969L502.236 748.371V747.096L503.311 746.499L504.386 747.096ZM502.316 747.136V748.252L503.311 748.81L504.267 748.252V747.136L503.311 746.578L502.316 747.136Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M476.194 741.837L474.761 741L473.367 741.837V743.431L474.761 744.267L476.194 743.431V741.837Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M558.697 770.206L549.857 765.106L541.018 770.206V780.407L549.817 785.507L558.697 780.407V770.206Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M511.195 774.589L510.438 774.151L509.721 774.589L509.682 775.426L510.438 775.864L511.195 775.426V774.589Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M432.434 750.802V755.344L428.452 757.655L424.51 755.344V750.802L428.492 748.491L432.434 750.802ZM424.948 751.041V755.145L428.452 757.177L431.996 755.145V751.081L428.452 749.009L424.948 751.041Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M445.813 745.821V748.252L443.702 749.487L441.592 748.252V745.821L443.702 744.586L445.813 745.821ZM441.831 745.941L441.791 748.132L443.702 749.208L445.574 748.132V745.941L443.702 744.865L441.831 745.941Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M507.889 760.604V771.202L498.691 776.502L489.533 771.202V760.564L498.731 755.264L507.889 760.604ZM490.529 761.162L490.489 770.645L498.691 775.386L506.894 770.645V761.201L498.691 756.46L490.529 761.162Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M562.081 745.981V746.857L561.325 747.295L560.568 746.857V745.981L561.325 745.582L562.081 745.981ZM560.648 746.02V746.778L561.325 747.176L562.002 746.778V746.02L561.325 745.622L560.648 746.02Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M496.502 751.479L496.462 767.577L482.525 775.625L468.629 767.537V751.439L482.565 743.431L496.502 751.479ZM470.142 752.316V766.66L482.565 773.832L494.989 766.7V752.316L482.565 745.144L470.142 752.316Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M460.427 753.87V755.902L458.675 756.898L456.963 755.902V753.87L458.675 752.874L460.427 753.87ZM457.003 753.91V755.862L458.715 756.818L460.387 755.862V753.91L458.715 752.914L457.003 753.91Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M531.105 771.641V773.713L529.273 774.749L527.441 773.713L527.481 771.601L529.273 770.565L531.105 771.641ZM527.521 771.681V773.713L529.273 774.749L531.065 773.713V771.681L529.313 770.645L527.521 771.681Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M458.634 775.785V777.219L457.4 777.896L456.205 777.219V775.785L457.4 775.107L458.634 775.785ZM456.245 775.785V777.139L457.439 777.857L458.594 777.179V775.785L457.439 775.107L456.245 775.785Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M534.011 747.136V758.611L524.056 764.349L514.102 758.611V747.096L524.056 741.359L534.011 747.136ZM514.42 747.295L514.38 758.452L524.056 764.07L533.732 758.492L533.772 747.295L524.096 741.717L514.42 747.295Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M572.595 751.28L572.555 767.816L558.221 776.103L543.926 767.816V751.24L558.26 742.992L572.595 751.28ZM544.364 751.479L544.324 767.577L558.26 775.625L572.197 767.616V751.519L558.3 743.431L544.364 751.479Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.193 772.796V773.832L423.356 774.31L422.48 773.832V772.796L423.356 772.318L424.193 772.796ZM422.48 772.796V773.792L423.317 774.271L424.153 773.792V772.796L423.317 772.318L422.48 772.796Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M450.073 766.182L450.034 778.892L439.084 785.188L428.094 778.853V766.182L439.084 759.847L450.073 766.182ZM428.412 766.341V778.653L439.084 784.829L449.755 778.693L449.795 766.341L439.123 760.165L428.412 766.341Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M486.11 768.971V779.968L476.554 785.467L467.037 779.968V768.971L476.593 763.473L486.11 768.971ZM467.316 769.091L467.276 779.809L476.554 785.148L485.831 779.809V769.091L476.593 763.751L467.316 769.091Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M548.703 746.379V754.866L541.297 759.129L533.971 754.866V746.339L541.337 742.116L548.703 746.379ZM534.17 746.459V754.747L541.337 758.89L548.504 754.747V746.459L541.337 742.315L534.17 746.459Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.6">
            <Path
              d="M510.518 801.803L490.927 790.487L471.337 801.763L471.297 824.435L490.887 835.751L510.478 824.475L510.518 801.803Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.6">
            <Path
              d="M563.953 807.9L551.331 800.608L538.669 807.9L538.629 822.483L551.291 829.814L563.913 822.523L563.953 807.9Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M503.51 776.621L503.47 782.359L498.493 785.228L493.516 782.359L493.555 776.581L498.533 773.713L503.51 776.621ZM494.073 776.94V782.08L498.493 784.63L502.953 782.08V776.94L498.533 774.39L494.073 776.94Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M549.301 791.324L549.261 819.455L524.893 833.48L500.604 819.375L500.643 791.284L524.972 777.259L549.301 791.324ZM503.271 792.798L503.232 817.861L524.932 830.412L546.633 817.901L546.673 792.838L524.972 780.287L503.271 792.798Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M380.471 776.223V778.096L378.839 779.012L377.246 778.096V776.223L378.839 775.267L380.471 776.223ZM377.405 776.342V777.976L378.879 778.813L380.312 778.016V776.342L378.879 775.506L377.405 776.342Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M354.907 780.486L352.797 779.251L350.727 780.486V782.917L352.797 784.112L354.907 782.917V780.486Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M596.088 773.673L596.048 803.796L583.943 796.783V780.646L596.088 773.673Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M591.07 772.278L587.566 770.286L584.102 772.278V776.303L587.566 778.295L591.07 776.303V772.278Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M461.621 810.729L448.441 803.078L435.221 810.689L435.182 825.949L448.401 833.6L461.621 825.989V810.729Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M390.624 817.263L389.509 816.626L388.395 817.263V818.538L389.509 819.176L390.624 818.538V817.263Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M594.255 807.421L581.314 799.931L568.373 807.382V822.323L581.274 829.774L594.215 822.323L594.255 807.421Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M385.728 796.345V812.203L371.991 820.132L358.254 812.203L358.294 796.345L372.031 788.415L385.728 796.345ZM359.727 797.181V811.326L371.951 818.419L384.215 811.366V797.221L371.991 790.129L359.727 797.181Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M535.722 774.47V778.096L532.577 779.889L529.471 778.096V774.47L532.616 772.677L535.722 774.47ZM529.789 774.629V777.857L532.577 779.49L535.364 777.896V774.669L532.577 773.035L529.789 774.629Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M466.677 774.549V775.864L465.562 776.502L464.447 775.824V774.549L465.562 773.912L466.677 774.549ZM464.567 774.589V775.745L465.562 776.342L466.558 775.745V774.589L465.562 774.031L464.567 774.589Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.3">
            <Path
              d="M596.048 799.333V817.98L595.371 817.582V799.731L596.048 799.333Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M420.37 812.84V815.988L417.663 817.542L414.955 815.988V812.84L417.663 811.286L420.37 812.84ZM415.035 812.88V815.908L417.663 817.422L420.291 815.908V812.88L417.663 811.366L415.035 812.88Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.75 776.223V793.396L409.858 802.003L395.006 793.396V776.183L409.898 767.616L424.75 776.223ZM395.404 776.422V793.157L409.858 801.524L424.312 793.197L424.352 776.462L409.898 768.095L395.404 776.422Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M482.407 782.439L482.367 807.182L460.945 819.534L439.562 807.143V782.399L460.985 770.047L482.407 782.439ZM440.2 782.718L440.16 806.784L460.985 818.857L481.81 806.864L481.849 782.797L461.025 770.724L440.2 782.718Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M353.156 808.896L353.116 825.352L338.862 833.56L324.646 825.312V808.856L338.901 800.648L353.156 808.896ZM325.084 809.095L325.045 825.113L338.901 833.121L352.758 825.113L352.798 809.135L338.941 801.086L325.084 809.095Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M446.689 775.107V787.818L435.66 794.153L424.67 787.778V775.067L435.7 768.732L446.689 775.107ZM424.949 775.227V787.619L435.66 793.834L446.371 787.658V775.267L435.7 769.051L424.949 775.227Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M563.157 782.08V783.634L561.803 784.431L560.449 783.634V782.08L561.803 781.283L563.157 782.08ZM560.489 782.12V783.634L561.803 784.391L563.117 783.634V782.12L561.803 781.363L560.489 782.12Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M514.977 824.236L515.016 807.342L529.63 798.895L544.243 807.382V824.276L529.59 832.723L514.977 824.236ZM543.685 823.917V807.661L529.63 799.532L515.574 807.661L515.534 823.917L529.59 832.046L543.685 823.917Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M542.212 785.029V790.806L537.235 793.675L532.258 790.766V785.029L537.235 782.16L542.212 785.029ZM532.775 785.347V790.448L537.235 793.037L541.655 790.487V785.347L537.235 782.757L532.775 785.347Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M588.004 799.771L587.964 827.862L563.636 841.887L539.307 827.822L539.346 799.731L563.675 785.666L588.004 799.771ZM541.974 801.245V826.348L563.636 838.899L585.336 826.388L585.376 801.325L563.675 788.734L541.974 801.245Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M419.174 784.63V786.503L417.542 787.459L415.949 786.503V784.63L417.542 783.714L419.174 784.63ZM416.148 784.71L416.108 786.383L417.582 787.22L419.015 786.383V784.71L417.582 783.873L416.148 784.71Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M387.998 786.702L385.888 785.507L383.817 786.702L383.777 789.133L385.888 790.328L387.998 789.133V786.702Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.5">
            <Path
              d="M500.323 819.176L487.143 811.525L473.924 819.136V834.396L487.104 842.047L500.323 834.436V819.176Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M429.369 825.67L428.254 825.033L427.139 825.67V826.985L428.254 827.623L429.369 826.985V825.67Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M331.655 782.718V786.344L328.47 788.176L325.324 786.344V782.678L328.47 780.885L331.655 782.718ZM325.683 782.877V786.144L328.51 787.778L331.337 786.144V782.917L328.51 781.283L325.683 782.877Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M424.432 804.792V820.65L410.694 828.579L396.957 820.61L396.997 804.752L410.734 796.863L424.432 804.792ZM398.47 805.628L398.43 819.773L410.654 826.826L422.918 819.773V805.628L410.694 798.576L398.47 805.628Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M574.425 782.917V786.543L571.319 788.336L568.174 786.503V782.917L571.319 781.084L574.425 782.917ZM568.492 783.116V786.344L571.28 787.977L574.067 786.344V783.116L571.28 781.522L568.492 783.116Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M505.422 782.997L505.382 784.272L504.267 784.909L503.152 784.272V782.997L504.267 782.319L505.422 782.997ZM503.312 783.076V784.232L504.307 784.829L505.303 784.232V783.076L504.307 782.518L503.312 783.076Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M353.474 794.791V797.779L350.886 799.293L348.258 797.779L348.298 794.751L350.886 793.276L353.474 794.791ZM348.337 794.83V797.739L350.846 799.213L353.394 797.739V794.83L350.886 793.356L348.337 794.83Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M459.073 821.288V824.395L456.366 825.989L453.658 824.395V821.288L456.366 819.694L459.073 821.288ZM453.738 821.327V824.395L456.366 825.91L458.994 824.395L459.034 821.367L456.366 819.813L453.738 821.327Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M350.768 827.543V829.615L348.976 830.691L347.145 829.615V827.543L348.976 826.467L350.768 827.543ZM347.184 827.583V829.615L348.936 830.651L350.688 829.615L350.728 827.583L348.936 826.547L347.184 827.583Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M463.453 784.67V801.843L448.561 810.41L433.709 801.803L433.749 784.63L448.601 776.063L463.453 784.67ZM434.107 784.869V801.604L448.561 809.971L463.055 801.604V784.909L448.601 776.542L434.107 784.869Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M521.11 790.886L521.07 815.63L499.648 827.942L478.266 815.55L478.305 790.846L499.728 778.494L521.11 790.886ZM478.863 791.165L478.823 815.231L499.648 827.304L520.473 815.271L520.513 791.205L499.688 779.171L478.863 791.165Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M391.859 817.343L391.82 833.799L377.605 842.007L363.35 833.759L363.389 817.303L377.605 809.095L391.859 817.343ZM363.788 817.542L363.748 833.52L377.605 841.569L391.461 833.56L391.501 817.542L377.644 809.533L363.788 817.542Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M485.392 783.515V796.265L474.363 802.6L463.373 796.225V783.515L474.403 777.139L485.392 783.515ZM463.692 783.674L463.652 796.066L474.363 802.242L485.074 796.066L485.114 783.714L474.403 777.498L463.692 783.674Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
        </G>
        <G opacity="0.7">
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.3672 103.997V99.795L14.0414 97.6502L17.6718 99.795V103.997L13.9976 106.098L10.3672 103.997ZM17.3219 103.778V100.014L14.0414 98.1317L10.8046 100.014L10.7609 103.778L14.0414 105.66L17.3219 103.778Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M0 100.408V98.1755L10.5414 92.0915V73.6644L0.0437414 67.5804V65.3482L12.5097 72.5702L12.466 93.2295L0 100.408Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M100.777 104.303V102.903L101.958 102.246L103.183 102.903V104.303L101.958 105.004L100.777 104.303ZM103.052 104.216V102.99L102.002 102.377L100.909 102.99V104.216L101.958 104.828L103.052 104.216Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M131.746 110.125L133.321 111L134.852 110.125V108.33L133.321 107.455L131.746 108.33V110.125Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M41.1152 78.9168L50.8256 84.5631L60.536 78.9606V67.7117L50.8693 62.1092L41.159 67.7117L41.1152 78.9168Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M93.2988 74.1459L94.1299 74.5836L94.961 74.1459V73.183L94.1299 72.7015L93.2988 73.183V74.1459Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M179.816 100.233V95.2429L184.19 92.7043L188.521 95.2429V100.276L184.147 102.771L179.816 100.233ZM188.04 99.97V95.5055L184.19 93.2295L180.298 95.4617V99.97L184.19 102.202L188.04 99.97Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M165.119 105.704V103.034L167.437 101.721L169.756 103.034V105.748L167.437 107.061L165.119 105.704ZM169.537 105.573V103.165L167.437 101.983L165.382 103.165V105.573L167.437 106.754L169.537 105.573Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M96.9277 89.4653V77.8226L107.032 72.0012L117.092 77.8663V89.5091L106.988 95.3304L96.9277 89.4653ZM115.999 88.8525L116.042 78.4353L107.032 73.2267L98.0212 78.4353V88.8087L107.032 94.0173L115.999 88.8525Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M37.3984 105.529V104.566L38.2295 104.084L39.0606 104.566V105.529L38.2295 106.01L37.3984 105.529ZM38.9731 105.485V104.61L38.2295 104.172L37.4859 104.61V105.441L38.2295 105.879L38.9731 105.485Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M109.438 99.4886L109.481 81.8056L124.79 73.0079L140.099 81.8494L140.056 99.5323L124.747 108.374L109.438 99.4886ZM138.394 98.5256V82.7685L124.79 74.89L111.1 82.7685V98.5256L124.747 106.404L138.394 98.5256Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.066 96.8624V94.6301L150.991 93.5359L152.872 94.6301V96.8624L150.991 97.9566L149.066 96.8624ZM152.828 96.8186V94.6739L150.991 93.6234L149.11 94.6739V96.8186L150.947 97.9128L152.828 96.8186Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M71.4707 77.3848V75.0651L73.439 73.927L75.4511 75.0651V77.3848L73.439 78.5229L71.4707 77.3848ZM75.4073 77.3411V75.1088L73.4828 74.0146L71.5144 75.1088V77.3411L73.439 78.4791L75.4073 77.3411Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M151.035 72.789V71.2571L152.391 70.4692L153.703 71.2571V72.789L152.391 73.5331L151.035 72.789ZM153.66 72.789V71.3009L152.347 70.5568L151.079 71.3009V72.789L152.347 73.5331L153.66 72.789Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M68.2344 104.259V91.6538L79.1695 85.3509L90.1046 91.6975V104.303L79.1695 110.606L68.2344 104.259ZM89.7546 104.128L89.7984 91.8289L79.1695 85.6573L68.5406 91.7851L68.4968 104.084L79.1257 110.256L89.7546 104.128Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.8496 99.7074L25.8933 81.543L41.6399 72.4827L57.3427 81.5868V99.7512L41.5961 108.812L25.8496 99.7074ZM56.9053 99.4886V81.8056L41.5961 72.9641L26.287 81.7618V99.4886L41.5524 108.33L56.9053 99.4886Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.871 76.0718V74.9775L189.833 74.4085L190.752 74.9775V76.0718L189.79 76.597L188.871 76.0718ZM190.752 76.0718V75.0213L189.833 74.4523L188.915 75.0213V76.0718L189.833 76.597L190.752 76.0718Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M160.439 83.3375L160.483 69.4188L172.556 62.4594L184.584 69.4188V83.3813L172.512 90.2969L160.439 83.3375ZM184.234 83.2062V69.6376L172.512 62.8533L160.789 69.5938L160.746 83.1625L172.468 89.9468L184.234 83.2062Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M120.854 80.2737L120.897 68.1932L131.351 62.153L141.805 68.1932V80.3174L131.307 86.3139L120.854 80.2737ZM141.499 80.1423L141.543 68.3683L131.351 62.5032L121.16 68.3683V80.1423L131.307 86.0075L141.499 80.1423Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M52.0957 105.135L52.1394 95.7681L60.2314 91.0848L68.2796 95.7681V105.135L60.1877 109.818L52.0957 105.135ZM68.0609 105.004V95.8994L60.1877 91.3474L52.3144 95.8994V105.004L60.1877 109.556L68.0609 105.004Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.6">
            <Path
              d="M94.041 44.2074L115.561 56.6818L137.082 44.2512L137.125 19.39L115.605 6.91562L94.0848 19.3462L94.041 44.2074Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.6">
            <Path
              d="M35.3418 37.5106L49.2512 45.5205L63.1169 37.5106L63.1607 21.4909L49.2512 13.4373L35.3855 21.4472L35.3418 37.5106Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.783 71.8699V65.567L107.251 62.4156L112.718 65.567L112.675 71.9136L107.207 75.0651L101.783 71.8699ZM112.15 71.5197V65.9172L107.294 63.0722L102.396 65.8734V71.5197L107.251 74.321L112.15 71.5197Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M51.4824 55.7188V24.8174L78.2515 9.41049L104.933 24.905V55.7626L78.164 71.1696L51.4824 55.7188ZM102.046 54.0994L102.09 26.5682L78.2515 12.7808L54.413 26.5244L54.3693 54.0556L78.2078 67.8431L102.046 54.0994Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M236.898 72.3513V70.2942L238.692 69.2437L240.485 70.2942V72.3513L238.692 73.358L236.898 72.3513ZM240.266 72.2638V70.4255L238.692 69.5063L237.073 70.4255V72.2638L238.648 73.183L240.266 72.2638Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M264.979 67.6242L267.297 68.9811L269.571 67.6242L269.615 64.9543L267.297 63.6412L264.979 64.9543V67.6242Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M0.0429688 75.1088L0.0867084 42.0189L13.3838 49.7224V67.4491L0.0429688 75.1088Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M5.55469 76.6408L9.40384 78.873L13.2092 76.6408V72.22L9.40384 70.0315L5.59843 72.22L5.55469 76.6408Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M147.756 34.403L162.234 42.8068L176.756 34.4468L176.799 17.683L162.278 9.27918L147.756 17.6392V34.403Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M225.744 27.2248L226.969 27.9688L228.194 27.2248V25.8241L226.969 25.1238L225.744 25.8241V27.2248Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M2.09961 38.0797L16.2715 46.2646L30.4871 38.0797V21.7098L16.3152 13.4811L2.09961 21.666V38.0797Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M231.123 50.2039L231.167 32.7835L246.213 24.0733L261.304 32.7835L261.26 50.2039L246.213 58.914L231.123 50.2039ZM259.642 49.2847V33.7464L246.213 25.9554L232.741 33.7464V49.2847L246.17 57.0319L259.642 49.2847Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M66.3535 74.2334V70.2504L69.809 68.2808L73.2208 70.2504V74.2334L69.7653 76.2031L66.3535 74.2334ZM72.8708 74.0584V70.513L69.809 68.7185L66.7472 70.513V74.0584L69.809 75.8091L72.8708 74.0584Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M142.199 74.1459V72.7453L143.424 72.0012L144.649 72.7453V74.1459L143.424 74.89L142.199 74.1459ZM144.517 74.0584V72.789L143.424 72.1325L142.33 72.789V74.0584L143.424 74.6711L144.517 74.0584Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.3">
            <Path
              d="M0.0878906 46.9211V26.4369L0.831477 26.8746V46.4834L0.0878906 46.9211Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M193.07 32.0832V28.6254L196.045 26.9184L199.019 28.6692V32.0832L196.045 33.7902L193.07 32.0832ZM198.932 32.0394V28.7129L196.045 27.0497L193.158 28.7129V32.0394L196.045 33.7464L198.932 32.0394Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.258 72.3076V53.4428L204.617 44.0323L220.932 53.4866V72.3513L204.573 81.7618L188.258 72.3076ZM220.494 72.0887V53.7054L204.617 44.5138L188.739 53.7054L188.695 72.045L204.573 81.2366L220.494 72.0887Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M124.922 65.4795L124.966 38.2985L148.498 24.7299L171.987 38.3423V65.5233L148.454 79.0919L124.922 65.4795ZM171.287 65.1731L171.33 38.7362L148.454 25.474L125.578 38.6924L125.534 65.1293L148.41 78.3478L171.287 65.1731Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.904 36.4164L266.948 18.3395L282.607 9.32295L298.222 18.3833V36.4602L282.563 45.4767L266.904 36.4164ZM297.741 36.1976L297.785 18.6459L282.563 9.80442L267.342 18.6021L267.298 36.1976L282.52 44.9953L297.741 36.1976Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M164.158 73.5769V59.5706L176.274 52.6112L188.347 59.6143V73.5769L176.231 80.5363L164.158 73.5769ZM188.04 73.4456V59.8332L176.274 53.0489L164.508 59.8332V73.4018L176.231 80.2299L188.04 73.4456Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.2168 65.8734V64.1664L37.704 63.3348L39.1911 64.1664V65.8734L37.704 66.7488L36.2168 65.8734ZM39.1474 65.8296V64.1664L37.704 63.3348L36.2605 64.1664V65.8296L37.704 66.6613L39.1474 65.8296Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M89.1433 19.5651L89.0996 38.1234L73.0469 47.4026L56.9941 38.0797V19.5213L73.0906 10.2859L89.1433 19.5651ZM57.6065 19.8715V37.7295L73.0469 46.6585L88.4872 37.7733L88.5309 19.9152L73.0906 10.9424L57.6065 19.8715Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.2246 62.6345V56.3316L64.7359 53.1802L70.1597 56.3316V62.6345L64.6922 65.7859L59.2246 62.6345ZM69.5911 62.3281V56.6818L64.6922 53.8368L59.837 56.6818V62.2843L64.6922 65.1293L69.5911 62.3281Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.92383 46.4397L8.96757 15.582L35.7367 0.175081L62.4183 15.6258L62.3745 46.5272L35.6492 61.9342L8.92383 46.4397ZM59.4877 44.8202L59.5314 17.289L35.6929 3.50158L11.8544 17.2015L11.8107 44.7764L35.6492 58.5639L59.4877 44.8202Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M194.383 63.0722V61.015L196.176 59.9645L197.926 61.015V63.0722L196.176 64.0789L194.383 63.0722ZM197.751 62.9846V61.1463L196.132 60.2271L194.558 61.1463V62.9846L196.132 63.9038L197.751 62.9846Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M228.631 60.7961L230.949 62.153L233.267 60.7961V58.1262L230.949 56.8131L228.631 58.1262V60.7961Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G opacity="0.5">
            <Path
              d="M105.24 25.1238L119.718 33.5276L134.24 25.1676L134.284 8.40379L119.762 1.31693e-06L105.24 8.36002V25.1238Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              d="M183.229 17.9894L184.409 18.6897L185.678 17.9894V16.545L184.453 15.8446L183.229 16.545V17.9894Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M290.523 65.2169L290.567 61.1901L294.023 59.1767L297.478 61.1901V65.2169L294.023 67.2303L290.523 65.2169ZM297.084 64.998V61.4089L293.979 59.6143L290.873 61.4089V64.998L293.979 66.7926L297.084 64.998Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.609 40.9247V23.5043L203.7 14.7942L218.79 23.5481L218.746 40.9685L203.656 49.6349L188.609 40.9247ZM217.128 40.0493L217.172 24.511L203.744 16.72L190.272 24.4673V40.0055L203.7 47.7965L217.128 40.0493Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.8379 64.9543V61.015L27.2496 59.0016L30.7051 61.015V64.9543L27.2496 66.9677L23.8379 64.9543ZM30.3552 64.7354V61.1901L27.2934 59.4393L24.2316 61.1901V64.7354L27.2934 66.4862L30.3552 64.7354Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M99.6836 64.9105V63.4661L100.908 62.7658L102.133 63.4661V64.9105L100.908 65.6108L99.6836 64.9105ZM102.002 64.8229V63.5536L100.908 62.9408L99.8148 63.5536V64.8229L100.908 65.4795L102.002 64.8229Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.555 51.9547V48.6282L269.398 47.0087L272.285 48.6282V51.9547L269.398 53.5741L266.555 51.9547ZM272.197 51.9109V48.7157L269.442 47.0962L266.642 48.7157V51.9109L269.442 53.5304L272.197 51.9109Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M150.555 22.804V19.39L153.529 17.683L156.503 19.39V22.804L153.529 24.5548L150.555 22.804ZM156.416 22.7603V19.39L153.529 17.7267L150.642 19.39V22.7603L153.529 24.4235L156.416 22.7603Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M269.527 15.9322V13.6562L271.496 12.5181L273.508 13.6562V15.9759L271.496 17.114L269.527 15.9322ZM273.464 15.8884V13.6562L271.539 12.5181L269.615 13.6562V15.8884L271.539 17.0264L273.464 15.8884Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M145.742 63.0284V44.1636L162.101 34.7532L178.416 44.2074V63.0722L162.057 72.4826L145.742 63.0284ZM177.979 62.8095V44.47L162.101 35.2784L146.223 44.4263L146.18 62.7658L162.057 72.0012L177.979 62.8095Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M82.4062 56.2003L82.45 29.0631L105.982 15.4945L129.471 29.1069L129.427 56.2441L105.895 69.8127L82.4062 56.2003ZM128.815 55.8939L128.859 29.457L105.982 16.2386L83.1061 29.4133L83.0624 55.8502L105.939 69.0686L128.815 55.8939Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M224.389 27.181L224.432 9.1041L240.091 0.0875411L255.707 9.1041V27.181L240.048 36.2413L224.389 27.181ZM255.226 26.9621L255.269 9.36672L240.048 0.569007L224.826 9.32295L224.782 26.9184L240.004 35.7161L255.226 26.9621Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
          <G>
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M121.643 64.2977V50.3352L133.759 43.332L145.831 50.3352V64.2977L133.715 71.3009L121.643 64.2977ZM145.481 64.1226L145.525 50.5103L133.759 43.7259L121.993 50.5103V64.0789L133.715 70.9069L145.481 64.1226Z"
              fill={props?.c1 || "#365673"}
            />
          </G>
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_4983_16150">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture24 = {
  ViewTexture,
  PdfTexture,
};

export default Texture24;
