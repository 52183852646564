import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/system";
import { ReactComponent as TabElement1Image } from "../assets/SVG/tabElement1.svg";
import { ReactComponent as TabElement2Image } from "../assets/SVG/section4Image.svg";

const tabs = [
  {
    heading: "Overflowing with useful features",
    subHeading:
      "Empowering self-service resume creation to land your dream job with ease. Trusted by thousands of professionals for its intuitive design and effective results.",
    buttonText: "Learn more",
    link: "/",
  },
  {
    heading: "Instant resume summary generator",
    subHeading:
      "Create a powerful resume profile witha single click. Say goodbye to writer's block. Get started for free!.",
    buttonText: "Learn more",
    link: "/",
  },
  {
    heading: "Versatile resume formats",
    subHeading:
      "Save your ideal resume effortlessy in popular formats like Microsoft Word and PDF in just one click. ",
    buttonText: "Learn more",
    link: "/",
  },
];

const tabElements = [
  {
    image: <TabElement1Image />,
  },
  {
    image: <TabElement2Image />,
  },
  {
    image: <TabElement1Image />,
  },
];

// Custom styled Tab component
const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none", // Disable uppercase text
  // color: 'blue', // Custom color for text
  padding: "12px 24px", // Custom padding
  minWidth: "36rem",
  width: "36rem",
  textAlign: "left",

  // '&.Mui-selected': {
  //   color: 'red', // Custom color for selected text
  // },
  // '&:hover': {
  //   backgroundColor: 'rgba(0, 0, 0, 0.1)', // Background color on hover
  // },
  ".MuiTab-wrapper": {
    // Styling the wrapper element
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//   function CustomTab(props:any) {
//     const { label, ...other } = props;
//     return (
//       <Tab
//         label={
//           <Box sx={{minWidth:"50rem",width:"50rem"}}>
//             {label}
//           </Box>
//         }
//         {...other}
//         sx={{
//             textTransform: 'none', // Disable uppercase text
//             color: 'blue', // Custom color for text
//             '&.Mui-selected': {
//               color: 'red', // Custom color for selected text
//             },
//             '&.MuiButtonBase-root-MuiTab-root': {
//                 minWidth: '50rem', // Example custom style
//               }
//           }}
//       />
//     );
//   }

const Section3 = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className="section-wrapper">
      <Box className="heading-wrapper">
        <Typography className="prefix" gutterBottom>
          Features
        </Typography>
        <Typography
          component="h3"
          variant="h4"
          className="heading"
          gutterBottom
        >
          Overflowing with useful features
        </Typography>
        <Typography className="sub-heading" gutterBottom>
          Empowering self-service resume creation to land your dream job with
          ease. Trusted by thousands of professionals for its intuitive design
          and effective results.
        </Typography>
      </Box>
      <Box
        sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}
        className="tabs-wrapper"
      >
        <Tabs
          orientation="vertical"
          // variant="scrollable"
          aria-label="Vertical tabs"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              left: 0,
              width: "4px", // Adjust the width of the indicator as needed
              bgcolor: "#012fff", // Adjust the color of the indicator
            },
            style: {
              right: "unset", // Remove the default right positioning
            },
          }}
          sx={{
            "& .MuiTabs-indicator": {
              left: 0, // Position the indicator on the left
            },
          }}
        >
          {tabs.map((tab) => (
            <CustomTab
              label={
                <Box className="tab-container">
                  <Typography
                    component="h6"
                    variant="h5"
                    className="heading"
                    gutterBottom
                  >
                    {tab.heading}
                  </Typography>
                  <Box className="sub-heading-cont">
                    <Typography className="sub-heading" gutterBottom>
                      {tab.subHeading}
                    </Typography>
                  </Box>
                  <Button className="button">{tab.buttonText}</Button>
                </Box>
              }
              wrapped
            />
          ))}
        </Tabs>
        {tabElements.map((ele, i) => (
          <TabPanel value={value} index={i}>
            {ele.image}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default Section3;
