import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import PdfFontAwesome from "../../components/PdfFontAwesome/PdfFontAwesome";
import {
  WorkExperienceTypes,
  capitalizeFirstLetter,
} from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const WorkExperienceTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { primaryColor, fontFamily, relativeFontSize },
  SectionHeader,
  SectionSubHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionSubHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      // Set the page to horizontal layout
      padding: 20,
      fontFamily,
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },

    atsIssue: {
      position: "relative",
    },
    atsIssueHigh: {
      padding: 5,
      border: "1.5px solid #EF4444",
      backgroundColor: "rgba(255, 182, 182, 0.20)",
    },
    atsIssueMedium: {
      padding: 5,
      border: "1.5px solid #FF8A1C",
      backgroundColor: "rgba(255, 224, 156, 0.20)",
    },
    atsIssueLow: {
      padding: 5,
      border: "1.5px solid #E9CB33",
      backgroundColor: "rgba(233, 203, 51, 0.20)",
    },
    atsIssueFixed: {
      padding: 5,
      border: "1px solid #19A44C",
    },
    fixATSButton: {
      width: 20,
      height: 20,
      position: "absolute",
      right: -10,
      top: -10,
    },
    fixATSButtonImg: {
      width: 20,
      height: 20,
    },
  });

  console.log(
    "extractedData?.metaInformation?.workExperienceIcon",
    extractedData?.metaInformation?.workExperienceIcon
  );

  // <View style={styles.headingContainer}>
  //   {extractedData?.metaInformation?.workExperienceIcon && (
  //     <PdfFontAwesome
  //       faIcon={
  //         (allIcons as any)[
  //           extractedData?.metaInformation?.workExperienceIcon || "faBriefcase"
  //         ]
  //       }
  //       style={{ color: "#ff0000" || "#000", width: "20px" }}
  //     />
  //   )}

  //   {SectionHeader ? (
  //     <SectionHeader>
  //       {extractedData?.metaInformation?.workExperienceLabel ||
  //         "WORK EXPERIENCE"}
  //     </SectionHeader>
  //   ) : (
  //     <Text style={styles.sectionHeader}>
  //       {extractedData?.metaInformation?.workExperienceLabel ||
  //         "WORK EXPERIENCE"}
  //     </Text>
  //   )}
  // </View>;

  return (
    <>
      {extractedData.workExperiences &&
        extractedData.workExperiences?.length > 0 && (
          <>
            {SectionHeader ? (
              <SectionHeader
                icon={
                  (allIcons as any)[
                    extractedData?.metaInformation?.workExperienceIcon
                  ]
                }
              >
                {extractedData?.metaInformation?.workExperienceLabel ||
                  "WORK EXPERIENCE"}
              </SectionHeader>
            ) : (
              <Text style={styles.sectionHeader}>
                {extractedData?.metaInformation?.workExperienceLabel ||
                  "WORK EXPERIENCE"}
              </Text>
            )}
          </>
        )}
      <BodyContainer>
        {extractedData.workExperiences &&
          extractedData.workExperiences.map(
            (exp: WorkExperienceTypes, index: number) => (
              <View
                key={index}
                style={
                  isATSPreview
                    ? index === 0
                      ? { ...styles.atsIssueHigh, marginBottom: 10 }
                      : index === 1
                      ? { ...styles.atsIssueFixed, marginBottom: 10 }
                      : {}
                    : {}
                }
              >
                {isATSPreview && (
                  <View style={styles.fixATSButton}>
                    {index === 0 && (
                      <>
                        <Image
                          src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`}
                          style={styles.fixATSButtonImg}
                        />
                        <View
                          style={{
                            ...styles.fixATSButtonImg,
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "0.1px",
                              color: "transparent",
                            }}
                          >
                            ATS_ISSUE-WORK_EXPERIENCE-{exp.index}
                          </Text>
                        </View>
                      </>
                    )}
                    {index === 1 && (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}/img/ats-fixed-icon.png`}
                        style={styles.fixATSButtonImg}
                      />
                    )}
                  </View>
                )}
                {SectionSubHeader ? (
                  <SectionSubHeader>
                    {exp.company}, {exp.role} -{" "}
                    {exp.jobType
                      ? capitalizeFirstLetter(exp.jobType.replace("_", " "))
                      : ""}
                  </SectionSubHeader>
                ) : (
                  <Text
                    style={{
                      ...styles.sectionHeader,
                      ...styles.sectionSubHeader,
                    }}
                  >
                    {exp.company}, {exp.role} -{" "}
                    {exp.jobType
                      ? capitalizeFirstLetter(exp.jobType.replace("_", " "))
                      : ""}
                  </Text>
                )}
                <Text style={styles.sectionContent}>
                  {exp.startDate as any} - {exp.endDate as any}
                </Text>
                <Text style={{ ...styles.sectionContent, ...styles.mb0 }}>
                  <Text>
                    {exp.keyResponsibilities?.replace(/<\/?[^>]+(>|$)/g, "")}
                  </Text>
                </Text>
              </View>
            )
          )}
      </BodyContainer>
    </>
  );
};

export default WorkExperienceTemplate;
