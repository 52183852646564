import {
  Box,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { IReference } from "../../../../components/utils";
import { setExtractedData } from "../../../../redux/actions";
import CustomSectionEdit from "./CustomSectionEdit";
import CustomSectionCard from "./CustomSectionCard";
import DoneIcon from "@mui/icons-material/Done";
import { ReactComponent as CustomEditLinkIcon } from "../../../../assets/SVG/link-edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CustomSection } from "../../../../assets/SVG/custom-icon.svg";

interface CoursesListProps {
  handleSectionDelete: (section: string) => void;
  sectionName: string;
  sectionLabel: string;
  sectionNameEditClick: (sectionName: string, sectionValue: string) => void;
  isEditable: boolean;
  sectionNameDoneClick: (sectionName: string, sectionValue: string) => void;
  handleDeleteEmptySection: (section: string) => void;
}

const DEFAULT_FORM_DATA: IReference = {
  organization: "",
  courseDescription: "",
  isEdit: true,
  isNewEntry: true,
};

const CustomSectionList: React.FC<CoursesListProps> = ({
  handleSectionDelete,
  sectionName,
  sectionLabel,
  sectionNameEditClick,
  isEditable,
  sectionNameDoneClick,
  handleDeleteEmptySection,
}) => {
  const extractedData = useSelector((state: StateProps) => state.extractedData);
  const dispatch = useDispatch();
  const [coursesList, setCoursesList] = useState<IReference[]>(
    extractedData?.customSections?.[sectionName]?.value ?? [],
  );
  const [draggingItemIndex, setDraggingItemIndex] = useState<number | null>(
    null,
  );
  const [customSectionName, setCustomSectionname] = useState<string>(
    sectionLabel ?? "",
  );
  const initialName = useRef<string>(sectionLabel ?? "");

  //Adding key to extrated course elements
  useEffect(() => {
    if (!extractedData?.customSections?.[sectionName]?.value?.[0]) {
      setCoursesList([{ ...DEFAULT_FORM_DATA, index: 0 }]);
    } else {
      const updatedList = extractedData?.customSections?.[
        sectionName
      ]?.value?.map((course: IReference, i: number) => {
        return { ...course, isEdit: false, index: i };
      });
      setCoursesList(updatedList ?? []);
    }
  }, [extractedData?.customSections?.[sectionName]?.value, sectionName]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    const updateCourseList = coursesList?.map((course) => ({
      ...course,
      isEdit: false,
    }));
    setCoursesList([
      ...updateCourseList,
      { ...DEFAULT_FORM_DATA, index: coursesList?.length ?? 1 },
    ]);
  };

  const handleDragStart = (index: number) => {
    setDraggingItemIndex(index);
  };

  const handleDragOver = (e: any, index: number) => {
    e.preventDefault();
  };

  const handleDrop = (index: number) => {
    const draggedOverItem =
      extractedData?.customSections?.[sectionName]?.value?.[index];

    // If the item is dragged over itself, don't change the list
    if (draggingItemIndex === index) {
      return;
    }

    if (
      draggedOverItem &&
      (draggingItemIndex || draggingItemIndex === 0) &&
      extractedData?.customSections?.[sectionName]?.value?.[draggingItemIndex]
    ) {
      const updatedItems = [
        ...(extractedData?.customSections?.[sectionName]?.value ?? []),
      ];

      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[draggingItemIndex];
      updatedItems[draggingItemIndex] = temp;

      dispatch(
        setExtractedData({
          ...extractedData,
          customSections: {
            ...extractedData.customSections,
            [sectionName]: { label: sectionLabel, value: updatedItems },
          },
        }),
      );
      setDraggingItemIndex(index);
    }
  };

  const handleDragEnd = () => {
    setDraggingItemIndex(null);
  };

  const handleCloseEditor = () => {
    sectionNameDoneClick(sectionName, initialName.current);
  };

  const handleEditDone = () => {
    initialName.current = customSectionName;
    sectionNameDoneClick(sectionName, customSectionName);
  };

  console.log(
    { sectionName, sectionLabel, isEditable, extractedData, coursesList },
    "from  Section List Component",
  );

  return (
    <Box className="courses-section">
      <Box className="heading-box">
        {isEditable ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <TextField
              onChange={(e) => setCustomSectionname(e.target.value)}
              value={customSectionName}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.2rem 0.2rem 0.2rem 0.5rem  !important",
                  borderRadius: "1px",
                  "& fieldset": {
                    paddingLeft: "0.5rem !important",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "22px !important",
                    "@media (max-height: 910px)": {
                      fontSize: "20px !important",
                    },
                    fontWeight: "600 !important",
                  },
                },
              }}
            />
            <CloseIcon
              sx={{ cursor: "pointer", color: "#EF4444" }}
              onClick={handleCloseEditor}
            />
            <DoneIcon
              sx={{ cursor: "pointer", color: "#19A44C" }}
              onClick={handleEditDone}
            />
          </Box>
        ) : (
          <Box className="icon-heading-cont">
            <Box className="icon-container">
              <CustomSection />
            </Box>
            <Typography
              variant="h5"
              color="color.primary"
              className="heading"
              sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              {sectionLabel}{" "}
              <CustomEditLinkIcon
                cursor={"pointer"}
                onClick={() => sectionNameEditClick(sectionName, sectionLabel)}
              />
            </Typography>
          </Box>
        )}

        <Typography
          className="sub-heading"
          onClick={() => {
            handleSectionDelete(sectionName);
          }}
        >
          Remove section
        </Typography>
      </Box>

      {coursesList?.map((course, index) => (
        <ListItem
          className="card-wrapper"
          draggable={course?.isEdit ? false : true}
          onDragStart={() => handleDragStart(index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDrop={() => handleDrop(index)}
          onDragEnd={handleDragEnd}
          sx={
            draggingItemIndex === index
              ? {
                  transition: "transform 0.3s ease",
                  opacity: "0.7",
                  transform: "scale(1.05)",
                  cursor: course?.isEdit ? "none" : "grab",
                }
              : {}
          }
        >
          {course?.isEdit ? (
            <CustomSectionEdit
              sectionName={sectionName}
              courseData={course}
              coursesList={coursesList}
              setCoursesList={setCoursesList}
              sectionLabel={sectionLabel}
              index={index}
              handleDeleteEmptySection={handleDeleteEmptySection}
            />
          ) : (
            <CustomSectionCard
              sectionName={sectionName}
              courseData={course}
              coursesList={coursesList}
              setCoursesList={setCoursesList}
              sectionLabel={sectionLabel}
            />
          )}
        </ListItem>
      ))}

      {!coursesList?.[coursesList?.length - 1]?.isNewEntry && (
        <Box className="add-course-button-cont">
          <Typography className="summary-ui-brand" onClick={handleAddCourse}>
            +Add More
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomSectionList;
