import { Box, Button, Rating, Typography } from "@mui/material";
import NewUpdateHeader from "./NewUpdateHeader";
import LandingPageHeader from "./LandingPageHeader";
import { ReactComponent as UserReviewIcon } from "../assets/SVG/users-avatar.svg";
import { ReactComponent as Section2Icon1 } from "../assets/SVG/section-2-icon-1.svg";
import { ReactComponent as Section2Icon2 } from "../assets/SVG/section-2-icon-2.svg";
import { ReactComponent as Section2Icon3 } from "../assets/SVG/section2-icon-3.svg";
import EastIcon from "@mui/icons-material/East";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import LandingFooter from "./LandingFooter";
import { ReactComponent as TabElement1Image } from "../assets/SVG/tabElement1.svg";

const section2CardDetails = [
  {
    headerIcon: <Section2Icon1 />,
    heading: "AI-Powered",
    subheading:
      "Leverage the lattest in artificial intelligence personalize resume highlight your skills and experience effectively.",
    buttonText: "Learn More",
    buttonIcon: <EastIcon fontSize="small" sx={{ marginLeft: "0.3rem" }} />,
    link: "/",
  },
  {
    headerIcon: <Section2Icon2 />,
    heading: "Easy-to-Use",
    subheading:
      "Creating resume is simple and straightforward. Just fill in your details, select your template, and watch as your resume comes to life in real-time.",
    buttonText: "Learn More",
    buttonIcon: <EastIcon fontSize="small" sx={{ marginLeft: "0.3rem" }} />,
    link: "/",
  },
  {
    headerIcon: <Section2Icon3 />,
    heading: "100's of Templates",
    subheading:
      "A wide range of professionally designed templates for finance, healthcare, tech, or creative fields, find a template that fits your style.",
    buttonText: "Learn More",
    buttonIcon: <EastIcon fontSize="small" sx={{ marginLeft: "0.3rem" }} />,
    link: "/",
  },
];

const LandingPage = () => {
  return (
    <Box className="landing-page-container">
      <NewUpdateHeader />
      <LandingPageHeader />

      <Box className="section-1-container">
        <Box className="left-section">
          <Box className="left-section-container">
            <Typography component="h1" variant="h3" className="heading">
              100% Free Resume Builder
            </Typography>
            <Typography className="sub-heading">
              A Free Resume Maker Tool, designed to revolutionize your job
              application process. Powered by advanced AI technology and
              featurung a diverse array of customizable templates.
            </Typography>
            <Box className="user-icon-review-cont">
              <UserReviewIcon />
              <Box className="review-container">
                <Box className="star">
                  <Rating name="read-only" value={5} readOnly />
                  <Typography>5.0</Typography>{" "}
                </Box>
                <Typography className="views">from 200+ views</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="right-section">
          <Box className="right-section-container">
            {/* <CreateAccount/> */}
            <TabElement1Image />
          </Box>
        </Box>
      </Box>

      <Box className="section-2-container">
        <Box className="card-container">
          {section2CardDetails?.map((card) => (
            <Box className="card-item">
              <Box className="icon-box">{card.headerIcon}</Box>
              <Box className="card-wrapper">
                <Typography
                  component="h6"
                  variant="h5"
                  className="heading"
                  gutterBottom
                >
                  {card.heading}
                </Typography>
                <Box className="sub-heading-cont">
                  <Typography className="sub-heading">
                    {card.subheading}
                  </Typography>
                </Box>
                <Button className="button">
                  {card.buttonText}
                  {card.buttonIcon}
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="section-3-container">
        <Section3 />
      </Box>

      <Box className="section-4-container">
        <Section4 />
      </Box>

      <Box className="section-5-container">
        <Section5 />
      </Box>

      <Box className="section-6-container">
        <Section6 />
      </Box>

      <Box className="section-7-container">
        <Section7 />
      </Box>

      <Box className="footer-container">
        <LandingFooter />
      </Box>
    </Box>
  );
};

export default LandingPage;
