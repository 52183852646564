import {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  Svg,
  LinearGradient,
  Stop,
} from "@react-pdf/renderer";

const ViewTexture = () => (
  <>
    <svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <g clip-path="url(#clip0_5107_111987)">
        <rect width="596" height="842" fill="white" />
        <path
          d="M595.781 76.5079L479.867 0.274419H580.326L595.781 10.4279V76.5079Z"
          fill="url(#paint0_linear_5107_111987)"
        />
        <path
          d="M595.78 94.1806H583.504L494.828 35.8941V2.0308L595.78 68.3852V94.1806Z"
          fill="url(#paint1_linear_5107_111987)"
        />
        <path
          d="M595.781 235.78L474.441 156.034V80.0204L595.781 159.766V235.78Z"
          fill="url(#paint2_linear_5107_111987)"
        />
        <path
          d="M397.822 86.387L387.902 79.8009V76.014L403.632 86.387H397.822Z"
          fill="url(#paint3_linear_5107_111987)"
        />
        <path
          d="M595.781 183.092H580.271L399.193 64.0494V-5.15902L595.781 124.092V183.092Z"
          fill="url(#paint4_linear_5107_111987)"
        />
        <path
          d="M595.781 94.6196L538.783 57.1889H595.781V94.6196Z"
          fill="url(#paint5_linear_5107_111987)"
        />
        <path
          d="M595.78 178.592L445.119 79.5814H509.625L595.78 136.221V178.592Z"
          fill="url(#paint6_linear_5107_111987)"
        />
        <path
          d="M595.78 169.426L408.125 46.1023H484.743L595.78 119.043V169.426Z"
          fill="url(#paint7_linear_5107_111987)"
        />
        <path
          d="M344.88 0.274419L373.927 19.374V30.5153L328 0.274419H344.88Z"
          fill="url(#paint8_linear_5107_111987)"
        />
        <path
          d="M595.781 129.58L552.266 100.986H594.466L595.781 101.809V129.58Z"
          fill="url(#paint9_linear_5107_111987)"
        />
        <path
          d="M595.782 152.083L479.703 75.7944V-32.3814L595.782 43.907V152.083Z"
          fill="url(#paint10_linear_5107_111987)"
        />
        <path
          d="M0 777.081L99.6667 841.741H13.302L0 833.133V777.081Z"
          fill="url(#paint11_linear_5107_111987)"
        />
        <path
          d="M0 762.064H10.5499L86.8234 811.514V840.252L0 784.006V762.064Z"
          fill="url(#paint12_linear_5107_111987)"
        />
        <path
          d="M31.8463 681.417L37.0229 684.783V686.725L28.832 681.417H31.8463Z"
          fill="url(#paint13_linear_5107_111987)"
        />
        <path
          d="M0 642L104.319 709.637V774.103L0 706.466V642Z"
          fill="url(#paint14_linear_5107_111987)"
        />
        <path
          d="M170.24 768.731L178.758 774.297V777.534L165.26 768.731H170.24Z"
          fill="url(#paint15_linear_5107_111987)"
        />
        <path
          d="M0 686.725H13.3675L169.06 787.631V846.336L0 736.757V686.725Z"
          fill="url(#paint16_linear_5107_111987)"
        />
        <path
          d="M0 761.741L49.0143 793.521H0V761.741Z"
          fill="url(#paint17_linear_5107_111987)"
        />
        <path
          d="M0 690.479L129.547 774.492H74.1111L0 726.466V690.479Z"
          fill="url(#paint18_linear_5107_111987)"
        />
        <path
          d="M0 698.246L161.393 802.906H95.4729L0 740.964V698.246Z"
          fill="url(#paint19_linear_5107_111987)"
        />
        <path
          d="M215.781 841.741L190.75 825.56V816.11L230.328 841.741H215.781Z"
          fill="url(#paint20_linear_5107_111987)"
        />
        <path
          d="M0 732.097L37.416 756.304H1.11396L0 755.592V732.097Z"
          fill="url(#paint21_linear_5107_111987)"
        />
        <path
          d="M0 713.003L99.7977 777.728V869.443L0 804.783V713.003Z"
          fill="url(#paint22_linear_5107_111987)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5107_111987"
          x1="233961"
          y1="157829"
          x2="317573"
          y2="212627"
        >
          <stop stop-color="#FFDC8A" />
          <stop offset="1" stop-color="#FFBC1F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5107_111987"
          x1="-125088"
          y1="-83993.6"
          x2="-162466"
          y2="-108490"
        >
          <stop offset="0.25" stop-color="#EDEDED" />
          <stop offset="1" stop-color="#8E939C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5107_111987"
          x1="-248964"
          y1="-200444"
          x2="-349705"
          y2="-266468"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5107_111987"
          x1="-8940.04"
          y1="-6929.37"
          x2="-9593.55"
          y2="-7357.66"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5107_111987"
          x1="-184492"
          y1="-122905"
          x2="-333095"
          y2="-220298"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5107_111987"
          x1="-299874"
          y1="-233837"
          x2="-346830"
          y2="-264611"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5107_111987"
          x1="-124923"
          y1="-93715.6"
          x2="-192466"
          y2="-137982"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5107_111987"
          x1="-131374"
          y1="-94728.1"
          x2="-231368"
          y2="-160262"
        >
          <stop offset="0.25" stop-color="#EDEDED" />
          <stop offset="1" stop-color="#8D00B7" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5107_111987"
          x1="-19997.8"
          y1="-13686.2"
          x2="-25394.2"
          y2="-17223.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5107_111987"
          x1="-106840"
          y1="-91270.8"
          x2="-119610"
          y2="-99640.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDC8A" />
          <stop offset="1" stop-color="#FFBC1F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5107_111987"
          x1="-392243"
          y1="-241339"
          x2="-529387"
          y2="-331220"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5107_111987"
          x1="44933.3"
          y1="-59012.4"
          x2="-6347.83"
          y2="-93086.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDC8A" />
          <stop offset="1" stop-color="#FFBC1F" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5107_111987"
          x1="-12523.1"
          y1="15614.5"
          x2="10407.3"
          y2="30850.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#EDEDED" />
          <stop offset="1" stop-color="#8E939C" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5107_111987"
          x1="6.39037"
          y1="1565.94"
          x2="152.694"
          y2="1663.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#EDEDED" />
          <stop offset="1" stop-color="#8E939C" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5107_111987"
          x1="-26742.2"
          y1="31766.2"
          x2="35037.4"
          y2="72815.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_5107_111987"
          x1="3909.25"
          y1="5317.11"
          x2="4309.31"
          y2="5582.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_5107_111987"
          x1="-20350"
          y1="23143.1"
          x2="70807.1"
          y2="83712.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_5107_111987"
          x1="-55905.3"
          y1="75691"
          x2="-27102.8"
          y2="94828.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_5107_111987"
          x1="-11310.4"
          y1="12878.8"
          x2="30116.5"
          y2="40405.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_5107_111987"
          x1="-13319.9"
          y1="15011.8"
          x2="48024.4"
          y2="55772.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#EDEDED" />
          <stop offset="1" stop-color="#8D00B7" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_5107_111987"
          x1="12479.8"
          y1="15748"
          x2="15797.1"
          y2="17952.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_5107_111987"
          x1="-17507.7"
          y1="23835.1"
          x2="-9675.19"
          y2="29039.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDC8A" />
          <stop offset="1" stop-color="#FFBC1F" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_5107_111987"
          x1="-67245.9"
          y1="88838"
          x2="16859"
          y2="144722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9E15" />
          <stop offset="0.7" stop-color="#EF184B" />
        </linearGradient>
        <clipPath id="clip0_5107_111987">
          <rect width="596" height="842" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

const PdfTexture = (props: any) => (
  <>
    <Svg width="596" height="842" viewBox="0 0 596 842" fill="none">
      <G clip-path="url(#clip0_5107_111987)">
        <Rect width="596" height="842" fill="white" />
        <Path
          d="M595.781 76.5079L479.867 0.274419H580.326L595.781 10.4279V76.5079Z"
          fill="url(#paint0_linear_5107_111987)"
        />
        <Path
          d="M595.78 94.1806H583.504L494.828 35.8941V2.0308L595.78 68.3852V94.1806Z"
          fill="url(#paint1_linear_5107_111987)"
        />
        <Path
          d="M595.781 235.78L474.441 156.034V80.0204L595.781 159.766V235.78Z"
          fill="url(#paint2_linear_5107_111987)"
        />
        <Path
          d="M397.822 86.387L387.902 79.8009V76.014L403.632 86.387H397.822Z"
          fill="url(#paint3_linear_5107_111987)"
        />
        <Path
          d="M595.781 183.092H580.271L399.193 64.0494V-5.15902L595.781 124.092V183.092Z"
          fill="url(#paint4_linear_5107_111987)"
        />
        <Path
          d="M595.781 94.6196L538.783 57.1889H595.781V94.6196Z"
          fill="url(#paint5_linear_5107_111987)"
        />
        <Path
          d="M595.78 178.592L445.119 79.5814H509.625L595.78 136.221V178.592Z"
          fill="url(#paint6_linear_5107_111987)"
        />
        <Path
          d="M595.78 169.426L408.125 46.1023H484.743L595.78 119.043V169.426Z"
          fill="url(#paint7_linear_5107_111987)"
        />
        <Path
          d="M344.88 0.274419L373.927 19.374V30.5153L328 0.274419H344.88Z"
          fill="url(#paint8_linear_5107_111987)"
        />
        <Path
          d="M595.781 129.58L552.266 100.986H594.466L595.781 101.809V129.58Z"
          fill="url(#paint9_linear_5107_111987)"
        />
        <Path
          d="M595.782 152.083L479.703 75.7944V-32.3814L595.782 43.907V152.083Z"
          fill="url(#paint10_linear_5107_111987)"
        />
        <Path
          d="M0 777.081L99.6667 841.741H13.302L0 833.133V777.081Z"
          fill="url(#paint11_linear_5107_111987)"
        />
        <Path
          d="M0 762.064H10.5499L86.8234 811.514V840.252L0 784.006V762.064Z"
          fill="url(#paint12_linear_5107_111987)"
        />
        <Path
          d="M31.8463 681.417L37.0229 684.783V686.725L28.832 681.417H31.8463Z"
          fill="url(#paint13_linear_5107_111987)"
        />
        <Path
          d="M0 642L104.319 709.637V774.103L0 706.466V642Z"
          fill="url(#paint14_linear_5107_111987)"
        />
        <Path
          d="M170.24 768.731L178.758 774.297V777.534L165.26 768.731H170.24Z"
          fill="url(#paint15_linear_5107_111987)"
        />
        <Path
          d="M0 686.725H13.3675L169.06 787.631V846.336L0 736.757V686.725Z"
          fill="url(#paint16_linear_5107_111987)"
        />
        <Path
          d="M0 761.741L49.0143 793.521H0V761.741Z"
          fill="url(#paint17_linear_5107_111987)"
        />
        <Path
          d="M0 690.479L129.547 774.492H74.1111L0 726.466V690.479Z"
          fill="url(#paint18_linear_5107_111987)"
        />
        <Path
          d="M0 698.246L161.393 802.906H95.4729L0 740.964V698.246Z"
          fill="url(#paint19_linear_5107_111987)"
        />
        <Path
          d="M215.781 841.741L190.75 825.56V816.11L230.328 841.741H215.781Z"
          fill="url(#paint20_linear_5107_111987)"
        />
        <Path
          d="M0 732.097L37.416 756.304H1.11396L0 755.592V732.097Z"
          fill="url(#paint21_linear_5107_111987)"
        />
        <Path
          d="M0 713.003L99.7977 777.728V869.443L0 804.783V713.003Z"
          fill="url(#paint22_linear_5107_111987)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5107_111987"
          x1="233961"
          y1="157829"
          x2="317573"
          y2="212627"
        >
          <Stop offset="0" stopColor="#FFDC8A" />
          <Stop offset="1" stopColor="#FFBC1F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5107_111987"
          x1="-125088"
          y1="-83993.6"
          x2="-162466"
          y2="-108490"
        >
          <Stop offset="0.25" stopColor="#EDEDED" />
          <Stop offset="1" stopColor="#8E939C" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5107_111987"
          x1="-248964"
          y1="-200444"
          x2="-349705"
          y2="-266468"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5107_111987"
          x1="-8940.04"
          y1="-6929.37"
          x2="-9593.55"
          y2="-7357.66"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5107_111987"
          x1="-184492"
          y1="-122905"
          x2="-333095"
          y2="-220298"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5107_111987"
          x1="-299874"
          y1="-233837"
          x2="-346830"
          y2="-264611"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_5107_111987"
          x1="-124923"
          y1="-93715.6"
          x2="-192466"
          y2="-137982"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_5107_111987"
          x1="-131374"
          y1="-94728.1"
          x2="-231368"
          y2="-160262"
        >
          <Stop offset="0.25" stopColor="#EDEDED" />
          <Stop offset="1" stopColor="#8D00B7" />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_5107_111987"
          x1="-19997.8"
          y1="-13686.2"
          x2="-25394.2"
          y2="-17223.1"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear_5107_111987"
          x1="-106840"
          y1="-91270.8"
          x2="-119610"
          y2="-99640.5"
        >
          <Stop offset="0" stopColor="#FFDC8A" />
          <Stop offset="1" stopColor="#FFBC1F" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear_5107_111987"
          x1="-392243"
          y1="-241339"
          x2="-529387"
          y2="-331220"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint11_linear_5107_111987"
          x1="44933.3"
          y1="-59012.4"
          x2="-6347.83"
          y2="-93086.2"
        >
          <Stop offset="0" stopColor="#FFDC8A" />
          <Stop offset="1" stopColor="#FFBC1F" />
        </LinearGradient>
        <LinearGradient
          id="paint12_linear_5107_111987"
          x1="-12523.1"
          y1="15614.5"
          x2="10407.3"
          y2="30850.5"
        >
          <Stop offset="0.25" stopColor="#EDEDED" />
          <Stop offset="1" stopColor="#8E939C" />
        </LinearGradient>
        <LinearGradient
          id="paint13_linear_5107_111987"
          x1="6.39037"
          y1="1565.94"
          x2="152.694"
          y2="1663.15"
        >
          <Stop offset="0.25" stopColor="#EDEDED" />
          <Stop offset="1" stopColor="#8E939C" />
        </LinearGradient>
        <LinearGradient
          id="paint14_linear_5107_111987"
          x1="-26742.2"
          y1="31766.2"
          x2="35037.4"
          y2="72815.6"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint15_linear_5107_111987"
          x1="3909.25"
          y1="5317.11"
          x2="4309.31"
          y2="5582.92"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint16_linear_5107_111987"
          x1="-20350"
          y1="23143.1"
          x2="70807.1"
          y2="83712.4"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint17_linear_5107_111987"
          x1="-55905.3"
          y1="75691"
          x2="-27102.8"
          y2="94828.7"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint18_linear_5107_111987"
          x1="-11310.4"
          y1="12878.8"
          x2="30116.5"
          y2="40405.2"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint19_linear_5107_111987"
          x1="-13319.9"
          y1="15011.8"
          x2="48024.4"
          y2="55772.6"
        >
          <Stop offset="0.25" stopColor="#EDEDED" />
          <Stop offset="1" stopColor="#8D00B7" />
        </LinearGradient>
        <LinearGradient
          id="paint20_linear_5107_111987"
          x1="12479.8"
          y1="15748"
          x2="15797.1"
          y2="17952.1"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <LinearGradient
          id="paint21_linear_5107_111987"
          x1="-17507.7"
          y1="23835.1"
          x2="-9675.19"
          y2="29039.5"
        >
          <Stop offset="0" stopColor="#FFDC8A" />
          <Stop offset="1" stopColor="#FFBC1F" />
        </LinearGradient>
        <LinearGradient
          id="paint22_linear_5107_111987"
          x1="-67245.9"
          y1="88838"
          x2="16859"
          y2="144722"
        >
          <Stop offset="0" stopColor="#FF9E15" />
          <Stop offset="0.7" stopColor="#EF184B" />
        </LinearGradient>
        <ClipPath id="clip0_5107_111987">
          <Rect width="596" height="842" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </>
);

const Texture30 = {
  ViewTexture,
  PdfTexture,
};

export default Texture30;
