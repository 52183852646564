import { Box, Button, Modal, Typography } from "@mui/material";
import { ReactComponent as RemoveSectionIcon } from "../../assets/SVG/new-delete-section.svg";
import { ReactComponent as CancelModalIcon } from "../../assets/SVG/cancel-modal.svg";
import { summarSectionModalstyle } from "../../ProcessResume/EditForms/Summary";

interface IDeleteConfirmationModal {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  name: string;
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModal> = ({
  open,
  onClose,
  onConfirm,
  name,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      aria-labelledby="delete-confirmation-modal-title"
      aria-describedby="delete-confirmation-modal-description"
    >
      <Box sx={summarSectionModalstyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            pt: 3,
            pl: 3,
            pr: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "1.75rem",
            }}
          >
            <RemoveSectionIcon />
            <Typography
              sx={{
                flex: "1",
                fontSize: "24px",
                fontWeight: "500",
                color: "#344054",
              }}
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Delete Resume
            </Typography>
            <Box>
              <Box sx={{ cursor: "pointer" }} onClick={onClose}>
                <CancelModalIcon />
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{ fontSize: "18px", fontWeight: "400", color: "#475569" }}
            id="keep-mounted-modal-description"
          >
            Are you sure you want to delete this resume ?
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              padding: "0.5rem 0",
              color: "#344054",
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            gap: "1rem",
            pr: 3,
            pb: 3,
            mt: 0.5,
          }}
        >
          <Button variant="contained" onClick={onConfirm}>
            Delete
          </Button>
          <Button
            onClick={onClose}
            sx={{ border: "1px solid #cbd5e1", color: "#182230" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
