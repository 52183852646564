export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const generateInitials = (fullName: string): string => {
  const words = fullName.split(" ");
  const first = words[0][0].toUpperCase();
  const last = words[words.length - 1][0].toUpperCase();

  if (words.length === 1) {
    return first;
  }

  return `${first}${last}`;
};

export const cloneElementWithCanvas = (element: any) => {
  const clone = element.cloneNode(true);
  const originalCanvases: any = element.querySelectorAll("canvas");
  const clonedCanvases = clone.querySelectorAll("canvas");

  originalCanvases.forEach((originalCanvas: any, index: number) => {
    const clonedCanvas = clonedCanvases[index];
    if (clonedCanvas) {
      clonedCanvas.width = originalCanvas.width;
      clonedCanvas.height = originalCanvas.height;
      const context = clonedCanvas.getContext("2d");
      context.drawImage(originalCanvas, 0, 0);
    }
  });

  return clone;
};

export const convertLocaleStringToNumber = (str: string) =>
  Number(str.replace(/,/g, ""));
