import { Add, AddCircle, AddTask, ContentCopy, DoneAll, Work } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  Typography,
} from "@mui/material";
import { checkMissingKeywords, IResume } from "../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { regexTotestAddKeyWord } from "../components/common/CommonFunctions";
import { ReactComponent as RegenerateIcon } from "./../assets/SVG/referesh-icon.svg";
import { retailorProfessionalSummary } from "../services/apiService";
import { setExtractedData } from "../redux/actions";

const ImproveResumeBtn = (props: any) => {
  const {
    section,
    value,
    handleGenerateWithAI = () => {},
    addMissingSkill = () => {},
    updateDesignation = () => {},
  } = props;
  
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let extractedData = useSelector((state: any) => state.extractedData);

  const [tailoringDone, setTailoringDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recheckDone, setRecheckDone] = useState(false);
  

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(extractedData, "extractedData");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* const getMissingKeywords = useMemo(() => {
    if (!extractedData.tailoredResumeObject || section === "" || value === "")
      return [];
    const missingKeywords = checkMissingKeywords(
      value,
      section,
      extractedData.tailoredResumeObject.keywordsToAdd
    );
    console.log(missingKeywords);
    return missingKeywords;
  }, [extractedData, section, value]); */

  const handleCopy = (keyword: string) => {
    navigator.clipboard.writeText(keyword);
  };

  const open = Boolean(anchorEl);

  const tailoredSectionFeedback = extractedData?.tailoredResumeObject
    ?.tailoredResumeFeedback
    ? extractedData?.tailoredResumeObject?.tailoredResumeFeedback[section]
    : {};

  const improvableScore = extractedData?.tailoredResumeObject?.tailoredResumeFeedback && (() => {
    const feedback = extractedData?.tailoredResumeObject?.tailoredResumeFeedback[section];
    if (section === "techSkills") {
      const primaryMaxScore = feedback?.primary?.maxScore || 0;
      const primaryScore = feedback?.primary?.score || 0;
      const secondaryMaxScore = feedback?.secondary?.maxScore || 0;
      const secondaryScore = feedback?.secondary?.score || 0;
      return (primaryMaxScore - primaryScore) + (secondaryMaxScore - secondaryScore);
    }
    return feedback?.maxScore - feedback?.score;
  })();

  /* if (
    improvableScore === 0 ||
    !extractedData.tailoredResumeObject?.tailoredResumeFeedback
  )
    return null; */

  const avatarColors = [
    "#455D7A",
    "#478600",
    "#003EB1",
    "#008886",
    "#00BB38",
    "#2D64BC",
    "#CF5D3F",
    "#5382A1",
    "#05A9A1",
    "#794D9A",
    "#00A068",
    "#976500",
    "#A90593",
    "#A92605",
    "#959A00",
    "#00A1CE",
    "#7460BE",
    "#5A88FF",
  ];

  const handleRecheck = async () => {
    setLoading(true);
    let tailoredResumeData = await retailorProfessionalSummary(extractedData?.tailoredResumeObject?.jobDetails, extractedData);
    
    tailoredResumeData = {
      ...extractedData?.tailoredResumeObject,
      tailoredResumeFeedback: {
          ...extractedData?.tailoredResumeObject?.tailoredResumeFeedback,
          professionalSummary: tailoredResumeData.tailoredResumeFeedback.professionalSummary
      }
    };

    dispatch(
      setExtractedData({
        ...extractedData,
        tailoredResumeObject: tailoredResumeData,
      })
    );
    setLoading(false);
    setRecheckDone(true);
    setTailoringDone(false);
  };

  const handleAddSkill = (skill: any, section: string) => {
    const updatedData = { ...extractedData };
    /* if (!updatedData.tailoredResumeObject) {
      updatedData.tailoredResumeObject = {} as any;
    }
    if (!updatedData.tailoredResumeObject.tailoredResumeFeedback) {
      updatedData.tailoredResumeObject.tailoredResumeFeedback = {};
    }
    if (!updatedData.tailoredResumeObject.tailoredResumeFeedback[section]) {
      updatedData.tailoredResumeObject.tailoredResumeFeedback[section] = {};
    }
    if (!updatedData.tailoredResumeObject.tailoredResumeFeedback[section].primaryTechSkills) {
      updatedData.tailoredResumeObject.tailoredResumeFeedback[section].primaryTechSkills = [];
    }
    updatedData.tailoredResumeObject.tailoredResumeFeedback[section].primaryTechSkills.push(skill); */
    
    const newSkill = {
      "label": skill,
      "value": skill,
      "score": 5,
      "total": 5,
      "isGraph": false,
      "graphType": "linear"
    };

    addMissingSkill(newSkill);
    updatedData[section] = [...(updatedData[section] ?? []), newSkill];
    dispatch(setExtractedData(updatedData));
  };
  let missingTechnicalSkills = [], missingSoftSkills: any = [];
  if(section === "technicalSkills") {
    const toolsTechnicalSkills = extractedData?.toolsTechnicalSkills || [];
    const toolsTechnicalSkillsLabels = toolsTechnicalSkills.map((skill: any) => skill.label);
    missingTechnicalSkills = [...tailoredSectionFeedback?.missingPreferred, ...tailoredSectionFeedback?.missingRequired]?.filter((skill: any) => !toolsTechnicalSkillsLabels.includes(skill));
  }
  else if (section === "softSkills") {
    const nonTechnicalSkills = extractedData?.nonTechnicalSkills || [];
    const toolsTechnicalSkillsLabels = nonTechnicalSkills.map((skill: any) => skill.label);
    missingSoftSkills = [...tailoredSectionFeedback?.missingPreferred, ...tailoredSectionFeedback?.missingRequired]?.filter((skill: any) => !toolsTechnicalSkillsLabels.includes(skill));

    // const nonTechnicalSkills = extractedData?.nonTechnicalSkills || [];
    // missingSoftSkills = [...tailoredSectionFeedback?.missingPreferred, ...tailoredSectionFeedback?.missingRequired]?.filter((skill: any) => !nonTechnicalSkills.includes(skill));
  }
  debugger;
  return (
    extractedData.tailoredResumeObject &&
    Object.keys(extractedData.tailoredResumeObject)?.length > 0 && (
      <Box>
        {/* <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
          marginLeft: 2,
          backgroundColor: '#0088ff',
          borderRadius: "20%",
          cursor: 'pointer'
        }}
        onClick={handleClick}
      >
        <AddTask fontSize="inherit" sx={{ color: "#FFF" }} /> */}

        <Box sx={{ display: "inline-block", ml: 2 }}>
          {section === "professionalSummary" && tailoringDone && !recheckDone &&
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 2,
                border: "1px solid #5594ff",
                borderRadius: "100px",
                cursor: "pointer",
                color: "#5594ff",
                px: 1,
              }}
              onClick={handleRecheck}
              className="regenrate-wrapper"
            >
              {loading ? <CircularProgress size={24} /> : <RegenerateIcon />}
              <Typography className="button" variant="caption">
                Recheck
              </Typography>
            </Box>
          }
          <Badge
            badgeContent={"+" + improvableScore + "%"}
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <Typography
              sx={{
                backgroundColor: "#0088ff",
                color: "#fff",
                px: 1,
                borderRadius: "4px",
              }}
            >
              Tailor to Job Post
            </Typography>
          </Badge>
        </Box>
        {/* </Box> */}
        {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1, textAlign: "center" }}>
          <Typography variant="body1" sx={{ pb: 0.6, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                mr: 0.5,
                backgroundColor: '#0088ff',
                borderRadius: "20%",
                cursor: 'pointer'
              }}
              onClick={handleClick}
            >
              <Work fontSize="inherit" sx={{ color: "#FFF" }} />
            </Box>
            {extractedData.tailoredResumeObject.designation}
          </Typography>
          <Typography variant="body2">
            {extractedData.tailoredResumeObject.keywordsToAdd?.length - getMissingKeywords?.length}/{extractedData.tailoredResumeObject.keywordsToAdd?.length} Keyword
          </Typography>
          <Divider sx={{ py: 0.5 }} />
          <List sx={{
            height: "250px",
            width: "200px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: '1px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1'
          }}>
            {extractedData.tailoredResumeObject.keywordsToAdd?.length > 0 ? (
              extractedData.tailoredResumeObject.keywordsToAdd.filter((k: string) => k.length < 20).map((keyword: string, index: number) => (
                <ListItem key={index} sx={{p: 0}}>
                  <ListItemButton sx={{px: 0}}>
                    <Typography variant="body2">{keyword}</Typography>
                    <ListItemSecondaryAction>
                      {getMissingKeywords.includes(keyword) ? (
                        <IconButton onClick={() => handleCopy(keyword)} size="small">
                          <ContentCopy sx={{ fontSize: "14px" }} />
                        </IconButton>
                      ) : (
                        <DoneAll fontSize="small" sx={{color: "green"}} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Typography variant="body2">No missing keywords</Typography>
            )}
          </List>
        </Box>
      </Popover> */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 1.4, maxWidth: 350, textAlign: "left" }}>
            {section === "designation" ? (
              <List disablePadding>
                <ListItem
                  sx={{
                    backgroundColor: "rgb(237, 247, 237)",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgb(239, 253, 239)",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <strong>{value}</strong>
                    <Add
                      onClick={() => {
                        updateDesignation();
                        handleClose();
                      }}
                    />
                  </Typography>
                </ListItem>
              </List>
            ) : (section === "technicalSkills") ? (
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  Missing Skills
                </Typography>
                <Divider />
                <List sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {[
                    ...missingTechnicalSkills
                  ].map((skill) => (
                    <ListItem 
                      key={skill}
                      sx={{ 
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        borderRadius: 1,
                        mb: 0.5
                      }}
                      onClick={() => handleAddSkill(skill, "toolsTechnicalSkills")}
                    >
                      <ListItemText primary={skill} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" size="small">
                          <AddCircle fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : (section === "softSkills") ? (
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  Missing Skills
                </Typography>
                <Divider />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {[
                    ...missingSoftSkills
                  ].map((skill) => (
                    <List sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {[
                        ...missingSoftSkills
                      ].map((skill) => (
                        <ListItem 
                          key={skill}
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            },
                            borderRadius: 1,
                            mb: 0.5
                          }}
                          onClick={() => handleAddSkill(skill, "nonTechnicalSkills")}
                        >
                          <ListItemText primary={skill} />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" size="small">
                              <AddCircle fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  ))}
                </Box>
              </Box>
            ) : (
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  Improvement
                </Typography>
                <Divider />
                {tailoredSectionFeedback?.suggestionsForImprovement ? (
                  <List disablePadding>
                    <Box sx={{ fontSize: "1em", color: "rgb(120, 120, 120)" }}>
                      <Typography variant="body2" sx={{ mb: 0.5, color: "#000" }}>Missing Skills/Keywords</Typography>
                      {[...tailoredSectionFeedback?.missingRequiredSkills, ...tailoredSectionFeedback?.missingPreferredSkills].map((skill: string) => 
                        <Chip
                          key={skill}
                          label={skill}
                          sx={{ mr: 1, mb: 1 }}
                        />
                      )}
                    </Box>
                    {tailoredSectionFeedback?.matchingSkills.length > 0 &&
                      <Box sx={{ fontSize: "1em", color: "rgb(120, 120, 120)" }}>
                        <Typography variant="body2" sx={{ mb: 0.5, color: "#000" }}>Matching Skills</Typography>
                        {tailoredSectionFeedback?.matchingSkills.map((skill: string) => 
                          <Chip
                            key={skill}
                            label={skill}
                            sx={{ mr: 1, mb: 1 }}
                          />
                        )}
                      </Box>
                    }
                    {tailoredSectionFeedback.suggestionsForImprovement?.length === 0 ? (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        No improvement info.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Suggestions for Improvement:
                        </Typography>
                        <List sx={{ pl: 2, mt: 1, backgroundColor: "rgb(253, 237, 237)" }}>
                          {tailoredSectionFeedback.suggestionsForImprovement.filter((s: any, i: number) => i < 3).map((suggestion: string, index: number) => (
                            <ListItem key={index} sx={{ py: 0.5 }}>
                              <ListItemIcon sx={{ minWidth: '24px' }}>
                                <AddCircle sx={{ fontSize: 8 }} />
                              </ListItemIcon>
                              <ListItemText 
                                primary={suggestion} 
                                sx={{ 
                                  '& .MuiListItemText-primary': { 
                                    fontSize: '0.875rem'
                                  } 
                                }} 
                              />
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                    {tailoredSectionFeedback.strengths?.length === 0 ? (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Sterengths not available.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          What is Good:
                        </Typography>
                        <List sx={{ pl: 2, mt: 1, backgroundColor: "rgb(237, 253, 248)" }}>
                          {tailoredSectionFeedback.strengths.map((suggestion: string, index: number) => (
                            <ListItem key={index} sx={{ py: 0.5 }}>
                              <ListItemIcon sx={{ minWidth: '24px' }}>
                                <AddCircle sx={{ fontSize: 8 }} />
                              </ListItemIcon>
                              <ListItemText 
                                primary={suggestion} 
                                sx={{ 
                                  '& .MuiListItemText-primary': { 
                                    fontSize: '0.875rem',
                                  } 
                                }} 
                              />
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                    <ListItem sx={{ justifyContent: "center" }}>
                      {section === "toolsTechnicalSkills" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Add Missing Skills
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleGenerateWithAI();
                          }}
                        >
                          Generate with AI
                        </Button>
                      )}
                    </ListItem>
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Improvement notes not available.
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Popover>
      </Box>
    )
  );
};

export default ImproveResumeBtn;
