import Layout from "../Layout";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Illustration_4 } from "../Icons";
import { Close, Search } from "@mui/icons-material";
import { useState } from "react";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import ResultsList from "./ResultsList";
import ProcessingPopup from "./ProcessingPopup";
import { IResume, removeProperties, WorkExperienceTypes } from "../utils";
import * as ATSCheckScreen from "./../../assets/lottie/ATSCheckScreen.json";
import Loading from "../Loading/Loading";
import { setExtractedData } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { getUsernameFromToken } from "../../services/getUserFromToken";
import {
  extractFaceFromPDF,
  extractText,
  fetchResumeById,
  generateContent,
  withAuthHandling,
} from "../../services/apiService";
import { useNavigate } from "react-router-dom";

const ATSChecker = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [popupDetails, setPopupDetails] = useState({
    open: false,
    title: "",
    subTitle: "",
    progress: 0,
    page: 0,
    resumeObject: {} as IResume,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchWithAuthHandling = withAuthHandling(navigate);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFileSelect = async (files: any) => {
    setPopupDetails((prevState) => ({
      ...prevState,
      open: true,
      title: "Uploading Resume...",
      subTitle: "Please wait, it might take few mins to upload.",
      progress: 20,
      page: 1,
    }));
    if (files?.length) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      console.log(process.env.REACT_APP_API_URL, "from API");

      try {
        const { textContent } = await extractText(
          formData,
          fetchWithAuthHandling,
        );
        const resumeObj = await generateContent(
          textContent,
          fetchWithAuthHandling,
          dispatch,
        );

        /**
         * Converting pdf to image
         */
        const reader: any = new FileReader();
        reader.onloadend = async () => {
          const base64string = reader.result.split(",")[1];
          const extractedFace = await extractFaceFromPDF(base64string);
          if (extractedFace) {
            resumeObj.profilePicture = extractedFace;
          }
        };
        reader.readAsDataURL(file);
        console.log("resumeObj", resumeObj);

        // Formatting resume object for projects section
        let formattedResumeObject = resumeObj;
        if (resumeObj?.projects?.[0]) {
          const formattedProjects = resumeObj?.projects?.map((proj: any) => ({
            ...proj,
            organization: proj?.title,
            courseDescription: proj?.description,
          }));
          formattedResumeObject = {
            ...formattedResumeObject,
            projects: formattedProjects,
          };
        }
        if (resumeObj?.workExperiences?.[0]) {
          const formattedExperience: WorkExperienceTypes[] =
            resumeObj?.workExperiences?.map((exp: WorkExperienceTypes) => ({
              ...exp,
              jobType: exp?.jobType ?? "fullTime",
              location: exp?.location ?? "",
            }));
          formattedResumeObject = {
            ...formattedResumeObject,
            workExperiences: formattedExperience,
          };
        }

        setPopupDetails((prevState) => ({
          ...prevState,
          open: true,
          title: "Resume Uploaded Successfully",
          subTitle:
            "In the next step, you can enter the job details to tailor this resume.",
          progress: 100,
          page: 1,
          resumeObject: formattedResumeObject,
        }));
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const itemClickHandler = async (resumeObject: any) => {
    setPopupDetails((prevState) => ({
      ...prevState,
      open: true,
      title: "Adding Resume...",
      subTitle: "Please wait, it might take few mins to analyze.",
      progress: 20,
      page: 1,
    }));

    const resume = await fetchResumeById(
      resumeObject.id,
      fetchWithAuthHandling,
      dispatch,
    );

    const cleanedResume = removeProperties(resume, [
      "profilePicture",
      "screenshot",
    ]);
    setPopupDetails((prevState) => ({
      ...prevState,
      open: true,
      title: "Resume Added Successfully",
      subTitle:
        "In the next step, you can enter the job details to tailor this resume.",
      progress: 100,
      page: 2,
      resumeObject: cleanedResume,
    }));
  };

  const username = getUsernameFromToken();

  return (
    <Layout sx={{ m: 2 }} title={`ATS Checker`} isHandSymbol={false}>
      <Grid container direction="column" spacing={2} /*  sx={{ my: 4 }} */>
        <Grid item>
          <Alert
            severity="info"
            icon={<Illustration_4 />}
            sx={{
              background: "#EBF2FF",
              borderRadius: "12px",
              "& .MuiAlert-icon": {
                padding: 0,
              },
              "& .MuiAlert-message": {
                fontSize: "18px",
                fontWeight: "bold",
                color: "#162664",
              },
            }}
          >
            Build a ATS Friendly resume today - All the major employers use ATS
            scores today to auto filter resumes with relevance.
          </Alert>
        </Grid>
        <Grid item className="top-heading-container">
          <Box sx={{ mb: 1, mt: 1, textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold" }}
              className="heading"
            >
              Pick a Resume to verify ATS Score
            </Typography>
            <Typography variant="h6" color="#5A697E" className="sub-heding">
              We can begin in a few ways: pick the one that works best for you.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "1.3rem", fontWeight: "600" }}>
              Select Resume
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ResultsList
        onFileUpload={handleFileSelect}
        itemClickHandler={itemClickHandler}
      />
      <ProcessingPopup
        open={popupDetails.open}
        onClose={() =>
          setPopupDetails((prevState) => ({ ...prevState, open: false }))
        }
        popupDetails={popupDetails}
        setPopupDetails={setPopupDetails}
      />
      {popupDetails.page === 3 && popupDetails.progress < 100 && (
        <Loading
          title={"Analyzing Your Resume for ATS Score"}
          subTitle={"Ensuring your resume is optimized and ready for ATS."}
          lottieContent={ATSCheckScreen}
        />
      )}
    </Layout>
  );
};

export default ATSChecker;
