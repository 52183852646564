import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactComponent as TabElement2Image } from "../assets/SVG/section4Image.svg";

const Section4 = () => {
  return (
    <Box className="section-4-wrapper">
      <Box className="left">
        <Box className="heading-wrapper">
          <Typography
            component="h3"
            variant="h4"
            className="heading"
            gutterBottom
          >
            Eye-catching <br /> resume Templates{" "}
          </Typography>
          <Typography className="sub-heading" gutterBottom>
            Impress Employers and recruters with our 25+ elegant,
            professionally-designed resume templates. Download in word or PDF.
          </Typography>
          <Box className="button-container">
            <Button className="learn-more">Learn More</Button>
            <Button className="get-started">Get started</Button>
          </Box>
        </Box>
      </Box>
      <Box className="right">
        <TabElement2Image />
      </Box>
    </Box>
  );
};

export default Section4;
