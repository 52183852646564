import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideSnackbar } from "../redux/actions";
import { StateProps } from "../redux/reducer";
import CustomizedAlert from "./CustomizedAlert";
import { capitalizeFirstLetter } from "./utils";

const SnackBar: React.FC = () => {
  const dispatch = useDispatch();
  const snackbarState = useSelector((state: StateProps) => state.snackbarState);

  const handleCloseSnackbar = () => {
    dispatch(hideSnackbar());
  };

  return (
    <CustomizedAlert
      alertState={snackbarState.open}
      title={capitalizeFirstLetter(snackbarState?.severity) ?? "Alert"}
      description={snackbarState.message}
      severity={snackbarState.severity as any}
      handleCloseSnackbar={handleCloseSnackbar}
    />
  );
};

export default SnackBar;
