import { IJobDetail } from "./job-types";

export const dummyJobs: IJobDetail[] = [
  {
    jobTitle: "UX Designer",
    companyName: "Master Card",
    companyLogo: "/img/job-board/company-logo.svg",
    city: "Paris",
    country: "France",
    jobType: "Internship",
    salary: "$65k - $75k",
    experience: "4+ years",
    posted: "2h ago",
    skills: [
      {
        technology: "Figma",
        logo: "/images/svg/technology-f",
      },
    ],
  },
  {
    jobTitle: "UX Designer",
    companyName: "Master Card",
    companyLogo: "/img/job-board/company-logo.svg",
    city: "Paris",
    country: "France",
    jobType: "Internship",
    salary: "$65k - $75k",
    experience: "4+ years",
    posted: "2h ago",
    skills: [
      {
        technology: "Figma",
        logo: "/images/svg/technology-f",
      },
    ],
  },
  {
    jobTitle: "UX Designer",
    companyName: "Master Card",
    companyLogo: "/img/job-board/company-logo.svg",
    city: "Paris",
    country: "France",
    jobType: "Internship",
    salary: "$65k - $75k",
    experience: "4+ years",
    posted: "2h ago",
    skills: [
      {
        technology: "Figma",
        logo: "/images/svg/technology-f",
      },
    ],
  },
  {
    jobTitle: "UX Designer",
    companyName: "Master Card",
    companyLogo: "/img/job-board/company-logo.svg",
    city: "Paris",
    country: "France",
    jobType: "Internship",
    salary: "$65k - $75k",
    experience: "4+ years",
    posted: "2h ago",
    skills: [
      {
        technology: "Figma",
        logo: "/images/svg/technology-f",
      },
    ],
  },
];
