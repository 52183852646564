import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIconsSolid from "@fortawesome/free-solid-svg-icons";
import * as faBrandIcons from "@fortawesome/free-brands-svg-icons";
import { Button, IconButton, Popover, TextField } from "@mui/material";
import { ReactComponent as PersonalDetailEditIcon } from "../../assets/SVG/personal-edit-icon.svg";

interface IIconPickerProps {
  selected?: faIconsSolid.IconDefinition | any;
  defaultSelected?: faIconsSolid.IconDefinition | any;
  handleChange: (icon: any) => void;
  tabData?: any[];
  showArrowIconsFirst?: boolean;
}

const DEFAULT_TAB_ID = "__icons__";

const faIcons = {
  ...faIconsSolid,
  ...faBrandIcons,
  // ...mappedRegularIcons,
};

const ignoreIcons = [
  faIcons.faFontAwesomeLogoFull,
  faIcons.fas,
  faIcons.prefix,
].map((i: any) => i.iconName);

const priorityIcons = [
  faIcons.faSquare,
  faIcons.faCircle,
  faIcons.faHeart,
  faIcons.faEgg,
  faIcons.faStar,
  faIcons.faCoffee,
  faIcons.faCertificate,
  faIcons.faHamburger,
  faIcons.faAppleAlt,
  faIcons.faDove,
  faIcons.faCat,
  faIcons.faFemale,
  faIcons.faMale,
  faIcons.faTrophy,
  faIcons.faCloud,
  faIcons.faPizzaSlice,
  faIcons.faIceCream,
  faIcons.faThumbsUp,
  faIcons.faFootballBall,
  faIcons.faFlag,
  faIcons.faGrinStars,
  faIcons.faSmile,
  faIcons.faFrown,
  faIcons.faMeh,
  faIcons.faAngry,
];

const priorityIconArrows = [
  faIcons.faArrowUp,
  faIcons.faArrowDown,
  faIcons.faArrowRight,
  faIcons.faArrowLeft,
  faIcons.faCircleArrowUp,
  faIcons.faCircleArrowDown,
  faIcons.faCircleArrowRight,
  faIcons.faCircleArrowLeft,
  faIcons.faArrowTrendUp,
  faIcons.faArrowTurnUp,
  faIcons.faArrowTrendDown,
  faIcons.faArrowTurnDown,
];

const getIconSpanElementClass = (
  defaultSelected: any,
  selected: any,
  selectedView: any,
  icon: any
) => {
  return defaultSelected &&
    selected &&
    (defaultSelected as any).iconName === icon.iconName &&
    (selected as any).iconName === icon.iconName
    ? "selected"
    : defaultSelected && (defaultSelected as any).iconName === icon.iconName
    ? "thin-selected"
    : (selected as any).iconName === icon.iconName
    ? "selected"
    : "";
};

const IconPicker: React.FC<IIconPickerProps> = (props) => {
  const {
    selected,
    defaultSelected = {},
    handleChange,
    tabData = [],
    showArrowIconsFirst = false,
  } = props;

  if (showArrowIconsFirst) {
    priorityIcons.unshift(...priorityIconArrows);
  }

  const allIcons = Object.entries(faIcons)
    .map((el: any) => ({ ...el[1], iconActualName: el[0] }))
    .filter((i: any) => !ignoreIcons.includes(i.iconName));
  const [filteredList, setFilteredList] = React.useState(allIcons);
  const [iconSearch, setIconSearch] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    setFilteredList(() => {
      const dups: any = [];
      return allIcons.filter((i: any) => {
        if (dups.includes(i.iconName)) return false;
        dups.push(i.iconName);
        return i.iconName.toLowerCase().includes(iconSearch);
      });
    });
  }, [iconSearch]);

  React.useEffect(() => {
    setCurrentPage(0);
  }, [filteredList]);

  const handleTabSelection = (tab: any) => {
    setSelectedView(tab);
  };

  const [selectedView, setSelectedView] = React.useState(DEFAULT_TAB_ID);

  const iconsList = filteredList;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "icon-picker-popover-modal" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <FontAwesomeIcon
          color="rgba(102, 112, 133, 1)"
          icon={selected}
          style={{ fontSize: 16 }}
        />
      </IconButton>
      <div className="editable-headingicon-input-icon">
        <PersonalDetailEditIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{
          borderRadius: "10px",
        }}
      >
        <div className="icon-picker-modal">
          <TextField
            variant="outlined"
            id="iconSearchInput"
            name="iconSearchInput"
            placeholder="Search Icons"
            value={iconSearch}
            onChange={(e) => setIconSearch(e.target.value)}
            fullWidth
          />
          <div
            style={{
              paddingTop: "1px",
              marginTop: "10px",
              ...(tabData.length > 0
                ? { height: "250px", width: "375px" }
                : { height: "240px", width: "375px" }),
            }}
          >
            {tabData.length > 0 && (
              <div className="uicomp-faicon-category">
                <div
                  className={`uicomp-faicon-category-title ${
                    selectedView === DEFAULT_TAB_ID ? "selected" : ""
                  }`}
                  onClick={() => handleTabSelection(DEFAULT_TAB_ID)}
                >
                  {" "}
                  Icons{" "}
                </div>
                {tabData.map((tab) => {
                  return (
                    <div
                      className={`uicomp-faicon-category-title ${
                        selectedView === tab.tabName ? "selected" : ""
                      }`}
                      onClick={() => handleTabSelection(tab.tabName)}
                    >
                      {tab.tabName}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="uicomp-faicon-flat">
              <div
                className={`uicomp-faicon-flat-list ${
                  iconsList.length === 0 && "empty-list"
                }`}
              >
                {iconsList.length === 0 && <span>No Icons Found</span>}
                {iconsList.map((icon: any) => {
                  return (
                    <span
                      className={`uicomp-faicon-flat-list-singleicon ${getIconSpanElementClass(
                        defaultSelected,
                        selected,
                        selectedView,
                        icon
                      )}`}
                      onClick={() => {
                        if (selectedView === DEFAULT_TAB_ID) {
                          const details = { ...icon };

                          handleChange(details);
                        } else {
                          const details = {
                            iconName: icon.iconName,
                          };
                          handleChange(details);
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        title={icon.iconName}
                        color="rgba(102, 112, 133, 1)"
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default IconPicker;
