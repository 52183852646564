import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Divider,
  Button,
  IconButton,
} from "@mui/material";

import { ReactComponent as JobBoardIllustration } from "../../assets/SVG/job-board-illustration.svg";
import Layout from "../Layout";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { Search } from "@mui/icons-material";
import JobBoardFilters from "./JobBoardFilters";
import JobBoardContent from "./JobBoardContent";

type Job = {
  slug: string;
  company_name: string;
  title: string;
  description: string;
  remote: boolean;
  url: string;
  tags: string[];
  job_types: string[];
  location: string;
  created_at: number;
};

const JobBoard: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0]);
    }
  }, [jobs]);

  useEffect(() => {
    filterJobs();
  }, [searchTerm, jobs]);

  const fetchJobs = async (search = "") => {
    const response = await fetch(`https://www.arbeitnow.com/api/job-board-api`);
    const data = await response.json();
    setJobs(data.data);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterJobs = () => {
    const filtered = jobs.filter(
      (job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredJobs(filtered);
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
  };

  const handleApplyClick = () => {};

  const handleSaveJobClick = () => {
    // Implement save job functionality here
    alert("Job saved!");
  };

  return (
    <Layout
      sx={{ p: 2, backgroundColor: "#F8F9FD", flexGrow: 1 }}
      title="Job Board"
    >
      <Box
        className="page-banner"
        sx={{
          background: "linear-gradient(180deg, #DAE8FF 0%, #FAFCFF 100%)",
          padding: "1.5rem",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <Typography variant="h4" sx={{ fontWeight: "500", color: "#002E7C" }}>
            Your Skills, Your Perfect Job!
          </Typography>
          <Typography variant="body1" sx={{ color: "#002E7C" }}>
            Searching for opportunities? Explore our latest job listings and
            apply to your perfect role today!
          </Typography>
        </Box>

        <Box>
          <JobBoardIllustration />
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <TextField
          placeholder="Search Jobs"
          variant="outlined"
          fullWidth
          sx={AuthInputFieldStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", gap: "1rem" }}>
        <JobBoardFilters />
        <JobBoardContent />
      </Box>
    </Layout>
  );
};

export default JobBoard;
