import { Box, Typography } from "@mui/material";
import { SecondaryButton } from "../../button/Buttons";
import { ReactComponent as TwoFactorAuthIcon } from "../../../assets/SVG/two-factor-authentication-icon.svg";

const TwoStepVerification = () => {
  return (
    <Box
      sx={{
        mt: 10,
        ml: 7,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TwoFactorAuthIcon />
        <Typography>Verification method</Typography>
        <Typography>We use this to verify your account.</Typography>
        <SecondaryButton variant="outlined" color="primary">
          Add New
        </SecondaryButton>
      </Box>
    </Box>
  );
};

export default TwoStepVerification;
