import { Box, Divider, List, ListItem, TextField } from "@mui/material";
import { ReactComponent as TeknoLogo } from "../assets/SVG/tekno-log.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { InputFieldCustomeStyle } from "../ProcessResume/EditForms/ProcessResumeUtils";

const optionList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Feature",
    link: "/",
  },
  {
    name: "Help",
    link: "/",
  },
  {
    name: "Privacy",
    link: "/",
  },
];

const LandingFooter = () => {
  return (
    <Box className="footer-wrapper">
      <Box className="footer-content">
        <Box className="section-1">
          <Box className="logo">
            <TeknoLogo />
          </Box>
          <Box className="options-wrapper">
            <List className="list">
              {optionList?.map((ele) => (
                <ListItem className="list-item">{ele?.name}</ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box className="section-2">
          <Box className="form-wrapper">
            <TextField
              required
              fullWidth
              name="email"
              type="email"
              id="email"
              placeholder="Enter your email"
              sx={{ ...InputFieldCustomeStyle, width: "22rem" }}
              inputProps={{
                sx: {
                  height: "1rem",
                },
              }}
            />
            <Button variant="contained" className="subscribe-button">
              Subscribe
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider></Divider>

      <Box className="rights">
        <Box className="rights-text">
          <Typography>@2024 Tekno. All rights reserved</Typography>
        </Box>
        <Box className="terms-conditions">
          <List className="terms-list">
            <ListItem>Terms</ListItem>
            <ListItem>Privacy</ListItem>
            <ListItem>Cookies</ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingFooter;
