import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import {
  setExtractedData,
  setIsTemplateChanged,
  setSelectedTemplateId,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { TemplateCategory, Templates } from "../utils";
import { ArrowBack } from "@mui/icons-material";
import { StateProps } from "../../redux/reducer";
import { SecondaryButton } from "../button/Buttons";
import { ReactComponent as TickIcon } from "./../../assets/SVG/tick-icon.svg";
import { withAuthHandling } from "../../services/apiService";

const TemplatesList = () => {
  const navigate = useNavigate();
  const { extractedData, selectedTemplateId, previewResumeScreenshot } =
    useSelector((state: StateProps) => state);
  const defaultSeletedTemplate = useRef<null | string>(null);
  const fetchWithAuthHandling = withAuthHandling(navigate);

  const [activeChips, setActiveChips] = React.useState<string[]>([
    TemplateCategory.All,
  ]);

  const [activeTemplate, setActiveTemplate] = React.useState<string>(
    selectedTemplateId || Templates[0].id
  );

  const dispatch = useDispatch();

  const handleChipClick = (label: string) => {
    setActiveChips([label]);
  };

  useEffect(() => {
    if (activeTemplate) {
      defaultSeletedTemplate.current = activeTemplate;
    }
  }, []);

  useEffect(() => {
    const activeTemplateDetails = Templates.find(
      (template) => template.id === activeTemplate
    );
    dispatch(setSelectedTemplateId(activeTemplate));
    if (activeTemplateDetails?.metaInformation) {
      dispatch(
        setExtractedData({
          ...extractedData,
          metaInformation: {
            ...extractedData.metaInformation,
            ...activeTemplateDetails.metaInformation,
          },
        })
      );
    }
  }, [activeTemplate]);

  const handleBackToEdit = () => {
    if (activeTemplate !== defaultSeletedTemplate?.current) {
      dispatch(setIsTemplateChanged(true));
    }
    navigate(-1);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h5"
          color="color.primary"
          gutterBottom
          sx={{ fontWeight: 600 }}
        >
          Choose Template
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            onClick={handleBackToEdit}
            startIcon={<ArrowBack />}
          >
            Back to Edit
          </Button>
        </Box>
      </Box>
      {/* <Box sx={{padding:"1rem 0"}} >
                <Stack direction="row" spacing={1} sx={{paddingBottom:"1rem"}}>
                    {Object.values(TemplateCategory).map((label) => (
                        <Chip label={label} color={activeChips.includes(label) ? "primary" : "default"} onClick={() => handleChipClick(label)} variant={activeChips.includes(label) ? 'filled' : 'outlined'} className="template-category-chip"/>
                    ))}
                </Stack>
            </Box> */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 250px )",
          gap: 2,
          mt: 2.5,
        }}
      >
        {/* minmax(240px, 1fr) */}
        {Templates.filter((template) => {
          if (activeChips?.[0] === "All") {
            return true;
          } else {
            return template.tag === activeChips?.[0];
          }
        }).map((template, index) => (
          <Box
            onClick={() => setActiveTemplate(template.id)}
            className={`template-preview-box ${
              activeTemplate === template.id ? "active" : ""
            }`}
            sx={{
              border: 1,
              borderRadius: 2,
              height: "340px",
              borderColor: "grey.400",
              cursor: "pointer",
              // maxWidth:"250px",
            }}
          >
            {activeTemplate === template.id && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <TickIcon />
              </Box>
            )}
            {template.img && (
              <img src={template.img} className="template-preview-img" />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TemplatesList;
