import { Typography, TextField, IconButton } from "@mui/material";
import IconPicker from "../../components/IconPicker/IconPicker";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../redux/reducer";
import { setExtractedData } from "../../redux/actions";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import * as allIcons from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ReactComponent as PersonalDetailEditIcon } from "../../assets/SVG/personal-edit-icon.svg";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  labelKey: string;
  iconKey: string;
  defaultLabel: string;
  defaultIcon: string;
}

const EditHeader = (props: IProps) => {
  const {
    labelKey,
    iconKey,
    defaultLabel = "Label",
    defaultIcon = "faBriefcase",
  } = props;
  const { extractedData, priorToggle } = useSelector(
    (state: StateProps) => state
  );
  const dispatch = useDispatch();
  
  const [isEditingName, setEditingName] = useState<boolean>(false);
  
  const metaInformation: any = extractedData?.metaInformation ?? {};

  const label = metaInformation[labelKey] ?? defaultLabel;
  const icon = metaInformation[iconKey] ?? defaultIcon;

  const [updatedName, setUpdatedName] = useState<string>(label);

  const handleLabelChange = (value: string, key: string) => {
    dispatch(
      setExtractedData({
        ...extractedData,
        metaInformation: { ...metaInformation, [key]: value },
      })
    );
  };
  return (
    <div className={`editable-heading-container ${isEditingName && "move-up"}`}>
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <div className="editable-headingicon-input">
          <IconPicker
            handleChange={(icon) =>
              handleLabelChange(icon.iconActualName, iconKey)
            }
            selected={(allIcons as any)[icon]}
          />
        </div>
        {!isEditingName && (
          <div className="editable-heading-input">
            <Typography variant="h6" color="color.primary" className="heading">
              {label}
            </Typography>
            <div
              className="editable-heading-input-icon"
              onClick={() => setEditingName(true)}
            >
              <PersonalDetailEditIcon />
            </div>
          </div>
        )}
        {isEditingName && (
          <>
            <TextField
              onChange={(e) => setUpdatedName(e.target.value)}
              value={updatedName}
              size="medium"
              className="editable-heading-inputfield"
            />
            <CloseIcon
              sx={{ color: "#EF4444", cursor: "pointer" }}
              onClick={() => {
                setEditingName(false);
                setUpdatedName(label);
              }}
            />
            <IconButton
              disabled={!updatedName}
              sx={{
                "&:hover": {
                  background: "none",
                },
                cursor: !updatedName ? "not-allowed" : "pointer",
              }}
            >
              <DoneIcon
                sx={{
                  color: updatedName ? "#19A44C" : "lightgray",
                }}
                onClick={() => {
                  handleLabelChange(updatedName, labelKey);
                  setEditingName(false);
                }}
              />
            </IconButton>
          </>
        )}
        {/* {isEditingName && (
          <TextField
            variant="standard"
            id="workExpLabel"
            name="workExpLabel"
            placeholder="Work Experience"
            value={metaInformation?.label}
            onChange={(e) => handleLabelChange(e.target.value, "label")}
            onBlur={() => setEditingName(false)}
          />
        )} */}
      </div>
    </div>
  );
};

export default EditHeader;
