import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { CourseType, IReference } from "../../components/utils";
import * as allIcons from "@fortawesome/free-solid-svg-icons";
import PdfFontAwesome from "../../components/PdfFontAwesome/PdfFontAwesome";

const HobbiesTemplate = ({
  extractedData,
  isATSPreview,
  styleVariables: { fontFamily, relativeFontSize },
  SectionHeader,
  SectionBody,
}: {
  extractedData: any;
  isATSPreview: boolean;
  styleVariables: any;
  SectionHeader?: any;
  SectionBody?: any;
}) => {
  const BodyContainer = SectionBody || ((props: any) => <>{props.children}</>);

  const styles = StyleSheet.create({
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 5,
    },
    mt20: {
      marginTop: 20,
    },
    mb0: {
      marginBottom: 0,
    },
    mb10: {
      marginBottom: 10,
    },
    sectionHeader: {
      fontSize: 16 + relativeFontSize,
      color: extractedData?.metaInformation?.primaryColor || "rgb(78, 96, 113)",
      fontFamily: fontFamily || "Aptos Bold",
      fontWeight: "bold",
      marginBottom: 10,
    },
    sectionSubHeader: {
      fontSize: 16 + relativeFontSize,
    },
    sectionContent: {
      fontSize: 12 + relativeFontSize,
      lineHeight: 1.5,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    flexWrap: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      width: "140px",
    },
    iconTextVertical: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 5,
      width: "70px",
      marginBottom: 10,
    },
  });

  const showHobbiesIcon = extractedData?.metaInformation?.showHobbiesIcon;

  const iconMapper = {
    Reading: allIcons.faBook,
    Writing: allIcons.faPen,
    Traveling: allIcons.faPlane,
    Cooking: allIcons.faUtensils,
    Gardening: allIcons.faSeedling,
    Photography: allIcons.faCamera,
    Painting: allIcons.faPalette,
    Drawing: allIcons.faPencilRuler,
    Music: allIcons.faMusic,
    Dancing: allIcons.faRunning,
    Hiking: allIcons.faMountain,
    Fishing: allIcons.faFish,
    Camping: allIcons.faCampground,
    Cycling: allIcons.faBicycle,
    Running: allIcons.faRunning,
    Swimming: allIcons.faSwimmer,
    Yoga: allIcons.faOm,
    Meditation: allIcons.faBrain,
    "Board Games": allIcons.faChess,
    Puzzles: allIcons.faPuzzlePiece,
    Crocheting: allIcons.faBacon,
    Sewing: allIcons.faBacon,
    Crafting: allIcons.faPaintBrush,
    Woodworking: allIcons.faHammer,
    "DIY Projects": allIcons.faTools,
    "Model Building": allIcons.faCubes,
    "Bird Watching": allIcons.faCrow,
    Stargazing: allIcons.faStar,
    Volunteering: allIcons.faHandHoldingHeart,
    Blogging: allIcons.faBlog,
    Vlogging: allIcons.faVideo,
    Podcasting: allIcons.faMicrophone,
    Collecting: allIcons.faBoxes,
    Antiquing: allIcons.faHourglass,
    Genealogy: allIcons.faTree,
    Astronomy: allIcons.faSun,
    "Science Experiments": allIcons.faFlask,
    "Home Brewing": allIcons.faBeer,
    "Wine Tasting": allIcons.faWineGlass,
    "Cooking Classes": allIcons.faChalkboardTeacher,
    "Language Learning": allIcons.faLanguage,
    "Martial Arts": allIcons.faHandRock,
    "Rock Climbing": allIcons.faMountain,
    Skydiving: allIcons.faPlane,
    "Scuba Diving": allIcons.faWater,
    Surfing: allIcons.faWater,
    Skiing: allIcons.faSkiing,
    Snowboarding: allIcons.faSnowboarding,
    Tennis: allIcons.faGolfBall,
    Golf: allIcons.faGolfBall,
    Basketball: allIcons.faBasketballBall,
    Baseball: allIcons.faBaseballBall,
    Football: allIcons.faFootballBall,
    Badminton: allIcons.faShuffle,
    "Table Tennis": allIcons.faTableTennis,
    Fencing: allIcons.faFutbol,
    Archery: allIcons.faBullseye,
    "Horseback Riding": allIcons.faHorse,
  };

  // Helper to split hobbies into rows of 2
  const chunkArray = (arr: string[], size: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  return (
    <>
      {extractedData?.hobbies && extractedData?.hobbies?.length > 0 && (
        <>
          {SectionHeader ? (
            <SectionHeader>HOBBIES</SectionHeader>
          ) : (
            <Text style={styles.sectionHeader}>HOBBIES</Text>
          )}
          {!showHobbiesIcon &&
            extractedData?.hobbies?.map((hob: string) => (
              <View>
                <Text style={styles.sectionContent}>{hob}</Text>
              </View>
            ))}

          <BodyContainer>
            {showHobbiesIcon && (
              <View style={styles.flexWrap}>
                {extractedData?.hobbies?.map((hob: string) => (
                  <View style={styles.iconTextVertical}>
                    <PdfFontAwesome
                      faIcon={(iconMapper as any)[hob] || allIcons.faSmile}
                      style={{ width: "35px", height: "40px" }}
                    />
                    <Text style={styles.sectionContent}>{hob}</Text>
                  </View>
                ))}
              </View>
            )}
          </BodyContainer>
        </>
      )}
    </>
  );
};

export default HobbiesTemplate;
