import React, { Component, ErrorInfo, ReactNode } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import NotFoundError404 from "./components/ErrorPages/NotFoundError404";

interface Props {
  children: ReactNode;
  location?: any;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location && this.state.hasError) {
      this.setState({ hasError: false, error: undefined });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  handleReset = () => {
    this.setState({ hasError: false, error: undefined });
  };

  render() {
    if (this.state.hasError) {
      return (
        <NotFoundError404 />
        // <Container maxWidth="sm">
        //   <Box
        //     sx={{
        //       display: "flex",
        //       flexDirection: "column",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       minHeight: "80vh",
        //       textAlign: "center",
        //       gap: 3,
        //     }}
        //   >
        //     <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main" }} />

        //     <Typography variant="h4" component="h1" gutterBottom>
        //       Oops! Something went wrong
        //     </Typography>

        //     <Typography variant="body1" color="text.secondary" paragraph>
        //       We're sorry for the inconvenience. Please try again or contact
        //       support if the problem persists.
        //     </Typography>

        //     {process.env.NODE_ENV === "development" && this.state.error && (
        //       <Box sx={{ mt: 2, p: 2, bgcolor: "grey.100", borderRadius: 1 }}>
        //         <Typography variant="body2" color="error" component="pre">
        //           {this.state.error.toString()}
        //         </Typography>
        //       </Box>
        //     )}

        //     <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         onClick={() => (window.location.href = "/")}
        //       >
        //         Go to Homepage
        //       </Button>
        //       <Button variant="outlined" onClick={this.handleReset}>
        //         Try Again
        //       </Button>
        //     </Box>
        //   </Box>
        // </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
