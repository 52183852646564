import { Box } from "@mui/material";
import React, { useState } from "react";
import { StyledTab, StyledTabs } from "../StyledComponents/StyledTabs";
import TabPanel from "../StyledComponents/TabPanel";
import JobCard from "./JobCard";
import { dummyJobs } from "./dummy-data";

const JobBoardContent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledTabs
        sx={{
          width: "100%",
          ".MuiTabs-flexContainer": {
            height: "30px",
          },
        }}
        value={activeTab}
        onChange={handleActiveTab}
      >
        <StyledTab sx={{ pt: 0 }} label="Recommended" />
        <StyledTab sx={{ pt: 0 }} label="All Jobs" />
        <StyledTab sx={{ pt: 0 }} label="Saved Jobs" />
        <StyledTab sx={{ pt: 0 }} label="Applied" />
      </StyledTabs>

      <TabPanel value={activeTab} index={0}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "20px", my: 2 }}
        >
          {dummyJobs.map((job, idx) => (
            <JobCard key={idx} job={job} />
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={activeTab} index={1}></TabPanel>
    </Box>
  );
};

export default JobBoardContent;
